import React from "react";
import classnames from 'classnames';

import withStyles from "@material-ui/core/styles/withStyles";

import {Dropdown} from "components/Prosemirror/icons";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {BUTTON, ACTIVE} from "components/Prosemirror/menu/components/styles";
import MenuPopout from 'components/Prosemirror/menu/components/MenuPopout';

const styles = theme => ({
  BUTTON,
  ACTIVE,
  root: {
    position: 'relative'
  }
});

class MenuSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleButtonClick(event) {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });

    const closeMenu = ev => {
      this.setState({anchorEl: null});
      document.removeEventListener('mousedown',closeMenu,false);
    };
    document.addEventListener('mousedown',closeMenu, false);
  }

  handleOptionClick(event, value) {
    const {prosemirror, command} = this.props;
    event.preventDefault();
    this.setState({ anchorEl: null });
    command(value)(prosemirror.state, prosemirror.dispatch.toProsemirror, prosemirror.view);
  }

  render() {
    const {classes, style, prosemirror, columns, options, title, active, enabled, selected} = this.props;
    if (!Array.isArray(options)) return false;
    const {anchorEl} = this.state;

    const selectedValue = selected(prosemirror.state);
    let selectedItem = options.find(option => option.value === selectedValue);
    if (!selectedItem) selectedItem = options[0];

    return (
      <span className={classes.root} style={style}>
        <button
          className={classnames({
            [classes.BUTTON]: true,
            [classes.ACTIVE]: active && active(prosemirror.state)
          })}
          title={title}
          disabled={enabled && !enabled(prosemirror.state)}
          onMouseDown={ev => this.handleButtonClick(ev)}
        ><Dropdown>{selectedItem.icon}</Dropdown></button>
        <MenuPopout anchorEl={anchorEl} style={style} columns={columns}>
          {options.map((option, i) => (
            <div
              className={classes.BUTTON}
              key={i}
              onMouseDown={ev => this.handleOptionClick(ev, option.value)}>
              <span title={option.title}>{option.icon}</span>
            </div>
          ))}
        </MenuPopout>
      </span>
    );
  }
}

export default withProsemirror(withStyles(styles)(MenuSelect));

