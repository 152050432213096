import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {Button, IconButton, Switch, Tooltip} from '@material-ui/core';
import IconLink from '@material-ui/icons/Link';
import IconVisibility from '@material-ui/icons/Visibility';
import IconVisibilityOff from '@material-ui/icons/VisibilityOff';

import {withClient} from "reducers/client";
import {aliasFor, elementParents} from "reducers/client/requestTypes";

import {mapParents} from "util/elementStructureRelations";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `${1}px solid #dddddd`,
    '&:last-of-type': {
      borderBottom: `none`,
    }
  },
  flexLeft: {
    flex: 1,
    textAlign: 'left'
  },
  flexCenter: {
    flex: 1,
    textAlign: 'center'
  },
  flexRight: {
    flex: 1,
    textAlign: 'right'
  },
  tooltip: {
    maxWidth: `${200}px`
  }
});

class MergeElementsRow extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    elementType: PropTypes.string.isRequired,
    mainElementId: PropTypes.string.isRequired,
    mergeElementId: PropTypes.string.isRequired,
    mainParents: PropTypes.object,
    mergeParents: PropTypes.object,
    isSelected: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      elementType,
      mainElementId,
      mergeElementId,
      mainParents: mainParentsRequest,
      mergeParents: mergeParentsRequest,
      isSelected,
      value,
      onChange,
      onClick
    } = this.props;

    let mainParents = mainParentsRequest.isLoaded() ? mapParents(mainParentsRequest.get().parents) : [];
    let mergeParents = mergeParentsRequest.isLoaded() ? mapParents(mergeParentsRequest.get().parents) : [];

    return (
      <div className={classes.root}>
        <div className={classes.flexLeft}>
          {mainElementId && (
            <Tooltip
              title="Link using this element. If unchecked, this element type will not be linked."
              classes={{tooltip: classes.tooltip}}
            >
              <Switch
                disabled={mainElementId === mergeElementId}
                checked={value === mainElementId}
                onChange={() => onChange(mainElementId)}
              />
            </Tooltip>
          )}
          {mainParents.length > 1 && (
            <Tooltip title={mainParents.map(p => `${p.bookName} ${p.name}`).join(', ')}>
              <IconButton>
                <IconLink />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.flexCenter}>
          <Button
            onClick={onClick}
            color={isSelected ? 'primary' : 'default'}
          >
            {elementType}
            {isSelected ? <IconVisibility /> : <IconVisibilityOff />}
          </Button>
        </div>
        <div className={classes.flexRight}>
          {mergeParents.length > 1 && (
            <Tooltip title={mergeParents.map(p => `${p.bookName} ${p.name}`).join(', ')}>
              <IconButton>
                <IconLink />
              </IconButton>
            </Tooltip>
          )}
          {mergeElementId && (
            <Tooltip
              title="Link using this element. If unchecked, this element type will not be linked."
              classes={{tooltip: classes.tooltip}}
            >
              <Switch
                disabled={mainElementId === mergeElementId}
                checked={value === mergeElementId}
                onChange={() => onChange(mergeElementId)}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

export default withClient({
  hooks: {
    mainParents: aliasFor('elementParents')(elementParents((state, props) => props.mainElementId)),
    mergeParents: aliasFor('elementParents')(elementParents((state, props) => props.mergeElementId))
  }
})(withStyles(
  styles
)(MergeElementsRow));