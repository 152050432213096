import React from "react";
import PropTypes from 'prop-types';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  LinearProgress
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

import {withClient} from "reducers/client";
import {gradeStandards} from "reducers/client/requestTypes";
import {reduxTable, paging} from "reducers/table";

const styles = theme => ({
  blacklist: {
    color: theme.palette.action.disabled,
    cursor: 'not-allowed',
  },
  disabledTable: {
    '& > tr > td': {
      color: theme.palette.action.disabled,
    }
  },
  paginationRoot: {
    width: `400px`,
  }
});

class BrowserStandardList extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    blacklist: PropTypes.arrayOf(PropTypes.string),
    gradeStandards: PropTypes.object,
    gradeId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };
  static defaultProps = {
    blacklist: [],
  };
  state = {
    standards: [],
    totalCount: 0,
  };

  componentDidMount() {
    const {gradeStandards} = this.props;
    if (gradeStandards.isLoaded()) this.updateState();
  }
  componentDidUpdate(prevProps) {
    const {gradeStandards} = this.props;
    const {gradeStandards:prevGradeStandards} = prevProps;
    if (gradeStandards.isLoaded() && gradeStandards.hasChanged(prevGradeStandards)) this.updateState();
  }

  updateState() {
    const {table: {pagination, actions}} = this.props;
    const {results: standards, totalCount} = this.props.gradeStandards.get();
    this.setState({ standards, totalCount });
    if (pagination.page * pagination.pageSize > totalCount) {
      actions.setPage(Math.floor(totalCount / pagination.pageSize));
    }
  }
  handleClick = standard => ev => {
    const {disabled, gradeStandards, onClick} = this.props;
    const selectionDisabled = disabled || gradeStandards.isLoading();
    if (!selectionDisabled) onClick(ev, standard);
  };

  render() {
    const {classes, disabled, blacklist, gradeStandards, table: {pagination, actions}, onClick} = this.props;
    const selectionDisabled = disabled || gradeStandards.isLoading();
    const {standards, totalCount} = this.state;
    if (!standards) return null;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                fullWidth
                label="Standard"
                value={pagination.search}
                onChange={ev => actions.setSearch(ev.target.value)}
              />
            </TableCell>
            <TablePagination
              className={classes.paginationRoot}
              padding="none"
              colSpan={1}
              count={totalCount}
              page={pagination.page}
              rowsPerPage={pagination.pageSize}
              onChangePage={(ev, page) => actions.setPage(page)}
              onChangeRowsPerPage={ev => actions.setPageSize(ev.target.value)}
            />
          </TableRow>
          {gradeStandards.isLoading() && (
            <TableRow style={{height:0}}>
              <TableCell colSpan={2} padding="none"><LinearProgress/></TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody className={selectionDisabled ? classes.disabledTable : ''}>
          {standards.map(standard => {
            const isBlacklisted = blacklist.includes(standard.id);
            const className = isBlacklisted ? classes.blacklist : '';
            return (
              <TableRow key={standard.id} hover onClick={this.handleClick(standard)}>
                <TableCell className={className}>
                  {standard.title}
                </TableCell>
                <TableCell className={className}>
                  {standard.description}
                </TableCell>
                <TableCell />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(reduxTable({
  table: 'BrowserStandardList',
  initialState: {
    searchColumns: ['title','description']
  }
})(withClient({
  hooks: {
    gradeStandards: paging('BrowserStandardList')(gradeStandards((state, props) => props.gradeId))
  }
})(BrowserStandardList)));
