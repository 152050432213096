import React from 'react';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconClose from '@material-ui/icons/Close';

import ElementAssociatedLessonsTable from '../PagedTable/ElementAssociatedLessonsTable';
import ElementAssociatedContainersTable from '../PagedTable/ElementAssociatedContainersTable';

import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
// import { withStyles } from '@material-ui/core/styles';
//
// const styles = theme => ({
//   modal: {
//     left: '10%',
//     right: '10%',
//   },
// });

class ElementAssociatedContentTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
    };
  };

  handleSelectTab = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  renderLessonsOrContainers = () => {
    return (
      this.state.selectedTab === 0 ?
        <ElementAssociatedLessonsTable /> :
        <ElementAssociatedContainersTable />
    );
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        style={{
          width: '80%',
          left: '10%',
        }}
      >
        <div>
          <Button variant={"fab"} color={"primary"}>
            <IconClose onClick={this.props.close} />
          </Button>
          <AppBar position={"static"}>
            <Tabs value={this.state.selectedTab} onChange={this.handleSelectTab} indicatorColor={'primary'}>
              <Tab label={"Lessons"} />
              <Tab label={"Containers"} />
            </Tabs>
          </AppBar>
          {this.renderLessonsOrContainers()}
        </div>
      </Modal>
    );
  }
}

export default ElementAssociatedContentTable;
