import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabbedViewRoutes} from './tabbedView';

export const TabbedViewsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:tabbedViewId`} component={TabbedViewRoutes} />
    <Redirect to="/404" />
  </Switch>
);
