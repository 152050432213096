import React from 'react';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {TabbedViewBreadcrumb} from "components/Breadcrumb";
import DetailsTable from 'components/Tables/DetailsTable';
import TabbedViewTabsTable from 'components/Tables/TabbedViewTabsTable';
import {withClient} from "reducers/client";
import {tabbedViewDetails, updateTabbedView} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class TabbedViewDetails extends React.Component {
  state = {
    editMode: false,
    tabbedViewDetails: {},
  };

  componentDidMount() {
    const {tabbedViewDetails:tabbedViewRequest} = this.props;
    if (tabbedViewRequest.isLoaded()) {
      const tabbedViewDetails = tabbedViewRequest.get();
      this.setState({
        tabbedViewDetails: {
          'Type': tabbedViewDetails.type,
          'Created': formatDate(tabbedViewDetails.createdAt),
          'Updated': formatDate(tabbedViewDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {tabbedViewDetails:tabbedViewRequest} = this.props;
    const {tabbedViewDetails:prevRequest} = prevProps;

    if (tabbedViewRequest.isLoaded() && tabbedViewRequest.hasChanged(prevRequest)) {
      const tabbedViewDetails = tabbedViewRequest.get();
      this.setState({
        tabbedViewDetails: {
          'Type': tabbedViewDetails.type,
          'Created': formatDate(tabbedViewDetails.createdAt),
          'Updated': formatDate(tabbedViewDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      tabbedViewDetails: {
        ...this.state.tabbedViewDetails,
        [key]: value,
      }
    });
  }
  toggleEditTabbedView() {
    const {editMode, tabbedViewDetails} = this.state;
    if (editMode) {
      const {tabbedViewDetails:tabbedViewRequest, updateTabbedView} = this.props;
      if (tabbedViewRequest.get().type !== tabbedViewDetails['Type']) {
        updateTabbedView.sendRequest({
          id: tabbedViewRequest.get().id,
          type: tabbedViewDetails['Type'],
        })
          .then(() => tabbedViewRequest.sendRequest());
      }
    }
    this.setState({editMode: !editMode});
  }

  render() {
    const {classes, tabbedViewDetails:tabbedViewRequest} = this.props;
    const {editMode, tabbedViewDetails} = this.state;

    if (!tabbedViewRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading TabbedView details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <TabbedViewBreadcrumb tabbedViewId={tabbedViewRequest.getParams()} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit TabbedView Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditTabbedView()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            TabbedView Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Type', value: tabbedViewDetails['Type']},
              {name: 'Created', value: tabbedViewDetails.Created, readOnly: true},
              {name: 'Updated', value: tabbedViewDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Tabs</Typography>
          <Paper>
            <TabbedViewTabsTable tabbedViewId={tabbedViewRequest.getParams()} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const TabbedViewDetailsView = withClient({
  hooks: {
    tabbedViewDetails: tabbedViewDetails((state, props) => state.tabbedViews.selected),
    updateTabbedView: updateTabbedView((state, props) => state.tabbedViews.selected),
  },
})(withStyles(styles)(TabbedViewDetails));
