import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {searchAssessmentProblems, addAssessmentProblemSearchFilter, makeFilterSelector, setFilterProperty, removeFilter} from "../../reducers/Search/assessmentProblems";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Radio,
  CircularProgress
} from "@material-ui/core";
import {Link} from "react-router-dom";

import SearchBar from 'components/Searchbar';
import SearchFilter from './SearchFilter';
import SearchAddFilterButton from './SearchAddFilterButton';

import {AssessmentProblemFilterTypes} from "../../constants/FilterTypes";

const styles = theme => ({
  root: {

  },
  toolbar: {
    display: 'flex',
    padding: `${8}px`
  },
  toolbarButton: {
    flex: 0,
    whiteSpace: 'nowrap',
    marginLeft: `${8}px`
  },
  filters: {

  },
  searchResult: {
    margin: `${8}px`,
    '& em': {
      fontStyle: 'normal',
      background: theme.palette.secondary.main
    },
  }
});

class AssessmentProblemSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      selection: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {searchAssessmentProblems, filters} = this.props;
    if (filters !== prevProps.filters) {
      searchAssessmentProblems(this.state.searchValue, filters);
    }
  };

  handleSearch = value => {
    this.setState({searchValue: value});
    const {searchAssessmentProblems, filters} = this.props;
    searchAssessmentProblems(value, filters);
  };

  handleFilterChange = () => {
    const {searchAssessmentProblems, filters} = this.props;
    searchAssessmentProblems(this.state.searchValue, filters);
  };

  handleClick = (event, row) => {
    const {select} = this.props;
    const prevSelection = this.state.selection;
    let selection;
    if (select === 'single') {
      if (prevSelection.length < 1 || row.id !== this.state.selection[0].id) selection = [row];
      else selection = [];
    } else if (select === 'multiple') {
      const i = prevSelection.findIndex(s => s.id === row.id);
      if (i !== -1) selection = prevSelection.slice(0,i).concat(prevSelection.slice(i + 1));
      else selection = prevSelection.concat(row);
    }

    if (selection) {
      this.setState({selection});
      if (typeof this.props.onSelectionChanged === 'function') this.props.onSelectionChanged(selection);
    }
  };

  handleAddFilter = filterType => {
    const {addFilter} = this.props;
    addFilter(filterType);
  };

  render() {
    const {select, searchResults, filters, classes} = this.props;
    const {selection} = this.state;

    let resultsDisplay = false;

    if (searchResults) {
      if (searchResults.pending) {
        resultsDisplay = (
          <TableRow>
            <TableCell align="center" colSpan={5}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        );
      } else if (searchResults.data && searchResults.data.length > 0) {
        resultsDisplay = searchResults.data.map((row) => {
          let bookName = '???';
          if (row.book) bookName = row.book.name.toUpperCase();
          const selected = selection && selection.some(s => s.id === row.id);
          return (
            <TableRow
              hover
              onClick={event => this.handleClick(event, row)}
              tabIndex={-1}
              selected={selected}
              key={row.id}>
              {select && (
                <TableCell padding="checkbox">
                  {select === 'single' ? (
                    <Radio checked={selected} />
                  ) : (
                    <Checkbox checked={selected} />
                  )}
                </TableCell>
              )}
              <TableCell>{row.name}</TableCell>
              <TableCell
                className={classes.searchResult}
                dangerouslySetInnerHTML={row.highlight && {__html: row.highlight.html}} />
              <TableCell>
                <Link to={`/app/assessmentproblems/${row.id}/editor`}>
                  <Button color="primary" value={row.id}>Edit Content</Button>
                </Link>
              </TableCell>
            </TableRow>
          );
        });
      }
    }

    return (<div className={classes.root}>
      <div className={classes.toolbar}>
        <SearchBar fullWidth onChange={this.handleSearch} />
        <SearchAddFilterButton onAddFilter={this.handleAddFilter} filterTypes={AssessmentProblemFilterTypes} filters={filters}/>
      </div>
      <div className={classes.filters}>
        {filters.map((filter, index) => <SearchFilter key={index} filterId={index} makeFilterSelector={makeFilterSelector} setFilterProperty={this.props.setFilterProperty} removeFilter={this.props.removeFilter}/>)}
      </div>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {select && <TableCell />}
              <TableCell>Name</TableCell>
              <TableCell>Matched text</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {resultsDisplay}
          </TableBody>
        </Table>
      </div>
    </div>);
  }
}

const mapStateToProps = (state, props) => ({
  filters: state.search.assessmentProblems.filters,
  searchResults: state.search.assessmentProblems.searchResults,
});
const mapDispatchToProps = dispatch => ({
  searchAssessmentProblems: (searchString, filters) => dispatch(searchAssessmentProblems(searchString, filters)),
  addFilter: filterType => dispatch(addAssessmentProblemSearchFilter(filterType)),
  setFilterProperty: (filterId, property, value) => dispatch(setFilterProperty(filterId, property, value)),
  removeFilter: filterId => dispatch(removeFilter(filterId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssessmentProblemSearch));
