import React, {Component} from 'react';
import {connect} from 'react-redux';
import {openClassificationDialog, setClassificationDialogData} from "../../../../reducers/Dialogs/Classifications/index";
import Button from '@material-ui/core/Button';

class EditClassificationButton extends Component {
  clicked = () => {
    const {classification} = this.props;
    this.props.setClassificationDialogData(classification);
    this.props.openClassificationDialog();
  };

  render(){
    return (
      <Button color="primary" onClick={this.clicked}>Edit</Button>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setClassificationDialogData: (payload) => dispatch(setClassificationDialogData(payload)),
  openClassificationDialog: () => dispatch(openClassificationDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassificationButton)

