import React, {Component} from 'react';
import { Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {renderField} from "components/Forms/fields";
import Button from '@material-ui/core/Button';
import {deleteMenu} from "reducers/menus";
import {withClient} from "reducers/client";
import {bookMenus, createMenuForBook} from "reducers/client/requestTypes";

class MenuForm extends Component {

  onSubmit = (values) => {
    const {createMenuForBook, bookMenus} = this.props;
    // const menu = this.props.menu || {};
    // values.id = menu.id;
    return createMenuForBook.sendRequest(values)
      .then((result) => {
        console.log("IN THEN");
        return bookMenus.sendRequest()
      })
      .then((result) => {
        console.log("in then then")
        this.props.onClose();
      })
  };

  deleteClicked = () => {
    const menu = this.props.menu || {};
    this.props.deleteMenu(menu.id);
    this.props.onClose();
  };

  render(){
    const { pristine, submitting, handleSubmit, menu } = this.props;
    return (
      <div>
        <h2 className="article-title">{this.props.menu ? 'Edit Menu': 'Create Menu'}</h2>
        <i className="nav-icon material-icons" onClick={this.deleteClicked} style={{position: 'absolute', top: 15, right: 15, color: '#c1c1c1', cursor: 'pointer'}}>delete</i>
        <div className="box box-default">
          <div className="box-body">
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field name="name" type="text" placeholder="Menu Name" component={renderField} label="Name" fullWidth />
              <Field name="type" type="text" placeholder="Menu Type" component={renderField} label="Type" fullWidth />
              <div>
                <br />
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>Save</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const CreateMenuForm = reduxForm({
  form: 'createMenu',
  onSubmitSuccess: (result, dispatch, props) => {
    props.onClose()
  },
})(MenuForm);

const mapStateToProps = state => ({
  formData: state.form.createMenu,
  initialValues: state.dialogs.menus.data,
  selectedBook: state.books.selected
});

const mapDispatchToProps = dispatch => ({
  deleteMenu: (id) => dispatch(deleteMenu(id))
});



export default withClient({
  hooks: {
    createMenuForBook: createMenuForBook((state, props) => state.books.selected),
    bookMenus: bookMenus((state, props) => state.books.selected),
  }
})( connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMenuForm));
