import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/standards/index';
import { requestClassifications } from '../../../../reducers/classificationsSelect';

import _ from 'lodash';

const countLessonsOrContainers = (standard, lessonsOrContainers) => {

  if (!lessonsOrContainers || !standard.contentVersions) return 0;

  let countArr = [];
  let singularForm = lessonsOrContainers.slice(0, lessonsOrContainers.length - 1);
  let element, items;

  //dedup here based on elementId + container/lessonId
  let contentVersionsWithElements  = standard.contentVersions.filter((cv) => !!cv.element);

  let  dedupedContentVersions = {};
  standard.contentVersions.forEach((cv) => {
    if (cv.element.elementType === singularForm) {
      let key = cv.element.id + cv.element[lessonsOrContainers][0].id;
      dedupedContentVersions[key] = cv
    }
  });

  contentVersionsWithElements = Object.values(dedupedContentVersions);

  contentVersionsWithElements.forEach(contentVersion => {
    element = contentVersion.element;
    items = contentVersion.element[lessonsOrContainers];
    if (element.elementType === singularForm) {
      items.forEach(item => {
        if (item.elementStructureRelation.contentable === singularForm) {
          countArr.push(items[0]);
        }
      });
    }
  });

  return countArr.length;
};

const columnData = [
  {
    id: 'classification',
    numeric: false,
    disablePadding: true,
    label: 'Classification',
    content: r => {
      if (r.classification) {
        return r.classification.name;
      }
    },
  },
  {
    id: 'shortTitle',
    numeric: false,
    disablePadding: false,
    label: 'Short Title',
    content: r => r.shortTitle,
  },
  // {
  //   id: 'type',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Type',
  //   content: r => r.type,
  // },
  // {
  //   id: 'grades',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Grades',
  //   content: r => r.grades,
  // },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    content: r => r.description.slice(0, 155),
  },
  {
    id: 'lessonCount',
    numeric: true,
    disablePadding: false,
    label: 'Lesson Count',
    content: r => countLessonsOrContainers(r, 'lessons'),
  },
  {
    id: 'containerCount',
    numeric: true,
    disablePadding: false,
    label: 'Lesson Content Container Count',
    content: r => countLessonsOrContainers(r, 'lessonContentContainers'),
  },
];

const BaseStandardsForClassificationTable = PagedTable({
  title: 'CPM Standards',
  columnData,
  reducer,
  select: 'multiple',
  actions: false,
});

// This allows us to extend PagedTable's default componentDidMount() to update
// the search field
// class StandardsForClassificationTable extends BaseStandardsForClassificationTable {
//   componentDidMount() {
//     this.props.changeSearchField([
//       'shortTitle',
//       'description',
//     ]);
//   }
// }

// Borrowed from src/components/Table/Base/index
// This connects the extended StandardsForClassificationTable to the store
const {Selectors, Actions} = reducer;
const mapStateToProps = state => ({
  pageRequest: Selectors.getPageRequest(state),
  data: Selectors.getPageContent(state),
  totalCount: Selectors.getTotalCount(state),
  tableState: Selectors.getState(state),
  classificationsSelect: state.classificationsSelect,
});

const mapDispatchToProps = dispatch => ({
  requestPage: () => dispatch(Actions.requestPage()),
  changePage: page => dispatch(Actions.changePage(page)),
  changePageSize: pageSize => dispatch(Actions.changePageSize(pageSize)),
  changeSort: column => dispatch(Actions.changeSort(column)),
  changeSearch: value => dispatch(Actions.changeSearch(value)),
  changeSearchField: value => dispatch(Actions.changeSearchField(value)),

  setSelection: values => dispatch(Actions.setSelection(values)),
  addSelection: values => dispatch(Actions.addSelection(values)),
  removeSelection: values => dispatch(Actions.removeSelection(values)),
  toggleSelection: values => dispatch(Actions.toggleSelection(values)),
  clearSelection: () => dispatch(Actions.clearSelection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseStandardsForClassificationTable);