import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconError from '@material-ui/icons/Error';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

import Button from "@material-ui/core/Button";

class ConfirmationDialog extends React.Component {
  render() {
    const { isOpen, title, message, onClose, onConfirm, ...other }  = this.props;

    return (<Dialog open={isOpen} onClose={onClose} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
ConfirmationDialog.defaultProps = {
  title: "Confirm",
  message: "Are you sure?"
};

export default ConfirmationDialog;
