import React from 'react';
import {connect} from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconCheck from '@material-ui/icons/Check';
import IconError from '@material-ui/icons/Error';

import {
  hideSnackbar,
  SNACKBAR_HIDDEN,
  SNACKBAR_SAVING,
  SNACKBAR_SAVE_SUCCESS,
  SNACKBAR_SAVE_ERROR
} from "reducers/Prosemirror";

const ProsemirrorSnackbar = ({snackbarStatus, mappedHideSnackbar}) => {
  let snackbarActions = null;
  let snackbarContent = null;
  let autoClose = 6000;

  if (snackbarStatus === SNACKBAR_SAVE_SUCCESS) {
    snackbarContent = <span>Successfully saved!</span>;
    snackbarActions = <IconCheck fontSize="small" />;
  } else if (snackbarStatus === SNACKBAR_SAVE_ERROR) {
    snackbarContent = <span>An error occurred while saving.</span>;
    snackbarActions = <IconError fontSize="small" />;
  } else if (snackbarStatus === SNACKBAR_SAVING) {
    snackbarContent = <span>Saving...</span>;
    snackbarActions = <CircularProgress size={20} />;
    autoClose = null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbarStatus !== SNACKBAR_HIDDEN}
      onClose={mappedHideSnackbar}
      onClick={mappedHideSnackbar}
      message={snackbarContent}
      action={snackbarActions}
      autoHideDuration={autoClose}
    />
  );
};

const mapStateToProps = (state, props) => ({
  snackbarStatus: state.prosemirror.snackbarStatus
});

const mapDispatchToProps = dispatch => ({
  mappedHideSnackbar: (ev, reason) => reason !== 'clickaway' && dispatch(hideSnackbar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProsemirrorSnackbar);
