import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconLink from '@material-ui/icons/Link';

import {withClient} from "reducers/client";
import {elementParents} from "reducers/client/requestTypes";

const mapParents = parents => {
  const containers = [];
  const books = {};

  parents.forEach(p => {
    if (p.type === 'book') books[p.id] = p.name;
    else containers.push(p);
  });
  return containers.map(c => ({
    ...c,
    bookName: books[c.bookId]
  })).sort((a,b) => {
    if (a.bookName < b.bookName) return -1;
    if (a.bookName > b.bookName) return 1;
    return 0;
  });
};

class ElementParents extends React.Component {
  static propTypes = {
    elementId: PropTypes.string.isRequired,
  };

  state = {
    anchor: null,
    elementId: null,
    parents: null,
  };

  componentDidMount() {
    const {elementParents} = this.props;
    if (elementParents.isLoaded()) {
      const parents = mapParents(elementParents.get().parents);
      this.setState({
        elementId: elementParents.getParams(),
        parents,
      });
    } else {
      this.setState({
        elementId: elementParents.getParams(),
      });
    }
  }
  componentDidUpdate(prevProps) {
    const {elementParents} = this.props;
    const {elementParents:prevParents} = prevProps;
    if (elementParents.isLoaded() && elementParents.hasChanged(prevParents)) {
      const parents = mapParents(elementParents.get().parents);
      this.setState({
        elementId: elementParents.getParams(),
        parents,
      })
    }
  }

  handleClick = event => {
    this.setState({anchor: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchor: null});
  };

  handleLink = parent => {
    const {history, elementParents} = this.props;
    history.push(`/app/${parent.type}s/${parent.id}/editor/${elementParents.get().elementType}`);
    this.handleClose();
  };

  render() {
    const {parents, anchor} = this.state;
    if (!parents || parents.length <= 1) return false;

    return (<div>
      {this.props.children}
      <Badge color="secondary" badgeContent={parents.length}>
        <Button variant="contained" onClick={this.handleClick}>
          Shared Content
          <IconLink />
        </Button>
      </Badge>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={this.handleClose}>
        {parents.map(p => (<MenuItem key={p.id} onClick={() => this.handleLink(p)}>
          {p.bookName} {p.name}
        </MenuItem>))}
      </Menu>
    </div>);
  }
}

export default withClient({
  hooks: {
    elementParents: elementParents((state, props) => props.elementId)
  }
})(withRouter(ElementParents));
