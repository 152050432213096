import React from 'react';
import PropTypes from 'prop-types';

import {
  Toolbar,
  Typography,
  IconButton
} from "@material-ui/core";
import IconBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import BrowserBookList from './BrowserList/BrowserBookList';
import BrowserCategoryList from './BrowserList/BrowserCategoryList';
import BrowserChapterList from './BrowserList/BrowserChapterList';
import BrowserLessonList from './BrowserList/BrowserLessonList';
import BrowserLessonContentContainerList from './BrowserList/BrowserLessonContentContainerList';
import BooksTable from 'components/Tables/PagedTable/BooksTable2';

const styles = theme => ({
  breadcrumb: {
    margin: theme.spacing(1),
  }
});

class LessonContentContainerBrowser extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    blacklist: PropTypes.arrayOf(PropTypes.string),
  };
  state = {
    selectedBook: null,
    selectedChapter: null,
    selectedLesson: null,
    selectedContainerId: null,
  };

  crumb(crumb) {
    return <Typography className={this.props.classes.breadcrumb} key={crumb.id} variant="button">{crumb.name}</Typography>
  }
  getBreadcrumb() {
    const {selectedBook, selectedChapter, selectedLesson} = this.state;
    let crumbs = [];
    if (selectedBook) {
      crumbs.push(<IconButton key={'Back'} onClick={this.handleClickBack}><IconBack fontSize="small"/></IconButton>);
      crumbs.push(this.crumb(selectedBook));
      if (selectedChapter) {
        crumbs.push('>');
        crumbs.push(this.crumb(selectedChapter));
        if (selectedLesson) {
          crumbs.push('>');
          crumbs.push(this.crumb(selectedLesson));
        }
      }
    }
    return crumbs;
  }

  handleClick(key, value) {
    this.setState({[key]:value});
  }
  handleContainerClick = (ev, row) => {
    const {onChange} = this.props;
    const {selectedBook} = this.state;
    if (typeof onChange === 'function') onChange([{
      ...row,
      bookId:selectedBook.id,
      bookName: selectedBook.name
    }]);
    this.setState({selectedContainerId: row.id});
  };
  handleClickBack = () => {
    if (this.state.selectedLesson) this.setState({selectedLesson: null});
    else if (this.state.selectedChapter) this.setState({selectedChapter: null});
    else if (this.state.selectedBook) this.setState({selectedBook: null});
  };

  render() {
    const {selectedBook, selectedChapter, selectedLesson, selectedContainerId} = this.state;
    let activeTable;
    if (selectedLesson) {
      activeTable = (
        <BrowserLessonContentContainerList
          blacklist={this.props.blacklist}
          lessonId={selectedLesson.id}
          onClick={this.handleContainerClick}
          selected={selectedContainerId}
        />
      );
    } else if (selectedChapter) {
      activeTable = (
        <BrowserLessonList
          chapterId={selectedChapter.id}
          onClick={(ev, row) => this.handleClick('selectedLesson',row)}
        />
      );
    } else if (selectedBook) {
      activeTable = (
        <BrowserChapterList
          bookId={selectedBook.id}
          onClick={(ev, row) => this.handleClick('selectedChapter',row)}
        />
      );
    } else {
      return <BooksTable onClick={(ev, row) => this.handleClick('selectedBook',row)} />;
    }

    return (<React.Fragment>
      <Toolbar>
        {this.getBreadcrumb()}
      </Toolbar>
      {activeTable}
    </React.Fragment>);
  }
}

export default withStyles(styles)(LessonContentContainerBrowser);
