import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {tabElements} from "reducers/client/requestTypes";

import {TabBreadcrumb} from 'components/Breadcrumb';

import ContentVersionMediasTable from 'components/ContentVersion/ContentVersionMediasTable';
import LinearProgress from "@material-ui/core/LinearProgress";


const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: theme.spacing(3),
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

class TabMedia extends React.Component {
  showTabEditor = () => {
    const {
      history,
      tabElements
    } = this.props;
    history.push(`/app/tabs/${tabElements.getParams()}/editor`);
  };

  render() {
    const {
      classes,
      tabElements,
    } = this.props;

    if (!tabElements.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Tab media...</h2>
      </div>);
    }

    const tabElement = tabElements.get()[0];

    return (
      <div className={classes.root}>
        <TabBreadcrumb tabId={tabElements.getParams()} />
        <div className={classes.buttonBox}>
          <Tooltip title="Tab Editor">
            <Fab color="primary" className={classes.button}>
              <IconEdit onClick={this.showTabEditor} />
            </Fab>
          </Tooltip>
        </div>

        <ContentVersionMediasTable contentVersionId={tabElement.publishedVersionId} />
      </div>
    );
  }
}

const getTabId = (state, props) => state.tabs.selected;
export const TabMediaView = withClient({
  hooks: {
    tabElements: tabElements(getTabId),
  },
})(withStyles(styles)(TabMedia));
