import React from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconDots from '@material-ui/icons/MoreHoriz';

import {formatDate} from 'constants/Date';
import {withClient} from "reducers/client";
import {tabbedViewTabs, tabbedViewTabOrder, tabbedViewCreateTab, updateTab, deleteTab} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import ConfirmationDialog from "components/Dialogs/Confirmation";

import TabbedViewTabsTableRow from './TabbedViewTabsTableRow';

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class TabbedViewTabsTable extends React.Component {
  state = {
    tabs: [],
    adding: false,
    editingId: -1,
    edit: {
      name: '',
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {tabbedViewTabs} = this.props;
    if (tabbedViewTabs.isLoaded()) {
      this.setState({ tabs: tabbedViewTabs.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {tabbedViewTabs} = this.props;
    const {tabbedViewTabs:prevTabs} = prevProps;
    if (tabbedViewTabs.isLoaded() && tabbedViewTabs.hasChanged(prevTabs)) {
      this.setState({ tabs: tabbedViewTabs.get() });
    }
  }

  isWaiting() {
    const {
      tabbedViewTabs,
      // tabbedViewChapterOrder,
      tabbedViewCreateTab,
      updateTab,
      deleteTab,
    } = this.props;
    return tabbedViewTabs.isLoading() ||
      // tabbedViewChapterOrder.isLoading() ||
      tabbedViewCreateTab.isLoading() ||
      updateTab.isLoading() ||
      deleteTab.isLoading();
  }
  pendingChanges() {
    const {tabs, adding, editingId, edit} = this.state;
    if (adding) {
      return edit.name.length > 0;
    }
    if (tabs[editingId]) {
      return tabs[editingId].name !== edit.name;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: '',
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.tabs[editingId].name || '',
      },
    });
  };
  handleClickSave = () => {
    const {tabbedViewCreateTab, updateTab, tabbedViewTabs} = this.props;
    const {tabs, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        tabbedViewCreateTab.sendRequest(edit)
          .then(() => tabbedViewTabs.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateTab.sendRequest(tabs[editingId].id, edit)
          .then(() => tabbedViewTabs.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteTab, tabbedViewTabs} = this.props;
    const {tabs, editingId} = this.state;
    deleteTab.sendRequest(tabs[editingId].id)
      .then(() => tabbedViewTabs.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        // return true;
        return false;
      } else if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    // Click directly on row = navigate
    this.props.history.push(`/app/tabs/${this.state.tabs[index].id}/editor`);
    return false;
  };
  handleChangeOrder = updatedList => {
    const tabs = this.props.tabbedViewTabs.get();
    this.props.tabbedViewTabOrder.sendRequest(updatedList.map(c => tabs[c].id))
      .then(() => this.props.tabbedViewTabs.sendRequest());
  };

  render() {
    const {tabs, adding, editingId, edit, confirmationOpen} = this.state;
    if (!tabs) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    // const disableDrag = true;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Tab"
        message={`Are you sure you want to delete Tab ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell />
            <TableCell padding="none" />
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={5} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={TabbedViewTabsTableRow}
          items={tabs}
          itemProps={{
            classes,
            adding: Boolean(adding),
            waiting: Boolean(waiting),
            pendingChanges: Boolean(this.pendingChanges()),
            editingId,
            edit,
            onEdit: this.handleEdit,
            onClickEdit: this.handleClickEdit,
            onClickSave: this.handleClickSave,
            onClickDelete: this.handleClickDelete,
          }}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={this.props.classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={5} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    tabbedViewTabs: tabbedViewTabs((state, props) => props.tabbedViewId),
    tabbedViewTabOrder: tabbedViewTabOrder((state, props) => props.tabbedViewId),
    tabbedViewCreateTab: tabbedViewCreateTab((state, props) => props.tabbedViewId),
    updateTab: updateTab(),
    deleteTab: deleteTab(),
  },
})(withStyles(styles)(withRouter(TabbedViewTabsTable)));