import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import IconLink from '@material-ui/icons/Link';
import IconUnlink from '@material-ui/icons/LinkOff';

import {getElementTypeByName} from "constants/ElementTypes";

const styles = theme => ({
  parentReference: {
    display: 'block',
    marginTop: '8px',
    padding: '6px 16px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginTop: 0
    }
  },
  parentReferenceDisabled: {
    color: '#b0b0b0',
    display: 'block',
    marginTop: '8px',
    padding: '6px 16px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 3px 1px -2px rgba(0,0,0,0.06)',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginTop: 0
    }
  }
});

import {ElementParentsReference} from "components/ContentReference";

let ElementPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired
});

class MergeInformationTable extends React.Component {
  static propTypes = {
    mainId: PropTypes.string.isRequired,
    mergeId: PropTypes.string.isRequired,
    mainElements: PropTypes.arrayOf(ElementPropType).isRequired,
    mergeElements: PropTypes.arrayOf(ElementPropType).isRequired,
    selection: PropTypes.objectOf(PropTypes.string).isRequired,
    referenceComponent: PropTypes.elementType.isRequired,
  };

  render() {
    const {
      classes,
      selection,
      mainId,
      mergeId,
      mainElements,
      mergeElements,
      referenceComponent: ReferenceComponent
    } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Element Type</TableCell>
            <TableCell>Group 1</TableCell>
            <TableCell padding="none" />
            <TableCell>Group 2</TableCell>
            <TableCell>Using</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(selection).map(k => {
            const elementType = getElementTypeByName(k);
            const mainElement = mainElements.find(e => e.elementType === k);
            const mergeElement = mergeElements.find(e => e.elementType === k);
            const mainSelected = mainElement && selection[k] === mainElement.id;
            const mergeSelected = mergeElement && selection[k] === mergeElement.id;
            const className = selection[k] ? classes.parentReference : classes.parentReferenceDisabled;

            return (
              <TableRow key={k}>
                <TableCell>{elementType.displayName || k}</TableCell>
                <TableCell>
                  {mainElement ? (
                    <ElementParentsReference
                      classes={{reference:className}}
                      elementId={mainElement.id}
                    />
                  ):(
                    <ReferenceComponent classes={{root:className}} contentableId={mainId} />
                  )}
                </TableCell>
                <TableCell padding="none">
                  {selection[k] ? (
                    <IconLink />
                  ):(
                    <IconUnlink />
                  )}
                </TableCell>
                <TableCell>
                  {mergeElement ? (
                    <ElementParentsReference
                      classes={{reference:className}}
                      elementId={mergeElement.id}
                    />
                  ):(
                    <ReferenceComponent classes={{root:className}} contentableId={mergeId} />
                  )}
                </TableCell>
                <TableCell>
                  {mainSelected && <ReferenceComponent contentableId={mainId} />}
                  {mergeSelected && <ReferenceComponent contentableId={mergeId} />}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(MergeInformationTable);