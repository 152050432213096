import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import IconDots from '@material-ui/icons/MoreHoriz';

import {withClient} from "reducers/client";
import {mediaAltTexts, createMediaAltText, updateMediaAltText, deleteMediaAltText} from "reducers/client/requestTypes";

import ConfirmationDialog from "components/Dialogs/Confirmation";
import Language, {AllLanguages} from "constants/Language";

import {MediaAltTextsTableRow} from "./MediaAltTextsTableRow";

const styles = theme => ({
  itemButton: {},
});

class MediaAltTextsTableBase extends React.Component {
  state = {
    altTexts: [],
    adding: false,
    editingId: -1,
    edit: {
      languageTag: Language.EN.value,
      altText: ''
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {mediaAltTexts} = this.props;
    if (mediaAltTexts.isLoaded()) {
      this.setState({ altTexts: mediaAltTexts.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {mediaAltTexts} = this.props;
    const {mediaAltTexts:prevAltTexts} = prevProps;
    if (mediaAltTexts.isLoaded() && mediaAltTexts.hasChanged(prevAltTexts)) {
      this.setState({ altTexts: mediaAltTexts.get() });
    }
  }

  isWaiting() {
    const {
      mediaAltTexts,
      createMediaAltText,
      updateMediaAltText,
      deleteMediaAltText,
    } = this.props;
    return mediaAltTexts.isLoading() ||
      createMediaAltText.isLoading() ||
      updateMediaAltText.isLoading() ||
      deleteMediaAltText.isLoading();
  }
  pendingChanges() {
    const {altTexts, adding, editingId, edit} = this.state;
    if (adding) {
      return edit.altText.length > 0;
    }
    if (altTexts[editingId]) return altTexts[editingId].altText !== edit.altText;
    return false;
  }
  canAdd() {
    const {altTexts, editingId} = this.state;
    return editingId === -1 && altTexts.length < AllLanguages.length;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    if (this.canAdd()) {
      const {altTexts} = this.state;
      this.setState({
        adding: true,
        edit: {
          languageTag: AllLanguages.find(l => !altTexts.some(t => t.languageTag === l.value)).value,
          altText: ''
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        id: this.state.altTexts[editingId].id,
        languageTag: this.state.altTexts[editingId].languageTag,
        altText: this.state.altTexts[editingId].altText
      },
    });
  };
  handleClickSave = () => {
    const {createMediaAltText, updateMediaAltText, mediaAltTexts} = this.props;
    const {adding, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        createMediaAltText.sendRequest(edit)
          .then(() => mediaAltTexts.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateMediaAltText.sendRequest(edit)
          .then(() => mediaAltTexts.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteMediaAltText, mediaAltTexts} = this.props;
    const {altTexts, editingId} = this.state;
    deleteMediaAltText.sendRequest(altTexts[editingId].id)
      .then(() => mediaAltTexts.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  render() {
    const {altTexts, adding, editingId, edit, confirmationOpen} = this.state;
    if (!altTexts) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Alt Text"
        message={`Are you sure you want to delete the ${Language[edit.languageTag].name} Alt Text?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Language</TableCell>
            <TableCell>Text</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={5} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <TableBody>
          {altTexts.map((altText, index) => (
            <MediaAltTextsTableRow
              key={altText.id}
              classes={classes}
              index={index}
              altText={altText}
              adding={adding}
              edit={edit}
              editingId={editingId}
              waiting={Boolean(waiting)}
              pendingChanges={Boolean(this.pendingChanges())}
              onEdit={this.handleEdit}
              onClickEdit={this.handleClickEdit}
              onClickDelete={this.handleClickDelete}
              onClickSave={this.handleClickSave}
            />
          ))}
        </TableBody>
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell>
                <Select
                  value={edit.languageTag}
                  onChange={ev => this.handleEdit('languageTag', ev)}
                  disabled={waiting}
                  fullWidth
                  autoFocus
                >
                  {AllLanguages.map(language => (
                    <MenuItem
                      key={language.value}
                      value={language.value}
                      disabled={altTexts.some(t => t.languageTag === language.value)}
                    >
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={edit.altText}
                  onChange={ev => this.handleEdit('altText', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={this.canAdd()} onClick={this.handleClickAdd}>
              <TableCell colSpan={5} align="center">
                {this.canAdd() ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

const getMediaId = (state, props) => props.mediaId;
export const MediaAltTextsTable = withClient({
  hooks: {
    mediaAltTexts: mediaAltTexts(getMediaId),
    createMediaAltText: createMediaAltText(getMediaId),
    updateMediaAltText: updateMediaAltText(getMediaId),
    deleteMediaAltText: deleteMediaAltText(getMediaId),
  },
})(withStyles(styles)(MediaAltTextsTableBase));