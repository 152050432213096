import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const LESSON_SELECT = 'LESSON_SELECT';
export const LESSON_LIST_FOR_CHAPTER = 'LESSON_LIST_FOR_CHAPTER';

// CHILD REDUCERS
const listByChapter = MultiRequest(LESSON_LIST_FOR_CHAPTER);

// ACTION CREATORS
export function selectLesson(lessonID) {
  return {type: LESSON_SELECT, payload: lessonID};
}

export function requestLessonListForChapter(chapterId) {
  const req = apiClient.getChapterLessons(chapterId);
  req.setSortFields(['displayOrder']);
  req.setOrderByAsc();
  req.limit(9999);
  return listByChapter.sendRequest(req, {key: chapterId});
}

// SELECTORS
const getChapterLessonListRequests = state => state.lessons.listByChapter;
export const makeChapterLessonListSelector = chapterIdResolver =>
  makeRequestSelector(chapterIdResolver, getChapterLessonListRequests);

export default combineReducers({
  selected: (state = null, action) => (action.type === LESSON_SELECT ? action.payload : state),
  listByChapter
});
