import React from "react";
import { redo, undo } from 'prosemirror-history';

import MenuGroup from '../components/MenuGroup';
import MenuButton from '../components/MenuButton';
import icons from '../../icons';

export default (<MenuGroup key="history">
  <MenuButton
    enabled={undo}
    command={undo}
    title="Undo last change">
    {icons.undo}
  </MenuButton>
  <MenuButton
    enabled={redo}
    command={redo}
    title="Redo last undone change">
    {icons.redo}
  </MenuButton>
</MenuGroup>);
