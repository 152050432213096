import React from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {reduxDialog} from "reducers/dialog";

import TableDialogForm from "./form";

class TableDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };

  handleSubmit = ev => {
    const { prosemirror, formData, dialog: {data} } = this.props;

    if (data && (data.pos != null)) {
      prosemirror.updateTable({
        pos: data.pos,
        attrs: {
          alignment: formData.alignment,
          presentationOnly: formData.presentationOnly,
        },
        columnWidth: parseInt(formData.columnWidth),
      });
    }

    this.handleClose();
  };

  render() {
    const { dialog: {isOpen, data} } = this.props;
    return (<Dialog open={isOpen} disableRestoreFocus>
      <DialogTitle>Table Properties</DialogTitle>
      <DialogContent>
        <TableDialogForm initialValues={data && data.attrs} onSubmit={this.handleSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={this.handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const formValues = getFormValues('tableDialog');

const mapStateToProps = (state, props) => ({
  formData: formValues(state),
});

export default connect(
  mapStateToProps
)(reduxDialog({
  dialog: 'prosemirror-table'
})(withProsemirror(TableDialog)));
