import { history } from 'prosemirror-history';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { columnResizing, tableEditing } from 'prosemirror-tables';
import homeworkHintDecorations from "./decorations/homeworkHint";
import linkEditor from './linkEditor';

import 'prosemirror-tables/style/tables.css';
import 'prosemirror-gapcursor/style/gapcursor.css';

import keys from './keys';
import rules from './rules';
import nodeViews, {TableView} from './nodeViews';
import columns from './columns';
import selection from './selection';

export default [
  rules,
  keys,
  dropCursor(),
  gapCursor(),
  history(),
  // columnResizing({View: TableView}),
  tableEditing(),
  columns(),
  selection(),
  nodeViews(),
  homeworkHintDecorations(),
  linkEditor()
];

// for tables
document.execCommand('enableObjectResizing', false, false);
document.execCommand('enableInlineTableEditing', false, false);
