import React from 'react';
import {connect} from 'react-redux';

import RemoteSelect from './RemoteSelect';
import AutocompleteClassificationStandard from '../../Autocomplete/AutocompleteClassificationStandard';

import {getClassificationList, requestClassificationList} from "reducers/classifications";

const FilterStandard = props => (
  <React.Fragment>
    <RemoteSelect
      label="Classification"
      request={props.classificationRequest}
      fetch={props.getClassifications}
      value={props.filterState.classificationId}
      onChange={value => props.onChange('classificationId', value)}
    />
    {props.selectedClassificationId && (<AutocompleteClassificationStandard
      fullWidth
      label="Standard"
      classificationId={props.selectedClassificationId}
      onSuggestionSelected={(event, {suggestion}) => props.onChange('standardId', suggestion.id)}
    />)}
  </React.Fragment>
);

const mapStateToProps = () => {
  return (state, props) => ({
    classificationRequest: getClassificationList(state, props),
    selectedClassificationId: props.filterState.classificationId
  });
};

const mapDispatchToProps = dispatch => ({
  getClassifications: () => dispatch(requestClassificationList())
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterStandard);