import React from 'react';
import {connect} from 'react-redux';

import RemoteSelect from './RemoteSelect';

import {getBookList, requestBookList} from "reducers/books";
import {makeBookChapterListSelector, requestChapterListForBook} from "reducers/chapters";
import {makeChapterLessonListSelector, requestLessonListForChapter} from "reducers/lessons";
import AutocompleteBook from "components/Autocomplete/AutocompleteBook";

const FilterBook = (props) => (
  <React.Fragment>
    <AutocompleteBook
      fullWidth
      label="Book"
      onSuggestionSelected={(event, {suggestion}) => props.onChange('bookId', suggestion.id)}
    />
    {/*<RemoteSelect*/}
      {/*label="Book"*/}
      {/*request={props.bookRequest}*/}
      {/*fetch={props.getBooks}*/}
      {/*value={props.filterState.bookId}*/}
      {/*onChange={value => props.onChange('bookId', value)} />*/}
    <RemoteSelect
      label="Chapter"
      request={props.chapterRequest}
      fetch={props.getChapters}
      value={props.filterState.chapterId}
      onChange={value => props.onChange('chapterId', value)} />
    <RemoteSelect
      label="Lesson"
      request={props.lessonRequest}
      fetch={props.getLessons}
      value={props.filterState.lessonId}
      onChange={value => props.onChange('lessonId', value)} />
  </React.Fragment>
);

const mapStateToProps = () => {
  const getChapterList = makeBookChapterListSelector(
    (state, props) => props.filterState.bookId
  );
  const getLessonList = makeChapterLessonListSelector(
    (state, props) => props.filterState.chapterId
  );
  return (state, props) => ({
    bookRequest: getBookList(state, props),
    chapterRequest: getChapterList(state, props),
    lessonRequest: getLessonList(state, props),
  });
};

const mapDispatchToProps = dispatch => ({
  getBooks: () => dispatch(requestBookList()),
  getChapters: bookId => dispatch(requestChapterListForBook(bookId)),
  getLessons: chapterId => dispatch(requestLessonListForChapter(chapterId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBook);