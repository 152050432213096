import React from 'react';
import QueueAnim from 'rc-queue-anim';
import {connect} from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import IconUpload from '@material-ui/icons/Publish';

import ImageSearch from 'components/Search/ImageSearch';
import ImageBrowser from 'components/Media/ImageBrowser';
import ImageUploadDialog from 'components/Dialogs/Media/ImageUpload';

import {openImageDialog} from "reducers/Dialogs/Image";

class MediaSearch extends React.Component {
  state = {
    viewportWidth: null
  };

  componentDidMount() {
    this.getViewportSize();
    window.addEventListener('resize', this.getViewportSize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getViewportSize);
  }

  getViewportSize = () => {
    if (!this.nextAnimationFrame) {
      this.nextAnimationFrame = window.requestAnimationFrame(() => {
        this.setState({viewportWidth: window.innerWidth});
        this.nextAnimationFrame = null;
      });
    }
  };

  handleUpload = event => {
    this.props.openImageDialog();
  };

  handleClick = (event, item) => {
    this.props.history.push(`/app/medias/${item.id}/details`);
  };

  render() {
    const {viewportWidth} = this.state;
    let columns = 4;
    if (viewportWidth < 480) columns = 1;
    else if (viewportWidth < 768) columns = 2;
    else if (viewportWidth < 1024) columns = 3;
    else if (viewportWidth < 1224) columns = 4;
    else if (viewportWidth < 1824) columns = 5;
    else columns = 6;

    return (
      <section className="container-fluid chapter">
        <QueueAnim type="bottom" className="ui-animate">
          <div key="1">
            <article className="article">
              <div>
                <ImageUploadDialog />
                <h2 className="article-title">
                  <Fab color="primary" style={{marginRight:`${8}px`}} onClick={this.handleUpload}>
                    <IconUpload />
                  </Fab>
                  Search Images
                </h2>
                <Paper>
                  <ImageSearch/>
                  <ImageBrowser columns={columns} onClick={this.handleClick}/>
                </Paper>
              </div>
            </article>
          </div>
        </QueueAnim>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openImageDialog: () => dispatch(openImageDialog()),
});

export const MediaSearchView = connect(null, mapDispatchToProps)(MediaSearch);