import {googleApiKey} from 'constants/Config';
const GOOGLE_FONTS_API = `https://www.googleapis.com/webfonts/v1/webfonts?key=${googleApiKey}`;

// ACTION TYPES
export const GOOGLE_FONTS_REQUEST = 'GOOGLE_FONTS_REQUEST';
export const GOOGLE_FONTS_COMPLETE = 'GOOGLE_FONTS_COMPLETE';
export const GOOGLE_FONTS_ERROR = 'GOOGLE_FONTS_ERROR';

// ACTION CREATORS
export const requestGoogleFonts = () => dispatch => new Promise((resolve, reject) => {
  dispatch({type: GOOGLE_FONTS_REQUEST});
  fetch(GOOGLE_FONTS_API)
    .then(response => response.json())
    .then(payload => {
      dispatch({type: GOOGLE_FONTS_COMPLETE, payload});
      resolve(payload);
    })
    .catch(err => {
      dispatch({type: GOOGLE_FONTS_ERROR, payload: err});
      reject(err);
    });
});

const initialState = {
  fonts: {},
  pending: false,
  completed: false,
  error: null,
};

export default (state = initialState, action) => {
  if (!action) return state;
  switch(action.type) {
    case GOOGLE_FONTS_REQUEST: return {...initialState, pending: true};
    case GOOGLE_FONTS_COMPLETE: return {...initialState, fonts: action.payload, completed: true};
    case GOOGLE_FONTS_ERROR: return {...initialState, error: action.payload, completed: true};
    default: return state;
  }
}
