// ACTION TYPES
export const CATEGORY_SELECT = 'CATEGORY_SELECT';

// ACTION CREATORS
export function selectCategory(categoryId) {
  return {type: CATEGORY_SELECT, payload: categoryId};
}

const initialState = {
  selected: null,
};

export default (state = initialState, action) => {
  if (action.type === CATEGORY_SELECT) {
    return {
      ...state,
      selected: action.payload
    };
  }
  return state;
}
