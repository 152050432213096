import {apiClient} from "constants/Clients";

export const bookMenus = key => ({
  request: (bookId) => apiClient.getBookMenus(bookId),
  key,
  auto: true
});
export const createMenuForBook = key => ({
  request: (bookId,menuInfo) => apiClient.createBookMenu(bookId, menuInfo),
  key
});
export const menuDetails = key => ({
  request: (menuId) => apiClient.getMenu(menuId),
  key,
  auto: true
});
export const menuItemsForMenu = key => ({
  request: (menuId) => apiClient.getMenuItemsForMenu(menuId),
  key,
  auto: true
});
export const menuRender = key => ({
  request: (menuId) => apiClient.renderMenu(menuId),
  key,
  auto: true
});
export const createMenuItem = key => ({
  request: (menuId, menuItemInfo) => apiClient.createMenuItemForMenu(menuId, menuItemInfo),
  key
});
export const updateMenuItem = key => ({
  request: (menuItemInfo) => apiClient.updateMenuItem(menuItemInfo),
});
export const deleteMenuItem = key => ({
  request: (menuItemId) => apiClient.deleteMenuItem(menuItemId),
});
