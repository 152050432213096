import Map from './util/map';

export default new Map({
  admin: {
    name: 'CPM Admin',
    id: 'CPM_ADMIN'
  },
  accountant: {
    name: 'CPM Accountant',
    id: 'CPM_ACCOUNTANT'
  },
  employee: {
    name: 'CPM Employee',
    id: 'CPM_EMPLOYEE'
  },
  regionalCoordinator: {
    name: 'Regional Coordinator',
    id: 'CPM_REGIONAL_COORDINATOR'
  },
  teacherLeader: {
    name: 'Teacher Leader',
    id: 'CPM_TEACHER_LEADER'
  },
  customer: {
    name: 'Customer',
    id: 'CUSTOMER'
  },
  faculty: {
    name: 'Faculty',
    id: 'FACULTY'
  },
  student: {
    name: 'Student',
    id: 'STUDENT'
  },
  user: {
    name: 'User',
    id: 'USER'
  },
  bulk: {
    name: 'Bulk API User',
    id: 'BULK_API'
  },
  noAssessment: {
    name: 'No Assessment',
    id: 'NO_ASSESSMENT'
  },
  previewOnly: {
    name: 'Preview Only',
    id: 'PREVIEW_ONLY'
  },
  ebookEditor: {
    name: 'eBooks Editor',
    id: 'CPM_EBOOK_EDITOR'
  },
  assessmentEditor: {
    name: 'Assessment Editor',
    id: 'ASSESSMENT_EDITOR'
  },
  homeworkEditor: {
    name: 'Homework Editor',
    id: 'HOMEWORK_HELP_EDITOR'
  },
  anonymous: {
    name: 'Anonymous',
    id: 'ANONYMOUS'
  }
});
