import * as ACTION_TYPES from './actions';

const initialState = {};
const initialDialogState = {
  isOpen: false,
  data: undefined,
  state: {},
};
const updateDialog = (state, action, values) => {
  let dialog = state[action.metadata.dialogId];
  if (!dialog) return state;
  return {
    ...state,
    [action.metadata.dialogId]: {
      ...dialog,
      ...values
    }
  }
};
const updateDialogState = (state, action, payload) => {
  let dialog = state[action.metadata.dialogId];
  if (!dialog) return state;
  if (dialog.state === payload) return state;
  // Type checking
  if ((typeof dialog.state !== typeof payload) || typeof payload !== 'object') {
    // Any non-object states replace each other
    return updateDialog(state, action, {state: payload});
  } else if (Array.isArray(dialog.state)) {
    // Skip updates for shallow-equal arrays, otherwise replace the old array
    if (Array.isArray(payload) && payload.every((v, i) => dialog.state[i] === v)) return state;
    return updateDialog(state, action, {state: payload});
  } else if (Array.isArray(payload)) {
    // If new state is array and old state is not, replace
    return updateDialog(state, action, {state: payload});
  } else {
    // Merge old and new states only if both are non-array objects
    if (Object.keys(payload).every(k => dialog.state[k] === payload[k])) return state;
    return {
      ...state,
      [action.metadata.dialogId]: {
        ...dialog,
        state: {
          ...dialog.state,
          ...payload,
        }
      }
    }
  }
};
const resetDialog = (state, action) => {
  let dialog = state[action.metadata.dialogId];
  if (!dialog) return state;
  return {
    ...state,
    [action.metadata.dialogId]: {
      ...initialDialogState,
      state: dialog.initialState,
      initialState: dialog.initialState,
    }
  }
};
const openDialog = (state, action, payload) => {
  let dialog = state[action.metadata.dialogId];
  if (!dialog) return state;
  return {
    ...state,
    [action.metadata.dialogId]: {
      ...dialog,
      isOpen: true,
      data: payload.data,
      state: {
        ...dialog.initialState,
        ...payload.state
      },
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.REGISTER:
      if (state[action.payload]) return state;
      return {
        ...state,
        [action.payload]: {
          ...initialDialogState,
          state: action.initialState || initialDialogState.state,
          initialState: action.initialState || initialDialogState.state,
        },
      };
    case ACTION_TYPES.UNREGISTER:
      if (!state[action.payload]) return state;
      let unregisteredState = {...state};
      delete unregisteredState[action.payload];
      return unregisteredState;
    case ACTION_TYPES.OPEN:
      return openDialog(state, action, action.payload);
    case ACTION_TYPES.CLOSE:
      return resetDialog(state, action);
    case ACTION_TYPES.SET_STATE:
      return updateDialogState(state, action, action.payload);
    default: return state;
  }
};
