import {apiClient} from "constants/Clients";

export const glossaryItemDetails = key => ({
  request: glossaryItemId => apiClient.getGlossaryItem(glossaryItemId),
  key,
  auto: true,
});
export const createGlossaryItem = {
  request: (glossaryItem) => apiClient.createGlossaryItem(glossaryItem),
};
export const updateGlossaryItem = key => ({
  request: (glossaryItemId, glossaryItem) => apiClient.updateGlossaryItem({id: glossaryItemId, ...glossaryItem}),
  key,
});
export const deleteGlossaryItem = key => ({
  request: glossaryItemId => apiClient.deleteGlossaryItem(glossaryItemId),
  key,
});
export const glossaryItemCreateElement = key => ({
  request: (glossaryItemId, element) => apiClient.createGlossaryItemElement(glossaryItemId, element),
  key,
});
export const glossaryItemElements = key => ({
  request: glossaryItemId => apiClient.getGlossaryItemElements(glossaryItemId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
