import {
  cyan,
  green,
  red
} from '@material-ui/core/colors';
import { white } from '@material-ui/core/colors/common'
import { createMuiTheme } from '@material-ui/core/styles';


/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  fontFamily: 'Roboto, sans-serif',
  borderRadius: 2,
  palette: {
    primary: {
      main: cyan[500],
      contrastText: '#fff'
    },
    secondary: green,
    error: {
      main: red[500],
      light: red[100]
    }
  },
});
