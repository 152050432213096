import React from 'react';
import { connect } from 'react-redux';
import {Prompt, Redirect} from 'react-router-dom';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";

import IconHistory from '@material-ui/icons/History';
import IconHourglass from '@material-ui/icons/HourglassEmpty';
import IconList from '@material-ui/icons/List';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCheckCircle from '@material-ui/icons/CheckCircle'

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import {defaultElementTypeForContentable} from 'constants/ElementTypes';
import {displayModes,sidebarModes} from "constants/Prosemirror";
import {containerMenu} from 'components/Prosemirror/menu/spec';

import LessonContentContainerNavigation from 'components/Navigation/LessonContentContainerNavigation';
import {LinkedLessonContentContainers} from 'components/LinkList';
import ContainerAssociatedContentTable from "components/Tables/ContainerAssociatedContentTable";

import {renderHomework} from "components/Prosemirror/render";

import ProsemirrorInterface from 'components/Prosemirror/components/ProsemirrorInterface';
import ProsemirrorEditor from 'components/Prosemirror/components/ProsemirrorEditor';
import ProsemirrorMenu from 'components/Prosemirror/components/ProsemirrorMenu';
import ProsemirrorEditModeToggle from 'components/Prosemirror/components/ProsemirrorEditModeToggle';
import ProsemirrorDisplayModeToggle from 'components/Prosemirror/components/ProsemirrorDisplayModeToggle';
import ProsemirrorSidebarMenu from 'components/Prosemirror/components/ProsemirrorSidebarMenu';
import ContentPreview from 'components/Editor/Views/ContentPreview';
import ElementVersionsTable from "components/Tables/PagedTable/ElementVersionsTable";
import MarkupPreview from "components/Editor/Views/MarkupPreview";
import HomeworkPreview from "components/Editor/Views/HomeworkPreview";

import {withClient, createSelector} from "reducers/client";
import {lessonContentContainerDetails, lessonContentContainerElements, elementDetails, updateLessonContentContainer} from "reducers/client/requestTypes";
import WcagReport from "components/Editor/Views/WcagReport";
import PublishStatusIndicator from "components/Editor/PublishStatusIndicator";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const sideNavWidth = 250;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${20}px`,
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s cubic-bezier(0, 0, 0.2, 1)',
    '.nav-collapsed &': {
      left: `${collapsedWidth}px`,
    }
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`
  },
  button: {
    margin: theme.spacing(1),
  },
  navBox: {
    marginTop: `${30}px`
  },
  spacer: {
    width: theme.spacing(1),
  },
  flexColumns: {
    display: 'flex',
    alignItems: 'center',
  },
  editorBox: {
    flex: 1
  },
  previewBox: {
    padding: '16px',
    overflowY: 'scroll',
  },
  compare: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  pane: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${4}px`,
    overflow: 'hidden',
    transition: 'width 0.3s cubic-bezier(0, 0, 0.2, 1)',
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff',
  },
  paneTitle: {
    flex: `0 0 ${48}px`,
    lineHeight: `${48}px`,
    textAlign: 'center',
    fontSize: '18pt',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    backgroundColor: '#f8f8f8',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
    zIndex: 1,
    verticalAlign: 'middle',
  },
  flexLeft: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
  },
  flexCenter: {
    flex: 1,
    textAlign: 'center',
  },
  flexRight: {
    flex: 1,
    textAlign: 'right',
  },
  inline: {
    display: 'inline-flex',
    fontSize: '14pt',
  }
});

class LessonContentContainerEditor extends React.Component {
  state = {
    showAssociated: false,
  };

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {lessonContentContainerDetails} = this.props;
      let containerId = lessonContentContainerDetails.getParams();
      this.props.history.push(`/app/lessonContentContainers/${containerId}/${path}`);
    }
  }
  showAssociatedContent = () => {
    this.setState({
      showAssociated: !this.state.showAssociated,
    });
  };

  renderEditor = elementData => {
    const {lessonContentContainerDetails, editMode, displayMode, sidebarMode, classes} = this.props;
    const currentVersion = elementData &&
      elementData.currentVersion;

    if (currentVersion) {
      return (<ProsemirrorInterface currentVersion={currentVersion}>
        <div className={classes.compare}>
          <div className={classes.pane}>
            <div className={classnames(classes.paneTitle, classes.flexColumns)}>
              <div className={classes.flexLeft}>
                <ProsemirrorEditModeToggle className={classes.inline} />
                <ProsemirrorDisplayModeToggle className={classes.inline} />
              </div>
              <div className={classes.flexCenter}>{sidebarMode === sidebarModes.COMPARE.id && 'C3PO'}</div>
              <ProsemirrorSidebarMenu className={classes.flexRight} />
            </div>
            {editMode ? (
              <React.Fragment>
                <ProsemirrorMenu>{containerMenu}</ProsemirrorMenu>
                <ProsemirrorEditor contentClassName={displayMode} contentableId={lessonContentContainerDetails.getParams()} />
              </React.Fragment>
            ) : (
              <ContentPreview
                contentVersionId={currentVersion.id}
                className={classnames(classes.previewBox, displayMode)}
                postProcess={renderHomework}
              />
            )}
          </div>
          {sidebarMode === sidebarModes.COMPARE.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>Homework Help</div>
            <div className={classnames(classes.previewBox)}>
              <HomeworkPreview homeworkId={lessonContentContainerDetails.getParams()} />
            </div>
          </div>}
          {sidebarMode === sidebarModes.MARKUP.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>Markup</div>
            <div className={classnames(classes.previewBox)}>
              <MarkupPreview/>
            </div>
          </div>}
          {sidebarMode === sidebarModes.WCAG.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>WCAG 2.0 Compliance Status</div>
            <WcagReport/>
          </div>}
          {/*{sidebarMode === sidebarModes.HISTORY.id && <div>*/}
          {/*  /!*<WithFetch request={contentRequest}>{this.renderHistory}</WithFetch>*!/*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
          {/*{sidebarMode === sidebarModes.COMPLIANCE.id && <div>*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
        </div>
      </ProsemirrorInterface>);
    } else {
      return <span style={{color:'red'}}>Unable to resolve content</span>;
    }
  };

  render() {
    const {
      sidebarMode,
      docChanged,
      classes,
      lessonContentContainerDetails,
      lessonContentContainerElements,
      elementDetails,
    } = this.props;

    const elementType = this.props.match.params.elementType || defaultElementType.name;
    let containerId = lessonContentContainerDetails.getParams();
    let editorContent = <CircularProgress/>;
    let contentVersionId = '';
    if (elementDetails.isLoaded()) {
      editorContent = this.renderEditor(elementDetails.get());
      contentVersionId = elementDetails.get().currentVersionId;
    } else if (!lessonContentContainerElements.isLoading() && !elementDetails.isLoading()) {
      let {elementType} = this.props.match.params;
      if (elementType && lessonContentContainerElements.isLoaded()) {
        let elements = lessonContentContainerElements.get();
        if (elements.length > 0 && !elements.find(e => e.elementType === elementType)) {
          return (<Redirect to={`/app/lessonContentContainers/${containerId}/editor/${elements[0].elementType}`} />);
        }
      }

      editorContent = this.renderEditor(null);
    }

    return (
      <div className={classes.root}>
        <Prompt
          when={docChanged}
          message="You have unsaved changes on this page. Are you sure you want to leave? Your changes will not be saved."
        />
        <div className={classes.buttonBox}>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <Tooltip title="Version History">
            <Fab color="primary" className={classes.button}>
              <IconHistory onClick={e => this.navigateTo(`versions/${elementType}`)} />
            </Fab>
          </Tooltip>
          {/*<Tooltip title={"Show Associated Content"}>*/}
          {/*  <Fab color={"primary"} className={classes.button}>*/}
          {/*    <IconList onClick={this.showAssociatedContent} />*/}
          {/*  </Fab>*/}
          {/*</Tooltip>*/}
          <Tooltip title={"Share with another Lesson Content Container"}>
            <Fab color={"primary"} className={classes.button}>
              <IconCallMerge onClick={() => this.navigateTo(`merge`)} />
            </Fab>
          </Tooltip>
          <Tooltip title={"Merge History"}>
            <Fab color={"primary"} className={classes.button}>
              <IconHourglass onClick={() => this.navigateTo(`mergeHistory`)} />
            </Fab>
          </Tooltip>
          <Tooltip title="Show Accessibility Compliance">
            <Fab color="primary" className={classes.button}>
              <IconCheckCircle onClick={() => this.navigateTo('accessibility')} />
            </Fab>
          </Tooltip>
        </div>
        <div className={classnames(classes.navBox, classes.flexColumns)}>
          <LessonContentContainerNavigation containerId={containerId} elementType={elementType} />
          <div className={classes.spacer} />
          <LinkedLessonContentContainers containerId={containerId} elementType={elementType} />
        </div>
        {editorContent}
        <ContainerAssociatedContentTable open={this.state.showAssociated} close={this.showAssociatedContent} />
      </div>);
  }
}

const mapStateToProps = (state, props) => ({
  displayMode: displayModes[state.prosemirror.displayMode].className,
  editMode: state.prosemirror.editMode,
  sidebarMode: state.prosemirror.sidebarMode,
  docChanged: state.prosemirror.docChanged,
});

const getLessonContentContainerId = (state, props) => state.lessonContentContainers.selected;
const getElementType = (state, props) => props.match.params.elementType || defaultElementType.name;
const getLessonContentContainerElements = createSelector('lessonContentContainerElements',getLessonContentContainerId);
export const LessonContentContainerEditorView = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getLessonContentContainerId),
    lessonContentContainerElements: lessonContentContainerElements(getLessonContentContainerId),
    elementDetails: elementDetails((state, props) => {
      const lessonContentContainerElements = getLessonContentContainerElements(state, props);
      if (lessonContentContainerElements && lessonContentContainerElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const lessonContentContainerElement = lessonContentContainerElements.get().find(e => e.elementType === elementType);
        if (lessonContentContainerElement) return lessonContentContainerElement.id;
      }
      return null;
    }),
  }
})(connect(
  mapStateToProps,
)(withStyles(styles)(
  LessonContentContainerEditor
)));
