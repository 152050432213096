import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import IconMerge from '@material-ui/icons/CallMerge';
import IconRemove from '@material-ui/icons/Remove';
import IconDots from "@material-ui/icons/MoreHoriz";

import {withClient} from "reducers/client";
import {
  lessonContainers,
  lessonContainerOrder,
  lessonCreateLessonContentContainer,
  updateLessonContentContainer,
  deleteLessonContentContainer,
  lessonContentContainerAddElement,
  lessonContentContainerRemoveElement
} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import LessonContainerTableRow from './LessonContainerTableRow';
import CreateLessonContentContainerElementDialog from "components/Dialogs/LessonContentContainers/CreateLessonContentContainerElement";
import LinkLessonContentContainerDialog from 'components/Dialogs/LessonContentContainers/LinkLessonContentContainer';
import ConfirmationDialog from "components/Dialogs/Confirmation";

import {openLessonContentContainerLinkDialog} from "reducers/Dialogs/LessonContentContainerLink";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class LessonContainersTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    lessonContainers: PropTypes.object.isRequired,
    lessonContainerOrder: PropTypes.object.isRequired,
    lessonCreateLessonContentContainer: PropTypes.object.isRequired,
    updateLessonContentContainer: PropTypes.object.isRequired,
    deleteLessonContentContainer: PropTypes.object.isRequired,
    lessonContentContainerAddElement: PropTypes.object.isRequired,
    lessonContentContainerRemoveElement: PropTypes.object.isRequired,

    lessonId: PropTypes.string.isRequired,

    openLessonContentContainerLinkDialog: PropTypes.func.isRequired,
  };

  state = {
    lessonContentContainers: [],
    adding: false,
    editingId: -1,
    edit: {
      name: '',
      number: '',
      elements: [],
      linkedContainer: null
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    const {classes, lessonContainers} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    if (lessonContainers.isLoaded()) {
      this.setState({ lessonContentContainers: lessonContainers.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {lessonContainers} = this.props;
    const {lessonContainers:prevLessonContentContainers} = prevProps;
    if (lessonContainers.isLoaded() && lessonContainers.hasChanged(prevLessonContentContainers)) {
      this.setState({ lessonContentContainers: lessonContainers.get() });
    }
    if (this.state.editingId >= this.state.lessonContentContainers.length) {
      this.setState({
        editingId: this.state.lessonContentContainers.length - 1
      });
    }
  }

  isWaiting() {
    const {
      lessonContainers,
      lessonContainerOrder,
      lessonCreateLessonContentContainer,
      updateLessonContentContainer,
      deleteLessonContentContainer,
      lessonContentContainerAddElement,
      lessonContentContainerRemoveElement
    } = this.props;
    return lessonContainers.isLoading() ||
      lessonContainerOrder.isLoading() ||
      lessonCreateLessonContentContainer.isLoading() ||
      updateLessonContentContainer.isLoading() ||
      deleteLessonContentContainer.isLoading() ||
      lessonContentContainerAddElement.isLoading() ||
      lessonContentContainerRemoveElement.isLoading();
  }
  pendingChanges() {
    const {lessonContentContainers, adding, editingId, edit} = this.state;
    if (adding) return edit.name.length > 0 && edit.number.length > 0;
    if (lessonContentContainers[editingId]) {
      return lessonContentContainers[editingId].name !== edit.name ||
        lessonContentContainers[editingId].number !== edit.number;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };

  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: '',
          number: '',
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.lessonContentContainers[editingId].name,
        number: this.state.lessonContentContainers[editingId].number,
      },
    });
  };
  handleClickSave = () => {
    const {lessonCreateLessonContentContainer, updateLessonContentContainer, lessonContainers} = this.props;
    const {lessonContentContainers, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        let containerData = {...edit};
        if (containerData.linkedContainer) {
          containerData.elementIds = containerData.linkedContainer.elements.map(e => e.id);
          delete containerData.linkedContainer;
        }
        lessonCreateLessonContentContainer.sendRequest(containerData)
          .then(() => lessonContainers.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateLessonContentContainer.sendRequest(lessonContentContainers[editingId].id, edit)
          .then(() => lessonContainers.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleClickLink = () => {
    const {openLessonContentContainerLinkDialog} = this.props;
    const {lessonContentContainers, editingId, edit:{linkedContainer}} = this.state;
    if (linkedContainer) {
      this.setState({
        edit: {
          ...this.state.edit,
          linkedContainer: null,
        }
      });
    } else {
      openLessonContentContainerLinkDialog();
    }
  };
  handleClickMerge = (event, element, container) => {
    const {lessonContentContainerAddElement, lessonContentContainerRemoveElement, deleteLessonContentContainer, lessonContainers} = this.props;
    const {lessonContentContainers, editingId} = this.state;

    let promises = [];
    promises.push(lessonContentContainerAddElement.sendRequest(lessonContentContainers[editingId].id, element.id));

    if (container.elements.length > 1) {
      promises.push(lessonContentContainerRemoveElement.sendRequest(container.id, element.id));
    } else {
      promises.push(deleteLessonContentContainer.sendRequest(container.id));
    }

    Promise.all(promises).then(() => lessonContainers.sendRequest());
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteLessonContentContainer, lessonContainers} = this.props;
    const {lessonContentContainers, editingId} = this.state;
    deleteLessonContentContainer.sendRequest(lessonContentContainers[editingId].id)
      .then(() => lessonContainers.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };
  handleConfirmLink = lessonContentContainer => {
    const {lessonContentContainers, edit, editingId} = this.state;
    if (editingId >= 0) {
      // console.log(lessonContentContainer);
      const {lessonContainers, lessonContentContainerAddElement} = this.props;
      let promises = lessonContentContainer.elements.map(
        element => lessonContentContainerAddElement.sendRequest(lessonContentContainers[editingId].id, element.id)
      );
      Promise.all(promises).then(() => lessonContainers.sendRequest());
    } else {
      this.setState({
        edit: {
          ...edit,
          linkedContainer: lessonContentContainer,
        }
      });
    }
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        return true;
      }
      t = t.parentElement;
    }
    return false;
  };
  handleChangeOrder = updatedList => {
    const lessonContentContainers = this.props.lessonContainers.get();
    this.props.lessonContainerOrder.sendRequest(updatedList.map(c => lessonContentContainers[c].id))
      .then(() => this.props.lessonContainers.sendRequest());
  };

  render() {
    const {lessonContentContainers, adding, editingId, edit, confirmationOpen} = this.state;
    if (!lessonContentContainers) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Lesson Content Container"
        message={`Are you sure you want to delete Lesson Content Container ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <CreateLessonContentContainerElementDialog />
      <LinkLessonContentContainerDialog
        onConfirm={this.handleConfirmLink}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"/>
            <TableCell>Name</TableCell>
            <TableCell>Number</TableCell>
            <TableCell>Content</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={7} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={LessonContainerTableRow}
          itemProps={{
            classes,
            adding: Boolean(adding),
            waiting: Boolean(waiting),
            pendingChanges: Boolean(this.pendingChanges()),
            editingId,
            edit,
            onEdit: this.handleEdit,
            onClickEdit: this.handleClickEdit,
            onClickSave: this.handleClickSave,
            onClickDelete: this.handleClickDelete,
            onClickMerge: this.handleClickMerge,
            onClickLink: this.handleClickLink,
          }}
          items={lessonContentContainers}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell padding="checkbox"/>
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.number}
                  onChange={ev => this.handleEdit('number', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell>
                {edit.linkedContainer ? (
                  <Button color="default" variant="outlined" onClick={this.handleClickLink}>
                    <IconRemove />
                    {edit.linkedContainer.bookName} {edit.linkedContainer.name}
                  </Button>
                ) : (
                  <Button color="default" variant="outlined" onClick={this.handleClickLink}>
                    <IconMerge />
                    Link
                  </Button>
                )}
              </TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={7} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

const mapDispatchToProps = dispatch => ({
  openLessonContentContainerLinkDialog: () => dispatch(openLessonContentContainerLinkDialog()),
});

export default withClient({
  hooks: {
    lessonContainers: lessonContainers((state, props) => props.lessonId),
    lessonContainerOrder: lessonContainerOrder((state, props) => props.lessonId),
    lessonCreateLessonContentContainer: lessonCreateLessonContentContainer((state, props) => props.lessonId),
    updateLessonContentContainer: updateLessonContentContainer(),
    deleteLessonContentContainer: deleteLessonContentContainer(),
    lessonContentContainerAddElement: lessonContentContainerAddElement(),
    lessonContentContainerRemoveElement: lessonContentContainerRemoveElement()
  },
})(connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(LessonContainersTable))));
