import {wrappingInputRule} from "prosemirror-inputrules";
import schema from "components/Prosemirror/schema";

const asciiLowerA = 'a'.charCodeAt(0) - 1;
const asciiUpperA = 'A'.charCodeAt(0) - 1;

const romanLookup = {
  'i': 1,
  'v': 5,
  'x': 10,
  'l': 50,
  'c': 100,
  'd': 500,
  'm': 1000
};
const romanIndex = str => {
  str = str.toLowerCase();
  let acc = 0;
  let lastValue = 0;
  for (let i = str.length - 1; i >= 0; --i) {
    let value = romanLookup[str[i]];
    if (value < lastValue) acc -= value;
    else acc += value;
    lastValue = value;
  }
  return acc;
};

export const orderedNumberList = wrappingInputRule(
  /^(\d+)\.\s$/,
  schema.nodes.ordered_list,
  match => ({ order: +match[1] }),
  (match, node) => node.childCount + node.attrs.order === +match[1]
);

export const orderedLowerAlphaList = wrappingInputRule(
  /^([a-z])\.\s$/,
  schema.nodes.ordered_list,
  match => {
    if (match[1] === 'i') return { order: 1, listStyle: 'lower-roman' };
    return { order: +match[1].charCodeAt(0) - asciiLowerA, listStyle: 'lower-alpha' };
  },
  (match, node) => {
    if (node.attrs.listStyle !== 'lower-alpha') return false;
    return node.childCount + node.attrs.order === +match[1].charCodeAt(0) - asciiLowerA
  }
);

export const orderedUpperAlphaList = wrappingInputRule(
  /^([A-Z])\.\s$/,
  schema.nodes.ordered_list,
  match => {
    if (match[1] === 'I') return { order: 1, listStyle: 'upper-roman' };
    return { order: +match[1].charCodeAt(0) - asciiUpperA, listStyle: 'upper-alpha' };
  },
  (match, node) => {
    if (node.attrs.listStyle !== 'upper-alpha') return false;
    return node.childCount + node.attrs.order === +match[1].charCodeAt(0) - asciiUpperA
  }
);

export const orderedLowerRomanList = wrappingInputRule(
  /^([ivxlcdm]+)\.\s$/,
  schema.nodes.ordered_list,
  match => ({ order: romanIndex(match[1]), listStyle: 'lower-roman' }),
  (match, node) => {
    if (node.attrs.listStyle !== 'lower-roman') return false;
    return node.childCount + node.attrs.order === romanIndex(match[1])
  }
);

export const orderedUpperRomanList = wrappingInputRule(
  /^([IVXLCDM]+)\.\s$/,
  schema.nodes.ordered_list,
  match => ({ order: romanIndex(match[1]), listStyle: 'upper-roman' }),
  (match, node) => {
    if (node.attrs.listStyle !== 'upper-roman') return false;
    return node.childCount + node.attrs.order === romanIndex(match[1])
  }
);

export const bulletList = wrappingInputRule(
  /^\s*([-+*])\s$/,
  schema.nodes.bullet_list
);

export const listRules = [
  orderedNumberList,
  orderedLowerAlphaList,
  orderedUpperAlphaList,
  orderedLowerRomanList,
  orderedUpperRomanList,
  bulletList,
];