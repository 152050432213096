import React from 'react';
import {connect} from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import ButtonBase from "@material-ui/core/ButtonBase";

const columnSetWidth = 12;
const styles = theme => ({
  root: {
  },
  row: {
    padding: theme.spacing(1),
    borderBottom: '1px solid #f0f0f0'
  },
  rowHeading: {
    display: 'inline-block',
    paddingBottom: `${24}px`,
    paddingRight: `${40}px`,
    fontWeight: 'bold'
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    padding: theme.spacing(1),
  },
  itemSelected: {
    backgroundColor: theme.palette.secondary.main
  },
  itemDisplay: {
    width: `${120}px`,
    height: `${80}px`,
    borderCollapse: 'collapse',
    backgroundColor: 'white'
  },
  itemColumn: {
    border: '1px solid #888888'
  },
  3: { width: `${3/columnSetWidth*100}%` },
  4: { width: `${4/columnSetWidth*100}%` },
  6: { width: `${6/columnSetWidth*100}%` },
  8: { width: `${8/columnSetWidth*100}%` },
  9: { width: `${9/columnSetWidth*100}%` }
});

export const LAYOUTS = [
  [3,9],
  [4,8],
  [6,6],
  [8,4],
  [9,3],
  [3,3,6],
  [3,6,3],
  [4,4,4],
  [6,3,3],
  [3,3,3,3]
];

const ColumnLayoutSelect = ({classes, selected, onChange}) => {
  const layoutOption = (key) => (
    <ButtonBase
      className={classnames(classes.item, {[classes.itemSelected]: selected === key})}
      onClick={ev => onChange(ev, key)}>
      <table className={classes.itemDisplay}>
        <tbody>
        <tr>{LAYOUTS[key].map((w,i) =>
          <td key={i} className={classes.itemColumn} style={{width:`${w/12*100}%`}}/>
        )}</tr>
        </tbody>
      </table>
    </ButtonBase>
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.rowHeading}>
          2 Columns
        </div>
        {layoutOption(0)}
        {layoutOption(1)}
        {layoutOption(2)}
        {layoutOption(3)}
        {layoutOption(4)}
      </div>
      <div className={classes.row}>
        <div className={classes.rowHeading}>
          3 Columns
        </div>
        {layoutOption(5)}
        {layoutOption(6)}
        {layoutOption(7)}
        {layoutOption(8)}
      </div>
      <div className={classes.row}>
        <div className={classes.rowHeading}>
          4 Columns
        </div>
        {layoutOption(9)}
      </div>
    </div>
  );
};

export default withStyles(styles)(ColumnLayoutSelect);