import React from 'react';
import {getFormValues, getFormSyncErrors, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';

import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconError from '@material-ui/icons/Error';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";

import {
  STATUS_READY,
  STATUS_WAITING,
  STATUS_SUCCESS,
  STATUS_FAILURE
} from 'constants/Dialogs';
import {
  closeLessonContentContainerElementDialog,
} from 'reducers/Dialogs/LessonContentContainerElement/index';

import {getValidElementTypesForContentable} from "constants/ElementTypes";
import {withClient} from "reducers/client/index";
import {lessonContentContainerElements, lessonContentContainerCreateElement, elementCreateCurrentVersion} from "reducers/client/requestTypes";

import CreateLessonContentContainerElementDialogForm from './form';

const types = getValidElementTypesForContentable('lessonContentContainer');

class CreateLessonContentContainerElementDialog extends React.Component {
  componentDidUpdate(prevProps) {
    const { isOpen, lessonContentContainerCreateElement, elementCreateCurrentVersion } = this.props;
    if (isOpen && !prevProps.isOpen) {
      lessonContentContainerCreateElement.clear();
      elementCreateCurrentVersion.clear();
    }
  }

  handleClose() {
    const { mappedCloseDialog } = this.props;
    mappedCloseDialog();
  }
  handleSubmit() {
    const { lessonContentContainerElements, lessonContentContainerCreateElement, elementCreateCurrentVersion } = this.props;
    const {elementType} = this.props.formData;
    lessonContentContainerCreateElement.sendRequest({elementType: elementType.name, language: 'English'})
      .then(result => elementCreateCurrentVersion.sendRequest(result.id, {html: ''}))
      .then(() => lessonContentContainerElements.sendRequest());
  }

  render() {
    const {
      isOpen,
      formData,
      formErrors,
      lessonContentContainerElements,
      lessonContentContainerCreateElement,
      elementCreateCurrentVersion
    }  = this.props;

    let content = false;
    let allowCancel = true;
    let showSubmit = false;
    let allowSubmit = false;
    let closeText = 'Cancel';
    let status = STATUS_READY;

    if (lessonContentContainerCreateElement.isError() || elementCreateCurrentVersion.isError()) {
      status = STATUS_FAILURE;
    } else if (elementCreateCurrentVersion.isLoaded()) {
      status = STATUS_SUCCESS;
    } else if (lessonContentContainerCreateElement.isLoading() || elementCreateCurrentVersion.isLoading()) {
      status = STATUS_WAITING;
    }

    switch(status) {
      case STATUS_READY:
        if (lessonContentContainerElements.isLoaded()) {
          let elements = lessonContentContainerElements.get();
          let validTypes = types.filter(t => !elements.some(e => e.elementType === t.name));

          content = <CreateLessonContentContainerElementDialogForm
            elementTypes={types}
            initialValues={{elementType: validTypes[0]}}
            blacklist={lessonContentContainerElements.get().map(e => e.elementType)}
          />;
        }
        showSubmit = true;
        allowSubmit = formData && !!formData.elementType && !formErrors.elementType;
        break;
      case STATUS_SUCCESS:
        content = (<div><IconCheckCircle /> Done</div>);
        closeText = 'Ok';
        break;
      case STATUS_WAITING:
        content = (<div style={{textAlign:'center'}}>
          <CircularProgress size={100} />
        </div>);
        allowCancel = false;
        break;
      case STATUS_FAILURE:
        content = (<div>
          <IconError /> An error occurred
        </div>);
        closeText = 'Ok';
        break;
      default:
        break;
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle>Add Content to Lesson Content Container</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={ev => this.handleClose(ev)} color="primary" disabled={!allowCancel}>
          {closeText}
        </Button>
        {showSubmit && <Button onClick={ev => this.handleSubmit(ev)} color="primary" disabled={!allowSubmit}>
          Submit
        </Button>}
      </DialogActions>
    </Dialog>);
  }
}


const formValues = getFormValues('createLessonContentContainerElementDialog');
const formErrors = getFormSyncErrors('createLessonContentContainerElementDialog');

const mapStateToProps = state => ({
  isOpen: state.dialogs.lessonContentContainerElement.isOpen,
  dialogData: state.dialogs.lessonContentContainerElement.data,

  formData: formValues(state),
  formErrors: formErrors(state),
});

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeLessonContentContainerElementDialog()),
  mappedSetStatus: status => dispatch(),
});

const getContainerId = state =>
  state.dialogs.lessonContentContainerElement.data &&
  state.dialogs.lessonContentContainerElement.data.containerId;
export default withClient({
  hooks: {
    lessonContentContainerElements: lessonContentContainerElements(getContainerId),
    lessonContentContainerCreateElement: lessonContentContainerCreateElement(getContainerId),
    elementCreateCurrentVersion: elementCreateCurrentVersion(),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLessonContentContainerElementDialog));
