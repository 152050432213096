import * as ACTION_TYPES from './actions';

const initialState = {};
const initialRequestState = {
  pending: false,
  completed: false,
  data: {},
  metadata: {},
  error: null,
};

const updateRequest = (state, action, value) => {
  let requests = state[action.metadata.name];
  if (!requests) return state;
  const requestId = requests.findIndex(r => r.metadata.params === action.metadata.params);
  if (requestId === -1) requests = requests.concat({...initialRequestState, ...value});
  else requests = requests.slice(0,requestId).concat({...requests[requestId], ...value}, requests.slice(requestId+1));
  return {
    ...state,
    [action.metadata.name]: requests,
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.REGISTER_REQUEST:
      return {
        ...state,
        [action.payload.name]: [],
      };
    case ACTION_TYPES.SEND_REQUEST:
      return updateRequest(state, action, {
        metadata: action.metadata,
        pending: true,
        completed: false
      });
    case ACTION_TYPES.REQUEST_SUCCESS:
      return updateRequest(state, action, {
        pending: false,
        completed: true,
        data: action.payload,
        error: null
      });
    case ACTION_TYPES.REQUEST_ERROR:
      return updateRequest(state, action, {
        pending: false,
        completed: true,
        data: {},
        error: action.payload
      });
    case ACTION_TYPES.CLEAR_REQUEST:
      return updateRequest(state, action, {
        ...initialRequestState,
        metadata: action.metadata,
      });
    default: return state;
  }
};
