import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {
  Radio,
  Typography,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import IconLink from '@material-ui/icons/Link';

import ElementTypes from 'constants/ElementTypes';
import {asArray} from "constants/util/map";

import {withClient} from "reducers/client";
import {tabbedViewTabs} from "reducers/client/requestTypes";

const elementTypes = asArray(ElementTypes);

const styles = theme => ({
  blacklist: {
    color: '#b0b0b0',
    cursor: 'not-allowed',
  },
});

class BrowserTabList extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    blacklist: PropTypes.arrayOf(PropTypes.string),
    tabbedViewTabs: PropTypes.object,
    tabbedViewId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.string,
  };
  static defaultProps = {
    blacklist: [],
  };

  render() {
    const {classes, blacklist, tabbedViewTabs, selected, onClick} = this.props;
    if (tabbedViewTabs.isLoaded()) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>
                Tab
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tabbedViewTabs.get().map(t => {
              const isBlacklisted = blacklist.includes(t.id);
              const className = isBlacklisted ? classes.blacklist : '';
              return (
                <TableRow
                  key={t.id}
                  hover={!isBlacklisted}
                  onClick={ev => !isBlacklisted && onClick(ev, t)}
                  className={className}
                  selected={selected === t.id}
                >
                  <TableCell padding="checkbox">
                    <Radio checked={selected === t.id} disabled={isBlacklisted} />
                  </TableCell>
                  <TableCell className={className}>
                    {t.name}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    return null;
  }
}

export default withClient({
  hooks: {
    tabbedViewTabs: tabbedViewTabs((state, props) => props.tabbedViewId)
  }
})(withStyles(
  styles
)(BrowserTabList));
