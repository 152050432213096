import {apiClient} from "constants/Clients";

export const assessmentProblemDetails = key => ({
  request: assessmentProblemId => apiClient.getAssessmentProblem(assessmentProblemId),
  key,
  auto: true,
});
export const assessmentProblemElements = key => ({
  request: assessmentProblemId => apiClient.getAssessmentProblemElements(assessmentProblemId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const assessmentProblemStandards = key => ({
  request: assessmentProblemId => apiClient.getStandardsForAssessmentProblem(assessmentProblemId),
  key,
  auto: true,
});
export const assessmentProblemAddElement = key => ({
  request: (containerId, elementId) => apiClient.addAssessmentProblemElement(containerId, elementId),
  key,
});
export const assessmentProblemRemoveElement = key => ({
  request: (containerId, elementId) => apiClient.removeAssessmentProblemElement(containerId, elementId),
  key,
});
export const updateAssessmentProblem = key => ({
  request: (assessmentProblemId, assessmentProblemInfo) => apiClient.updateAssessmentProblem({...assessmentProblemInfo, id: assessmentProblemId}),
  key,
});
export const deleteAssessmentProblem = key => ({
  request: assessmentProblemId => apiClient.deleteAssessmentProblem(assessmentProblemId),
  key,
});
