import React from 'react';
import QueueAnim from 'rc-queue-anim';
import Paper from '@material-ui/core/Paper';

import LessonContentContainerSearch from 'components/Search/LessonContentContainerSearch';

export const LessonContentContainerSearchView = props => (
  <section className="container-fluid chapter">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              Search Lesson Content Containers
            </h2>
            <Paper>
              <LessonContentContainerSearch />
            </Paper>
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);
