import React from "react";
import PropTypes from 'prop-types';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import {withClient} from "reducers/client";
import {bookSections} from "reducers/client/requestTypes";

class BrowserChapterList extends React.Component {
  static propTypes = {
    bookSections: PropTypes.object,
    bookId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {bookSections, onClick} = this.props;
    if (bookSections.isLoaded()) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Section
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookSections.get().map(s => (
              <TableRow key={s.id} hover onClick={ev => onClick(ev, s)}>
                <TableCell>
                  {s.type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    return null;
  }
}

export default withClient({
  hooks: {
    bookSections: bookSections((state, props) => props.bookId)
  }
})(BrowserChapterList);
