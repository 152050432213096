import {lessonBlocks,containerBlocks, assessmentBlocks} from './groups/blocks';
import {lessonMarks, containerMarks, assessmentMarks} from './groups/marks';
import {lessonInsert, containerInsert, assessmentInsert} from './groups/insert';
import {tableMenu, assessmentTableMenu} from "./groups/table";
import {listMenu} from "./groups/list";
import history from './groups/history';
import columns from './groups/columns';

export const lessonMenu = [
  lessonBlocks,
  lessonMarks,
  lessonInsert,
  tableMenu,
  columns,
  listMenu,
  history,
];

export const containerMenu = [
  containerBlocks,
  containerMarks,
  containerInsert,
  tableMenu,
  columns,
  listMenu,
  history,
];

export const assessmentMenu = [
  assessmentBlocks,
  assessmentMarks,
  assessmentInsert,
  assessmentTableMenu,
  columns,
  listMenu,
  history,
];
