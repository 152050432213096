import {apiClient} from "constants/Clients";

export const elementDetails = key => ({
  request: elementId => apiClient.getElement(elementId),
  key,
  auto: true,
});
export const elementStandards = key => ({
  request: elementId => apiClient.getClassificationStandardsForElementPublishedVersion(elementId),
  key,
  auto: true,
});
export const elementRemoveStandardFromPublishedVersion = key => ({
  request: (elementId, standardId) => apiClient.removeClassificationStandardFromElementPublishedVersion(elementId, standardId),
  key,
});
export const elementAddStandardToPublishedVersion = key => ({
  request: (elementId, standardId) => apiClient.addClassificationStandardToElementPublishedVersion(elementId, standardId),
  key,
});
export const elementParents = key => ({
  request: elementId => apiClient.getParentsForElement(elementId),
  key,
  auto: true,
});
export const updateElement = key => ({
  request: (elementId, element) => apiClient.updateElement({id: elementId, ...element}),
  key,
});
export const deleteElement = key => ({
  request: elementId => apiClient.deleteElement(elementId),
  key,
});
export const elementCreateCurrentVersion = key => ({
  request: (elementId, contentVersion, isDraft) => {
    let req = apiClient.createElementCurrentVersion(elementId, contentVersion);
    if (isDraft) req.setQueryParams({isDraft});
    return req;
  },
  key
});
export const elementSplitCurrentVersion = key => ({
  request: (elementId, contentVersion, isDraft, splitId) => {
    console.log('elementSplitCurrentVersion',elementId, contentVersion, isDraft, splitId);
    let req = apiClient.createElementCurrentVersion(elementId, contentVersion);
    let params = {
      splitId,
      comment: "SAVE_AND_UNLINK"
    };
    if (isDraft) params.isDraft = "true";
    req.setQueryParams(params);
    return req;
  },
  key
});
export const elementUnicodeCSV = key => ({
  request: query => apiClient.searchElementsForUnicodeAndGetCSV(query),
  key
});
