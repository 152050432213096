import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {SectionDetailsView} from "views/section/SectionDetails";

import {selectSection} from "reducers/sections";

export const SectionRoutes = ({match: {url, path, params: {sectionId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectSection(sectionId))
  }, [sectionId]);

  const selectedSection = useSelector(state => state.sections.selected);
  if (!selectedSection) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={SectionDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
