import {apiClient} from "../../../constants/Clients";

export default function image(dom) {
  dom.querySelectorAll('img').forEach(function (element) {
    // eBooks source quick fix
    const src = element.getAttribute('src');
    if (src[0] === '/') {
      element.setAttribute('src',`https://ebooks-test.cpm.org${src}`);
    } else if (src.startsWith('images/')) {
      element.setAttribute('src',`https://ebooks-test.cpm.org/${src}`);
    }
  });
}
