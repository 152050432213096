import React from "react";
import PropTypes from 'prop-types';

import {TableCell, TableSortLabel, Tooltip} from "@material-ui/core";

const TableSortHeading = ({pagination: {sort}, sortId, onClick, children, ...props}) => (
  <TableCell
    {...props}
    sortDirection={sort.by === sortId ? (sort.asc ? 'asc' : 'desc') : false}
  >
    <Tooltip
      title="Sort"
      enterDelay={300}
    >
      <TableSortLabel
        active={sort.by === sortId}
        direction={sort.asc ? 'asc' : 'desc'}
        onClick={onClick}
      >
        {children}
      </TableSortLabel>
    </Tooltip>
  </TableCell>
);

TableSortHeading.propTypes = {
  pagination: PropTypes.shape({
    sort: PropTypes.shape({
      by: PropTypes.string.isRequired,
      asc: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  sortId: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default TableSortHeading;