import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {closeMergeConfirmationDialog} from "reducers/Dialogs/MergeConfirmation";

import Button from "@material-ui/core/Button";

import {withClient, createLoadedSelector} from "reducers/client";
import {manual, aliasFor, mergeTabs, tabDetails, tabbedViewTabs, tabElements} from "reducers/client/requestTypes";

import {TabReference} from "components/ContentReference";
import MergeInformationTable from 'components/Tables/MergeInformationTable';

class MergeTabsConfirmationDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      tabIds: PropTypes.arrayOf(PropTypes.string),
      selection: PropTypes.object,
    }),
    mergeTabs: PropTypes.object,
    mainTab: PropTypes.object,
    mergeTab: PropTypes.object,
    mainTabbedViewTabs: PropTypes.object,
    mergeTabbedViewTabs: PropTypes.object,
    mainTabElements: PropTypes.object,
    mergeTabElements: PropTypes.object,
    mappedCloseDialog: PropTypes.func.isRequired,
    history: PropTypes.object,
    staticContext: PropTypes.object,
  };

  handleConfirm = () => {
    const {mergeTabs, mappedCloseDialog, history, data} = this.props;

    mergeTabs.sendRequest(data)
      .then(() => {
        const {
          mainTabbedViewTabs: tabbedView0,
          mergeTabbedViewTabs: tabbedView1,
          mainTabElements: tab0,
          mergeTabElements: tab1
        } = this.props;
        // Refresh data that may be affected by the merge
        if (tabbedView0.isLoaded()) tabbedView0.sendRequest();
        if (tabbedView1.isLoaded() && tabbedView0.getParams() !== tabbedView1.getParams()) tabbedView1.sendRequest();
        if (tab0.isLoaded()) tab0.sendRequest();
        if (tab1.isLoaded() && tab0.getParams() !== tab1.getParams()) tab1.sendRequest();
        mappedCloseDialog();
        history.push(`/app/tabs/${data.tabIds[0]}/editor`);
      });
  };
  handleClose = () => {
    const {mappedCloseDialog} = this.props;
    mappedCloseDialog();
  };

  render() {
    const {
      isOpen,
      data,
      mergeTabs,
      mainTab,
      mergeTab,
      mainTabbedViewTabs,
      mergeTabbedViewTabs,
      mainTabElements,
      mergeTabElements,
      mappedCloseDialog,
      history,
      staticContext,
      ...other
    }  = this.props;
    return (<Dialog open={isOpen} onClose={this.handleClose} {...other}>
      <DialogTitle>Confirm merge</DialogTitle>
      <MergeInformationTable
        mainId={mainTab.getParams()}
        mergeId={mergeTab.getParams()}
        mainElements={mainTabElements.isLoaded() ? mainTabElements.get() : []}
        mergeElements={mergeTabElements.isLoaded() ? mergeTabElements.get() : []}
        selection={data && data.selection}
        referenceComponent={({contentableId, ...props}) => <TabReference tabId={contentableId} {...props} />}
      />
      <DialogActions>
        <Button onClick={this.handleClose} color="default" disabled={mergeTabs.isLoading()}>
          Cancel
        </Button>
        <Button onClick={this.handleConfirm} color="primary" disabled={mergeTabs.isLoading()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = state => ({
  isOpen: state.dialogs.mergeConfirmation.isOpen,
  data: state.dialogs.mergeConfirmation.data,
});
const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeMergeConfirmationDialog())
});

const getTabId = index => state => {
  const {data} = state.dialogs.mergeConfirmation;
  return data && data.tabIds && data.tabIds[index];
};
const getTabbedViewId = index => createLoadedSelector('tabDetails', getTabId(index), tab => tab.tabbedViewId);
export default withClient({
  hooks: {
    mergeTabs,
    mainTab: aliasFor('tabDetails')(tabDetails(getTabId(0))),
    mergeTab: aliasFor('tabDetails')(tabDetails(getTabId(1))),
    mainTabbedViewTabs: manual(aliasFor('tabbedViewTabs')(tabbedViewTabs(getTabbedViewId(0)))),
    mergeTabbedViewTabs: manual(aliasFor('tabbedViewTabs')(tabbedViewTabs(getTabbedViewId(1)))),
    mainTabElements: manual(aliasFor('tabElements')(tabElements(getTabId(0)))),
    mergeTabElements: manual(aliasFor('tabElements')(tabElements(getTabId(1)))),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MergeTabsConfirmationDialog)));
