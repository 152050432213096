import React from 'react';
import { ReactReduxContext } from 'react-redux';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import {options} from "components/Prosemirror/index";
import {styleLinkPlugin} from "components/Prosemirror/plugins/linkEditor";
import {styleMathQuillPlugin} from "components/Prosemirror/plugins/nodeViews/mathQuill";
import {styleMathpixMediaPlugin} from "components/Prosemirror/plugins/nodeViews/media/mathpix";
import {styleMathMLMediaPlugin} from "components/Prosemirror/plugins/nodeViews/media/mathml";
import Prosemirror from '../interface';

import {inputDebounce} from 'constants/Config';

const styles = theme => ({
  ...styleLinkPlugin(theme),
  ...styleMathQuillPlugin(theme),
  ...styleMathpixMediaPlugin(theme),
  ...styleMathMLMediaPlugin(theme),
});

const context = React.createContext(null);

// Limit state updates to once every {inputDebounce} ms (default: 2 per second)
const batch = f => {
  let lock = null;
  let cache = null;
  const setLock = () => {
    if (cache) {
      f(...cache);
      cache = null;
      lock = window.setTimeout(setLock, inputDebounce);
    } else {
      lock = null;
    }
  };
  return (...args) => {
    if (!lock) {
      f(...args);
      lock = window.setTimeout(setLock, inputDebounce);
    } else {
      cache = args;
    }
  }
};

class ProsemirrorInterface extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reduxStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    currentVersion: PropTypes.object.isRequired,
    options: PropTypes.shape({
      schema: PropTypes.object,
      plugins: PropTypes.array,
    }),
  };

  constructor(props) {
    super(props);
    const {classes, reduxStore, history, currentVersion} = props;

    try {
      this.interface = new Prosemirror(reduxStore, history, classes);

      this.state = {state: this.interface.init(this.mergeOptions(options), currentVersion)};

      this.interface.observe(batch(state => this.setState({state})));
    } catch (ex) {
      this.interface = null;
      this.state = {
        error: ex
      };
      console.error(ex.stack);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.interface) return false;
    const {currentVersion, options:propOptions} = this.props;
    if (prevProps.currentVersion !== currentVersion ||
      prevProps.options !== propOptions) {
      this.setState({state: this.interface.init(this.mergeOptions(options), currentVersion)});
    }
  }

  mergeOptions(options) {
    const {options:propOptions} = this.props;
    if (!propOptions) return options;
    let finalOptions = Object.assign({}, options);
    if (propOptions.schema) finalOptions.schema = propOptions.schema;
    if (propOptions.plugins) finalOptions.plugins = options.plugins.concat(propOptions.plugins);
    return finalOptions;
  }

  render() {
    if (this.interface) {
      const contextValue = {
        state: this.interface.state,
        prosemirror: this.interface,
      };

      return (
        <context.Provider value={contextValue}>
          {this.props.children}
        </context.Provider>
      );
    } else if (this.state.error) {
      return (
        <div>
          An error occurred while loading the editor:
          <div>
            <span>{this.state.error.name}</span> :: <span>{this.state.error.message}</span>
          </div>
          Please try loading a previous version.
        </div>
      )
    }
    return false;
  }
}

export default withRouter(withStyles(styles)(props => (
  <ReactReduxContext.Consumer>
    {({ store }) => <ProsemirrorInterface reduxStore={store} {...props} />}
  </ReactReduxContext.Consumer>
)));

export const withProsemirror = BaseComponent => props => (<context.Consumer>
  {value => {
    if (!value ||!value.state) return false;
    return <BaseComponent prosemirror={value.prosemirror} {...props} />;
  }}
</context.Consumer>);
