import React, {Component} from 'react';
import {connect} from 'react-redux';
import {openStandardDialog, setStandardDialogData} from "../../../../reducers/Dialogs/Standards/index";
import Button from '@material-ui/core/Button';

class EditStandardButton extends Component {
  clicked = () => {
    const {standard} = this.props;
    this.props.setStandardDialogData(standard);
    this.props.openStandardDialog();
  };

  render(){
    return (
      <Button color="primary" onClick={this.clicked}>Edit</Button>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setStandardDialogData: (payload) => dispatch(setStandardDialogData(payload)),
  openStandardDialog: () => dispatch(openStandardDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStandardButton)

