import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

import AutocompleteGoogleFont from 'components/Autocomplete/AutocompleteGoogleFont';
import {webFonts} from "constants/StyleTypes";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  input: {
    margin: 0,
    flex: 1,
  }
});

class FontEditor extends React.Component {
  state = {
    googleFont: null,
    webFont: '',
  };

  componentDidMount() {
    if (typeof this.props.value === 'object') {
      this.setState(this.props.value);
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value && typeof this.props.value === 'object') {
      this.setState(this.props.value);
    }
  }

  handleSelectGoogleFont(googleFont) {
    const {webFont} = this.state;
    this.setState({googleFont});
    this.props.onChange({webFont, googleFont});
  }
  handleSelectWebFont(webFont) {
    const {googleFont} = this.state;
    this.setState({webFont});
    this.props.onChange({webFont, googleFont});
  }

  render() {
    const {classes, onChange} = this.props;
    const {webFont} = this.state;
    return (
      <div className={classes.root}>
        <AutocompleteGoogleFont
          fullWidth
          className={classes.input}
          style={{marginRight: '20px'}}
          label="Search for a Google Font"
          onSuggestionSelected={(event, {suggestion}) => this.handleSelectGoogleFont(suggestion)}
        />
        <FormControl className={classes.input} required>
          <InputLabel>Select a web-safe font</InputLabel>
          <Select
            value={webFont}
            onChange={ev => this.handleSelectWebFont(ev.target.value)}
          >
            <MenuItem value="">---</MenuItem>
            {webFonts.map(font => (
              <MenuItem key={font.name} value={font.family} style={{fontFamily:font.family}}>{font.name}</MenuItem>
            ))}

          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(FontEditor);
