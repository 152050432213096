// ACTION TYPES
export const SECTION_SELECT = 'SECTION_SELECT';

// ACTION CREATORS
export function selectSection(sectionId) {
  return {type: SECTION_SELECT, payload: sectionId};
}

const initialState = {
  selected: null,
};

export default (state = initialState, action) => {
  if (action.type === SECTION_SELECT) {
    return {
      ...state,
      selected: action.payload
    };
  }
  return state;
}
