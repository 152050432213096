import {Field, Fields, reduxForm} from "redux-form";
import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from '@material-ui/core/styles';

import AutocompleteLessonContentContainer from 'components/Autocomplete/AutocompleteLessonContentContainer';
import {renderSelect} from "components/Forms/fields";

const styles = theme => ({
  formRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
});

const renderAutocomplete = ({name, containerId, containers, ...other}) => (
  <AutocompleteLessonContentContainer
    containers={containers}
    onSuggestionSelected={(event, {suggestion}) => {
      name.input.onChange(suggestion.name);
      containerId.input.onChange(suggestion.id);
    }}
    {...name.input}
    onChange={(event, {newValue}) => {
      name.input.onChange(newValue);
      containerId.input.onChange('');
    }}
    {...other}
  />
);

const LessonContentContainerDialogCreateForm = ({classes, containers, elementTypes}) => {
  return (<form>
    <div className={classes.formRow}>
      <Fields
        names={["name","containerId"]}
        component={renderAutocomplete}
        containers={containers}
        label="Name"
        fullWidth
      />
    </div>
    <div className={classes.formRow}>
      <Field
        name="elementType"
        component={renderSelect}
        label="Element Type"
        disabled={elementTypes.length <= 1}
        fullWidth
      >
        {elementTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
      </Field>
    </div>
  </form>);
};

export default reduxForm({
  form: 'lessonContentContainerDialogCreate'
})(withStyles(
  styles
)(LessonContentContainerDialogCreateForm));
