import React, {useState, useCallback} from 'react';

import {
  Typography,
  TextField,
  makeStyles,
  createStyles
} from '@material-ui/core';

import {DownloadUnicodeCSVButton} from "./DownloadUnicodeCSVButton";

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  flexShrink: {
    flex: 0,
    whiteSpace: 'noWrap',
    margin: theme.spacing(1)
  },
  flexGrow: {
    flex: 1,
    margin: theme.spacing(1)
  }
}));

export const UnicodeCSV = props => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const handleChange = useCallback(ev => setSearchValue(ev.target.value));

  return (
    <div className={classes.root}>
      <div className={classes.flexShrink}>
        <Typography variant="h6">Search for Special Characters</Typography>
      </div>
      <div className={classes.flexGrow}>
        <TextField
          fullWidth
          label="Search Term"
          value={searchValue}
          onChange={handleChange}
        />
      </div>
      <div className={classes.flexShrink}>
        <DownloadUnicodeCSVButton query={searchValue} />
      </div>
    </div>
  )
};
