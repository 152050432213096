import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {BookListView} from "views/book/BookList";
import {BookRoutes} from './book/index';

export const BooksRoutes = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}`} to={`${match.path}/list`} />
    <Route path={`${match.path}/list`} component={BookListView} />
    <Route path={`${match.path}/:bookId`} component={BookRoutes} />
    <Redirect to="/404" />
  </Switch>
);
