import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Prompt} from "react-router-dom";
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Button,
  CircularProgress
} from "@material-ui/core";
import IconHandle from "@material-ui/icons/DragHandle";
import IconDelete from "@material-ui/icons/Delete";
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import IconMerge from '@material-ui/icons/CallMerge';
import IconAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";

import {formatDate} from "constants/Date";
import {getValidElementTypesForContentable} from "constants/ElementTypes";
import {withClient} from "reducers/client";
import {manual, lessonContainers, lessonContentContainerElements, lessonContentContainerRemoveElement} from "reducers/client/requestTypes";
import {openLessonContentContainerElementDialog, setLessonContentContainerElementDialogData} from "reducers/Dialogs/LessonContentContainerElement";
import ConfirmationDialog from "components/Dialogs/Confirmation";

import LessonContainerElement from './LessonContainerElement';

const elementTypes = getValidElementTypesForContentable('lessonContentContainer');

class LessonContainerTableRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    lessonContainers: PropTypes.object.isRequired,
    lessonContentContainerElements: PropTypes.object.isRequired,
    lessonContentContainerRemoveElement: PropTypes.object.isRequired,
    openLessonContentContainerElementDialog: PropTypes.func.isRequired,
    setLessonContentContainerElementDialogData: PropTypes.func.isRequired,

    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    adding: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    pendingChanges: PropTypes.bool.isRequired,
    editingId: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,

    onEdit: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickMerge: PropTypes.func.isRequired,
    onClickLink: PropTypes.func.isRequired,
  };

  state = {
    confirmationOpen: false,
    confirmationElement: {},
    dirty: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { item, editingId, onEdit, index, lessonContentContainerElements } = this.props;
    const { item:prevItem, editingId:prevEditingId, lessonContentContainerElements:prevElements } = prevProps;

    if (this.state.dirty && item !== prevItem) {
      this.setState({ dirty: false });
    }
    if (prevEditingId !== editingId && editingId === index) {
      onEdit('elements', {target: {value: lessonContentContainerElements.get()}});
    }
    if (lessonContentContainerElements.isLoaded() && lessonContentContainerElements.hasChanged(prevElements)) {
      onEdit('elements', {target: {value: lessonContentContainerElements.get()}});
    }
  }

  handleDelete = (event, element) => {
    this.setState({
      confirmationOpen: true,
      confirmationElement: element,
    });
    event.stopPropagation();
  };
  handleMerge = (event, element) => {
    const {item: container, onClickMerge} = this.props;
    onClickMerge(event, element, container);
    event.stopPropagation();
  };
  handleClickAddElement = event => {
    const {item: container, openLessonContentContainerElementDialog, setLessonContentContainerElementDialogData} = this.props;
    setLessonContentContainerElementDialogData({containerId: container.id});
    openLessonContentContainerElementDialog();
    this.setState({dirty: true});
    event.stopPropagation();
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {lessonContentContainerRemoveElement, lessonContentContainerElements} = this.props;
    const {confirmationElement} = this.state;
    lessonContentContainerRemoveElement.sendRequest(confirmationElement.id)
      .then(() => lessonContentContainerElements.sendRequest())
      .then(() => this.setState({dirty: true}));
  };

  render() {
    const {
      classes,
      lessonContainers,
      lessonContentContainerElements,
      lessonContentContainerRemoveElement,
      openLessonContentContainerElementDialog,
      setLessonContentContainerElementDialogData,
      item: container,
      index,
      adding,
      waiting,
      pendingChanges,
      editingId,
      edit,
      onEdit,
      onClickEdit,
      onClickSave,
      onClickDelete,
      onClickMerge,
      onClickLink,
      ...other
    } = this.props;
    const {confirmationOpen, confirmationElement, dirty} = this.state;

    let elements = (
      dirty &&
      lessonContentContainerElements.isLoaded() ?
        lessonContentContainerElements.get() :
        container.elements
    ) || [];
    const isEditingThis = editingId === index;
    const isEditingAnother = !isEditingThis && editingId !== -1;

    return (<React.Fragment>
      <Prompt
        when={dirty}
        message={() => { console.log('PROMPT', lessonContainers); lessonContainers.sendRequest(); return true; }}
      />
      <ConfirmationDialog
        title="Unlink Lesson Content Container"
        message={`Are you sure you want to unlink the ${confirmationElement.elementType} content from Lesson Content Container ${container.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <TableRow hover {...other}>
        <TableCell padding="checkbox">
          <IconHandle className={classes.dragHandle}/>
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <TextField
              value={edit.name}
              onChange={ev => onEdit('name', ev)}
              disabled={waiting}
              autoFocus
            />
          ) : (
            container.name
          )}
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <TextField
              value={edit.number}
              onChange={ev => onEdit('number', ev)}
              disabled={waiting}
            />
          ) : (
            container.number
          )}
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <React.Fragment>
              {(dirty && lessonContentContainerElements.isLoading()) || lessonContentContainerRemoveElement.isLoading() ? (
                <CircularProgress size={20} />
              ) : (
                <React.Fragment>
                  {elements.map(e => (
                    <LessonContainerElement
                      key={e.id}
                      containerId={container.id}
                      element={e}
                      mode="remove"
                      onRemove={ev => this.handleDelete(ev, e)}
                      disabled={waiting}
                    />
                  ))}
                  {elements.length < 1 && (
                    <Button color="default" variant="outlined" onClick={onClickLink} disabled={waiting}>
                      <IconMerge /> Link
                    </Button>
                  )}
                  {elements.length < elementTypes.length && (
                    <Button color="secondary" variant="outlined" onClick={this.handleClickAddElement} disabled={waiting}>
                      <IconAdd />
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {elements.map(e => {
                let disabled = waiting;
                if (isEditingAnother && edit.elements) {
                  disabled = disabled || !!edit.elements.find(other => other.elementType === e.elementType);
                }
                return (
                  <LessonContainerElement
                    key={e.id}
                    containerId={container.id}
                    element={e}
                    mode={isEditingAnother ? "merge" : "link"}
                    onMerge={ev => this.handleMerge(ev, e)}
                    disabled={disabled}
                  />
                );
              })}
            </React.Fragment>
          )}
        </TableCell>
        <TableCell padding="none" align="right">
          {isEditingThis ? (
            <React.Fragment>
              <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
                <IconDelete fontSize="small"/>
              </IconButton>
              <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
                {pendingChanges ? (
                  <IconSave fontSize="small"/>
                ):(
                  <IconCancel fontSize="small"/>
                )}
              </IconButton>
            </React.Fragment>
          ) : !adding && !isEditingAnother && (
            <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
              <IconEdit fontSize="small"/>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    lessonContainers: manual(lessonContainers((state, props) => props.item.lessonId)),
    lessonContentContainerElements: lessonContentContainerElements((state, props) => props.item.id),
    lessonContentContainerRemoveElement: lessonContentContainerRemoveElement((state, props) => props.item.id),
  }
})(connect(null,
  dispatch => ({
    openLessonContentContainerElementDialog: () => dispatch(openLessonContentContainerElementDialog()),
    setLessonContentContainerElementDialogData: data => dispatch(setLessonContentContainerElementDialogData(data)),
  }),
)(LessonContainerTableRow));
