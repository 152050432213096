import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {AppContainer} from "containers/App";

import {CategoriesRoutes} from './categories';
import {BooksRoutes} from './books';
import {SectionsRoutes} from './sections';
import {GlossaryRoutes} from './glossary';
import {TabbedViewsRoutes} from './tabbedViews';
import {TabsRoutes} from './tabs';
import {ChaptersRoutes} from './chapters';
import {LessonsRoutes} from './lessons';
import {LessonContentContainersRoutes} from './lessonContentContainers';
import {ElementsRoutes} from './elements';
import {MediasRoutes} from './medias';
import {LandingView} from "views/app/Landing";
import {ClassificationsRoutes} from './classifications';
import {StandardsRoutes} from './standards';
import {AssessmentProblemsRoutes} from './assessmentProblems';
import {ReportsView} from "views/app/Reports";
import {AssessmentGroupsRoutes} from './assessmentGroups';
import {UtilitiesView} from "views/app/Utilities";

export const AppRoutes = props => {
  const {match} = props;
  return (
    <AppContainer {...props}>
      <Switch>
        <Redirect exact from="/app" to={`${match.path}/landing`} />
        <Route path={`${match.path}/landing`} component={LandingView} />
        <Route path={`${match.path}/categories`} component={CategoriesRoutes} />
        <Route path={`${match.path}/books`} component={BooksRoutes} />
        <Route path={`${match.path}/sections`} component={SectionsRoutes} />
        <Route path={`${match.path}/glossary`} component={GlossaryRoutes} />
        <Route path={`${match.path}/tabbedViews`} component={TabbedViewsRoutes} />
        <Route path={`${match.path}/tabs`} component={TabsRoutes} />
        <Route path={`${match.path}/chapters`} component={ChaptersRoutes} />
        <Route path={`${match.path}/lessons`} component={LessonsRoutes} />
        <Route path={`${match.path}/lessonContentContainers`} component={LessonContentContainersRoutes} />
        <Route path={`${match.path}/elements`} component={ElementsRoutes} />
        <Route path={`${match.path}/medias`} component={MediasRoutes} />
        <Route path={`${match.path}/standards`} component={StandardsRoutes} />
        <Route path={`${match.path}/classifications`} component={ClassificationsRoutes} />
        <Route path={`${match.path}/assessmentProblems`} component={AssessmentProblemsRoutes} />
        <Route path={`${match.path}/assessmentGroups`} component={AssessmentGroupsRoutes} />
        <Route path={`${match.path}/reports`} component={ReportsView} />
        <Route path={`${match.path}/utilities`} component={UtilitiesView} />
        <Redirect to="/404" />
      </Switch>
    </AppContainer>
  );
}
