import React from "react";

import MenuItem from "@material-ui/core/MenuItem";

import Autocomplete from '.';

import {apiClient} from "../../constants/Clients";
import {inputDebounce} from "../../constants/Config";

const MAX_LENGTH = 40;

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = (suggestion, {query, isHighlighted}) => {
  const {name, description} = suggestion;
  let label = null;
  if (description) {
    if (query) {
      let index = description.toLowerCase().indexOf(query.toLowerCase());
      if (index !== -1) {
        let match = description.slice(index, index + query.length);
        let prefix = '';
        let suffix = '';

        if (description.length > MAX_LENGTH) {
          let offset = Math.min(description.length - MAX_LENGTH, Math.max(0, index - MAX_LENGTH / 2));
          let end = Math.min(description.length, offset+MAX_LENGTH);

          prefix = (offset > 0 ? '...' : '')+description.slice(offset, index);
          suffix = description.slice(index + query.length, end) + (offset + MAX_LENGTH <= description.length ? '...' : '');
        } else {
          prefix = description.slice(0, index);
          suffix = description.slice(index + query.length);
        }
        label = <span>{name} ({prefix}<b>{match}</b>{suffix})</span>;
      }
    }

    if (label === null) {
      if (description.length > MAX_LENGTH) {
        label = `${name} (${description.slice(0,MAX_LENGTH)}...)`;
      } else {
        label = `${name} (${description})`;
      }
    }
  } else {
    label = name;
  }

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {label}
      </div>
    </MenuItem>
  );
};

class AutocompleteBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      waiting: false,
      suggestions: []
    };
  }

  onSuggestionsFetchRequested = ({value}) => {
    if (this.timeout) { clearTimeout(this.timeout); }
    this.setState({
      waiting: true,
    });

    this.timeout = setTimeout(() => {
      const req = apiClient.getBooks();
      req.limit(5);
      req.setFilter(`name='%${value}%' OR description='%${value}%'`);
      req.setSortFields(['name']);
      req.setOrderByAsc();
      req.exec((err, results) => {
        if (err) {
          console.error("AutocompleteBook.fetch ERROR :: ",err);
          this.setState({
            suggestions: [],
            waiting: false,
          });
        } else {
          this.setState({
            suggestions: results,
            waiting: false,
          });
        }
      });
    }, inputDebounce);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      waiting: false,
    });
  };

  onChange = (event, {newValue}) => {
    this.setState({ value: newValue });
  };

  render() {
    const {value, waiting, suggestions} = this.state;

    return (<Autocomplete
      value={value}
      waiting={waiting}
      suggestions={suggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      onChange={this.onChange}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      {...this.props}
    />);
  }
}

export default AutocompleteBook;