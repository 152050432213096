export function clean(dom) {
  if (dom.childNodes.length === 1) {
    // Strip out the unnecessary 'li' tag
    const li = dom.childNodes[0];
    if (li.tagName === 'li') {
      while (li.firstChild) { dom.appendChild(li.firstChild); }
      dom.removeChild(li);
    }
  } else if (dom.childNodes.length > 1) {
    // If the content consists of multiple 'li' elements, wrap them all in a 'ul'
    if (dom.childNodes[0].tagName === 'li') {
      const ul = document.createElement('ul');
      while (dom.firstChild) { ul.appendChild(dom.firstChild); }
      dom.appendChild(ul);
    }
  }
  // Remove homework help content from references, it will not be shown
  Array.from(dom.getElementsByClassName('homeworkOnly')).forEach(e => {
    if (e.parentNode) e.parentNode.removeChild(e);
  })
}
