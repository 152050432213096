import { Plugin, PluginKey } from 'prosemirror-state';
import {NodeSelection} from 'prosemirror-state';

import {icon} from '@fortawesome/fontawesome-svg-core';
import {faWrench} from '@fortawesome/free-solid-svg-icons';

import schema from "components/Prosemirror/schema/index";
import {getMarkedRange} from "components/Prosemirror/util";
import {PLUGIN_INTERFACE} from './interface';

export const PLUGIN_LINK_EDITOR = new PluginKey('link-editor');
export const styleLinkPlugin = theme => ({
  linkViewAnchor: {
    position: 'relative'
  },
  linkViewContainer: {
    display: 'flex',

    position: 'absolute',

    borderColor: theme.palette.primary.main,
    borderWidth: `${1}px`,
    borderStyle: 'solid',

    backgroundColor: 'white',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    zIndex: 200
  },
  linkViewWrapper: {
    position: 'relative',
    minWidth: `${200}px`,
    maxWidth: `${800}px`,
    whiteSpace: 'nowrap',
  },
  linkViewSpacer: {
    display: 'block',
    visibility: 'hidden',
    padding: `${2}px`,

    fontSize: `${14}px`,
    fontFamily: 'sans-serif'
  },
  linkViewInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: `${100}%`,

    border: 'none',
    padding: `${2}px ${1}px`,
    background: 'none',

    fontSize: `${14}px`,
    fontFamily: 'sans-serif'
  },
  linkViewButton: {
    border: 'none',
    borderLeft: `1px solid ${theme.palette.primary.main}`,

    cursor: 'pointer',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#666',
      background: '#fff'
    },
  }
});

const MARK_TYPE_LINK = schema.marks.link;

const initialState = {
  start: null,
  end: null,
  mark: null,
};

class LinkEditor {
  constructor(view) {
    let pm = PLUGIN_INTERFACE.get(view.state).interface;
    this.outerView = view;

    this.active = {...initialState};

    this.container = document.createElement('div');
    this.container.setAttribute('class',pm.classes.linkViewContainer);
    this.container.style.display = 'none';

    this.inputWrapper = document.createElement('div');
    this.inputWrapper.setAttribute('class',pm.classes.linkViewWrapper);

    this.inputSpacer = document.createElement('span');
    this.inputSpacer.setAttribute('class',pm.classes.linkViewSpacer);
    this.inputSpacer.innerText = '';

    this.hrefInput = document.createElement('input');
    this.hrefInput.setAttribute('class',pm.classes.linkViewInput);
    this.hrefInput.value = '';
    this.hrefInput.addEventListener('input', ev => this.onInput(ev));
    this.hrefInput.addEventListener('keydown', ev => this.onKeyDown(ev));

    this.advancedButton = document.createElement('button');
    this.advancedButton.setAttribute('class',pm.classes.linkViewButton);
    this.advancedButton.addEventListener('click', ev => this.onClickButton(ev));

    this.icon = icon(faWrench).node[0];

    this.container.appendChild(this.inputWrapper);
    this.inputWrapper.appendChild(this.hrefInput);
    this.inputWrapper.appendChild(this.inputSpacer);
    this.container.appendChild(this.advancedButton);
    this.advancedButton.appendChild(this.icon);
    this.outerView.root.body.appendChild(this.container);
    this.outerView.dom.addEventListener('click', this.onClickLink, false);
  }

  select(view, mark, start, end) {
    if (mark !== this.active.mark) { this.active.mark = mark; }
    if (end !== this.active.end) { this.active.end = end; }
    if (start !== this.active.start) {
      this.active.start = start;
      this.showEditor(view, mark.attrs.href);
    }
  }

  showEditor(view, href) {
    // Update the editor value
    if (href != null && this.hrefInput.value !== href) {
      // Update the editor's text value
      this.hrefInput.value = href;
      this.inputSpacer.innerText = href;
    }
    // Position editor box next to the active link
    let {left, bottom} = view.coordsAtPos(this.active.start);
    this.container.style.display = '';
    const width = this.container.offsetWidth;
    const viewWidth = this.outerView.root.body.offsetWidth;
    if (left + width > viewWidth) {
      this.container.style.left = `${viewWidth - width}px`;
    } else {
      this.container.style.left = `${left}px`;
    }
    this.container.style.top = `${bottom}px`;
  }

  hideEditor() {
    if (this.container.style.display !== 'none') {
      // Hide the editor if no link is selected
      this.container.style.display = 'none';
      this.active = {...initialState};
    }
  }

  onClickLink(event) {
    if (event.target.tagName.toLowerCase() === 'a') event.preventDefault();
  }

  onClickButton(event) {
    this.outerView.dispatch({
      external: true,
      method: 'link',
      active: this.active,
    });
  }

  onKeyDown(event) {
    // Override ctrl-a functionality since it interferes with Prosemirror
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      this.hrefInput.select();
      event.preventDefault();
      return false;
    }
  }

  onInput(event) {
    const href = this.hrefInput.value;
    this.inputSpacer.innerText = href;

    const {start, end, mark} = this.active;
    const {tr} = this.outerView.state;

    if (mark) {
      tr.removeMark(start, end, mark);
      tr.addMark(start, end, MARK_TYPE_LINK.create({href}));
      this.outerView.dispatch(tr);
    }

    this.showEditor(this.outerView);
  }

  update(view) {
    const {node, anchor, $cursor} = view.state.selection;
    if (node) {
      const nodeMark = node.marks.find(m => m.type === MARK_TYPE_LINK);
      if (nodeMark) {
        // Select a marked range around a node selection
        const {start, end} = getMarkedRange(view.state.doc, nodeMark, anchor);
        this.select(view, nodeMark, start, end);
      } else {
        this.hideEditor();
      }
    } else if ($cursor) {
      const linkMark = $cursor.marks().find(m => m.type === MARK_TYPE_LINK);
      if (linkMark) {
        // Select a marked range around the cursor
        const {start, end} = getMarkedRange(view.state.doc, linkMark, $cursor.pos);
        this.select(view, linkMark, start, end);
      } else {
        this.hideEditor();
      }
    } else {
      this.hideEditor();
    }
  }

  destroy() {
    this.outerView.dom.removeEventListener('click', this.onClickLink, false);
    this.container.parentNode.removeChild(this.container);
  }
}

export default () => {
  let editor = null;

  return new Plugin({
    key: PLUGIN_LINK_EDITOR,
    view: editorView => new LinkEditor(editorView)
  });
}
