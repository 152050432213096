import {onDoubleClick} from "../util";
import MathJax from 'MathJax';
import {log} from "../../../../../constants/Config";

export default class FormulaMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    const latex = media.latex;
    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] create CpmMediaView#${this.outerView.id}::FormulaMediaView`, latex);
    }

    this.dom = document.createElement('script');
    this.dom.setAttribute('type','math/tex');
    this.dom.innerText = latex;
    this.outerView.dom.appendChild(this.dom);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.dispatch({method: 'mathquill', latex: latex});
    });

    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));
  }

  destroy() {
    const math = MathJax.Hub.getAllJax(this.outerView.dom);
    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] destroy CpmMediaView#${this.outerView.id}::FormulaMediaView`, math);
    }
    math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
  }
}