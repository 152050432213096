import React, {useCallback} from 'react';

import {
  Button,
  CircularProgress,
  Tooltip
} from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/GetApp';

import {withClient} from "reducers/client";
import {elementUnicodeCSV} from "reducers/client/requestTypes";

const DownloadUnicodeCSVButton = ({elementUnicodeCSV, query, ...otherProps}) => {
  const handleClick = useCallback(ev => {
    elementUnicodeCSV.sendRequest(query).then(result => {
      // Prompt a download of result (blob)
      let download = document.createElement('a');
      download.href = URL.createObjectURL(result);
      download.target = '_blank';
      download.download = 'results.csv';
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    })
  }, [query]);

  return (
    <Tooltip title="This may take several minutes to generate the CSV. Please be patient.">
      <span>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
          disabled={elementUnicodeCSV.isLoading()}
          {...otherProps}>
          {elementUnicodeCSV.isLoading() ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <DownloadIcon />
          )}
          Download CSV
        </Button>
      </span>
    </Tooltip>
  )
};
const DownloadUnicodeCSVButtonWithClient = withClient({
  hooks: {
    elementUnicodeCSV: elementUnicodeCSV((state, props) => props.query)
  }
})(DownloadUnicodeCSVButton);

export {
  DownloadUnicodeCSVButtonWithClient as DownloadUnicodeCSVButton
}
