import React from 'react';
import { connect } from 'react-redux';
import {Prompt} from "react-router-dom";

// Material
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

// Icons
import IconHistory from '@material-ui/icons/History';
import IconVisibility from '@material-ui/icons/Visibility';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconPublish from '@material-ui/icons/Publish';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import IconList from '@material-ui/icons/List';
import IconHourglass from '@material-ui/icons/HourglassEmpty';
import IconImage from '@material-ui/icons/Image';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

// Components
import ElementAssociatedContentTable from "components/Tables/ElementAssociatedContentTable";
import { TabBreadcrumb } from 'components/Breadcrumb';
import ProsemirrorEditModeToggle from "components/Prosemirror/components/ProsemirrorEditModeToggle";
import ProsemirrorSidebarMenu from "components/Prosemirror/components/ProsemirrorSidebarMenu";
import ProsemirrorEditor from 'components/Prosemirror/components/ProsemirrorEditor';
import ProsemirrorInterface from "components/Prosemirror/components/ProsemirrorInterface";
import ProsemirrorMenu from "components/Prosemirror/components/ProsemirrorMenu";
import {lessonMenu, problemMenu} from 'components/Prosemirror/menu/spec';

import PublishStatusIndicator from 'components/Editor/PublishStatusIndicator';
import ContentPreview from "components/Editor/Views/ContentPreview";
import HtmlPreview from "components/Editor/Views/HtmlPreview";
import WithFetch from "components/WithFetch";
import {LinkedTabs} from "components/LinkList";

// Constants
import { types } from 'constants/ApprovalStatuses';
import { color } from 'constants/Config';
import {displayModes, sidebarModes} from "constants/Prosemirror";

// Actions
import {
  createApprovalStatusForContentVersion,
  requestApprovalForContentVersion,
  publishElementCurrentVersion,
} from "reducers/elements";
import {renderHomework, renderLesson} from "components/Prosemirror/render";

// Client
import {withClient, createSelector, createLoadedSelector} from "reducers/client";
import {tabDetails, tabElements, elementDetails, contentVersionApprovalStatus} from "reducers/client/requestTypes";
import MarkupPreview from "components/Editor/Views/MarkupPreview";

const sideNavWidth = 75;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${20}px`,
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s cubic-bezier(0, 0, 0.2, 1)',
    '.nav-collapsed &': {
      left: `${collapsedWidth}px`,
    }
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `${30}px`,
  },
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`
  },
  button: {
    margin: theme.spacing(1),
  },
  statusButton: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: color.gray,
    },
  },
  draftButton: {
    backgroundColor: color.infoAlt,
  },
  awaitingReviewButton: {
    backgroundColor: color.warning,
  },
  disapprovedButton: {
    backgroundColor: color.danger,
  },
  approvedButton: {
    backgroundColor: color.warning,
  },
  publishedButton: {
    backgroundColor: color.success,
  },
  navBox: {
    marginTop: theme.spacing(1)
  },
  flexColumns: {
    display: 'flex',
    alignItems: 'center',
  },
  editorBox: {
    flex: 1
  },
  previewBox: {
    padding: '16px',
    overflowY: 'scroll',
  },
  textField: {
    width: '100%',
  },
  compare: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  pane: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${4}px`,
    overflow: 'hidden',
    transition: 'width 0.3s cubic-bezier(0, 0, 0.2, 1)',
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff',
  },
  paneTitle: {
    flex: `0 0 ${48}px`,
    lineHeight: `${48}px`,
    textAlign: 'center',
    fontSize: '18pt',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    backgroundColor: '#f8f8f8',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
    zIndex: 1,
    verticalAlign: 'middle',
  },
  flexLeft: {
    flex: 1,
    textAlign: 'left',
  },
  flexCenter: {
    flex: 1,
    textAlign: 'center',
  },
  flexRight: {
    flex: 1,
    textAlign: 'right',
  },
});

class TabEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAssociated: false,
      showAccessibility: false,
      showPublishDialog: false,
      submitterComments: '',
    };
  }

  showAssociatedContent = () => {
    this.setState({
      showAssociated: !this.state.showAssociated,
    });
  };

  showElementImages = () => {
    const {elementDetails} = this.props;
    this.props.history.push(`/app/elements/${elementDetails.getParams()}/media`);
  };

  showElementVersions = () => {
    const {elementDetails} = this.props;
    this.props.history.push(`/app/elements/${elementDetails.getParams()}/versions`);
  };

  showMerge = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/merge`);
  };
  showMergeHistory = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/mergeHistory`);
  };

  showElementAccessibility = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/accessibility`);
    }
  };

  handlePublishApprovedContentVersion = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      const {id, currentVersionId} = elementDetails.get();
      this.props.publishElementCurrentVersion(id, currentVersionId)
        .then(() => this.props.requestElementDetails(id))
        .then(() => this.props.getApprovalStatusForContentVersion(currentVersionId));
    }
  };

  handleRequestApproval = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      const {currentVersionId} = elementDetails.get();
      const approvalStatusInfo = {
        approvalStatus: types.AWAITING_REVIEW,
        submitterComment: this.state.submitterComments,
      };
      this.props.requestApprovalForContentVersion(currentVersionId, approvalStatusInfo)
        .then(() => this.showElementAccessibility());
    }
  };

  handleSubmitterCommentsChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTogglePublishDialog = () => {
    this.setState({
      showPublishDialog: !this.state.showPublishDialog,
    });
  };

  renderApprovalStatusIndicator = () => {
    const { elementDetails, contentVersionApprovalStatus, classes } = this.props;

    if (!contentVersionApprovalStatus.isLoaded()) {
      return null;
    }

    const { DRAFT, AWAITING_REVIEW, APPROVED, DISAPPROVED } = types;
    const { approvalStatus } = contentVersionApprovalStatus.get();
    switch(approvalStatus) {
      case DRAFT:
        return (
          <Tooltip title="Submit this version for review and approval to publish.">
            <Fab
              className={classnames(classes.draftButton, classes.statusButton)}
              color="inherit"
              variant="extended"
              onClick={this.handleTogglePublishDialog}
            >
              <IconPublish />
              Draft
            </Fab>
          </Tooltip>
        );
      case AWAITING_REVIEW:
        return (
          <Tooltip title="This content version is awaiting review.">
            <Fab
              className={classnames(classes.awaitingReviewButton, classes.statusButton)}
              color="inherit"
              variant="extended"
              onClick={this.showElementAccessibility}
            >
              <IconPublish />
              Awaiting Review
            </Fab>
          </Tooltip>
        );
      case APPROVED:
        const { currentVersionId, publishedVersionId } = elementDetails.get();
        if (currentVersionId !== publishedVersionId) {
          return (
            <Tooltip title="This content version has been approved. Click to publish.">
              <Fab
                className={classnames(classes.approvedButton, classes.statusButton)}
                color="secondary"
                variant="extended"
                onClick={this.handlePublishApprovedContentVersion}
              >
                <IconLibraryBooks />
                Approved
              </Fab>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="This is the most recently published content version.">
            <Fab variant="extended" color="secondary" className={classnames(classes.publishedButton, classes.statusButton)}>
              <IconLibraryBooks />
              Published
            </Fab>
          </Tooltip>
        );
      case DISAPPROVED:
        return (
          <Tooltip title="This content version has been rejected. Please edit it and try again.">
            <Fab variant="extended" color="secondary" className={classnames(classes.disapprovedButton, classes.statusButton)}>
              <IconLibraryBooks />
              Rejected
            </Fab>
          </Tooltip>
        );
    }
  };

  renderApprovalDialog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.showPublishDialog}
        onClose={this.handleTogglePublishDialog}
      >
        <DialogTitle>Request approval this content version?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to publish this content version? This content version will be submitted for review and approval. If approved, it will be published.
          </DialogContentText>
        </DialogContent>

        <DialogContent>
          <TextField
            className={classes.textField}
            label={'Submitter Comments'}
            multiline={true}
            placeholder={'Comments'}
            value={this.state.comments}
            onChange={this.handleSubmitterCommentsChange('submitterComments')}
          />
        </DialogContent>

        <DialogContent>
          <Button
            color="secondary"
            fullWidth={true}
            variant="extendedFab"
            onClick={this.handleRequestApproval}
          >
            <IconPublish className={classes.buttonIcon} />
            Submit for review!
          </Button>
        </DialogContent>
      </Dialog>
    );
  };

  renderEditor = elementData => {
    const {editMode, displayMode, sidebarMode, tabDetails, classes} = this.props;
    const currentVersion = elementData &&
      elementData.currentVersion;

    if (currentVersion) {
      return (<ProsemirrorInterface currentVersion={currentVersion}>
        <div className={classes.compare}>
          <div className={classes.pane}>
            <div className={classnames(classes.paneTitle, classes.flexColumns)}>
              <ProsemirrorEditModeToggle className={classes.flexLeft} />
              <div className={classes.flexCenter}>{sidebarMode === sidebarModes.COMPARE.id && 'C3PO'}</div>
              <ProsemirrorSidebarMenu className={classes.flexRight} />
            </div>
            {editMode ? (
              <React.Fragment>
                <ProsemirrorMenu>{lessonMenu}</ProsemirrorMenu>
                <ProsemirrorEditor contentClassName={displayMode} contentableId={tabDetails.getParams()} />
              </React.Fragment>
            ) : (
              <ContentPreview
                contentVersionId={currentVersion.id}
                className={classnames(classes.previewBox, displayMode)}
                postProcess={renderLesson}
              />
            )}
          </div>
          {sidebarMode === sidebarModes.COMPARE.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>eBooks</div>
            <HtmlPreview
              html={currentVersion.html}
              className={classnames(classes.previewBox,displayMode)}
              postProcess={renderLesson}
            />
          </div>}
          {sidebarMode === sidebarModes.MARKUP.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>Markup</div>
            <div className={classnames(classes.previewBox)}>
              <MarkupPreview/>
            </div>
          </div>}
          {/*{sidebarMode === sidebarModes.HISTORY.id && <div>*/}
          {/*  /!*<WithFetch request={contentRequest}>{this.renderHistory}</WithFetch>*!/*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
          {/*{sidebarMode === sidebarModes.COMPLIANCE.id && <div>*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
        </div>
      </ProsemirrorInterface>);
    } else {
      return <span style={{color:'red'}}>Unable to resolve content</span>;
    }
  };

  render() {
    const {
      sidebarMode,
      docChanged,
      classes,
      tabDetails,
      elementDetails,
    } = this.props;

    let selectedElementId = elementDetails.getParams() || '';
    let editorContent = <CircularProgress/>;
    let contentVersionId = '';
    if (elementDetails.isLoaded()) {
      editorContent = this.renderEditor(elementDetails.get());
      contentVersionId = elementDetails.get().currentVersionId;
    } else if (elementDetails.isError()) {
      editorContent = this.renderEditor(null);
    }

    return (
      <div className={classes.root}>
        <Prompt
          when={docChanged}
          message="You have unsaved changes on this page. Are you sure you want to leave? Your changes will not be saved."
        />
        <div className={classes.buttonBox}>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          {this.renderApprovalDialog()}
          <Tooltip title="Images Overview">
            <Fab color="primary" className={classes.button}>
              <IconImage onClick={this.showElementImages} />
            </Fab>
          </Tooltip>
          <Tooltip title="Version History">
            <Fab color="primary" className={classes.button}>
              <IconHistory onClick={this.showElementVersions} />
            </Fab>
          </Tooltip>
          {/*<Tooltip title="Show Associated Content">*/}
          {/*  <Fab color="primary" className={classes.button}>*/}
          {/*    <IconList onClick={this.showAssociatedContent} />*/}
          {/*  </Fab>*/}
          {/*</Tooltip>*/}
          <Tooltip title={"Share with another Tab"}>
            <Fab color={"primary"} className={classes.button}>
              <IconCallMerge onClick={this.showMerge} />
            </Fab>
          </Tooltip>
          <Tooltip title={"Merge History"}>
            <Fab color={"primary"} className={classes.button}>
              <IconHourglass onClick={this.showMergeHistory} />
            </Fab>
          </Tooltip>
          <Tooltip title="Show Accessibility Compliance">
            <Fab color="primary" className={classes.button}>
              <IconCheckCircle onClick={this.showElementAccessibility} />
            </Fab>
          </Tooltip>
        </div>
        <ElementAssociatedContentTable open={this.state.showAssociated} close={this.showAssociatedContent} />
        <div className={classes.navigation}>
          <Typography className={classes.title} variant="h6">Tab Content Editor</Typography>
          <TabBreadcrumb tabId={tabDetails.getParams()} />
          <div className={classes.spacer} />
          <LinkedTabs tabId={tabDetails.getParams()} />
        </div>
        {editorContent}
      </div>);
  }
}

const mapStateToProps = state => ({
  editMode: state.prosemirror.editMode,
  displayMode: displayModes.DISPLAY_EBOOKS.className,
  sidebarMode: state.prosemirror.sidebarMode,
  docChanged: state.prosemirror.docChanged,
});

const mapDispatchToProps = dispatch => ({
  createApprovalStatusForContentVersion: (contentVersionId, approvalStatusInfo) =>
    dispatch(createApprovalStatusForContentVersion(contentVersionId, approvalStatusInfo)),
  requestApprovalForContentVersion: (contentVersionId, approvalStatusInfo) =>
    dispatch(requestApprovalForContentVersion(contentVersionId, approvalStatusInfo)),
  publishElementCurrentVersion: (elementId, contentVersionId) =>
    dispatch(publishElementCurrentVersion(elementId, contentVersionId)),
});

const getTabId = (state, props) => state.tabs.selected;
const getElementId = createLoadedSelector('tabElements', getTabId, tabElements => tabElements[0].id);
const getCurrentVersionId = createLoadedSelector('elementDetails', getElementId, elementDetails => elementDetails.currentVersionId);
export const TabEditorView = connect(mapStateToProps, mapDispatchToProps)(
  withClient({
    hooks: {
      tabDetails: tabDetails(getTabId),
      tabElements: tabElements(getTabId),
      elementDetails: elementDetails(getElementId),
      contentVersionApprovalStatus: contentVersionApprovalStatus(getCurrentVersionId),
    }
  })(
    withStyles(styles)(TabEditor)
  )
);
