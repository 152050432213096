import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconDots from "@material-ui/icons/MoreHoriz";

import {formatDate} from 'constants/Date';
import {withClient} from "reducers/client";
import {chapterLessons, chapterLessonOrder, chapterCreateLesson, updateLesson, deleteLesson} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import ConfirmationDialog from "components/Dialogs/Confirmation";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class ChapterLessonsTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    chapterLessons: PropTypes.object.isRequired,
    chapterLessonOrder: PropTypes.object.isRequired,
    chapterCreateLesson: PropTypes.object.isRequired,
    updateLesson: PropTypes.object.isRequired,
    deleteLesson: PropTypes.object.isRequired,

    chapterId: PropTypes.string.isRequired,
  };

  state = {
    lessons: [],
    adding: false,
    editingId: -1,
    edit: {
      name: ''
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    const {classes, chapterLessons} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    if (chapterLessons.isLoaded()) {
      this.setState({ lessons: chapterLessons.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {chapterLessons} = this.props;
    const {chapterLessons:prevLessons} = prevProps;
    if (chapterLessons.isLoaded() && chapterLessons.hasChanged(prevLessons)) {
      this.setState({ lessons: chapterLessons.get() });
    }
  }

  isWaiting() {
    const {
      chapterLessons,
      chapterLessonOrder,
      chapterCreateLesson,
      updateLesson,
      deleteLesson,
    } = this.props;
    return chapterLessons.isLoading() ||
      chapterLessonOrder.isLoading() ||
      chapterCreateLesson.isLoading() ||
      updateLesson.isLoading() ||
      deleteLesson.isLoading();
  }
  pendingChanges() {
    const {lessons, adding, editingId, edit} = this.state;
    if (adding) return edit.name.length > 0;
    if (lessons[editingId]) return lessons[editingId].name !== edit.name;
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: ''
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.lessons[editingId].name
      },
    });
  };
  handleClickSave = () => {
    const {chapterCreateLesson, updateLesson, chapterLessons} = this.props;
    const {lessons, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        chapterCreateLesson.sendRequest(edit)
          .then(() => chapterLessons.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateLesson.sendRequest(lessons[editingId].id, edit)
          .then(() => chapterLessons.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteLesson, chapterLessons} = this.props;
    const {lessons, editingId} = this.state;
    deleteLesson.sendRequest(lessons[editingId].id)
      .then(() => chapterLessons.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        return true;
      } else if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    // Click directly on row = navigate
    this.props.history.push(`/app/lessons/${this.state.lessons[index].id}/details`);
    return false;
  };
  handleChangeOrder = updatedList => {
    const lessons = this.props.chapterLessons.get();
    this.props.chapterLessonOrder.sendRequest(updatedList.map(c => lessons[c].id))
      .then(() => this.props.chapterLessons.sendRequest());
  };

  renderLesson = ({item:chapter, ...props}) => {
    const {classes} = this.props;
    const {adding, editingId, edit} = this.state;
    const waiting = this.isWaiting();
    return (<TableRow hover {...props}>
      <TableCell padding="checkbox">
        <IconHandle className={classes.dragHandle}/>
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.name}
            onChange={ev => this.handleEdit('name', ev)}
            disabled={waiting}
            autoFocus
          />
        ) : (
          chapter.name
        )}
      </TableCell>
      <TableCell>
        {formatDate(chapter.createdAt)}
      </TableCell>
      <TableCell>
        {formatDate(chapter.updatedAt)}
      </TableCell>
      <TableCell padding="none" align="right">
        {editingId === props.index ? (
          <React.Fragment>
            <IconButton onClick={this.handleClickDelete} className={classes.itemButton} disabled={waiting}>
              <IconDelete fontSize="small"/>
            </IconButton>
            <IconButton onClick={this.handleClickSave} className={classes.itemButton} disabled={waiting}>
              {this.pendingChanges() ? (
                <IconSave fontSize="small"/>
              ):(
                <IconCancel fontSize="small"/>
              )}
            </IconButton>
          </React.Fragment>
        ) : !adding && editingId === -1 && (
          <IconButton onClick={() => this.handleClickEdit(props.index)} className={classes.itemButton} disabled={waiting}>
            <IconEdit fontSize="small"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>);
  }

  render() {
    const {lessons, adding, editingId, edit, confirmationOpen} = this.state;
    if (!lessons) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Lesson"
        message={`Are you sure you want to delete Lesson ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"/>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={5} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={this.renderLesson}
          items={lessons}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell padding="checkbox"/>
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={5} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    chapterLessons: chapterLessons((state, props) => props.chapterId),
    chapterLessonOrder: chapterLessonOrder((state, props) => props.chapterId),
    chapterCreateLesson: chapterCreateLesson((state, props) => props.chapterId),
    updateLesson: updateLesson(),
    deleteLesson: deleteLesson(),
  },
})(withStyles(styles)(withRouter(ChapterLessonsTable)));