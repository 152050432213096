const initialSettings = {
  isOpen: false,
  data: null
};

// ACTION TYPES
export const DIALOG_CLASSIFICATION_OPEN = 'DIALOG_CLASSIFICATION_OPEN';
export const DIALOG_CLASSIFICATION_CLOSE = 'DIALOG_CLASSIFICATION_CLOSE';
export const DIALOG_CLASSIFICATION_SET_DATA = 'DIALOG_CLASSIFICATION_SET_DATA';

// ACTION CREATORS
export const openClassificationDialog = () => ({type: DIALOG_CLASSIFICATION_OPEN});
export const closeClassificationDialog = () => ({type: DIALOG_CLASSIFICATION_CLOSE});
export const setClassificationDialogData = data => ({type: DIALOG_CLASSIFICATION_SET_DATA, data});

export default (state = initialSettings, action) => {
  switch (action.type) {
    case DIALOG_CLASSIFICATION_OPEN: return {...state, isOpen: true};
    case DIALOG_CLASSIFICATION_CLOSE: return {...state, isOpen: false, data: null};
    case DIALOG_CLASSIFICATION_SET_DATA: return {...state, data: action.data};
    default: return state;
  }
};
