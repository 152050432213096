import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Select
} from "@material-ui/core";
import IconDelete from "@material-ui/icons/Delete";
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";

import {formatDate} from "constants/Date";
import {asArray} from "constants/util/map";
import ElementTypes from "constants/ElementTypes";
import IconCancel from "../../../../node_modules/@material-ui/icons/Cancel";

const elementTypes = asArray(ElementTypes);

class GlossaryItemElementTableRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,

    element: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    adding: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    pendingChanges: PropTypes.bool.isRequired,
    editingId: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,

    onEdit: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  };

  handleClick = event => {
    const {index, onClick} = this.props;
    onClick(event, index);
  };

  render() {
    const {
      classes,
      element,
      index,
      adding,
      waiting,
      pendingChanges,
      editingId,
      edit,
      onEdit,
      onClick,
      onClickEdit,
      onClickSave,
      onClickDelete,
      ...other
    } = this.props;
    return (<TableRow hover {...other} onClick={this.handleClick}>
      <TableCell>
        {editingId === index ? (
          <Select
            value={edit.elementType}
            onChange={ev => onEdit('elementType', ev)}
            disabled={waiting}
            fullWidth
            autoFocus
          >
            <MenuItem value="">---</MenuItem>
            {elementTypes.map(type => (
              <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
            ))}
          </Select>
        ) : (
          element.elementType
        )}
      </TableCell>
      <TableCell>
        {editingId === index ? (
          <TextField
            value={edit.title}
            onChange={ev => onEdit('title', ev)}
            disabled={waiting}
            autoFocus
          />
        ) : (
          element.title
        )}
      </TableCell>
      <TableCell>
        {editingId === index ? (
          <TextField
            value={edit.description}
            onChange={ev => onEdit('description', ev)}
            disabled={waiting}
          />
        ) : (
          element.description
        )}
      </TableCell>
      <TableCell>
        {formatDate(element.createdAt)}
      </TableCell>
      <TableCell>
        {formatDate(element.updatedAt)}
      </TableCell>
      <TableCell padding="none" align="right">
        {editingId === index ? (
          <React.Fragment>
            <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
              <IconDelete fontSize="small"/>
            </IconButton>
            <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
              {pendingChanges ? (
                <IconSave fontSize="small"/>
              ):(
                <IconCancel fontSize="small"/>
              )}
            </IconButton>
          </React.Fragment>
        ) : !adding && editingId === -1 && (
          <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
            <IconEdit fontSize="small"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>);
  }
}

export default GlossaryItemElementTableRow;