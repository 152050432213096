import React from 'react';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {formatDate} from 'constants/Date';

import PagedTable from '../Base/index';
import reducer, {deleteSelectedBooks} from "reducers/LegacyTables/books/index";

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Book Name',
    content: r => r.name
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Book Description',
    content: r => r.description
  },
  {
    id: 'Date Created',
    numeric: true,
    disablePadding: false,
    label: 'Date Created',
    content: r => formatDate(r.createdAt)
  },
  {
    id: 'Last Updated',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated',
    content: r => formatDate(r.updatedAt)
  },
  {
    id: 'Buttons',
    numeric: true,
    disablePadding: false,
    label: '',
    content: r => <Link to={`/app/books/${r.id}/details`}><Button color="primary" value={r.id}>Book Details</Button></Link>
  },
];

const Actions = props => (
  <Tooltip key="delete" title="Delete">
    <IconButton onClick={() => props.deleteSelection()} aria-label="Delete">
      <DeleteIcon/>
    </IconButton>
  </Tooltip>
);
const ConnectedActions = connect(
  state => ({}),
  dispatch => ({
    deleteSelection: () => dispatch(deleteSelectedBooks())
  }))(Actions);

export default PagedTable({
  title: 'CPM eBooks',
  columnData,
  reducer,
  select: 'single',
  // actions: <ConnectedActions />
});
