import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import {withClient} from "reducers/client";
import {homeworkResources} from "reducers/client/requestTypes";
import renderHWH from "./homeworkHelp";

class HomeworkPreview extends React.Component {
  static propTypes = {
    homeworkId: PropTypes.string.isRequired,
    homeworkResources: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    if (props.contentRef) this.contentRef = props.contentRef;
    else this.contentRef = React.createRef();
  }

  componentDidMount() { this.tryRender(); }
  componentDidUpdate(prevProps) { this.tryRender(prevProps); }
  tryRender(prevProps) {
    const dom = this.contentRef.current;
    const {homeworkResources} = this.props;
    const prevResources = prevProps && prevProps.homeworkResources;
    if (dom && homeworkResources.isLoaded() && homeworkResources.hasChanged(prevResources)) {
      renderHWH(dom, homeworkResources.get());
    }
  }

  render() {
    const {homeworkId, homeworkResources, ...other} = this.props;
    if (homeworkResources.isLoading()) return <CircularProgress />;
    if (homeworkResources.isError()) {
      return <Typography variant="caption">{homeworkResources.get().details.error}</Typography>;
    }
    if (homeworkResources.get()) {
      return (<div {...other} ref={this.contentRef}/>);
    } else {
      return <span style={{color:'red'}}>Unable to resolve content</span>;
    }
  }
}

export default withClient({
  hooks: {
    homeworkResources: homeworkResources((state, props) => props.containerId),
  }
})(HomeworkPreview);