import React from 'react';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {GlossaryItemBreadcrumb} from "components/Breadcrumb";
import DetailsTable from 'components/Tables/DetailsTable';
import {withClient} from "reducers/client";
import {glossaryItemDetails, updateGlossaryItem} from "reducers/client/requestTypes";
import GlossaryItemElementsTable from "components/Tables/GlossaryItemElementsTable";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class GlossaryItemDetails extends React.Component {
  state = {
    editMode: false,
    glossaryItemDetails: {},
  };

  componentDidMount() {
    const {glossaryItemDetails:glossaryItemRequest} = this.props;
    if (glossaryItemRequest.isLoaded()) {
      const glossaryItemDetails = glossaryItemRequest.get();
      this.setState({
        glossaryItemDetails: {
          'Title': glossaryItemDetails.title,
          'Created': formatDate(glossaryItemDetails.createdAt),
          'Updated': formatDate(glossaryItemDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {glossaryItemDetails:glossaryItemRequest} = this.props;
    const {glossaryItemDetails:prevRequest} = prevProps;

    if (glossaryItemRequest.isLoaded() && glossaryItemRequest.hasChanged(prevRequest)) {
      const glossaryItemDetails = glossaryItemRequest.get();
      this.setState({
        glossaryItemDetails: {
          'Title': glossaryItemDetails.title,
          'Created': formatDate(glossaryItemDetails.createdAt),
          'Updated': formatDate(glossaryItemDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      glossaryItemDetails: {
        ...this.state.glossaryItemDetails,
        [key]: value,
      }
    });
  }
  toggleEditGlossaryItem() {
    const {editMode, glossaryItemDetails} = this.state;
    if (editMode) {
      const {glossaryItemDetails:glossaryItemRequest, updateGlossaryItem} = this.props;
      if (glossaryItemRequest.get().title !== glossaryItemDetails['Title']) {
        updateGlossaryItem.sendRequest({
          id: glossaryItemRequest.get().id,
          title: glossaryItemDetails['Title'],
        })
          .then(() => glossaryItemRequest.sendRequest());
      }
    }
    this.setState({editMode: !editMode});
  }

  render() {
    const {classes, glossaryItemDetails:glossaryItemRequest} = this.props;
    const {editMode, glossaryItemDetails} = this.state;

    if (!glossaryItemRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading GlossaryItem details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <GlossaryItemBreadcrumb glossaryItemId={glossaryItemRequest.getParams()} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit GlossaryItem Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditGlossaryItem()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            GlossaryItem Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Title', value: glossaryItemDetails['Title']},
              {name: 'Created', value: glossaryItemDetails.Created, readOnly: true},
              {name: 'Updated', value: glossaryItemDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Elements</Typography>
          <Paper>
            <GlossaryItemElementsTable glossaryItemId={glossaryItemRequest.getParams()} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const GlossaryItemDetailsView = withClient({
  hooks: {
    glossaryItemDetails: glossaryItemDetails((state, props) => state.glossary.selected),
    updateGlossaryItem: updateGlossaryItem((state, props) => state.glossary.selected),
  },
})(withStyles(styles)(GlossaryItemDetails));
