import React from 'react';
import {Prompt, Redirect} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {lessonDetails, lessonElements} from "reducers/client/requestTypes";

import {LessonBreadcrumb} from 'components/Breadcrumb';

import LessonElementMediasTable from 'components/Lesson/LessonElementMediasTable';

import {getElementTypeByName} from "constants/ElementTypes";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: theme.spacing(3),
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

class LessonMedia extends React.Component {
  showElementEditor = () => {
    const {
      history,
      lessonElements,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;
    let elementId = lessonElements.get().find(el => el.elementType === elementType).id;
    history.push(`/app/lessons/${lessonElements.getParams()}/editor/${elementId}/`);
  };
  handleChangeElementType = (ev, value) => {
    const { history, match: {path, url, params} } = this.props;
    let newPath = path.replace(':lessonId',params.lessonId).replace(':elementType', value);
    if (url !== newPath) history.push(newPath);
  };

  render() {
    const {
      classes,
      lessonElements:elementsRequest,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;

    if (!elementsRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Lesson media...</h2>
      </div>);
    }

    const lessonElements = elementsRequest.get().sort((a,b) => {
      const typeA = getElementTypeByName(a.elementType);
      const typeB = getElementTypeByName(b.elementType);
      return typeA.displayOrder - typeB.displayOrder;
    });
    if (!elementType) {
      return <Redirect to={`${url}/${lessonElements[0].elementType}`} />;
    }

    let elementId = lessonElements.find(el => el.elementType === elementType).id;

    return (
      <div className={classes.root}>
        <LessonBreadcrumb lessonId={elementsRequest.getParams()} />
        <div className={classes.buttonBox}>
          <Tooltip title="Lesson Editor">
            <Fab color="primary" className={classes.button}>
              <IconEdit onClick={this.showElementEditor} />
            </Fab>
          </Tooltip>
        </div>
        <AppBar position="static">
          <Tabs value={elementType} onChange={this.handleChangeElementType}>
            {lessonElements.map(element => {
              const elementType = getElementTypeByName(element.elementType);
              return (
                <Tab
                  key={element.elementType}
                  value={element.elementType}
                  label={elementType ? elementType.displayName || elementType.name : element.elementType}
                />
              )
            })}
          </Tabs>
        </AppBar>
        <LessonElementMediasTable lessonId={elementsRequest.getParams()} elementId={elementId} />
      </div>
    );
  }
}

export const LessonMediaView = withClient({
  hooks: {
    lessonDetails: lessonDetails((state, props) => state.lessons.selected),
    lessonElements: lessonElements((state, props) => state.lessons.selected),
  },
})(withStyles(styles)(LessonMedia));
