import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import classnames from 'classnames';

import styles from './style';
import BreadcrumbButton from './BreadcrumbButton';
import {withClient} from "reducers/client";
import Button from "@material-ui/core/Button";

const navHeight = 33;

export const makeBreadcrumb = options => {
  let link = options.link || (details => '/');
  let title = options.title || (details => details.id);

  class Breadcrumb extends React.Component {
    state = {
      parentsShown: false,
      parentsLoaded: 4,
    };

    handleButtonClick = () => this.setState({ parentsShown: true });
    handleScroll = ev => {
      const scrollParent = Math.ceil(ev.target.scrollTop / navHeight) + 4;
      if (scrollParent > this.state.parentsLoaded) {
        this.setState({parentsLoaded: scrollParent});
      }
    };

    render() {
      const {details:detailsRequest, child, classes} = this.props;

      let content = null;
      if (detailsRequest.isLoaded()) {
        const details = detailsRequest.get();

        let button = (<BreadcrumbButton
          className={classes.button}
          link={link(details)}
          active={!child}>
          {title(details)}
        </BreadcrumbButton>);

        if (options.parents) {
          const {parentsShown, parentsLoaded} = this.state;
          const parents = options.parents(details);
          if (parentsShown) {
            return (<nav
              className={classnames({
                [classes.root]: true,
                [classes.scrolling]: parents.length > 4
              })}
              onScroll={this.handleScroll}
            >
              {parents.slice(0,parentsLoaded).map((parent, i) => (
                <div key={parent.key}>
                  {parent} &gt; {button}
                </div>
              ))}
              {parentsLoaded < parents.length && (
                <div
                  className={classes.spacer}
                  style={{height:`${(parents.length - parentsLoaded) * navHeight}px`}}
                />
              )}
            </nav>);
          } else if (parents.length > 1) {
            content = (<React.Fragment>
              <Button color="default" onClick={this.handleButtonClick}>(Multiple Parents)</Button>
              &gt;
              {button}
            </React.Fragment>);
          } else if (parents.length > 0) {
            content = (<React.Fragment>
              {parents}
              &gt;
              {button}
            </React.Fragment>);
          } else {
            content = (<React.Fragment>
              <BreadcrumbButton>No parents found</BreadcrumbButton>
              &gt;
              {button}
            </React.Fragment>);
          }
        } else {
          content = button;
        }
      } else {
        content = <BreadcrumbButton>???</BreadcrumbButton>;
      }

      if (!child) return (<nav className={classes.root}>{content}</nav>);
      return content;
    }
  }

  return withClient({
    hooks: { details: options.request },
  })(withStyles(
    styles
  )(Breadcrumb));
};
