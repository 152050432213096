const initialSettings = {
  isOpen: false,
  data: null
};

// ACTION TYPES
export const DIALOG_STANDARD_OPEN = 'DIALOG_STANDARD_OPEN';
export const DIALOG_STANDARD_CLOSE = 'DIALOG_STANDARD_CLOSE';
export const DIALOG_STANDARD_SET_DATA = 'DIALOG_STANDARD_SET_DATA';

// ACTION CREATORS
export const openStandardDialog = () => ({type: DIALOG_STANDARD_OPEN});
export const closeStandardDialog = () => ({type: DIALOG_STANDARD_CLOSE});
export const setStandardDialogData = data => ({type: DIALOG_STANDARD_SET_DATA, data});

export default (state = initialSettings, action) => {
  switch (action.type) {
    case DIALOG_STANDARD_OPEN: return {...state, isOpen: true};
    case DIALOG_STANDARD_CLOSE: return {...state, isOpen: false, data: null};
    case DIALOG_STANDARD_SET_DATA: return {...state, data: action.data};
    default: return state;
  }
};
