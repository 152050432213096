import React from 'react';

import IconWarning from '@material-ui/icons/Warning';

import {withClient,createSelector} from "reducers/client";
import {tabElements, elementDetails} from "reducers/client/requestTypes";

import elementTypes from "constants/ElementTypes";

import ContentPreview from "./ContentPreview";

const TabPreview = ({
                          tabElements,
                          elementDetails,
                          tabId,
                          elementType,
                          ...innerProps
                        }) => {
  if (!tabElements.isLoaded()) return null;
  if (!tabElements.get().find(e => e.elementType === elementType)) {
    return (
      <p><IconWarning style={{verticalAlign:'middle'}}/> No "{elementType}" element found.</p>
    );
  }
  if (!elementDetails.isLoaded()) return null;
  return <ContentPreview contentVersionId={elementDetails.get().currentVersionId} {...innerProps} />;
};

const getTabId = (state, props) => props.tabId;
const getElementType = (state, props) => props.elementType || elementTypes.tabbedContent.name;
const getTabElements = createSelector('tabElements',getTabId);
export default withClient({
  hooks: {
    tabElements: tabElements(getTabId),
    elementDetails: elementDetails((state, props) => {
      const tabElements = getTabElements(state, props);
      if (tabElements && tabElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const tabElement = tabElements.get().find(e => e.elementType === elementType);
        if (tabElement) return tabElement.id;
      }
      return null;
    }),
  }
})(TabPreview);
