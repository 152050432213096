import React, {Component} from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import {connect} from 'react-redux';
import {closeClassificationDialog, setClassificationDialogData} from "../../../reducers/Dialogs/Classifications";
import {makeClassificationSelector, saveClassification, requestClassificationDetails} from "../../../reducers/classifications";
import ClassificationCreateForm from './ClassificationForm';

class ClassificationModal extends Component {

  renderForm(classification){
    return <ClassificationCreateForm classification={classification} onClose={this.props.mappedCloseDialog} save={(payload) => this.props.mappedSaveClassification(payload)}/>
  }

  closeDialog = () => {
      this.props.mappedSetClassificationDialogData(null);
      this.props.mappedCloseDialog();
  };

  render(){
      return (
        <Dialog open={this.props.isOpen} onClose={this.closeDialog} maxWidth={'md'} fullWidth>
          <DialogContent>
            <div className="row">
              <div className="col-sm-2">
              </div>
              <div className="col-sm-8">
                {this.renderForm()}
              </div>
              <div className="col-sm-2">
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )
  }
}

const mapStateToProps = () => {
  const getClassification = makeClassificationSelector((state, props) => {
    return props.classificationId
  });
  return (state, props) => ({
    isOpen: state.dialogs.classifications.isOpen,
    nodeData: state.dialogs.classifications.data,

    classificationRequest: getClassification(state, props),
    // saveRequest: state.medias.save,
  });
};

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeClassificationDialog()),
  mappedSetClassificationDialogData: (payload) => dispatch(setClassificationDialogData(payload)),
  mappedSaveClassification: payload => dispatch(saveClassification(payload)),
  mappedRequestClassification: classificationId => dispatch(requestClassificationDetails(classificationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationModal)