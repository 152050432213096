import {NodeSelection, TextSelection} from "prosemirror-state";

import {makeMediaSelector, requestMediaDetails} from "reducers/medias";
import {PLUGIN_INTERFACE} from "components/Prosemirror/plugins/interface";

import {log} from "constants/Config";
import MediaTypes from "../../../../constants/MediaTypes";
import ImageMediaView from "components/Prosemirror/plugins/nodeViews/media/image";
import MathpixMediaView from "components/Prosemirror/plugins/nodeViews/media/mathpix";
import FormulaMediaView from "components/Prosemirror/plugins/nodeViews/media/formula";

const defaultInputAttributes = {type: 'text'};
const input = (root, getValue, setValue, attrs) => {
  attrs = { ...defaultInputAttributes, ...attrs };
  const dom = document.createElement('input');
  dom.setAttribute('type','text');
  for (let name in attrs) { if (attrs.hasOwnProperty(name)) {
    dom.setAttribute(name, attrs[name]);
  }}
  dom.value = getValue();

  dom.addEventListener('mousedown', ev => {
    // Prevent dragging the title node while clicking inside input
    root.setAttribute('draggable', false);
    const reset = ev => {
      root.setAttribute('draggable', false);
      root.removeEventListener('mouseup', reset);
    };
    root.addEventListener('mouseup', reset);
    ev.stopPropagation();
  });
  dom.addEventListener('mousemove', ev => ev.stopPropagation());
  dom.addEventListener('input', ev => {
    if (dom.value !== getValue()) setValue(dom.value);
  });
  return dom;
};

let count = 0;

export default class LessonTitleView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;
    if (log.prosemirror) console.log(`[PROSEMIRROR] construct LessonTitleView#${this.id}`, node);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('header');
    this.dom.setAttribute('class', 'lessonTitle');

    this.numberInput = input(
      this.dom,
      () => this.node.attrs.number,
      value => this.updateAttribute('number', value),
      {placeholder: '#', class:'lessonNumber'}
    );
    this.titleInput = input(
      this.dom,
      () => this.node.attrs.title,
      value => this.updateAttribute('title', value),
      {placeholder: 'Title', class:'lessonTitle'}
    );
    this.subtitleInput = input(
      this.dom,
      () => this.node.attrs.subtitle,
      value => this.updateAttribute('subtitle', value),
      {placeholder: 'Subtitle', class:'lessonSubtitle'}
    );

    this.titleImage = document.createElement('div');
    this.titleImage.setAttribute('class','imagePlaceholder');
    this.titleImage.style.width = this.node.attrs['media-scale'];
    this.titleImage.innerHTML = '?';

    this.titleImage.addEventListener('click', ev => {
      this.outerView.dispatch({
        external: true,
        method: 'lessonTitleImage',
        nodeView: {
          node: this.node,
          view: this.outerView,
          getPos: this.getPos
        }
      })
    });

    this.numberInput.addEventListener('keydown', ev => this.cycleInput(ev, null, this.titleInput));
    this.titleInput.addEventListener('keydown', ev => this.cycleInput(ev, this.numberInput, this.subtitleInput));
    this.subtitleInput.addEventListener('keydown', ev => this.cycleInput(ev, this.titleInput, null));

    this.titleHeading = document.createElement('h1');
    this.titleHeading.appendChild(this.numberInput);
    this.titleHeading.appendChild(this.titleInput);
    this.subtitleHeading = document.createElement('p');
    this.subtitleHeading.appendChild(this.subtitleInput);

    this.inputContainer = document.createElement('div');
    this.inputContainer.setAttribute('class','lessonTitleInputs');
    this.inputContainer.appendChild(this.titleHeading);
    this.inputContainer.appendChild(this.subtitleHeading);

    this.imageContainer = document.createElement('div');
    this.imageContainer.setAttribute('class','lessonTitleImage');
    this.imageContainer.appendChild(this.titleImage);

    this.dom.appendChild(this.inputContainer);
    this.dom.appendChild(this.imageContainer);

    this.mediaSelector = makeMediaSelector(() => this.node.attrs['media-id']);
    this.unsubscribe = this.interface.observeRedux(this.mediaSelector, requestState => this.onMediaUpdated(requestState));
  }

  onMediaUpdated(requestState) {
    const mediaId = this.node.attrs['media-id'];
    if (requestState.id !== mediaId) return;
    if (log.prosemirror) console.log(`[PROSEMIRROR] observe Redux LessonTitleView#${this.id}`, this.node, requestState);
    if (requestState.isFetchRequired()) {
      this.interface.dispatch.toRedux(requestMediaDetails(mediaId));
    } else if (requestState.isLoaded()) {
      const media = requestState.get();
      if (media.type === MediaTypes.IMAGE.name || media.type === MediaTypes.MATHPIX.name) {
        let img = document.createElement('img');
        img.setAttribute('src',media.publicUrl);
        img.setAttribute('alt',this.node.attrs['media-alt']);
        img.style.width = this.node.attrs['media-scale'];

        img.addEventListener('click', ev => {
          this.outerView.dispatch({
            external: true,
            method: 'lessonTitleImage',
            nodeView: {
              node: this.node,
              view: this.outerView,
              getPos: this.getPos
            }
          })
        });

        this.imageContainer.replaceChild(img, this.titleImage);
        this.titleImage = img;
      } else {
        console.warn(`[PROSEMIRROR] WARNING LessonTitleView#${this.id} : Unsupported media type`, media);
      }
    } else if (requestState.isError()) {
      console.error(`[PROSEMIRROR] REDUX ERROR LessonTitleView#${this.id}`, requestState.error);
    }
  }

  selectNode() {
    // this.numberInput.focus();
  }

  update(node) {
    if (node.type.name !== 'lessonTitle') return false;
    const {number, title, subtitle} = node.attrs;

    if (number !== this.numberInput.value) this.numberInput.value = number;
    if (title !== this.titleInput.value) this.titleInput.value = title;
    if (subtitle !== this.subtitleInput.value) this.subtitleInput.value = subtitle;
    if (this.titleImage.style.width !== node.attrs['media-scale']) {
      this.titleImage.style.width = node.attrs['media-scale'];
    }

    this.node = node;
    return true;
  }

  updateAttribute(name, value) {
    const {tr} = this.outerView.state;
    tr.setNodeMarkup(this.getPos(), null, {
      ...this.node.attrs,
      [name]: value
    });
    this.outerView.dispatch(tr);
  }
  cycleInput(ev, prev, next) {
    let exitPos = null;
    if (ev.key === 'ArrowUp') {
      if (prev) { prev.focus(); }
      else { exitPos = this.getPos(); }
    } else if (ev.key === 'ArrowDown') {
      if (next) { next.focus(); }
      else { exitPos = this.getPos() + this.node.nodeSize; }
    }

    if (exitPos !== null) {
      this.outerView.focus();

      const {tr, doc} = this.outerView.state;
      tr.setSelection(TextSelection.create(doc, exitPos));
      this.outerView.dispatch(tr);
    }
  }

  destroy() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] destroy LessonTitleView#${this.id}`);
    this.unsubscribe();
  }

  stopEvent(ev) {
    return !!ev.key || ev.type === 'input' || ev.type === 'copy' || ev.type === 'paste';
  }
  ignoreMutation() { return true; }
}
