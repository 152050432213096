import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import {
  Chip,
  IconButton,
  TextField
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";

const MediaTags = ({value, disabled, onChange, ...innerProps}) => {
  const [newTagName, setNewTagName] = useState('');
  const handleChangeNewTagName = useCallback(ev => setNewTagName(ev.target.value), [setNewTagName]);
  const handleSaveNewTag = useCallback(
    () => {
      onChange(value.concat(newTagName));
      setNewTagName('');
    },
    [value, onChange, newTagName, setNewTagName]
  );
  const handleTagInputKeyPress = useCallback(ev => {
    if(ev.keyCode == 13 && newTagName.length > 0) handleSaveNewTag();
  }, [newTagName, handleSaveNewTag]);
  const handleRemoveTag = useCallback(tag => {
    return onChange(value.filter(t => t !== tag));
  }, [value, onChange]);

  return (
    <TextField
      value={newTagName}
      onChange={handleChangeNewTagName}
      disabled={disabled}
      inputProps={{onKeyDown:handleTagInputKeyPress}}
      InputProps={{
        startAdornment: value.length > 0 && value.map(tag => (
          <Chip
            key={tag}
            label={tag}
            onDelete={() => handleRemoveTag(tag)}
          />
        )),
        endAdornment: (newTagName.length > 0 &&
          <IconButton onClick={handleSaveNewTag}>
            <IconAdd />
          </IconButton>
        )
      }}
      {...innerProps}
    />
  );
};
MediaTags.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
MediaTags.defaultProps = {
  disabled: false,
  onChange: () => {}
};

export {MediaTags}
