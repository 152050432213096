import { nodes }  from 'prosemirror-schema-basic/src/schema-basic';

export function patch(spec) {
  // const toDOM = node => {
  //   const {number, title, subtitle} = node.attrs;
  //   const mediaAttrs = {
  //     ['media-id']: node.attrs['media-id'],
  //     alt: node.attrs['media-alt']
  //   };
  //   return [
  //     'div',
  //     {class: 'lessonTitle'},
  //     ['c3po-media', mediaAttrs],
  //     ['h1',{},
  //       ['span',{class: 'lessonNumber'},number],
  //       title
  //     ],
  //     ['h2',{},subtitle],
  //   ]
  // };
  // const parseDOM = [{
  //   tag: 'div',
  //   getAttrs: dom => {
  //
  //
  //     return false;
  //   }
  // }];
  //
  // spec.nodes.lessonTitle = {
  //   attrs: {
  //     number: {default: ''},
  //     title: {default: ''},
  //     subtitle: {default: ''},
  //     'media-id': {default: ''},
  //     'media-alt': {default: ''},
  //   },
  //   group: 'block',
  //   draggable: true,
  //   toDOM,
  //   parseDOM,
  // };

  // const getAttrs = containerType => dom => ({
  //   containerType,
  //   containerId: dom.getAttribute('problem-id') || dom.getAttribute('problemId'),
  //   elementType: dom.getAttribute('element-type') || dom.getAttribute('elementType'),
  // });
  // const setAttrs = ({containerType, containerId, elementType}) => ({
  //   class: 'contentContainerReference',
  //   'container-type': containerType,
  //   'container-id': containerId,
  //   'element-type': elementType,
  // });
  //
  // spec.nodes.contentContainerReference = {
  //   attrs: {
  //     containerType: {},
  //     containerId: {},
  //     elementType: {},
  //   },
  //   group: 'block',
  //   draggable: true,
  //   toDOM: node => ['div', setAttrs(node.attrs)],
  //   parseDOM: [{
  //     tag: 'div.problemReference',
  //     getAttrs: getAttrs('lessonContentContainer'),
  //     priority: 61
  //   },{
  //     tag: 'div.answerReference',
  //     getAttrs: getAttrs('lessonContentContainer'),
  //     priority: 61
  //   }]
  // };

  return spec;
}

export default {
  nodes: {}
};
