export const mapParents = parents => {
  const containers = [];
  const books = {};

  parents.forEach(p => {
    if (p.type === 'book') books[p.id] = p.name;
    else containers.push(p);
  });
  return containers.map(c => ({
    ...c,
    bookName: books[c.bookId]
  }));
};
