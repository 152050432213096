import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {withClient} from "reducers/client";
import {
  bookDetails,
  bookLessons,
  bookMenus,
  updateBook,
  bookChapters,
  bookSections,
  bookSectionElements,
  menuItemsForMenu,
  createMenuItem, menuRender,
} from "reducers/client/requestTypes";
import {withStyles} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";

const styles = theme => ({
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  paddingLeft20:{
    paddingLeft: 20
  }
});

class AddMenuItemForm extends Component {
  state = {
    type: 'chapter',
    value: '',
    properties: { ebooksUrl: ''},
    displayName: '',
    name: '',
    altTitle: '',
    selectedLesson: '',
    selectedElement: '',
    parentMenuItemId: this.props.data.parentMenuItemId
  };

  handleChangeType = (event) => {
    this.setState({type: event.target.value})
  };

  handleChangeTargetElement = (event, type) => {
    const {bookDetails} = this.props;
    const bookName = bookDetails.state.data.name;
    const item = event.target.value;
    const name = item.name ? item.name : item.title;
    let ebooksUrl = type === 'chapter' ? 'javascript:;' : `bookdb.php?title=${encodeURIComponent(bookName)}&name=${encodeURIComponent(name)}&type=${encodeURIComponent(type)}`;

    let element;
    if(type === 'misc'){
      element = item;
    } else if(type === 'lesson'){
      element =  item.elements.find(e => e.elementType === type);
    }

    if(element && type !== 'chapter' ){
      ebooksUrl += `&c3po_id=${element.id}`
    }
    this.setState({
      selectedElement: item,
      displayName: name,
      name: name,
      value: item.id,
      properties: {...this.state.properties, ebooksUrl}
    })
  };

  renderSelect = (request, name, type) => {

    const {classes} = this.props;

    if(!request.isLoaded()){
      return <LinearProgress />
    }

    const items = request.state.data;
    return (
      <div className={`${classes.flexCol} ${classes.paddingLeft20}`}>
        <br />
        <Select
          value={this.state.selectedElement}
          onChange={(event) => this.handleChangeTargetElement(event, type)}
          inputProps={{
            id: 'element-picker',
            name: {name}
          }}
        >
          {items.map((item) => <MenuItem key={`menuItem-${item.id}`} value={item} >{item.name ? item.name : item.title}</MenuItem>)}
        </Select>
      </div>
    )
  };

  renderLinkInput = () => {
    return (
        <TextField
          id="value-field"
          label="Value"
          value={this.state.value}
          onChange={this.handleChangeText('value')}
          margin="normal"
        />
      )
  };

  renderElementGrabberOrLinkInput = () => {
    const {bookLessons:lessonsRequest, bookChapters:chaptersRequest, bookSections:sectionsRequest, bookSectionElements:sectionElementsRequest} = this.props;
    switch (this.state.type) {
      case 'chapter':
        return this.renderSelect(chaptersRequest, 'chapter', 'chapter');
      case 'section':
        return this.renderSelect(sectionsRequest, 'section', 'chapter');
      case 'lesson':
        return this.renderSelect(lessonsRequest, 'lesson', 'lesson');
      case 'misc':
        return this.renderSelect(sectionElementsRequest, 'misc', 'misc');
      case 'link':
        return this.renderLinkInput();
      default:
        return this.renderLinkInput();
    }
  };

  handleChangeText = name => event => {
    this.setState({[name]: event.target.value})
  };

  handleChangeEbooksUrl = (event) => {
    this.setState({properties: {...this.state.properties, ebooksUrl: event.target.value}})
  };

  saveMenuItem = () => {
    const {menuItemsForMenu, menuRender, createMenuItem} = this.props;
    createMenuItem.sendRequest(this.state)
      .then((result) => {
        menuItemsForMenu.sendRequest();
        menuRender.sendRequest();
        this.props.mappedCloseDialog();
      });

  };

  render(){
    const {createMenuItem, classes}= this.props;
    return (
      <div className={classes.flexCol}>
        <br />
        <Select
          value={this.state.type}
          onChange={this.handleChangeType}
        >
          <MenuItem value={'chapter'}>Chapter</MenuItem>
          <MenuItem value={'section'}>Section</MenuItem>
          <MenuItem value={'lesson'}>Lesson</MenuItem>
          <MenuItem value={'misc'}>Misc</MenuItem>
          <MenuItem value={'link'}>Link</MenuItem>
        </Select>

        {this.state.type &&
          this.renderElementGrabberOrLinkInput()
        }

        <TextField
          id="display-name-field"
          label="Display Name"
          value={this.state.displayName}
          onChange={this.handleChangeText('displayName')}
          margin="normal"
        />

        <TextField
          id="name-field"
          label="Name"
          value={this.state.name}
          onChange={this.handleChangeText('name')}
          margin="normal"
        />

        <TextField
          id="value-field"
          label="Ebooks URL"
          value={this.state.properties.ebooksUrl}
          onChange={this.handleChangeEbooksUrl}
          margin="normal"
        />

        <TextField
          id="alt-title-field"
          label="Alt Title"
          value={this.state.altTitle}
          onChange={this.handleChangeText('altTitle')}
          margin="normal"
        />

        <Button variant={"contained"} color={'primary'} onClick={this.saveMenuItem} >Save</Button>

      </div>
    )
  }
}

export default withClient({
  hooks: {
    bookDetails: bookDetails((state, props) => state.books.selected),
    updateBook: updateBook((state, props) => state.books.selected),
    bookMenus: bookMenus((state, props) => state.books.selected),
    bookLessons: bookLessons((state, props) => state.books.selected),
    bookChapters: bookChapters((state, props) => state.books.selected),
    bookSections: bookSections((state, props) => state.books.selected),
    bookSectionElements: bookSectionElements((state, props) => state.books.selected),
    createMenuItem: createMenuItem((state, props) => state.menus.selected),
    menuItemsForMenu: menuItemsForMenu((state, props) => state.menus.selected),
    menuRender: menuRender((state, props) => state.menus.selected),
  },
})(withStyles(styles)(AddMenuItemForm));