import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import CategoriesTable from 'components/Tables/CategoriesTable';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class CategoryList extends React.Component {
  render() {
    const {classes} = this.props;
    return (<div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="h3" gutterBottom>CPM eBook Categories</Typography>
        <Paper>
          <CategoriesTable />
        </Paper>
      </div>
    </div>);
  }
}

export const CategoryListView = withStyles(styles)(CategoryList);
