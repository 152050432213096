import React from 'react';
import {Node, DOMParser, DOMSerializer} from 'prosemirror-model';
import { EditorState, NodeSelection, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import {
  setEditorDocChanged,
  showSnackbarSaving,
  showSnackbarSaveSuccess,
  showSnackbarSaveError
} from "reducers/Prosemirror";
import {dialogOpen, dialogSetState} from "reducers/dialog/actions";

import {createObserver} from "reducers/client";
import {elementDetails, elementCreateCurrentVersion} from "reducers/client/requestTypes";

import interfacePlugin from './plugins/interface';

import {findDescendants, getContext, commonAncestor, matchTypes, findNodesInRange} from "./util";
import {getColumnWidth, setColumnWidth} from "components/Prosemirror/commands/table";

import {log} from 'constants/Config';
import {LAYOUTS} from "../Dialogs/Prosemirror/Columns/LayoutSelect";

import {updateDoc} from './schema';

export default class ProsemirrorInterface {
  constructor(reduxStore, history, classes) {
    this.redux = {store: reduxStore};
    this.dispatch = {
      toRedux: reduxStore.dispatch,
      toProsemirror: (transaction) => this.handle(transaction),
      toRouter: history
    };
    this.classes = classes;
    this.__observers = [];
  }

  init(options, contentVersion) {
    const { schema } = options;
    const plugins = options.plugins.concat(interfacePlugin(this));

    if (log.prosemirror) {
      console.group('[PROSEMIRROR] INTERFACE INIT _________________________');
      console.log('options:', options);
      console.log('contentVersion:', contentVersion);
    }

    let doc = null;
    if (contentVersion) {
      if (contentVersion.markup) {
        // Load from JSON
        let documentJSON = contentVersion.markup;
        if (typeof documentJSON === 'string') { documentJSON = JSON.parse(documentJSON); }
        // TODO : re-save all pages that were saved previous to commit a723107, then delete this hack
        if (!documentJSON.type) {
          documentJSON = {
            type: 'doc',
            content: documentJSON
          };
        }

        if (log.prosemirror) {
          console.log('documentJSON from markup:', documentJSON);
        }
        doc = Node.fromJSON(schema, documentJSON);
      } else {
        // Load from HTML if no JSON is present
        const fragment = document.createElement('article');
        fragment.innerHTML = contentVersion.html;

        if (log.prosemirror) {
          console.log('document HTML:', contentVersion.html);
        }
        doc = DOMParser.fromSchema(schema).parse(fragment, {preserveWhitespace: true});
      }
      this.observer = createObserver(
        {
          hooks: {
            elementDetails: elementDetails((state, props) => props.elementId),
            elementCreateCurrentVersion: elementCreateCurrentVersion((state, props) => props.elementId),
          },
          props: {
            elementId: contentVersion.elementId,
          }
        },
        this.getReduxStore(),
        ({elementDetails, elementCreateCurrentVersion}) => {
          this.elementDetails = elementDetails;
          this.elementCreateCurrentVersion = elementCreateCurrentVersion;
        }
      );
    }

    this.options = {
      plugins,
      schema,
      doc
    };
    this.contentVersion = contentVersion;
    this.initialDoc = doc;

    this.state = EditorState.create(this.options);
    if (log.prosemirror) {
      console.log('State:',this.state);
      console.groupEnd();
    }
    if (this.view) {
      this.view.updateState(this.state);
    }
    this.dispatch.toRedux(setEditorDocChanged(false));
    // Perform any necessary updates
    updateDoc(this.state, this.dispatch.toProsemirror);
    return this.state;
  }

  getContentVersion() { return this.contentVersion; }
  getReduxStore() {
    return this.redux.store;
  }
  getReduxState() {
    return this.redux.store.getState();
  }
  observeRedux(selector, observer) {
    const {store} = this.redux;
    let currentValue;

    const handleChange = () => {
      let previousValue = currentValue;
      currentValue = selector(store.getState());

      if (previousValue !== currentValue) {
        observer(currentValue, previousValue);
      }
    };

    const unsubscribe =  store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
  }

  isConnected() {
    return this.view != null;
  }
  connect(node, options) {
    this.view = new EditorView(node, {
      state: this.state,
      dispatchTransaction: this.dispatch.toProsemirror,
      ...options,
    });
  }
  disconnect() {
    this.view.destroy();
    this.view = null;
  }

  getHTML() {
    const fragment = DOMSerializer.fromSchema(this.state.schema).serializeFragment(this.state.doc.content);
    const converter = document.createElement('article');
    converter.appendChild(fragment);
    return converter.innerHTML;
  }

  debug() {
    const output = resolvedPos => {
      console.group(`Prosemirror Node: ${resolvedPos.parent.type.name}`);
      console.log('Position:', resolvedPos);
      console.log('Node:', resolvedPos.parent);
      console.groupEnd();
    };

    if (this.isConnected()) {
      const {selection} = this.state;
      if (selection.to === selection.from) {
        const node = selection.$from.parent;
        return (<div>
          <div>Prosemirror is online.</div>
          <div>
            Cursor at:
            [<b>{selection.from}</b>]
            Inside:
            [<a href="javascript:void(0)" title="show debug info in the console"  onClick={()=>output(selection.$from)}>
              {node.type.name}
            </a>]
          </div>
          <div>
            Offset:
            [<b>{selection.$from.parentOffset}</b>]
            Node Size:
            [<b>{node.nodeSize}</b>]
          </div>
        </div>);
      } else {
        const from = selection.$from.parent;
        const to = selection.$to.parent;
        if (from === to) {
          return (<div>
            <div>Prosemirror is online.</div>
            <div>
              Selected:
              [<b>{selection.from}</b>]
              to
              [<b>{selection.to}</b>]
              Inside:
              [<a href="javascript:void(0)" title="show debug info in the console" onClick={()=>output(selection.$from)}>
                {from.type.name}
              </a>]
            </div>
            <div>
              Offset:
              [<b>{selection.$from.parentOffset}</b>]
              Node Size:
              [<b>{from.nodeSize}</b>]
            </div>
          </div>);
        } else {
          const ancestor = commonAncestor(selection.$from, selection.$to);
          return (<div>
            <div>Prosemirror is online.</div>
            <div>
              Selected:
              [<b>{selection.from}</b>]
              to
              [<b>{selection.to}</b>]
            </div>
            <div>
              Multiple Nodes, common ancestor:
              [<a href="javascript:void(0)" title="show debug info in the console" onClick={()=>output(ancestor)}>
                {ancestor.nodeAfter.type.name}
              </a>]
            </div>
          </div>);
        }
      }
    } else {
      return 'Prosemirror is offline.';
    }
  }

  observe(callback) {
    this.__observers.push(callback);
    return () => this.__observers.splice(this.__observers.indexOf(callback), 1);
  }

  onStateChange(...params) {
    this.__observers.forEach(fn => fn(...params));
  }

  handle(transaction) {
    // External flag dictates whether events should be handled by Redux or PM
    if (!transaction.external) {
      if (this.isConnected()) {
        this.state = this.view.state.apply(transaction);
        this.view.updateState(this.state);
      } else {
        this.state = this.state.apply(transaction);
      }
      if (transaction.docChanged) {
        const wasPreviouslyChanged = this.getReduxState().prosemirror.docChanged;
        if (this.state.doc.eq(this.initialDoc)) {
          if (wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(false));
        } else {
          if (!wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(true));
        }
      }
      if (this.onStateChange) this.onStateChange(this.state, transaction);
    }

    switch (transaction.method) {
      case 'redirect': return this.redirect(transaction.to);
      case 'quickSave': return this.quickSave();
      case 'save': return this.promptSave();
      case 'contentContainerReference': return this.promptContentContainerReference();
      case 'image': return this.promptImage(transaction.nodeView);
      case 'lessonTitleImage': return this.promptLessonTitleImage(transaction.nodeView);
      case 'columns': return this.promptColumns(transaction.nodeView);
      case 'table': return this.promptTable();
      case 'homework': return this.promptHomework();
      case 'mathquill': return this.promptMathquill(transaction.nodeView, transaction.latex);
      case 'mathpix': return this.promptMathpix(transaction.nodeView);
      case 'mathml': return this.promptMathml(transaction.nodeView);
      case 'desmos': return this.promptDesmos(transaction.nodeView);
      case 'embed': return this.promptEmbed(transaction.nodeView);
      case 'link': return this.promptLink(transaction.active);
      default: return false;
    }
  }

  ping() {
    // Update the state with a blank transaction to make the editor
    // account for changes in the view
    this.handle(this.view.state.tr.setMeta('ping',true));
  }

  redirect(to) {
    this.dispatch.toRouter.push(to);
  }

  promptContentContainerReference() {
    // If the selection is currently empty
    const {selection, schema} = this.state;
    if (selection.$cursor) {
      this.dispatch.toRedux(dialogOpen('prosemirror-lessonContentContainer',{
        elementId: this.contentVersion.elementId,
      }));
    } else {
      const slice = selection.content();
      const fragment = DOMSerializer.fromSchema(schema).serializeFragment(slice.content);
      const converter = document.createElement('article');
      converter.appendChild(fragment);

      if (log.prosemirror) {
        console.group('[PROSEMIRROR] INTERFACE :: promptContentContainerReference() _________________________');
        console.log('slice:', slice);
        console.log('fragment:', fragment);
        console.groupEnd();
      }

      this.dispatch.toRedux(dialogOpen('prosemirror-lessonContentContainer',{
        elementId: this.contentVersion.elementId,
        markup: slice.content.toJSON(),
        html: converter.innerHTML
      }));
    }
  }

  quickSave() {
    const contentVersion = {
      elementId: this.contentVersion.elementId,
      markup: this.state.doc.toJSON(),
      html: this.getHTML()
    };

    this.dispatch.toRedux(showSnackbarSaving());
    this.elementCreateCurrentVersion.sendRequest(contentVersion, true)
      .then(() => {
        this.dispatch.toRedux(showSnackbarSaveSuccess());
        return this.elementDetails.sendRequest();
      })
      .catch(() => {
        this.dispatch.toRedux(showSnackbarSaveError());
      });
  }

  promptSave() {
    let data = {
      elementId: this.contentVersion.elementId,
      markup: this.state.doc.toJSON(),
      html: this.getHTML()
    };
    this.dispatch.toRedux(dialogOpen('prosemirror-save', data));
  }

  promptImage(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    let state = undefined;
    if (data && data.attrs && data.attrs['media-id']) {
      state = {mediaId: data.attrs['media-id']};
    }
    this.dispatch.toRedux(dialogOpen('prosemirror-image', data, state));
  }
  promptLessonTitleImage(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    let state = undefined;
    if (data && data.attrs) {
      state = {
        mediaId: data.attrs['media-id'],
        scale: parseInt(data.attrs['media-scale'])
      };
    }
    this.dispatch.toRedux(dialogOpen('prosemirror-lessonTitleImage', data, state));
  }

  promptColumns(nodeView) {
    this.dispatch.toRedux(dialogOpen('prosemirror-columns'));
  }

  promptTable() {
    const {$from, $to} = this.state.selection;
    const [$table] = findNodesInRange($from, $to, node => node.type.spec.tableRole === 'table');
    if ($table) {
      let columnWidth = getColumnWidth(this.state);
      let data = {
        attrs: {
          ...$table.nodeAfter.attrs,
          columnWidth
        },
        pos: $table.pos
      };
      this.dispatch.toRedux(dialogOpen('prosemirror-table', data));
    }
  }
  updateTable(options) {
    if (options.attrs) {
      this.updateNodeAttrs(options.attrs, options.pos);
    }
    if (options.columnWidth) {
      setColumnWidth(options.columnWidth)(this.state, this.dispatch.toProsemirror);
    }
  }

  promptDesmos(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    this.dispatch.toRedux(dialogOpen('prosemirror-desmos', data));
  }

  promptEmbed(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    this.dispatch.toRedux(dialogOpen('prosemirror-embed', data));
  }

  promptHomework() {
    const hints = findDescendants(this.state.doc, node => node.type.name === 'homeworkHint');
    let options = hints.map(node => ({id: node.attrs.id, text: node.textContent }));
    this.dispatch.toRedux(dialogOpen('prosemirror-homework', options));
  }

  promptMathquill(nodeView, latex) {
    let data = {latex};

    if (nodeView) {
      data.attrs = nodeView.node.attrs;
      data.pos = nodeView.getPos();
    } else {
      const {selection, schema} = this.state;
      if (!selection.$cursor) {
        const slice = selection.content();
        const fragment = DOMSerializer.fromSchema(schema).serializeFragment(slice.content);
        const converter = document.createElement('div');
        converter.appendChild(fragment);

        data.html = converter.firstChild.innerHTML;
      }
    }
    this.dispatch.toRedux(dialogOpen('prosemirror-mathquill', data));
  }
  promptMathpix(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    this.dispatch.toRedux(dialogOpen('prosemirror-mathpix', data));
  }
  promptMathml(nodeView) {
    let data = nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;
    this.dispatch.toRedux(dialogOpen('prosemirror-mathml', data));
  }

  promptLink(active) {
    let data = active ? {
      attrs: active.node ? active.node.attrs : active.mark.attrs,
      start: active.start,
      end: active.end
    } : null;
    this.dispatch.toRedux(dialogOpen('prosemirror-link', data));
  }

  insertNode(nodeTypeName, attrs, meta) {
    if (log.prosemirror) {
      console.group('[PROSEMIRROR] INTERFACE :: insertNode() _________________________');
      console.log('nodeTypeName:', nodeTypeName);
      console.log('attrs:', attrs);
      console.log('meta:', meta);
      console.log('selection:', this.state.selection);
      console.groupEnd();
    }
    const node = this.state.schema.nodes[nodeTypeName].createAndFill(attrs);
    let {tr} = this.state;
    tr.replaceSelectionWith(node);
    if (meta) Object.keys(meta).forEach(key => tr.setMeta(key, meta[key]));
    this.dispatch.toProsemirror(tr);
  }
  updateNodeAttrs(attrs, pos) {
    this.dispatch.toProsemirror(this.state.tr.setNodeMarkup(pos, null, attrs));
  }

  updateMarkAttrs(start, end, markType, attrs) {
    const {doc, tr} = this.state;
    let activeMark = doc.resolve(start+1).marks().find(m => m.type === markType);
    tr.removeMark(start, end, activeMark);
    tr.addMark(start, end, markType.create(attrs));
    this.dispatch.toProsemirror(tr);
  }

  insertColumns(layoutID) {
    const {nodes} = this.state.schema;

    const columns = LAYOUTS[layoutID].map(width => nodes.column.createAndFill({width}));
    const columnSet = nodes.columnSet.createAndFill(null, columns);

    this.dispatch.toProsemirror(this.state.tr.replaceSelectionWith(columnSet));
  }
  updateColumns(layoutID, pos) {

  }

  insertHomeworkContainer(hintID) {
    const hh = this.state.schema.nodes.homeworkHintTarget.createAndFill({ hintID });
    this.dispatch.toProsemirror(this.state.tr.replaceSelectionWith(hh));
  }
}
