import React from 'react';
import QueueAnim from 'rc-queue-anim';

import BooksTable from 'components/Tables/PagedTable/BooksTable';

export const BookListView = props => (
  <section className="container-fluid chapter">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              CPM Books
            </h2>
            <BooksTable />
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);
