import React from 'react';
import {connect} from 'react-redux';

import {makeClassificationSelector, requestClassificationDetails} from "../../reducers/classifications";
import WithFetch from "components/WithFetch";

class ClassificationDisplayName extends React.Component {
  render() {
    const {classificationRequest, requestClassificationDetails} = this.props;
    return <WithFetch request={classificationRequest} fetch={requestClassificationDetails}>
      {classification => classification.name}
    </WithFetch>
  }
}

const mapStateToProps = () => {
  const getClassification = makeClassificationSelector((state, props) => props.classificationId);

  return (state, props) => ({
    classificationRequest: getClassification(state, props),
  });
};
const mapDispatchToProps = dispatch => ({
  requestClassificationDetails: classificationId =>
    dispatch(requestClassificationDetails(classificationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationDisplayName);