import {apiClient} from "constants/Clients";

export const lessonDetails = key => ({
  request: lessonId => apiClient.getLesson(lessonId),
  key,
  auto: true,
});
export const updateLesson = key => ({
  request: (lessonId, lesson) => apiClient.updateLesson({id: lessonId, ...lesson}),
  key,
});
export const deleteLesson = key => ({
  request: lessonId => apiClient.deleteLesson(lessonId),
  key,
});
export const lessonCreateElement = key => ({
  request: (lessonId, element) => apiClient.createLessonElement(lessonId, element),
  key,
});
export const lessonElements = key => ({
  request: lessonId => apiClient.getLessonElements(lessonId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const lessonElementMedias = key => ({
  request: ({lessonId, elementId}) => apiClient.getMediaForLessonElement(lessonId,elementId)
    .limit(100),
  key,
  auto: true,
});
export const lessonElementMediasByType = type => key => ({
  request: ({lessonId, elementId}) => apiClient.getMediaForLessonElement(lessonId,elementId)
    .setQueryParams({type})
    .limit(100),
  key,
  auto: true,
});
export const lessonCreateLessonContentContainer = key => ({
  request: (lessonId, container) => apiClient.createLessonContentContainerForLesson(lessonId, container),
  key,
});
export const lessonContainers = key => ({
  request: lessonId => apiClient.getLessonContentContainersForLesson(lessonId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const lessonContainerOrder = key => ({
  request: (lessonId, containerIds) => apiClient.setLessonContentContainerOrderForLesson(lessonId, containerIds),
  key,
});
