import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import Button from "@material-ui/core/Button";

import {closeLessonContentContainerLinkDialog} from 'reducers/Dialogs/LessonContentContainerLink';

import LessonContentContainerBrowser from 'components/Browser/LessonContentContainerBrowser';

class LinkLessonContentContainerDialog extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
  };
  state = {
    selectedLessonContentContainer: null
  };

  componentDidUpdate(prevProps) {
    const {isOpen} = this.props;
    if (isOpen && !prevProps.isOpen) this.setState({selectedLessonContentContainer:null});
  }

  handleSelectLessonContentContainer = ([selectedLessonContentContainer]) => {
    this.setState({selectedLessonContentContainer});
  };
  handleClose = () => {
    const { mappedCloseDialog } = this.props;
    mappedCloseDialog();
  };
  handleConfirm = () => {
    const { mappedCloseDialog, onConfirm } = this.props;
    mappedCloseDialog();
    if (typeof onConfirm === 'function') onConfirm(this.state.selectedLessonContentContainer);
  };

  render() {
    const {isOpen}  = this.props;
    const {selectedLessonContentContainer} = this.state;

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle>Select Lesson Content Container to Link</DialogTitle>
      <div style={{overflowY: 'scroll'}}>
        <LessonContentContainerBrowser onChange={this.handleSelectLessonContentContainer} />
      </div>
      <DialogActions>
        <Button onClick={this.handleClose} color="default">
          Cancel
        </Button>
        <Button onClick={this.handleConfirm} color="primary" disabled={!selectedLessonContentContainer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = state => ({
  isOpen: state.dialogs.lessonContentContainerLink.isOpen,
});

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeLessonContentContainerLinkDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkLessonContentContainerDialog);
