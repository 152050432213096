import React from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconDots from '@material-ui/icons/MoreHoriz';

import {formatDate} from 'constants/Date';
import {withClient} from "reducers/client";
import {categories, categoryOrder, createCategory, updateCategory, deleteCategory} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import ConfirmationDialog from "components/Dialogs/Confirmation";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class CategoriesTable extends React.Component {
  state = {
    categories: [],
    adding: false,
    editingId: -1,
    edit: {
      shortName: '',
      displayName: '',
      description: ''
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {categories} = this.props;
    if (categories.isLoaded()) {
      this.setState({ categories: categories.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {categories} = this.props;
    const {categories:prevCategories} = prevProps;
    if (categories.isLoaded() && categories.hasChanged(prevCategories)) {
      this.setState({ categories: categories.get() });
    }
  }

  isWaiting() {
    const {
      categories,
      categoryOrder,
      createCategory,
      updateCategory,
      deleteCategory,
    } = this.props;
    return categories.isLoading() ||
      categoryOrder.isLoading() ||
      createCategory.isLoading() ||
      updateCategory.isLoading() ||
      deleteCategory.isLoading();
  }
  pendingChanges() {
    const {categories, adding, editingId, edit} = this.state;
    if (adding) {
      return edit.shortName.length > 0 &&
        edit.displayName.length > 0;
    }
    if (categories[editingId]) {
      return categories[editingId].shortName !== edit.shortName ||
        categories[editingId].displayName !== edit.displayName ||
        categories[editingId].description !== edit.description;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          shortName: '',
          displayName: '',
          description: ''
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    const category = this.state.categories[editingId];
    this.setState({
      editingId,
      edit: {
        shortName: category.shortName || '',
        displayName: category.displayName || '',
        description: category.description || '',
      },
    });
  };
  handleClickSave = () => {
    const {createCategory, updateCategory, categories:categoriesRequest} = this.props;
    const {categories, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        createCategory.sendRequest(edit)
          .then(() => categoriesRequest.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateCategory.sendRequest(categories[editingId].id, edit)
          .then(() => categoriesRequest.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteCategory, categories:categoriesRequest} = this.props;
    const {categories, editingId} = this.state;
    deleteCategory.sendRequest(categories[editingId].id)
      .then(() => categoriesRequest.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        return true;
      } else if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    // Click directly on row = navigate
    this.props.history.push(`/app/categories/${this.state.categories[index].id}/details`);
    return false;
  };
  handleChangeOrder = updatedList => {
    const categories = this.props.categories.get();
    this.props.categoryOrder.sendRequest(updatedList.map(c => categories[c].id))
      .then(() => this.props.categories.sendRequest());
  };

  renderCategory = ({item:category, ...props}) => {
    const {classes} = this.props;
    const {categorys, adding, editingId, edit} = this.state;
    const waiting = this.isWaiting();
    return (<TableRow hover {...props}>
      <TableCell padding="checkbox">
        <IconHandle className={classes.dragHandle}/>
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.shortName}
            onChange={ev => this.handleEdit('shortName', ev)}
            disabled={waiting}
            autoFocus
          />
        ) : (
          category.shortName
        )}
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.displayName}
            onChange={ev => this.handleEdit('displayName', ev)}
            disabled={waiting}
          />
        ) : (
          category.displayName
        )}
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.description}
            onChange={ev => this.handleEdit('description', ev)}
            disabled={waiting}
          />
        ) : (
          category.description
        )}
      </TableCell>
      <TableCell>
        {formatDate(category.createdAt)}
      </TableCell>
      <TableCell>
        {formatDate(category.updatedAt)}
      </TableCell>
      <TableCell padding="none" align="right">
        {editingId === props.index ? (
          <React.Fragment>
            <IconButton onClick={this.handleClickDelete} className={classes.itemButton} disabled={waiting}>
              <IconDelete fontSize="small"/>
            </IconButton>
            <IconButton onClick={this.handleClickSave} className={classes.itemButton} disabled={waiting}>
              {this.pendingChanges() ? (
                <IconSave fontSize="small"/>
              ):(
                <IconCancel fontSize="small"/>
              )}
            </IconButton>
          </React.Fragment>
        ) : !adding && editingId === -1 && (
          <IconButton onClick={() => this.handleClickEdit(props.index)} className={classes.itemButton} disabled={waiting}>
            <IconEdit fontSize="small"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>);
  };

  render() {
    const {categories, adding, editingId, edit, confirmationOpen} = this.state;
    if (!categories) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Category"
        message={`Are you sure you want to delete Category ${edit.displayName}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"/>
            <TableCell>Short Name</TableCell>
            <TableCell>Display Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={7} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={this.renderCategory}
          items={categories}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell padding="checkbox"/>
              <TableCell>
                <TextField
                  value={edit.shortName}
                  onChange={ev => this.handleEdit('shortName', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.displayName}
                  onChange={ev => this.handleEdit('displayName', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.description}
                  onChange={ev => this.handleEdit('description', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={this.props.classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={7} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    categories,
    categoryOrder,
    createCategory,
    updateCategory: updateCategory(),
    deleteCategory: deleteCategory(),
  },
})(withStyles(styles)(withRouter(CategoriesTable)));