import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const classifications = {
  request: () =>  apiClient.getClassifications(),
  key: DEFAULT_KEY,
  auto: true,
};
export const classificationDetails = key => ({
  request: (classificationId) => apiClient.getClassification(classificationId),
  key,
  auto: true,
});
export const classificationGrades = key => ({
  request: (classificationId) => apiClient.getGradesForClassification(classificationId),
  key,
  auto: true,
});
