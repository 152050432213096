const initialSettings = {
  isOpen: false,
  data: null
};

// ACTION TYPES
export const DIALOG_MENUITEM_OPEN = 'DIALOG_MENUITEM_OPEN';
export const DIALOG_MENUITEM_CLOSE = 'DIALOG_MENUITEM_CLOSE';
export const DIALOG_MENUITEM_SET_DATA = 'DIALOG_MENUITEM_SET_DATA';

// ACTION CREATORS
export const openMenuItemDialog = () => ({type: DIALOG_MENUITEM_OPEN});
export const closeMenuItemDialog = () => ({type: DIALOG_MENUITEM_CLOSE});
export const setMenuItemDialogData = data => ({type: DIALOG_MENUITEM_SET_DATA, data});

export default (state = initialSettings, action) => {
  switch (action.type) {
    case DIALOG_MENUITEM_OPEN: return {...state, isOpen: true};
    case DIALOG_MENUITEM_CLOSE: return {...state, isOpen: false, data: null};
    case DIALOG_MENUITEM_SET_DATA: return {...state, data: action.data};
    default: return state;
  }
};
