import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, } from 'redux';
import { createLogger} from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleWare from 'redux-thunk';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import APPCONFIG from 'constants/Config';

import {RootRoutes} from './routes';
import {BaseContainer} from './containers/Base';
import createRootReducer from './reducers';

const history = createHashHistory();
let reduxMiddleware = [thunkMiddleWare, promiseMiddleware(), routerMiddleware(history)];
if (APPCONFIG.log.redux) reduxMiddleware = reduxMiddleware.concat(createLogger());
const store = createStore(
  createRootReducer(connectRouter(history)),
  composeWithDevTools(applyMiddleware(...reduxMiddleware))
);

function scrollToTop() {
  window.scrollTo(0, 0);
}

render(
  <Provider store={store}>
    <ConnectedRouter
      onUpdate={scrollToTop}
      history={history}>
      <BaseContainer>
        {RootRoutes}
      </BaseContainer>
    </ConnectedRouter>

  </Provider>,
  document.getElementById('app-container')
);
