import {updateColumnsOnResize as updateColumns} from 'prosemirror-tables';

// COPIED AND MODIFIED FROM prosemirror-tables/tableview
export default class TableView {
  constructor(node, cellMinWidth) {
    this.node = node;
    this.cellMinWidth = cellMinWidth;
    this.dom = document.createElement("div");
    this.dom.className = "tableWrapper";
    this.table = this.dom.appendChild(document.createElement("table"));
    this.colgroup = this.table.appendChild(document.createElement("colgroup"));
    this.contentDOM = this.table.appendChild(document.createElement("tbody"));
    this.update(node);
  }

  update(node) {
    if (node.type !== this.node.type) return false;
    this.node = node;
    updateColumns(node, this.colgroup, this.table, this.cellMinWidth);

    switch (node.attrs.alignment) {
      case 'left': this.table.style.margin = 'auto'; this.table.style.marginLeft = '0'; break;
      case 'center': this.table.style.margin = 'auto'; break;
      case 'right': this.table.style.margin = 'auto'; this.table.style.marginRight = '0'; break;
      default: this.table.style.margin = ''; break;
    }

    return true;
  }

  ignoreMutation(record) {
    return record.type === "attributes" && (record.target === this.table || this.colgroup.contains(record.target))
  }
}