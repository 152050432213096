import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuPreview from './MenuPreview';
import MenuItemTools from './MenuItemTools';
import {connect} from 'react-redux';

class MenuSidePanel extends Component {
  state = {
    currentTab: 'preview',
  };

  componentWillReceiveProps(nextProps){
    if(nextProps.activeMenuItem.id){
      this.setState({currentTab: 'tools'})
    }
  }

  renderContent(){
    if(this.state.currentTab === 'preview'){
      return <MenuPreview menu={this.props.menu}/>
    } else {
      return <MenuItemTools menu={this.props.menu} />
    }
  }

  handleChangeTab = (eve, currentTab) => {
    this.setState({currentTab})
  };

  render(){
    return (
      <div>
        <AppBar position="static">
          <Tabs value={this.state.currentTab} onChange={this.handleChangeTab}>
            <Tab label="Preview" value="preview" />
            <Tab label="tools" value="tools" disabled={!this.props.activeMenuItem.id}/>
          </Tabs>
        </AppBar>
        {this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  activeMenuItem: state.menus.activeMenuItem
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSidePanel)