import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {ADD_STANDARDS_TO_CLASSIFICATION, REMOVE_STANDARD_FROM_CLASSIFICATION} from "../../classifications";
import {STANDARD_SAVE} from "../../standards";

const StandardsForClassification = PagedTable(
    'standardsForClassification',
    state => apiClient.getStandardsForClassification(state.classificationsSelect.selected),
    (state, action) => {
      if (action && action.type === CLIENT_REQUEST_SUCCESS && (action.requestType === REMOVE_STANDARD_FROM_CLASSIFICATION || action.requestType === ADD_STANDARDS_TO_CLASSIFICATION || action.requestType === STANDARD_SAVE)) {
        return invalidateState(state);
      }
      return state;
    }
);

export default StandardsForClassification;