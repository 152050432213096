import React from 'react';
import {connect} from 'react-redux';

import QueueAnim from 'rc-queue-anim';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconAdd from '@material-ui/icons/Add';

import BookMenusTable from 'components/Tables/PagedTable/BookMenusTable';
import {openMenuDialog} from "reducers/Dialogs/Menu";
import MenuDialog from 'components/Dialogs/Menu';

const MenusList = ({openMenuDialog}) => (
  <section className={"container-fluid chapter"}>
    <QueueAnim type={"bottom"} className={"ui-animate"}>
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              <MenuDialog/>
              <Tooltip title="Create New Menu">
                <Fab color="primary" style={{'marginRight':'20px'}}>
                  <IconAdd onClick={openMenuDialog} />
                </Fab>
              </Tooltip>
              Menus
            </h2>
            <BookMenusTable />
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  openMenuDialog:()=> dispatch(openMenuDialog())
});

export const BookMenuListView = connect(mapStateToProps, mapDispatchToProps)(MenusList);