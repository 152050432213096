import React from 'react';
import PropTypes from 'prop-types';

import {Button, Tooltip, CircularProgress} from "@material-ui/core";
import IconRemove from "@material-ui/icons/Remove";
import IconLink from "@material-ui/icons/Link";
import IconMerge from "@material-ui/icons/CallMerge";
import {Link} from "react-router-dom";

import {getElementTypeByName} from 'constants/ElementTypes';

import {withClient} from "reducers/client";
import {manual, elementDetails} from "reducers/client/requestTypes";

const getTooltip = html => {
  let plainText = html.replace(/(<[^>]*>|&[^;]*;)/gi, ' ');
  return plainText.slice(0,400) + (plainText.length > 400 ? '...' : '');
};

class LessonContainerElement extends React.PureComponent {
  static propTypes = {
    containerId: PropTypes.string.isRequired,
    element: PropTypes.shape({
      elementType: PropTypes.string,
    }).isRequired,
    mode: PropTypes.oneOf(['link','remove','merge']).isRequired,
    onRemove: PropTypes.func,
    onMerge: PropTypes.func,
    disabled: PropTypes.bool,
  };
  state = {
    tooltip: null,
    delay: null
  };

  componentDidUpdate() {
    const {elementDetails} = this.props;
    if (this.state.tooltip === null && elementDetails.isLoaded()) {
      let html = elementDetails.get().currentVersion &&
        elementDetails.get().currentVersion.html;
      if (html) this.setState({tooltip: getTooltip(html)});
      else this.setState({tooltip: false});
    }
  }

  handleTooltipOpen = () => {
    if (!this.delay && this.props.elementDetails.isFetchRequired()) {
      this.delay = window.setTimeout(() => {
        this.delay = null;
        this.props.elementDetails.sendRequest();
      },500);
    }
  };
  handleTooltipClose = () => {
    if (this.delay) {
      window.clearTimeout(this.delay);
      this.delay = null;
    }
  };

  render() {
    const {containerId, element, mode, onRemove, onMerge, elementDetails, disabled} = this.props;
    let tooltip = <CircularProgress />;
    if (this.state.tooltip !== null) {
      if (this.state.tooltip) tooltip = this.state.tooltip;
      else tooltip = '';
    }

    let elementType = getElementTypeByName(element.elementType);
    let content = null;
    let linkedContent = element.links && element.links.length > 0 ? <IconLink fontSize="small" /> : null;
    switch(mode) {
      case 'link':
        content = (
          <Link to={`/app/lessonContentContainers/${containerId}/editor/${elementType.name}`}>
            <Button color="primary" value={containerId} disabled={disabled}>
              {elementType.displayName || elementType.name}
              {linkedContent}
            </Button>
          </Link>
        );
        break;
      case 'remove':
        content = (
          <span>
            <Button variant="outlined" onClick={onRemove} disabled={disabled}>
              <IconRemove /> {elementType.displayName || elementType.name}
              {linkedContent}
            </Button>
          </span>
        );
        break;
      case 'merge':
        content = (
          <span>
            <Button color="primary" variant="outlined" onClick={onMerge} disabled={disabled}>
              <IconMerge /> {elementType.displayName || elementType.name}
              {linkedContent}
            </Button>
          </span>
        );
        break;
    }

    if (tooltip !== '') {
      return (<Tooltip title={tooltip} onOpen={this.handleTooltipOpen} onClose={this.handleTooltipClose}>
        {content}
      </Tooltip>);
    }
    return content;
  }
}

export default withClient({
  hooks: {
    elementDetails: manual(elementDetails((state, props) => props.element.id)),
  }
})(LessonContainerElement);
