import React from 'react';
import {createSelector} from "reselect";
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconRemoveCircle from '@material-ui/icons/RemoveCircle';
import withStyles from '@material-ui/core/styles/withStyles';
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

import {createDialogDataSelector, reduxDialog} from "reducers/dialog";
import {withClient} from "reducers/client";
import {mediaDetails, createMedia, addMediaTags, mediaByTags} from "reducers/client/requestTypes";

import MathJax from 'components/Math/MathJax';

import MediaTypes from 'constants/MediaTypes';

const styles = theme => ({
  root: {}
});

class MathpixDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleAccept = () => {
    this.findOrCreateMedia(MediaTypes.FORMULA.name).then(media => {
      this.insertMedia(media);
      this.handleClose();
    });
  };
  handleReject = () => {
    this.findOrCreateMedia(MediaTypes.IMAGE.name).then(media => {
      this.insertMedia(media);
      this.handleClose();
    });
  };
  findOrCreateMedia(type) {
    const existingMedia = this.getExistingMedia(type);
    if (existingMedia) {
      return Promise.resolve(existingMedia);
    } else {
      return this.createNewMedia(type);
    }
  }
  getExistingMedia(type) {
    const {mediaByTags} = this.props;
    if (mediaByTags.isLoaded()) {
      const existingMedia = mediaByTags.get();
      return existingMedia && existingMedia.find(m => m.type === type);
    }
  }
  createNewMedia(type) {
    const {mediaDetails, createMedia, addMediaTags} = this.props;
    return createMedia.sendRequest({
      ...mediaDetails.get(),
      id: undefined,
      type
    }).then(media => {
      addMediaTags.sendRequest(media.id, [`MATHPIX-${mediaDetails.getParams()}`]);
      return media;
    });
  }
  insertMedia(media) {
    const {prosemirror, dialog: {data}} = this.props;
    if (data) {
      const {attrs, pos} = data;
      if (media.type === MediaTypes.FORMULA.name) {
        delete attrs.align;
        delete attrs.vAlign;
        delete attrs.width;
        delete attrs.height;
        delete attrs.horizontalPadding;
        delete attrs.verticalPadding;
        delete attrs.border;
      }
      prosemirror.updateNodeAttrs({...attrs, 'media-id': media.id}, pos);
    }
  }

  render() {
    const { dialog: {isOpen}, mediaDetails, createMedia } = this.props;

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
      <DialogTitle>Mathpix</DialogTitle>
      <DialogContent>
        {(!mediaDetails.isLoaded() || createMedia.isLoading()) ? (
          <CircularProgress />
        ) : (
          <div>
            <div className="row">
              <div className="col-sm-6">
                <h6>Original Image</h6>
              </div>
              <div className="col-sm-6">
                <h6>MathPix detected LaTeX</h6>
              </div>
            </div>
            <div className="row" style={{backgroundColor: '#eeeeee', margin: `${10}px 0`}}>
              <div className="col-sm-6" style={{textAlign:'center'}}>
                <img style={{maxWidth:'100%'}} src={mediaDetails.get().publicUrl} />
              </div>
              <div className="col-sm-6" style={{textAlign:'center'}}>
                <MathJax type="math/tex" text={mediaDetails.get().latex} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6" style={{textAlign:'center'}}>
                <Button variant="contained" color="default" onClick={this.handleReject}>
                  <IconRemoveCircle/>
                  Revert to Image
                </Button>
              </div>
              <div className="col-sm-6" style={{textAlign:'center'}}>
               <Button variant="contained" color="primary" onClick={this.handleAccept}>
                 <IconCheckCircle/>
                 Accept LaTeX
               </Button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>);
  }
}


const getDialogData = createDialogDataSelector('prosemirror-mathpix');
const getMediaId = state => {
  let data = getDialogData(state);
  return data && data.attrs && data.attrs['media-id'];
};
const getMediaTag = createSelector(getMediaId, mediaId => mediaId && [`MATHPIX-${mediaId}`]);
export default withClient({
  hooks: {
    mediaDetails: mediaDetails(getMediaId),
    mediaByTags: mediaByTags(getMediaTag),
    createMedia,
    addMediaTags: addMediaTags(),
  }
})(reduxDialog({
  dialog: 'prosemirror-mathpix',
  initialState: {latex: ''}
})(withStyles(styles)(withProsemirror(MathpixDialog))));
