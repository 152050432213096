import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";

import {formatDate} from "constants/Date";
import {withClient} from "reducers/client";
import {assessmentGroupDetails, updateAssessmentGroup} from "reducers/client/requestTypes";

import GroupAssessmentProblemsTable from 'components/Tables/GroupAssessmentProblemsTable';
import {AssessmentGroupBreadcrumb} from 'components/Breadcrumb';

import DetailsTable from "components/Tables/DetailsTable";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: `${100}px`,
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

class AssessmentGroupDetails extends React.Component {
  state = {
    showAssociated: false,
    editMode: false,
    assessmentGroupDetails: {},
  };

  componentDidMount() {
    const {assessmentGroupDetails:assessmentGroupRequest} = this.props;
    if (assessmentGroupRequest.isLoaded()) {
      const assessmentGroupDetails = assessmentGroupRequest.get();
      this.setState({
        assessmentGroupDetails: {
          'Name': assessmentGroupDetails.name,
          'Created': formatDate(assessmentGroupDetails.createdAt),
          'Updated': formatDate(assessmentGroupDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {assessmentGroupDetails:assessmentGroupRequest} = this.props;
    const {assessmentGroupDetails:prevRequest} = prevProps;

    if (assessmentGroupRequest.isLoaded() && assessmentGroupRequest.hasChanged(prevRequest)) {
      const assessmentGroupDetails = assessmentGroupRequest.get();
      this.setState({
        assessmentGroupDetails: {
          'Name': assessmentGroupDetails.name,
          'Created': formatDate(assessmentGroupDetails.createdAt),
          'Updated': formatDate(assessmentGroupDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      assessmentGroupDetails: {
        ...this.state.assessmentGroupDetails,
        [key]: value,
      }
    });
  }
  toggleEditAssessmentGroup() {
    const {editMode, assessmentGroupDetails} = this.state;
    if (editMode) {
      const {assessmentGroupDetails:assessmentGroupRequest, updateAssessmentGroup} = this.props;
      if (assessmentGroupRequest.get().name !== assessmentGroupDetails.Name) {
        updateAssessmentGroup.sendRequest({
          id: assessmentGroupRequest.get().id,
          name: assessmentGroupDetails.Name,
        })
          .then(() => assessmentGroupRequest.sendRequest(assessmentGroupRequest.get().id));
      }
    }
    this.setState({editMode: !editMode});
  }
  showAssociatedContent = () => {
    this.setState({
      showAssociated: !this.state.showAssociated,
    });
  };

  render() {
    const { assessmentGroupDetails:assessmentGroupRequest, classes } = this.props;
    const {editMode, assessmentGroupDetails} = this.state;

    if (!assessmentGroupRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading AssessmentGroup details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <AssessmentGroupBreadcrumb assessmentGroupId={assessmentGroupRequest.get().id}/>
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit AssessmentGroup Details"}>
              <Fab color="primary" style={{'marginRight': '20px'}} onClick={() => this.toggleEditAssessmentGroup()}>
                {editMode ? <IconSave/> : <IconEdit/>}
              </Fab>
            </Tooltip>
            AssessmentGroup
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Name', value: assessmentGroupDetails.Name},
              {name: 'Created', value: assessmentGroupDetails.Created, readOnly: true},
              {name: 'Updated', value: assessmentGroupDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Assessment Problems</Typography>
          <Paper>
            <GroupAssessmentProblemsTable assessmentGroupId={assessmentGroupRequest.get().id} />
          </Paper>
        </div>
      </div>
    );

  }
}

export const AssessmentGroupDetailsView = withClient({
  hooks: {
    assessmentGroupDetails: assessmentGroupDetails((state, props) => state.assessmentGroups.selected),
    updateAssessmentGroup: updateAssessmentGroup((state, props) => state.assessmentGroups.selected),
  },
})(withStyles(styles)(AssessmentGroupDetails));
