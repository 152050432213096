import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {LessonContentContainerEditorView} from "views/lessonContentContainer/LessonContentContainerEditor";
import {LessonContentContainerVersionsView} from "views/lessonContentContainer/LessonContentContainerVersions";
import {LessonContentContainerMergeView} from "views/lessonContentContainer/LessonContentContainerMerge";
import {LessonContentContainerMergeHistoryView} from "views/lessonContentContainer/LessonContentContainerMergeHistory";
import {LessonContentContainerAccessibilityView} from "views/lessonContentContainer/LessonContentContainerAccessibility";

import {selectLessonContentContainer} from "reducers/lessonContentContainers";

export const LessonContentContainerRoutes = ({match: {url, path, params: {lessonContentContainerId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectLessonContentContainer(lessonContentContainerId))
  }, [lessonContentContainerId]);

  const selectedLessonContentContainer = useSelector(state => state.lessonContentContainers.selected);
  if (!selectedLessonContentContainer) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/editor/:elementType`} component={LessonContentContainerEditorView} />
      <Route path={`${path}/editor`} component={LessonContentContainerEditorView} />
      <Route path={`${path}/versions/:elementType`} component={LessonContentContainerVersionsView} />
      <Route path={`${path}/versions`} component={LessonContentContainerVersionsView} />
      <Route path={`${path}/mergeHistory`} component={LessonContentContainerMergeHistoryView} />
      <Route path={`${path}/merge`} component={LessonContentContainerMergeView} />
      <Route path={`${path}/accessibility`} component={LessonContentContainerAccessibilityView} />
      <Redirect to="/404" />
    </Switch>
  );
};
