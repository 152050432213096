import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {selectClassification} from "reducers/classificationsSelect";
import {removeStandardFromClassification} from "reducers/classifications";

import ConfirmationDialog from 'components/Dialogs/Confirmation';

class RemoveStandardButton extends Component {
  state = {
    confirmationOpen: false,
  };

  handleClick = () => {
    this.setState({confirmationOpen: true});
  };
  handleClose = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    const {standard, selectedClassification} = this.props;
    let payload = {
      standardId: standard.id,
      classificationId: selectedClassification
    };
    this.setState({confirmationOpen: false});
    return this.props.removeStandardFromClassification(payload)
  };

  render(){
    const {standard} = this.props;
    const {confirmationOpen} = this.state;

    return (<React.Fragment>
      <ConfirmationDialog
        title="Remove Standard from Classification"
        message={`Are you sure you want to delete the standard, "${standard.description}"?`}
        isOpen={confirmationOpen}
        onClose={this.handleClose}
        onConfirm={this.handleConfirm}
      />
      <Button color="primary" onClick={this.handleClick}>Remove</Button>
    </React.Fragment>)
  }
}

const mapStateToProps = (state) => ({
  selectedClassification: state.classificationsSelect.selected,
});

const mapDispatchToProps = (dispatch) => ({
  selectClassification: standard => dispatch(selectClassification(standard)),
  removeStandardFromClassification: payload => dispatch(removeStandardFromClassification(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveStandardButton)

