import React from 'react';
import { connect } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';

import IconPencil from "@material-ui/icons/Create";
import IconVisibility from "@material-ui/icons/Visibility";

import {setEditorMode} from "reducers/Prosemirror";
import {sidebarModes} from "constants/Prosemirror";
import {ToggleIconButton} from "../../Button/ToggleButton";

const canEdit = mode => !sidebarModes[mode].disableWhenDirty;

const ProsemirrorEditModeToggle = ({docChanged, editMode, sidebarMode, setEditorMode, ...innerProps}) =>
  (
    <div {...innerProps}>
      <Tooltip title="Edit">
        <ToggleIconButton onClick={()=>setEditorMode(true)} checked={editMode} disabled={!canEdit(sidebarMode)}>
          <IconPencil />
        </ToggleIconButton>
      </Tooltip>
      <Tooltip title="Preview">
        <ToggleIconButton onClick={()=>setEditorMode(false)} checked={!editMode} disabled={docChanged}>
          <IconVisibility />
        </ToggleIconButton>
      </Tooltip>
    </div>
  );

const mapStateToProps = state => ({
  docChanged: state.prosemirror.docChanged,
  editMode: state.prosemirror.editMode,
  sidebarMode: state.prosemirror.sidebarMode,
});
const mapDispatchToProps = dispatch => ({ setEditorMode: active => dispatch(setEditorMode(active)) });

export default connect(mapStateToProps,mapDispatchToProps)(ProsemirrorEditModeToggle);