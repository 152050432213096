import React from 'react';

import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  input: {
    margin: 0,
    flex: 1,
  }
});

const FONT_WEIGHTS = [
  {name: 'Regular', value:'normal', italic: true},
  {name: 'Bold', value:'bold', italic: true}
];

class FontEditor extends React.Component {
  state = {
    fontWeight: '',
    fontItalic: false,
    loadAll: false,
    weightOptions: [],
  };

  componentDidMount() {
    let {value, styleData} = this.props;
    if (!(typeof value === 'object')) {
      value = {};
    }
    if (styleData['font-family'] && styleData['font-family'].googleFont) {
      this.setState({
        ...value,
        weightOptions: this.getGoogleFontVariants(styleData['font-family'].googleFont)
      });
    } else {
      this.setState({
        ...value,
        weightOptions: FONT_WEIGHTS
      });
    }
  }
  componentDidUpdate(prevProps) {
    let {value, styleData} = this.props;
    if(prevProps.value !== value && typeof value === 'object') {
      this.setState(value);
    }
    if (prevProps.styleData !== styleData && prevProps.styleData['font-family'] !== styleData['font-family']) {
      if (styleData['font-family'] && styleData['font-family'].googleFont) {
        this.setState({
          ...value,
          weightOptions: this.getGoogleFontVariants(styleData['font-family'].googleFont)
        });
      } else {
        this.setState({
          ...value,
          weightOptions: FONT_WEIGHTS
        });
      }
    }
  }
  getGoogleFontVariants(font) {
    let variants = [{name: 'Regular', value:'normal', italic: false}];
    font.variants.forEach(v => {
      if (v === 'regular') return;
      if (v === 'italic') return variants[0].italic = true;
      const italic = v.indexOf('italic') !== -1;
      const base = parseInt(v).toString();
      let existing = variants.find(e => e.value === base);
      if (!existing) {
        variants.push({
          name: base,
          value: base,
          italic
        });
      } else if (italic) existing.italic = true;
    });
    return variants;
  }

  handleChangeWeight = ev => {
    this.setState({fontWeight: ev.target.value});
    this.props.onChange({weight: ev.target.value, italic: this.state.fontItalic, loadAll: this.state.loadAll});
  };
  handleChangeItalic = ev => {
    this.setState({fontItalic: ev.target.checked});
    this.props.onChange({weight: this.state.fontWeight, italic: ev.target.checked, loadAll: this.state.loadAll});
  };
  handleChangeLoadAll = ev => {
    this.setState({loadAll: ev.target.checked});
    this.props.onChange({weight: this.state.fontWeight, italic: this.state.fontItalic, loadAll: ev.target.checked});
  };

  render() {
    const {classes, onChange, styleData} = this.props;
    const {fontWeight, fontItalic, loadAll, weightOptions} = this.state;

    console.log(styleData);
    let selectedWeightOption = weightOptions.find(w => w.value === fontWeight);

    return (
      <div className={classes.root}>
        <FormControl className={classes.input} style={{marginRight: '20px'}}>
          <InputLabel>Font Weight</InputLabel>
          <Select
            value={fontWeight}
            onChange={this.handleChangeWeight}
          >
            {weightOptions.map(w => (
              <MenuItem key={w.name} value={w.value} style={{fontWeight:w.value}}>{w.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          className={classes.input}
          control={
            <Checkbox checked={fontItalic} onChange={this.handleChangeItalic} value="fontItalic" />
          }
          label="Italic"
          disabled={!selectedWeightOption || !selectedWeightOption.italic}
        />
        <Tooltip title="When this is checked, all variants of the Google font will be loaded. This will cause longer load times and should only be used for body text (where bold and italic variants are required on-demand)">
          <FormControlLabel
            className={classes.input}
            control={
              <Checkbox checked={loadAll} onChange={this.handleChangeLoadAll} value="loadAll" />
            }
            label="Preload All Variants"
            disabled={!styleData['font-family'] || !styleData['font-family'].googleFont}
          />
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles)(FontEditor);
