import React from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableRow, TableCell
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const handleChange = (ev, key, onChange) => onChange(key, ev.target.value);
export default ({fields, editMode, onChange}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {fields.map((field, i) => (
            <TableCell key={i}>
              {field.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {fields.map((field, i) => editMode ? (
            <TableCell key={i}>
              <TextField
                disabled={Boolean(field.readOnly)}
                value={field.value || ''}
                onChange={ev => handleChange(ev, field.name, onChange)}
              />
            </TableCell>
          ) : (
            <TableCell key={i}>
              {field.value}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};