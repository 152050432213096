import React from 'react';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconClose from '@material-ui/icons/Close';
import IconSave from '@material-ui/icons/Save';
import IconHistory from '@material-ui/icons/History';
import {withStyles} from '@material-ui/core/styles';

import {LessonContentContainerBreadcrumb} from 'components/Breadcrumb';
import LessonContentContainerPreview from 'components/Editor/Views/LessonContentContainerPreview';

import Tooltip from "@material-ui/core/Tooltip";

import LessonContentContainerBrowser from 'components/Browser/LessonContentContainerBrowser';
import MergeContainersConfirmationDialog from 'components/Dialogs/Merge/MergeContainersConfirmationDialog';
import {openMergeConfirmationDialog, setMergeConfirmationDialogData} from "reducers/Dialogs/MergeConfirmation";
import {withClient} from "reducers/client";
import {lessonContentContainerDetails, lessonContentContainerElements} from "reducers/client/requestTypes";

import {defaultElementTypeForContentable} from 'constants/ElementTypes';

import {MergeLessonContentContainerElements} from 'components/Merge/MergeElements';
import PublishStatusIndicator from "components/Editor/PublishStatusIndicator";
import {renderHomework} from "components/Prosemirror/render";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const gutter = 30;

const styles = theme => ({
  root: {
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${gutter}px`,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`,
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: `${gutter}px`
  },

  compare: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0 0 ${3}px rgba(0,0,0,0.3)`,
  },
  'compare-row': {
    display: 'flex',
    flexDirection: 'row',
  },
  'compare-title': {
    flex: 1,
    padding: '4px 0',
    borderBottom: `${1}px solid #dddddd`,
    height: '58px',
    textAlign: 'center',
    backgroundColor: '#f8f8f8',
    '&:first-of-type': {
      borderRight: `${1}px solid #dddddd`,
    }
  },
  'compare-preview': {
    flex: 1,
    borderTop: `${1}px solid #dddddd`,
    padding: `${16}px`,
    backgroundColor: '#ffffff',
    '&:first-of-type': {
      borderRight: `${1}px solid #dddddd`,
    }
  },
  'compare-pane': {
    minHeight: `${180}px`,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
  },
  'compare-subtitle': {
    padding: '4px 0',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: '#ff0000',
    fontWeight: 'bold',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
  },
  'compare-button-left': {
    margin: theme.spacing(1),
    float: 'left',
  },
  'confirm-button': {
    textAlign: 'center',
    padding: theme.spacing(1),
  }
});

class MergeLessonContentContainers extends React.Component {
  state = {
    containerToMerge: null,
    mergeAllElements: false,
    elementType: defaultElementType.name,
    selection: {},
  };

  componentDidMount() {
    const {mergeId} = this.props.match.params;
    if (mergeId) {
      this.setState({containerToMerge: {id: mergeId}});
    }
    const {lessonContentContainerElements} = this.props;
    if (lessonContentContainerElements.isLoaded()) {
      let elements = lessonContentContainerElements.get();
      if (elements.length > 0) this.setState({elementType: elements[0].elementType});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {mergeId} = this.props.match.params;
    if (mergeId && mergeId !== prevProps.match.params.mergeId) {
      this.setState({containerToMerge: {id: mergeId}});
    }
    const {lessonContentContainerElements} = this.props;
    const {lessonContentContainerElements: prevElements} = prevProps;
    if (lessonContentContainerElements.isLoaded() && lessonContentContainerElements.hasChanged(prevElements)) {
      let elements = lessonContentContainerElements.get();
      if (elements.length > 0) this.setState({elementType: elements[0].elementType});
    }
  }

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {lessonContentContainerDetails} = this.props;
      let containerId = lessonContentContainerDetails.getParams();
      this.props.history.push(`/app/lessonContentContainers/${containerId}/${path}`);
    }
  }

  handleContainerSelected = selection => {
    console.log('merge.handleContainerSelected',selection);
    if (selection && selection[0]) {
      this.setState({containerToMerge: selection[0]});
    }
  };
  handleCancel = () => {
    this.setState({containerToMerge: null});
  };
  handleConfirm = () => {
    const {lessonContentContainerDetails, openMergeConfirmationDialog, setMergeConfirmationDialogData} = this.props;
    const {containerToMerge, selection} = this.state;
    setMergeConfirmationDialogData({
      lessonContentContainerIds: [lessonContentContainerDetails.getParams(), containerToMerge.id],
      selection,
      comment: "MANUAL_MERGE"
    });
    openMergeConfirmationDialog();
  };
  handleElementTypeChange = elementType => {
    console.log(elementType);
    this.setState({elementType});
  };
  handleSelectionChange = selection => {
    this.setState({
      selection: {
        ...this.state.selection,
        ...selection
      }
    });
  };

  render() {
    const {
      classes,
      lessonContentContainerDetails: lessonContentContainerRequest,
      lessonContentContainerElements,
    } = this.props;
    const {containerToMerge, elementType, selection} = this.state;

    const containerId = lessonContentContainerRequest.getParams();
    if (!lessonContentContainerRequest.isLoaded() || containerId == null) {
      return <div />;
    }

    const lessonContentContainerDetails = lessonContentContainerRequest.get();
    return (
      <div className={classes.root}>
        <MergeContainersConfirmationDialog />
        <div className={classes.buttonBox}>
          <Tooltip title="Merge History">
            <Fab color="primary" className={classes.button}>
              <IconHistory onClick={e => this.navigateTo('mergeHistory')} />
            </Fab>
          </Tooltip>
          <Tooltip title="Back">
            <Fab color="primary" className={classes.button}>
              <IconArrowBack onClick={e => this.navigateTo('editor')} />
            </Fab>
          </Tooltip>
        </div>
        <h2 className="article-title">Find duplicate for lesson content container '{lessonContentContainerDetails.name}'</h2>
        {containerToMerge === null ? (
          <div>
            <LessonContentContainerBreadcrumb containerId={containerId} />
            <Paper>
              <LessonContentContainerBrowser onChange={this.handleContainerSelected} blacklist={[containerId]} />
            </Paper>
          </div>
        ) : (
          <div>
            <div className={classes.compare}>
              <div className={classes['compare-row']}>
                <div className={classes['compare-title']}>
                  <LessonContentContainerBreadcrumb containerId={containerId} />
                </div>
                <div className={classes['compare-title']}>
                  <Button
                    className={classes['compare-button-left']}
                    variant="outlined"
                    size="small"
                    onClick={this.handleCancel}
                  >
                    <IconClose />
                    Cancel
                  </Button>
                  <LessonContentContainerBreadcrumb containerId={containerToMerge.id} />
                </div>
              </div>
              <MergeLessonContentContainerElements
                mergeId={containerToMerge.id}
                mainElements={lessonContentContainerElements.get()}
                elementType={elementType}
                selection={selection}
                onChangePreview={this.handleElementTypeChange}
                onChangeSelection={this.handleSelectionChange}
              />
              <div className={classes['compare-row']}>
                <div className={classes['compare-preview']}>
                  <LessonContentContainerPreview
                    containerId={containerId}
                    elementType={elementType}
                    postProcess={renderHomework}
                  />
                </div>
                <div className={classes['compare-preview']}>
                  <LessonContentContainerPreview
                    containerId={containerToMerge.id}
                    elementType={elementType}
                    postProcess={renderHomework}
                  />
                </div>
              </div>
            </div>
            <div className={classes['confirm-button']}>
              <Button color="primary" variant="contained" size="large" onClick={this.handleConfirm} style={{margin:`0 ${10}px`}}>
                <IconSave /> Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = dispatch => ({
  openMergeConfirmationDialog: () => dispatch(openMergeConfirmationDialog()),
  setMergeConfirmationDialogData: data => dispatch(setMergeConfirmationDialogData(data)),
});

export const LessonContentContainerMergeView = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(state => state.lessonContentContainers.selected),
    lessonContentContainerElements: lessonContentContainerElements(state => state.lessonContentContainers.selected),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(
  styles
)(MergeLessonContentContainers)));
