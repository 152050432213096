import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabEditorView} from "views/tab/TabEditor";
import {TabMediaView} from "views/tab/TabMedia";
import {TabMergeView} from "views/tab/TabMerge";
import {TabMergeHistoryView} from "views/tab/TabMergeHistory";

import {selectTab} from "reducers/tabs";

export const TabRoutes = ({match: {url, path, params: {tabId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectTab(tabId))
  }, [tabId]);

  const selectedTab = useSelector(state => state.tabs.selected);
  if (!selectedTab) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/editor`} component={TabEditorView}/>
      <Route path={`${path}/media`} component={TabMediaView}/>
      <Route path={`${path}/merge`} component={TabMergeView}/>
      <Route path={`${path}/mergeHistory`} component={TabMergeHistoryView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
