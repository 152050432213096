import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";

import IconEllipse from "@material-ui/icons/MoreHoriz";
import IconArrowForward from "@material-ui/icons/KeyboardArrowRight";
import IconArrowBack from "@material-ui/icons/KeyboardArrowLeft";

import {
  getElementTypeByName,
  defaultElementTypeForContentable,
  getValidSiblingTypeNamesForElementTypeByName
} from "constants/ElementTypes";
import {LessonContentContainerBreadcrumb} from "components/Breadcrumb";
import {ElementSelector} from "components/Navigation/ElementSelector";

import {withClient, createLoadedSelector} from "reducers/client";
import {lessonContainers, lessonContentContainerDetails, lessonContentContainerElements} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
  navButton: {
    width: `${90}px`,
  },
  menuButton: {
    marginLeft: theme.spacing(1)
  }
});

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

class LessonContentContainerNavigation extends React.Component {
  static propTypes = {
    containerId: PropTypes.string.isRequired,
    elementType: PropTypes.string.isRequired,
    lessonContentContainerDetails: PropTypes.object,
    lessonContentContainerElements: PropTypes.object,
    lessonContainers: PropTypes.object,
    classes: PropTypes.objectOf(PropTypes.string)
  };

  state = {
    menuAnchor: null
  };

  handleSelectElement = elementType => {
    this.props.history.push(`/app/lessonContentContainers/${this.props.containerId}/editor/${elementType}`);
  };

  goToContainer = containerId => () => {
    const elementType = this.props.elementType || defaultElementType.name;
    this.props.history.push(`/app/lessonContentContainers/${containerId}/editor/${elementType}`);
  };

  render() {
    const {
      containerId,
      elementType,
      lessonContentContainerDetails,
      lessonContentContainerElements,
      lessonContainers,
      classes
    } = this.props;
    if (!lessonContentContainerDetails.isLoaded()) return false;

    let elementTypeDefinition = getElementTypeByName(elementType);
    let elementTypeName = (elementTypeDefinition && elementTypeDefinition.displayName) || elementType;

    let prevContainer = null;
    let prevContainerName = <IconEllipse />;
    let nextContainer = null;
    let nextContainerName = <IconEllipse />;

    if (lessonContainers.isLoaded()) {
      const validSiblingTypeNames = getValidSiblingTypeNamesForElementTypeByName(elementType);
      const containers = lessonContainers.get();
      let currentIndex = containers.findIndex(p => p.id === containerId);
      for (let i = currentIndex - 1; i >= 0; --i) {
        if (containers[i].elements.some(e => validSiblingTypeNames.includes(e.elementType))) {
          prevContainer = this.goToContainer(containers[i].id);
          prevContainerName = containers[i].name;
          break;
        }
      }
      for (let i = currentIndex + 1; i <= containers.length - 1; ++i) {
        if (containers[i].elements.some(e => validSiblingTypeNames.includes(e.elementType))) {
          nextContainer = this.goToContainer(containers[i].id);
          nextContainerName = containers[i].name;
          break;
        }
      }
    }

    return (<div className={classes.root}>
      <Button
        className={classes.navButton}
        color="default"
        variant="outlined"
        onClick={prevContainer}
        disabled={!prevContainer}
      >
        <IconArrowBack/>
        {prevContainerName}
      </Button>
      <Typography className={classes.title} variant="h6">{elementTypeName}</Typography>
      <LessonContentContainerBreadcrumb containerId={containerId} />
      <Button
        className={classes.navButton}
        color="default"
        variant="outlined"
        onClick={nextContainer}
        disabled={!nextContainer}
      >
        {nextContainerName}
        <IconArrowForward/>
      </Button>
      {lessonContentContainerElements.isLoaded() && (
        <ElementSelector
          className={classnames(classes.navButton,classes.menuButton)}
          elements={lessonContentContainerElements.get()}
          value={elementType}
          onClick={value => this.handleSelectElement(value)}
        />
      )}
    </div>);
  }
}

const getContainerId = (state, props) => props.containerId;
const getLessonId = createLoadedSelector('lessonContentContainerDetails', getContainerId, (container => container.lessonId));
export default withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getContainerId),
    lessonContentContainerElements: lessonContentContainerElements(getContainerId),
    lessonContainers: lessonContainers(getLessonId),
  }
})(withRouter(withStyles(styles)(LessonContentContainerNavigation)));
