import {useRef, useState, useEffect} from "react";

export const useCachedClientRequest = clientRequest => {
  const prevState = useRef(clientRequest);
  const [cache, setCache] = useState(clientRequest.get());
  useEffect(() => {
    if (clientRequest.isLoaded() && clientRequest.hasChanged(prevState.current)) {
      setCache(clientRequest.get());
    }
    prevState.current = clientRequest;
  },[clientRequest]);
  return cache;
};
