import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabRoutes} from './tab';

export const TabsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:tabId`} component={TabRoutes} />
    <Redirect to="/404" />
  </Switch>
);
