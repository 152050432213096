import React from 'react';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/contentVersionStandards/index';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router-dom';

const columnData = [
  {
    id: 'classification',
    numeric: false,
    disablePadding: false,
    label: 'Classification & Grade(s)',
    content: s => s.classification.name + ', Grade(s): ' + s.grades.join(', '),
  },
  {
    id: 'shortTitle',
    numeric: false,
    disablePadding: false,
    label: 'Standard Short Title',
    content: s => <Link to={`/app/standards/${s.id}/details`}><Button color="primary" value={s.id}>{s.shortTitle}</Button></Link>
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    content: s => {
      if (s.description.length < 100) {
        return s.description
      }
      return s.description.slice(0, 100) + '...';
    },
  },
];

export default PagedTable({
  columnData,
  reducer,
  select: 'none',
});