import React, {Component} from 'react';

import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from "@material-ui/core";

import {BookBreadcrumb} from "components/Breadcrumb";
import Menu from 'components/Menu/Menu';
import MenuSidePanel from "components/Menu/MenuSidePanel";

import {withClient} from "reducers/client";
import {bookDetails, updateBook, menuDetails} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});


class BookMenu extends Component {
  state = {

  };

  render(){
    const {classes, bookDetails:bookRequest, menuDetails:menuRequest} = this.props;

    if (!bookRequest.isLoaded() || !menuRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Menu details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <BookBreadcrumb bookId={bookRequest.get().id} />
          <div className="row">
            <div className="col-sm-4">
              <MenuSidePanel />
            </div>
            <div className="col-sm-8">
              <Menu />
            </div>
          </div>
          <Paper>
          </Paper>
        </div>
      </div>
    )
  }
}

export const BookMenuEditorView = withClient({
  hooks: {
    bookDetails: bookDetails((state, props) => state.books.selected),
    updateBook: updateBook((state, props) => state.books.selected),
    menuDetails: menuDetails((state, props) =>  state.menus.selected)
  },
})(withStyles(styles)(BookMenu));
