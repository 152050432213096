import React from 'react';

export const LandingView = () => (
  <section className="container-fluid with-maxwidth ">
    <h2 className="article-title">Welcome!</h2>
    <div className="row">
      <div className="box box-default col-sm-12">
        <div className="box-body">
          <p>
            First, This is a Proto Type!  We know you won't like it.  That's OK! Let's Use this to make ourselves Better
          </p>
        </div>
      </div>
    </div>
  </section>
);
