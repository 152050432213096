import React, {Component} from 'react';
import {withClient} from "reducers/client";
import {bookDetails, bookCoverMediaDetails, updateBook} from "reducers/client/requestTypes";
import {withStyles} from "@material-ui/core";
import {BookBreadcrumb} from "components/Breadcrumb";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ImageSearch from 'components/Search/ImageSearch';
import Button from '@material-ui/core/Button';
import IconUpload from '@material-ui/icons/Publish';
import Fab from '@material-ui/core/Fab';
import ImageBrowser from 'components/Media/ImageBrowser';
import ImageUploadDialog from 'components/Dialogs/Media/ImageUpload';

import {openImageDialog} from "reducers/Dialogs/Image";



const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class BookCoverMedia extends Component {

  state = {
    viewportWidth: null,
    selectedMedia: null
  };

  componentDidMount() {
    this.getViewportSize();
    window.addEventListener('resize', this.getViewportSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getViewportSize);
  }

  getViewportSize = () => {
    if (!this.nextAnimationFrame) {
      this.nextAnimationFrame = window.requestAnimationFrame(() => {
        this.setState({viewportWidth: window.innerWidth});
        this.nextAnimationFrame = null;
      });
    }
  };

  handleClick = (event, selectedMedia) => {
    this.setState({selectedMedia})
  };

  saveClicked = () => {
    const { bookDetails:bookRequest, bookCoverMediaDetails:mediaRequest, updateBook } = this.props;
    // update coverMediaId for book here
    const book = bookRequest.state.data;
    updateBook.sendRequest(
      { id: book.id, coverMediaId: this.state.selectedMedia.id }
    )
      .then(() => bookRequest.sendRequest())
      .then(() => mediaRequest.sendRequest())
      .then(() => this.setState({selectedMedia: null}))
  };

  revertClicked = () => {
    this.setState({selectedMedia: null})
  };

  openUploadDialog = event => {
    this.props.openImageDialog();
  };

  handleUpload = uploadedMedia => {
    this.setState({selectedMedia: uploadedMedia})
  };

  render(){
    const {classes, bookDetails:bookRequest, bookCoverMediaDetails:mediaRequest } = this.props;

    if (!bookRequest.isLoaded() || !mediaRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Book details...</h2>
      </div>);
    }

    const book = bookRequest.state.data;
    const media = this.state.selectedMedia || mediaRequest.state.data;
    console.table(media);
    const {viewportWidth} = this.state;
    let columns = 4;
    if (viewportWidth < 480) columns = 1;
    else if (viewportWidth < 768) columns = 2;
    else if (viewportWidth < 1024) columns = 3;
    else if (viewportWidth < 1224) columns = 4;
    else if (viewportWidth < 1824) columns = 5;
    else columns = 6;
    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <BookBreadcrumb bookId={bookRequest.get().id} />
          <Typography variant="h3" gutterBottom>
            Book Cover Media
          </Typography>

          <ImageUploadDialog afterImageUpload={this.handleUpload}/>

          <div style={{display: 'flex'}}>
            <div style={{flex:1, padding: 10, maxWidth: '40%'}}>
              <div style={{display: 'flex', justifyContent: 'space-around', width: '100%', padding: 10}}>
                <Button style={{width: '35%'}} onClick={() => this.saveClicked(book)} variant="contained" color="primary" disabled={!this.state.selectedMedia}>Save</Button>
                <Button style={{width: '35%'}} onClick={this.revertClicked} variant="contained" color="primary" disabled={!this.state.selectedMedia}>Revert</Button>
              </div>
              <img src={media.publicUrl} style={{width: '100%'}} />
            </div>
            <Paper style={{flex:2, maxWidth: '60%'}}>
              <div style={{display: 'flex'}}>
                <Fab color="primary" style={{margin: 8, width: 40, height: 40}} onClick={this.openUploadDialog}>
                  <IconUpload />
                </Fab>
                <div style={{width: '90%'}}>
                  <ImageSearch/>
                </div>
              </div>
              <ImageBrowser columns={columns} onClick={this.handleClick}/>
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  openImageDialog: () => dispatch(openImageDialog()),
});

export const BookCoverView = withClient({
  hooks: {
    bookDetails: bookDetails((state, props) => state.books.selected),
    bookCoverMediaDetails: bookCoverMediaDetails((state, props) => state.books.selected),
    updateBook: updateBook((state, props) => state.books.selected),
  },
})(withStyles(styles)(connect(null, mapDispatchToProps)(BookCoverMedia)));
