import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';

import {withClient} from "reducers/client";
import {glossaryItemElements, glossaryItemCreateElement, updateElement, deleteElement} from "reducers/client/requestTypes";

import {asArray} from "constants/util/map";
import ElementTypes from 'constants/ElementTypes';
import GlossaryItemElementTableRow from './GlossaryItemElementTableRow';
import IconDots from "../../../../node_modules/@material-ui/icons/MoreHoriz";
import ConfirmationDialog from "components/Dialogs/Confirmation";

const elementTypes = asArray(ElementTypes);

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class GlossaryItemElementsTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    glossaryItemElements: PropTypes.object.isRequired,
    glossaryItemCreateElement: PropTypes.object.isRequired,
    updateElement: PropTypes.object.isRequired,
    deleteElement: PropTypes.object.isRequired,

    glossaryItemId: PropTypes.string.isRequired,
  };

  state = {
    elements: [],
    adding: false,
    editingId: -1,
    edit: {
      name: '',
      number: '',
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    const {classes, glossaryItemElements} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    if (glossaryItemElements.isLoaded()) {
      this.setState({ elements: glossaryItemElements.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {glossaryItemElements} = this.props;
    const {glossaryItemElements:prevElements} = prevProps;
    if (glossaryItemElements.isLoaded() && glossaryItemElements.hasChanged(prevElements)) {
      this.setState({ elements: glossaryItemElements.get() });
    }
  }

  isWaiting() {
    const {
      glossaryItemElements,
      glossaryItemCreateElement,
      updateElement,
      deleteElement
    } = this.props;
    return glossaryItemElements.isLoading() ||
      glossaryItemCreateElement.isLoading() ||
      updateElement.isLoading() ||
      deleteElement.isLoading();
  }
  pendingChanges() {
    const {elements, adding, editingId, edit} = this.state;
    if (adding) return edit.elementType.length > 0 && edit.title.length > 0;
    if (elements[editingId]) {
      return elements[editingId].elementType !== edit.elementType ||
        elements[editingId].title !== edit.title ||
        elements[editingId].description !== edit.description;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };

  handleClick = (ev, index) => {
    const {adding, editingId} = this.state;
    if (!adding && editingId === -1) {
      let t = ev.target;
      while (t && t !== ev.currentTarget) {
        // Don't trigger navigation if clicking on a button
        if (t.classList.contains(this.props.classes.itemButton)) {
          return false;
        }
        t = t.parentElement;
      }
      // Click directly on row = navigate
      this.props.history.push(`/app/elements/${this.state.elements[index].id}/editor`);
    }
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          elementType: '',
          title: '',
          description: '',
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        elementType: this.state.elements[editingId].elementType || '',
        title: this.state.elements[editingId].title || '',
        description: this.state.elements[editingId].description || '',
      },
    });
  };
  handleClickSave = () => {
    const {glossaryItemCreateElement, updateElement, glossaryItemElements} = this.props;
    const {elements, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        glossaryItemCreateElement.sendRequest(edit)
          .then(() => glossaryItemElements.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateElement.sendRequest(elements[editingId].id, edit)
          .then(() => glossaryItemElements.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteElement, glossaryItemElements} = this.props;
    const {elements, editingId} = this.state;
    deleteElement.sendRequest(elements[editingId].id)
      .then(() => glossaryItemElements.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  render() {
    const {elements, adding, editingId, edit, confirmationOpen} = this.state;
    if (!elements) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Element"
        message={`Are you sure you want to delete the ${edit.elementType} Element?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Element Type</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={7} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <TableBody>
          {elements.map((element,index) => (
            <GlossaryItemElementTableRow
              key={element.id}
              index={index}
              element={element}
              classes={classes}
              adding={Boolean(adding)}
              waiting={Boolean(waiting)}
              pendingChanges={Boolean(this.pendingChanges())}
              editingId={editingId}
              edit={edit}
              onEdit={this.handleEdit}
              onClick={this.handleClick}
              onClickEdit={this.handleClickEdit}
              onClickSave={this.handleClickSave}
              onClickDelete={this.handleClickDelete}
            />
          ))}
        </TableBody>
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell>
                <Select
                  value={edit.elementType}
                  onChange={ev => this.handleEdit('elementType', ev)}
                  disabled={waiting}
                  fullWidth
                  autoFocus
                >
                  <MenuItem value="">---</MenuItem>
                  {elementTypes.map(type => (
                    <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.title}
                  onChange={ev => this.handleEdit('title', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.description}
                  onChange={ev => this.handleEdit('description', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={7} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    glossaryItemElements: glossaryItemElements((state, props) => props.glossaryItemId),
    glossaryItemCreateElement: glossaryItemCreateElement((state, props) => props.glossaryItemId),
    updateElement: updateElement(),
    deleteElement: deleteElement(),
  },
})(withStyles(styles)(withRouter(GlossaryItemElementsTable)));
