import React from "react";
import classnames from 'classnames';

import {withStyles} from "@material-ui/core/styles";

import {Dropdown} from "components/Prosemirror/icons";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {BUTTON, ACTIVE} from "components/Prosemirror/menu/components/styles";
import {toggleCellBorders} from "components/Prosemirror/commands/table";

import {asArray} from "../../../../constants/util/map";

const styles = theme => ({
  BUTTON,
  ACTIVE,
  root: {
    position: 'relative'
  },
  dropdownFix: {
    position: 'absolute',
    top: `${-2}px`,
  },
  menu: {
    position: 'absolute',
    left: 0,
    top: `${-7}px`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    zIndex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  hidden: {
    display: 'none'
  },
  boxIcon: {
    width: `${17}px`,
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '8px 9px',
  },
  box: {
    width: `${8}px`,
    height: `${8}px`,
    border: `1px dotted rgba(255,255,255,0.5)`,
    '&:nth-of-type(1)': {
      borderRight: 'none',
      borderBottom: 'none',
    },
    '&:nth-of-type(2)': {
      width: `${9}px`,
      borderBottom: 'none',
    },
    '&:nth-of-type(3)': {
      height: `${9}px`,
      borderRight: 'none',
    },
    '&:nth-of-type(4)': {
      width: `${9}px`,
      height: `${9}px`,
    },
    '$BUTTON:hover &': {
      borderColor: 'rgba(0,0,0,0.5)',
    }
  },
  boxLeft: {
    borderLeft: `1px solid white`,
    '$BUTTON:hover &': {
      borderLeftColor: 'black',
    }
  },
  boxRight: {
    borderRight: `1px solid white`,
    '$BUTTON:hover &': {
      borderRightColor: 'black',
    }
  },
  boxTop: {
    borderTop: `1px solid white`,
    '$BUTTON:hover &': {
      borderTopColor: 'black',
    }
  },
  boxBottom: {
    borderBottom: `1px solid white`,
    '$BUTTON:hover &': {
      borderBottomColor: 'black',
    }
  }
});

const BORDER_OPTIONS = {
  ALL: {
    id: 'ALL',
    lines: {top: true, middle: true, bottom: true, left: true, center: true, right: true},
  },
  INSIDE: {
    id: 'INSIDE',
    lines: {top: false, middle: true, bottom: false, left: false, center: true, right: false},
  },
  OUTSIDE: {
    id: 'OUTSIDE',
    lines: {top: true, middle: false, bottom: true, left: true, center: false, right: true},
  },
  TOP: {
    id: 'TOP',
    lines: {top: true, middle: false, bottom: false, left: false, center: false, right: false},
  },
  HORIZONTAL: {
    id: 'HORIZONTAL',
    lines: {top: false, middle: true, bottom: false, left: false, center: false, right: false},
  },
  BOTTOM: {
    id: 'BOTTOM',
    lines: {top: false, middle: false, bottom: true, left: false, center: false, right: false},
  },
  LEFT: {
    id: 'LEFT',
    lines: {top: false, middle: false, bottom: false, left: true, center: false, right: false},
  },
  VERTICAL: {
    id: 'VERTICAL',
    lines: {top: false, middle: false, bottom: false, left: false, center: true, right: false},
  },
  RIGHT: {
    id: 'RIGHT',
    lines: {top: false, middle: false, bottom: false, left: false, center: false, right: true},
  },
};
const options = asArray(BORDER_OPTIONS);
const IconBorderAll = ({className, classes, lines}) => (
  <div className={classnames(classes.boxIcon, className)}>
    <div className={classnames(classes.box, {
      [classes.boxLeft]: lines.left,
      [classes.boxTop]: lines.top,
    })} />
    <div className={classnames(classes.box, {
      [classes.boxLeft]: lines.center,
      [classes.boxRight]: lines.right,
      [classes.boxTop]: lines.top,
    })} />
    <div className={classnames(classes.box, {
      [classes.boxLeft]: lines.left,
      [classes.boxTop]: lines.middle,
      [classes.boxBottom]: lines.bottom,
    })} />
    <div className={classnames(classes.box, {
      [classes.boxLeft]: lines.center,
      [classes.boxRight]: lines.right,
      [classes.boxTop]: lines.middle,
      [classes.boxBottom]: lines.bottom,
    })} />
  </div>
);

class TableBorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleButtonClick(event) {
    event.preventDefault();
    this.setState({ menuOpen: true });

    const closeMenu = ev => {
      this.setState({menuOpen: false});
      document.removeEventListener('mousedown',closeMenu,false);
    }
    document.addEventListener('mousedown',closeMenu, false);
  }

  handleOptionClick(event, value) {
    const {prosemirror} = this.props;
    event.preventDefault();
    this.setState({ menuOpen: false });
    toggleCellBorders(value)(prosemirror.state, prosemirror.dispatch.toProsemirror, prosemirror.view);
  }

  render() {
    const {classes, title} = this.props;
    const {menuOpen} = this.state;

    return (
      <span className={classes.root}>
        <button
          className={classnames(classes.BUTTON)}
          title={title}
          onMouseDown={ev => this.handleButtonClick(ev)}
        >
          <Dropdown>
            <IconBorderAll className={classes.dropdownFix} classes={classes} lines={BORDER_OPTIONS.ALL.lines} />
          </Dropdown>
        </button>
        <div className={classnames({
            [classes.menu]: true,
            [classes.hidden]: !menuOpen
          })}>
          {options.map(o => (
            <div className={classes.BUTTON}
                 key={o.id}
                 onMouseDown={ev => this.handleOptionClick(ev, o)}>
              <IconBorderAll classes={classes} lines={o.lines} />
            </div>
          ))}
        </div>
      </span>
    );
  }
}

export default withStyles(styles)(withProsemirror(TableBorder));

