import {findAncestor, matchTypes} from "../util";

export const and = (...conditions) => state => {
  for (let i = 0; i < conditions.length; ++i) {
    if (!conditions[i](state)) return false;
  }
  return true;
};
export const or = (...conditions) => state => {
  for (let i = 0; i < conditions.length; ++i) {
    if (conditions[i](state)) return true;
  }
  return false;
};
export const not = condition => state => {
  return !condition(state);
};

export const markActive = type => (state) => {
  const { from, $from, to, empty } = state.selection;

  return empty
    ? type.isInSet(state.storedMarks || $from.marks())
    : state.doc.rangeHasMark(from, to, type);
};

export const blockActive = (type, attrs = {}) => (state) => {
  const { $from, to, node } = state.selection;

  if (node) {
    return node.hasMarkup(type, attrs);
  }

  return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
};

export const canInsert = type => (state) => {
  const { $from } = state.selection;

  for (let d = $from.depth; d >= 0; d--) {
    const index = $from.index(d);

    if ($from.node(d).canReplaceWith(index, index, type)) {
      return true;
    }
  }

  return false;
};

export const exists = type => state => {
  let found = false;
  state.doc.descendants(node => {
    if (found) return false;
    if (node.type === type) {
      found = true;
      return false;
    }
  });
  return found;
};

export const selectionInside = (...types) => state => {
  const { $from } = state.selection;

  for (let d = $from.depth; d >= 0; d--) {
    if (types.includes($from.node(d).type)) return true;
  }

  return false;
};

export const selectionContains = type => state => {
  const { $from, $to } = state.selection;
  if ($from.nodeAfter && $from.nodeAfter.type === type) return true;
  if ($to.nodeBefore && $to.nodeBefore.type === type) return true;

  let found = false;
  state.selection.content().content.descendants(node => {
    if (found) return false;
    if (node.type === type) {
      found = true;
      return false;
    }
  });

  return found;
};

export const selectionNotEmpty = state => !state.selection.empty;

export const promptForURL = () => window && window.prompt('Enter the URL', 'https://');

export const getAttribute = (nodeTypes, attributeName) => {
  const match = matchTypes(nodeTypes);
  return state => {
    const { $from } = state.selection;
    const $node = findAncestor($from, match);
    if ($node) return $node.nodeAfter.attrs[attributeName];
    return null;
  };
};