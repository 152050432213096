import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import LessonContentContainerPreview from 'components/Editor/Views/LessonContentContainerPreview';

import {withClient} from "reducers/client";
import {
  lessonContainers,
} from "reducers/client/requestTypes";

import LessonContentContainerDialogInsertForm from './insertForm';

class LessonContentContainerDialogInsertTab extends React.Component {

  static propTypes = {
    parentElementType: PropTypes.object.isRequired,
    lessonId: PropTypes.string.isRequired,
  };
  state = {
    formContainerOptions: []
  };

  componentDidMount() {
    const {lessonContainers} = this.props;
    if (lessonContainers.isLoaded()) {
      this.setState({
        formContainerOptions: this.getFormOptions(lessonContainers.get())
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {lessonContainers} = this.props;
    const {lessonContainers: prevContainers} = this.props;
    if (lessonContainers.isLoaded() && lessonContainers.hasChanged(prevContainers)) {
      this.setState({
        formContainerOptions: this.getFormOptions(lessonContainers.get())
      });
    }
  }

  getFormOptions(containers) {
    const {parentElementType} = this.props;
    let validChildElementTypes = null;
    if (parentElementType && parentElementType.validChildElementTypes) {
      validChildElementTypes = parentElementType.validChildElementTypes;
    }

    console.log(parentElementType,containers);

    return containers.map(container => {
      let elementTypes = container.elements.map(el => el.elementType);
      if (validChildElementTypes) {
        elementTypes = elementTypes.filter(type => validChildElementTypes.includes(type));
      }
      return {
        id: container.id,
        name: container.name,
        elementTypes
      };
    }).filter(container => container.elementTypes.length > 0);
  }

  render() {
    const {parentElementType, formData} = this.props;
    const {formContainerOptions} = this.state;

    return (
      <div className="row">
        <div className="col-sm-3">
          <LessonContentContainerDialogInsertForm
            parentType={parentElementType}
            containers={formContainerOptions}
          />
        </div>
        <div className="col-sm-9">
          <h2 className="article-title">Preview</h2>
          {formData && formData.elementType && (
            <LessonContentContainerPreview
              containerId={formData.selectedContainer.id}
              elementType={formData.elementType}
            />
          )}
        </div>
      </div>
    );
  }
}

const insertFormValues = getFormValues('lessonContentContainerDialogInsert');
const mapStateToProps = () => {
  return (state, props) => ({
    formData: insertFormValues(state)
  });
};

const getLessonId = (state, props) => props.lessonId;
export default withClient({
  hooks: {
    lessonContainers: lessonContainers(getLessonId),
  }
})(connect(
  mapStateToProps
)(withProsemirror(LessonContentContainerDialogInsertTab)));
