import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const books = {
  request: () =>  apiClient.getBooks()
    .limit(100)
    .setSortFields(['name'])
    .setOrderByAsc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const bookDetails = key => ({
  request: bookId =>  apiClient.getBook(bookId),
  key,
  auto: true,
});
export const updateBook = key => ({
  request: (bookId, book) => apiClient.updateBook({id: bookId, ...book}),
  key,
});
export const deleteBook = key => ({
  request: (bookId) => apiClient.deleteBook(bookId),
  key,
});
export const bookCreateChapter = key => ({
  request: (bookId, chapter) => apiClient.createChapter({...chapter, bookId}),
  key,
});
export const bookChapters = key => ({
  request: bookId => apiClient.getBookChapters(bookId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookCoverMediaDetails = key => ({
  request: bookId => apiClient.getBookCoverMedia(bookId),
  key,
  auto: true,
});
export const bookChapterOrder = key => ({
  request: (bookId, chapterIds) => apiClient.setBookChapterOrder(bookId, chapterIds),
  key,
});
export const bookCreateSection = key => ({
  request: (bookId, section) => apiClient.createSection({...section, bookId}),
  key,
});
export const bookSections = key => ({
  request: bookId => apiClient.getBookSections(bookId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookSectionOrder = key => ({
  request: (bookId, sectionIds) => apiClient.setBookSectionOrder(bookId, sectionIds),
  key,
});
export const bookAddGlossaryItem = key => ({
  request: (bookId, glossaryItemId) => apiClient.addGlossaryItemToBook(bookId, glossaryItemId),
  key,
});
export const bookGlossaryItems = key => ({
  request: bookId => apiClient.getBookGlossaryItems(bookId)
    .limit(100)
    .setSortFields(['title'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookCreateGlossaryItem = key => ({
  request: (bookId, glossaryItem) => apiClient.createBookGlossaryItem(bookId, glossaryItem),
  key,
});
export const bookUpdateStyleSheet = key => ({
  request: (bookId, stylesheet) => apiClient.updateBookStyleSheet(bookId, stylesheet),
  key,
});
export const bookRevertStyleSheet = key => ({
  request: bookId => apiClient.rollbackBookStyleSheet(bookId),
  key,
});
export const bookLessons = key => ({
  request: (bookId) => apiClient.getBookLessons(bookId)
    .limit(1000)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookSectionElements = key => ({
  request: (bookId) => apiClient.getBookSectionElements(bookId)
    .limit(1000),
});
export const bookByEbooksKey = key => ({
  request: ebooksKey => apiClient.getBookByEbooksKey(ebooksKey),
  key,
  auto: true,
});
