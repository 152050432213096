import React from "react";
import PropTypes from 'prop-types';

import {
  TableRow,
  TableCell
} from "@material-ui/core";

import {withClient} from "reducers/client";
import {categories} from "reducers/client/requestTypes";

class BrowserCategoryList extends React.Component {
  static propTypes = {
    categories: PropTypes.object,
  };

  render() {
    const {categories} = this.props;
    if (categories.isLoaded()) {
      return (
        <React.Fragment>
          {categories.get().map(c => (
            <TableRow key={c.id}>
              <TableCell>
                {c.name}
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      );
    }
    return null;
  }
}

export default withClient({
  hooks: {
    categories
  }
})(BrowserCategoryList);