import * as React from "react";
import {connectAdvanced} from "react-redux";

import {selectorFactory} from './selectorFactory';

export const withClient = options => BaseComponent => {
  let baseName = BaseComponent.displayName || BaseComponent.name || 'Component';
  const connectOptions = {
    getDisplayName: name => `withClient(${baseName})`,
    methodName: 'withClient',
    forwardRef: true,
    ...options
  };

  return connectAdvanced(selectorFactory, connectOptions)(BaseComponent);
};
