import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ChapterRoutes} from './chapter/index';

export const ChaptersRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:chapterId`} component={ChapterRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
