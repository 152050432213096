import {Form, Field, reduxForm} from "redux-form";
import {renderField, renderSelect, renderRadio, renderCheckbox} from "../../../Forms/fields";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React from "react";

const required = value => (value && value.length ? undefined : 'Required');

const DesmosDialogForm = ({nodeData,formData}) =>  (<form>
  <Field name="type" type="text" component={renderSelect} label="Type" disabled={!!nodeData} required validate={required}>
    <MenuItem value="graph">Graphing Calculator</MenuItem>
    <MenuItem value="sci">Scientific Calculator</MenuItem>
    <MenuItem value="ff">Four Function Calculator</MenuItem>
  </Field>
  {formData && formData.type === 'graph' && (
    <Field name="src" type="text" component={renderField} label="desmos.com calculator URL" fullWidth />
  )}
  {formData && formData.type === 'sci' && (
    <Field name="degreeMode" type="text" component={renderRadio} label="Angle Mode">
      <FormControlLabel value="true" control={<Radio />} label="Degrees" />
      <FormControlLabel value="false" control={<Radio />} label="Radians" />
    </Field>
  )}
  {formData && (<div className="row">
    <div className={`col-sm-${formData.type === 'graph' ? 4 : 6}`}>
      <Field name="width" type="number" component={renderField} label="Width" fullWidth />
    </div>
    <div className={`col-sm-${formData.type === 'graph' ? 4 : 6}`}>
      <Field name="height" type="number" component={renderField} label="Height" fullWidth />
    </div>
    {formData.type === 'graph' && (
      <div className="col-sm-4">
        <Field name="fullscreen" type="checkbox" component={renderCheckbox} label="Allow Fullscreen" />
      </div>
    )}
  </div>)}
</form>);

export default reduxForm({
  form: 'desmosDialog'
})(DesmosDialogForm);