import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import IconReset from '@material-ui/icons/Refresh';

import {MediaBrowser} from "components/Media/MediaBrowser";
import ImageToolbar from "components/Dialogs/Prosemirror/Image/imageToolbar";

import {withClient} from "reducers/client";
import {mediaDetails} from "reducers/client/requestTypes";
import {createDialogStateSelector, reduxDialog} from "reducers/dialog";

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

const styles = theme => ({
  imagePreview: {
  },
  imageBrowserToolbar: {
    display: 'flex'
  },
  imageSearch: {
    flex: 1
  },
  imageUpload: {
    flex: '0 140px',
  },
  formRow: {
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginRight: theme.spacing(1)
  }
});

class LessonTitleImageDialog extends React.Component {
  state = {
    imageUploading: false
  };

  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleSelectImage = (ev, media) => media && this.props.dialog.actions.setState({mediaId: media.id});
  handleUploadImage = media => this.handleSelectImage(null, media);
  handleChangeSize = (ev) => {
    const {dialog: {actions}} = this.props;
    actions.setState({scale: ev.target.value});
  };
  handleReset = () => {
    const {dialog: {actions}} = this.props;
    actions.setState({mediaId: null});
  };
  handleSubmit = () => {
    const { dialog: {data, state}, prosemirror } = this.props;
    if (data !== null) {
      if (data.pos !== undefined) {
        prosemirror.updateNodeAttrs({
          ...data.attrs,
          'media-id': state.mediaId,
          'media-scale': state.scale ? `${state.scale}px` : ''
        }, data.pos);
        this.handleClose();
      }
    }
  };
  readyToSubmit = () => {
    const {dialog: {state}} = this.props;
    return state && state.mediaId;
  };

  render() {
    const { classes, dialog: {isOpen, data, state}, mediaDetails } = this.props;

    let title, content, allowSubmit, allowUpload;

    if (!state.mediaId) {
      title = 'Select Image';
      content = <MediaBrowser onClick={this.handleSelectImage} />;
      allowSubmit = false;
      allowUpload = true;
    } else {
      let imagePreview;
      if (mediaDetails.isLoaded()) {
        imagePreview = (<img
          alt=""
          className={classes.imagePreview}
          style={{width:state.scale ? `${state.scale}px` : ''}}
          src={mediaDetails.get().publicUrl}
        />);
      } else {
        imagePreview = <CircularProgress />;
      }

      title = 'Edit Image';
      content = (
        <div className="row">
          <div className="col-sm-6">
            <div className={classes.formRow}>
              <Button onClick={this.handleReset} variant="contained">
                <IconReset />
                Change
              </Button>
            </div>
            <div className="displayEbooks">
              <header className="lessonTitle">
                {imagePreview}
                <h1><span className="lessonNumber">#</span> Title</h1>
                <p>Subtitle</p>
              </header>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={classes.formRow}>
              <TextField
                className={classes.formControl}
                label="Image Size"
                type="number"
                value={state.scale}
                onChange={this.handleChangeSize} />
            </div>
          </div>
        </div>
      );
      allowSubmit = true;
      allowUpload = false;
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
      <DialogTitle>
        <ImageToolbar
          title={title}
          allowUpload={allowUpload}
          onUploadImage={this.handleUploadImage}
        />
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose}>Cancel</Button>
        <Button onClick={this.handleSubmit} color="primary" disabled={!allowSubmit}>Save</Button>
      </DialogActions>
    </Dialog>);
  }
}



const getDialogState = createDialogStateSelector('prosemirror-lessonTitleImage');
const getMediaId = state => {
  const dialogState = getDialogState(state);
  return dialogState && dialogState.mediaId;
};

export default withClient({
  hooks: {
    mediaDetails: mediaDetails(getMediaId)
  }
})(reduxDialog({
  dialog: 'prosemirror-lessonTitleImage',
  initialState: {
    mediaId: null,
    scale: '',
  }
})(withProsemirror(withStyles(styles)(LessonTitleImageDialog))));
