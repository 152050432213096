import {TextSelection, NodeSelection} from 'prosemirror-state';
import {isInTable, selectionCell, CellSelection} from "prosemirror-tables";
import {findWrapping, canSplit} from 'prosemirror-transform';
import {matchTypes, findAncestor, findNodesInRange, getMarkedRange} from "../util";
import schema from "../schema";

export const toggleBlock = (type, attrs) => {
  const match = matchTypes(type);

  return (state, dispatch) => {
    const {$from, $to} = state.selection;
    const $block = findAncestor($from, match);
    if ($block) {
      const content = $block.nodeAfter.slice(0);
      const above = $block.parent;
      const index = $block.index();
      // console.log('toggleBlock()', above, index, content);
      if (!above.canReplace(index,index,content)) return false;
      const from = $block.pos;
      const to = $block.pos + $block.nodeAfter.nodeSize;
      if (dispatch) {
        console.log('toggleBlock - removing', $block, above, index, from, to, content);
        let tr = state.tr.replace(from, to, content);
        tr.setSelection(TextSelection.create(tr.doc, state.selection.anchor - 1, state.selection.head - 1));
        dispatch(tr.scrollIntoView());
      }
    } else {
      const range = $from.blockRange($to);
      const wrapping = range && findWrapping(range, type, attrs);
      if (!wrapping) { return false }
      if (dispatch) {
        console.log('toggleBlock - wrapping', wrapping, range);
        dispatch(state.tr.wrap(range, wrapping).scrollIntoView());
      }
    }
    return true;
  }
};

export const splitOn = (...types) => (state,dispatch) => {
  const {$from, $to} = state.selection;
  let matchDepth = -1;
  for (let d = $from.depth; d >= 0; --d) {
    if (types.includes($from.node(d).type)) {
      matchDepth = d;
      break;
    }
  }

  if (matchDepth === -1) return false;

  const splitDepth = $from.depth - matchDepth + 1;

  if (!canSplit(state.doc, $from.pos, splitDepth)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.split($from.pos, splitDepth));
  }

  return true;
};

export const removeMark = type => (state, dispatch) => {
  const {doc, tr} = state;
  const {anchor, head} = state.selection;

  if (dispatch) {
    doc.nodesBetween(anchor, head, (node, pos, parent, index) => {
      let marks = node.marks.filter(m => m.type === type);
      if (marks.length > 0) {
        marks.forEach(mark => {
          const {start, end} = getMarkedRange(doc, mark, pos);
          tr.removeMark(start, end, mark);
        })
      }
    });
    dispatch(tr);
  }
  return true;
};

const isParagraphOrHeading = matchTypes([schema.nodes.paragraph,schema.nodes.heading]);
const setCellAlignment = (tr, node, pos, alignment) => {
  tr.setNodeMarkup(pos, null, {...node.attrs, alignment});
  const $from = tr.doc.resolve(pos);
  const $to = tr.doc.resolve(pos + node.nodeSize);
  const paragraphs = findNodesInRange($from, $to, isParagraphOrHeading);
  paragraphs.forEach($node => {
    tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, alignment})
  });
};

export const setAlignment = alignment => (state, dispatch) => {
  if (isInTable(state)) {
    let $cell = selectionCell(state);
    if (dispatch) {
      const {tr} = state;
      if (state.selection instanceof CellSelection)
        state.selection.forEachCell((node, pos) => setCellAlignment(tr, node, pos, alignment));
      else {
        setCellAlignment(tr, $cell.nodeAfter, $cell.pos, alignment);
      }
      dispatch(tr);
    }
    return true;
  } else {
    const {$from, $to} = state.selection;
    const paragraphs = findNodesInRange($from, $to, isParagraphOrHeading);
    if (dispatch) {
      const {tr} = state;
      paragraphs.forEach($node => {
        tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, alignment})
      });
      dispatch(tr);
    }
    return paragraphs.length > 0;
  }
};

export const setAttributes = (nodeTypes, attrs) => {
  const match = matchTypes(nodeTypes);

  return (state, dispatch) => {
    const {$from, $to} = state.selection;
    let nodes;
    if (state.selection.node && match(state.selection.node)) {
      nodes = [state.selection.$from];
    } else {
      nodes = findNodesInRange($from, $to, match);
    }
    if (nodes.length === 0) return false;
    if (dispatch) {
      const {tr} = state;
      nodes.forEach($node => {
        tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, ...attrs})
      });
      if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
      dispatch(tr);
    }
    return true;
  };
};

const isOrderedList = matchTypes(schema.nodes.ordered_list);
export const rotateList = amount => (state, dispatch) => {
  const {$from, $to} = state.selection;
  let nodes;
  if (state.selection.node && isOrderedList(state.selection.node)) {
    nodes = [state.selection.$from];
  } else {
    nodes = findNodesInRange($from, $to, isOrderedList);
  }
  if (nodes.length === 0) return false;
  if (dispatch) {
    const {tr} = state;
    nodes.forEach($node => {
      const {attrs} = $node.nodeAfter;
      tr.setNodeMarkup($node.pos, null, {...attrs, order: Math.max(1, attrs.order + amount)});
    });
    if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
    dispatch(tr);
  }
  return true;
};

const canIndent = node => node.type.attrs.indent;
export const indent = amount => (state, dispatch) => {
  const {$from, $to} = state.selection;
  let nodes;
  if (state.selection.node && canIndent(state.selection.node)) {
    nodes = [state.selection.$from];
  } else {
    nodes = findNodesInRange($from, $to, canIndent);
  }
  if (nodes.length === 0) return false;
  if (nodes.some($node => $node.nodeAfter.attrs.indent + amount < 0)) return false;
  if (dispatch) {
    const {tr} = state;
    nodes.forEach($node => {
      const {attrs} = $node.nodeAfter;
      tr.setNodeMarkup($node.pos, null, {...attrs, indent: attrs.indent + amount});
    });
    if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
    dispatch(tr);
  }
  return true;
};