import {createSelector} from "reselect";
import {combineReducers} from 'redux';

import {apiClient} from "../constants/Clients";
import ClientRequest, {RequestState} from "./util/clientRequest";
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const CLASSIFICATION_SELECT = 'CLASSIFICATION_SELECT';
export const CLASSIFICATION_LIST = 'CLASSIFICATION_LIST';
export const CLASSIFICATION_DETAILS = 'CLASSIFICATION_DETAILS';
export const CLASSIFICATION_SAVE = 'CLASSIFICATION_SAVE';
export const CLASSIFICATION_DELETE  = 'CLASSIFICATION_DELETE';
export const ADD_STANDARDS_TO_CLASSIFICATION = 'ADD_STANDARDS_TO_CLASSIFICATION';
export const REMOVE_STANDARD_FROM_CLASSIFICATION = 'REMOVE_STANDARD_FROM_CLASSIFICATION';

// CHILD REDUCERS
const list = ClientRequest(CLASSIFICATION_LIST);
const details = MultiRequest(CLASSIFICATION_DETAILS);
const save = ClientRequest(CLASSIFICATION_SAVE);
const destroy = ClientRequest(CLASSIFICATION_DELETE);
const addStandards = ClientRequest(ADD_STANDARDS_TO_CLASSIFICATION);
const removeStandard = ClientRequest(REMOVE_STANDARD_FROM_CLASSIFICATION);

// ACTION CREATORS
export function selectClassification(classificationId) {
  return {type: CLASSIFICATION_SELECT, payload: classificationId};
}

export function requestClassificationList() {
  const req = apiClient.getClassifications();
  req.limit(9999);
  return list.sendRequest(req);
}
export function requestClassificationDetails(classificationId) {
  return details.sendRequest(apiClient.getClassification(classificationId), {key: classificationId});
}

export function deleteClassification(id){
  let req = apiClient.deleteClassification(id);
  return destroy.sendRequest(req)
}

export function saveClassification(data){
  let req;
  // If there is an ID this is an update.  Else create a new one
  if (data.id) { req = apiClient.updateClassification(data); }
  else { req = apiClient.createClassification(data); }
  return save.sendRequest(req);
}

export function mapStandardContentRelations(data){
  let req = apiClient.mapContentRelationsForClassificationStandard(data.standardId, data.standardIds);
  return addStandards.sendRequest(req)
}

export function removeStandardFromClassification(data){
  let req = apiClient.removeStandardFromClassification(data.classificationId, data.standardId);
  return addStandards.sendRequest(req)
}

// SELECTORS
const getClassificationListRequest = state => state.classifications.list;
export const getClassificationList = createSelector(
  [getClassificationListRequest],
  classifications => new RequestState(1, classifications)
);

const getClassificationDetailRequests = state => state.classifications.details;
export const makeClassificationSelector = classificationIdResolver =>
  makeRequestSelector(classificationIdResolver, getClassificationDetailRequests);

// REDUCER
export default combineReducers({
  selected: (state = null, action) => (action.type === CLASSIFICATION_SELECT ? action.payload : state),
  list,
  details,
});