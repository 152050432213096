import {onDoubleClick} from "../util";
import MathJax from 'MathJax';
import {log} from "constants/Config";

export const styleMathMLMediaPlugin = theme => ({
  mmlViewBadge: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    marginRight: `${4}px`,
    marginBottom: `${4}px`,
    lineHeight: `${10}px`,
    zIndex: 1,

    border: 'none',
    color: 'white',
    fontFamily: 'initial',
    fontSize: '10px',
    fontStyle: 'initial',
    fontWeight: 'initial',
    textShadow: 'red 1px 1px,'+
    'red -1px 1px,'+
    'red 1px -1px,'+
    'red -1px -1px'
  },
});

export default class MathMLMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] create CpmMediaView#${this.outerView.id}::MathMLMediaView`, media);
    }

    this.dom = document.createElement('script');
    this.dom.setAttribute('type','math/tex');
    this.dom.innerText = media.latex;

    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));

    this.outerView.dom.appendChild(this.dom);

    this.badge = document.createElement('div');
    this.badge.setAttribute('class',this.outerView.interface.classes.mmlViewBadge);
    this.badge.innerText = "MML";
    this.outerView.dom.appendChild(this.badge);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.dispatch({method: 'mathml'});
    });
  }

  destroy() {
    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] destroy CpmMediaView#${this.outerView.id}::MathMLMediaView`, this.media);
    }
    
    const math = MathJax.Hub.getAllJax(this.outerView.dom);
    math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
    this.outerView.dom.removeChild(this.badge);
  }
}