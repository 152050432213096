import React from 'react';

import {
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';

function sortDirection(asc) { return asc ? 'asc' : 'desc'; }
function tooltipAlign(align) {
  switch(align) {
    case 'left': return 'bottom-start';
    case 'right': return 'bottom-end';
    default: return 'bottom';
  }
}

/**
 * @typedef {Object} ColumnData
 * @property {string} id - in-code identifier for the column
 * @property {boolean} numeric - whether the column is numeric (right-justified)
 * @property {boolean} disablePadding - remove the padding around the cells in the column
 * @property {string} label - the name of the column to display
 * @property {function} content - content mapping function (row, props) => React.Component
 */

/**
 * PagedTableHeadCells - header cells for paged tables
 * @param props - properties provided by PagedTable HOC
 * @returns {React.Fragment}
 * @constructor
 */
export const PagedTableHeadCells = (props) => {
  const { onSelectAllClick, onRequestSort, sort, select, numSelected, count, columnData } = props;
  let headers = columnData.map(column => (
    <TableCell
      key={column.id}
      align={column.align}
      padding={column.disablePadding ? 'none' : 'default'}
      sortDirection={sort.by === column.id ? sortDirection(sort.asc) : false}
    >
      {column.sort === undefined || column.sort ? (
        <Tooltip
          title="Sort"
          placement={tooltipAlign(column.align)}
          enterDelay={300}
        >
          <TableSortLabel
            active={sort.by === column.id}
            direction={sortDirection(sort.asc)}
            onClick={event => onRequestSort(event, column.id)}
          >
            {column.label}
          </TableSortLabel>
        </Tooltip>
      ):(column.label)}
    </TableCell>
  ));
  if (select === 'single') {
    headers.unshift(<TableCell key="checkbox" padding="checkbox" />);
  } else if (select === 'multiple') {
    headers.unshift(<TableCell key="checkbox" padding="checkbox">
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < count}
        checked={numSelected > 0 && numSelected === count}
        onChange={onSelectAllClick}
      />
    </TableCell>);
  }
  return headers;
};

/**
 * PagedTableBodyCells - body cells for paged tables
 * @param props - properties provided by PagedTable HOC
 * @returns {React.Fragment}
 * @constructor
 */
export const PagedTableBodyCells = (props) => {
  const { row, columnData, select, isSelected } = props;
  let cells = columnData.map(col => (
    <TableCell key={col.id} align={col.align}>
      {col.content({...row, isSelected})}
    </TableCell>
  ));
  if (select === 'single') {
    cells.unshift(<TableCell key="checkbox" padding="checkbox">
      <Radio checked={isSelected} />
    </TableCell>);
  } else if (select === 'multiple') {
    cells.unshift(<TableCell key="checkbox" padding="checkbox">
      <Checkbox checked={isSelected} />
    </TableCell>);
  }
  return cells;
};
