import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentProblemRoutes} from './assessmentProblem';
import {AssessmentProblemSearchView} from "views/assessmentProblem/AssessmentProblemSearch";

export const AssessmentProblemsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/search`} component={AssessmentProblemSearchView}/>
    <Route path={`${match.path}/:assessmentProblemId`} component={AssessmentProblemRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
