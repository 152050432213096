import {apiClient} from "constants/Clients";

export const tabbedViewDetails = key => ({
  request: tabbedViewId => apiClient.getTabbedView(tabbedViewId),
  key,
  auto: true,
});
export const updateTabbedView = key => ({
  request: (tabbedViewId, tabbedView) => apiClient.updateTabbedView({id: tabbedViewId, ...tabbedView}),
  key,
});
export const deleteTabbedView = key => ({
  request: tabbedViewId => apiClient.deleteTabbedView(tabbedViewId),
  key,
});
export const tabbedViewCreateTab = key => ({
  request: (tabbedViewId, tab) => apiClient.createTabbedViewTab(tabbedViewId, tab),
  key,
});
export const tabbedViewTabs = key => ({
  request: tabbedViewId => apiClient.getTabbedViewTabs(tabbedViewId)
    .limit(100)
    .setSortFields(['tabOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const tabbedViewTabOrder = key => ({
  request: (tabbedViewId, tabIds) => apiClient.setBookChapterOrder(tabbedViewId, tabIds),
  key,
});
