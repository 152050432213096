import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {CategoryListView} from "views/category/CategoryList";
import {CategoryRoutes} from './category';

export const CategoriesRoutes = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}`} to={`${match.path}/list`} />
    <Route path={`${match.path}/list`} component={CategoryListView} />
    <Route path={`${match.path}/:categoryId`} component={CategoryRoutes} />
    <Redirect to="/404" />
  </Switch>
);
