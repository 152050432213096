import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {SectionRoutes} from './section';

export const SectionsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:sectionId`} component={SectionRoutes} />
    <Redirect to="/404" />
  </Switch>
);
