export default theme => ({
  root: {
    margin: `${theme.spacing(1)}px 0`,
  },
  button: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  scrolling: {
    maxHeight: `${140}px`,
    overflowY: 'scroll',
  },
  spacer: {}
});
