import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {ChapterDetailsView} from "views/chapter/ChapterDetails";

import {selectChapter} from "reducers/chapters";

export const ChapterRoutes = ({match: {url, path, params: {chapterId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectChapter(chapterId))
  }, [chapterId]);

  const selectedChapter = useSelector(state => state.chapters.selected);
  if (!selectedChapter) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={ChapterDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
