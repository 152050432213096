import {fade} from "../../../node_modules/@material-ui/core/styles/colorManipulator";
import withStyles from "../../../node_modules/@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    borderColor: theme.palette.primary.main,
  }
});

const Toggle = Component => {
  return React.forwardRef(({checked, children, classes, ...innerProps}, ref) => (
    <Component {...innerProps} classes={checked ? classes : {}} ref={ref}>{children}</Component>
  ));
};

export const ToggleButton = withStyles(styles)(Toggle(Button));
export const ToggleIconButton = withStyles(styles)(Toggle(IconButton));