import React from 'react';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/elementAssociatedLessons/index';
import Button from '@material-ui/core/Button/Button';
import {Link} from 'react-router-dom';

const columnData = [
  // {
  //   id: 'book',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Book',
  //   content: l => l.book.name.toUpperCase(),
  // },
  {
    id: 'lesson',
    numeric: false,
    disablePadding: false,
    label: 'Lesson Name',
    content: l => l.name,
  },
  {
    id: 'elementType',
    numeric: false,
    disablePadding: true,
    label: 'Element Type',
    content: l => {
      let extra = '';
      if (l.elements[1]) {
        extra = ' EXTRA';
      }
      return l.elements[0].elementType + extra;
    }
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
    content: l => <Link to={`/app/lessons/${l.id}/details`}><Button color="primary" value={l.id}>Lesson Details</Button></Link>
  },
];

export default PagedTable({
  columnData,
  reducer,
  select: 'multiple',
});