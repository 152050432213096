import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';

import {searchImages, addImageSearchFilter} from "../../reducers/Search/images";

import Paper from "@material-ui/core/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

import SearchBar from 'components/Searchbar';
import ImageSearchFilter from './ImageSearchFilter';
import SearchAddFilterButton from './SearchAddFilterButton';

import {ImageFilterTypes} from "constants/FilterTypes";

const styles = theme => ({
  root: {

  },
  toolbar: {
    display: 'flex',
    padding: `${8}px`
  },
  toolbarButton: {
    flex: 0,
    whiteSpace: 'nowrap',
    marginLeft: `${8}px`
  },
  filters: {

  },
  searchResult: {
    margin: `${8}px`,
    '& em': {
      fontStyle: 'normal',
      background: theme.palette.secondary.main
    },
  }
});

class ImageSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {searchImages, filters} = this.props;
    if (filters !== prevProps.filters) {
      searchImages(this.state.searchValue, filters);
    }
  };

  handleSearch = value => {
    this.setState({searchValue: value});
    const {searchImages, filters} = this.props;
    searchImages(value, filters);
  };

  handleFilterChange = () => {
    const {searchImages, filters} = this.props;
    searchImages(this.state.searchValue, filters);
  };

  handleClick = (event, row) => {

  };

  handleAddFilter = filterType => {
    const {addFilter} = this.props;
    addFilter(filterType);
  };

  render = () => {
    console.log('IMAGE SEARCH PROPS', this.props);
    const {classes, images, filters, searchResults, searchImages, addFilter, className, ...props} = this.props;

    // let resultsDisplay = false;
    //
    // if (searchResults && searchResults.length) {
    //   debugger;
    //   resultsDisplay = searchResults.map((row) => {
    //     const bookName = row.book.name.toUpperCase();
    //     return (
    //       <TableRow
    //         hover
    //         onClick={event => this.handleClick(event, row)}
    //         tabIndex={-1}
    //         key={row.id}>
    //         <TableCell>
    //           <Link to={`/app/books/${row.bookId}/details`}>
    //             <Button color="primary" value={row.bookId}>View {bookName}</Button>
    //           </Link>
    //         </TableCell>
    //         <TableCell>
    //           <Link to={`/app/lessons/${row.lesson.id}/details`}>
    //             <Button color="primary" value={row.lesson.id}>{row.lesson.name}</Button>
    //           </Link>
    //         </TableCell>
    //         <TableCell>{row.name}</TableCell>
    //         <TableCell
    //           className={classes.searchResult}
    //           dangerouslySetInnerHTML={row.highlight && {__html: row.highlight.html}} />
    //         <TableCell>
    //           <Link to={`/app/images/${row.id}/editor`}>
    //             <Button color="primary" value={row.id}>Edit Image</Button>
    //           </Link>
    //         </TableCell>
    //       </TableRow>
    //     );
    //   });
    // }

    return (<div className={classnames(classes.root,className)} {...props}>

        <div className={classes.toolbar}>
          <SearchBar fullWidth onChange={this.handleSearch} />
          <SearchAddFilterButton onAddFilter={this.handleAddFilter} filterTypes={ImageFilterTypes} filters={filters}/>
        </div>
        <div className={classes.filters}>
          {filters.map((filter, index) => <ImageSearchFilter key={index} filterId={index}/>)}
        </div>
        {/*<div className={classes.tableWrapper}>*/}
          {/*<Table className={classes.table}>*/}
            {/*<TableHead>*/}
              {/*<TableRow>*/}
                {/*<TableCell>Book</TableCell>*/}
                {/*<TableCell>Lesson</TableCell>*/}
                {/*<TableCell>Image</TableCell>*/}
                {/*<TableCell>Matched text</TableCell>*/}
                {/*<TableCell />*/}
              {/*</TableRow>*/}
            {/*</TableHead>*/}
            {/*<TableBody>*/}
              {/*{resultsDisplay}*/}
            {/*</TableBody>*/}
          {/*</Table>*/}
        {/*</div>*/}

    </div>);
  }
}

const mapStateToProps = (state, props) => ({
  images: state.search.images,
  filters: state.search.images.filters,
  searchResults: state.search.images.searchResults.data
});
const mapDispatchToProps = dispatch => ({
  searchImages: (searchString, filters) => dispatch(searchImages(searchString, filters)),
  addFilter: filterType => dispatch(addImageSearchFilter(filterType))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ImageSearch));
