import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from '../../util/pagedTable';

const AssociatedLessonsTable = PagedTable(
    'lessonAssociatedLessons',
    state => apiClient.getAssociatedLessonsForElement(state.elements.selected),
    (state, action) => {
      return state;
    }
);

export default AssociatedLessonsTable;