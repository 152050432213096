import React from 'react';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {withClient} from "reducers/client";
import {chapterDetails, updateChapter} from "reducers/client/requestTypes";

import { ChapterBreadcrumb } from 'components/Breadcrumb';
import ChapterLessonsTable from 'components/Tables/ChapterLessonsTable';
import ChapterAssessmentGroupsTable from 'components/Tables/ChapterAssessmentGroupsTable';
import DetailsTable from "components/Tables/DetailsTable";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class ChapterDetails extends React.Component {
  state = {
    editMode: false,
    chapterDetails: {},
  };

  componentDidMount() {
    const {chapterDetails:chapterRequest} = this.props;
    if (chapterRequest.isLoaded()) {
      const chapterDetails = chapterRequest.get();
      this.setState({
        chapterDetails: {
          'Name': chapterDetails.name,
          'Created': formatDate(chapterDetails.createdAt),
          'Updated': formatDate(chapterDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {chapterDetails:chapterRequest} = this.props;
    const {chapterDetails:prevRequest} = prevProps;

    if (chapterRequest.isLoaded() && chapterRequest.hasChanged(prevRequest)) {
      const chapterDetails = chapterRequest.get();
      this.setState({
        chapterDetails: {
          'Name': chapterDetails.name,
          'Created': formatDate(chapterDetails.createdAt),
          'Updated': formatDate(chapterDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      chapterDetails: {
        ...this.state.chapterDetails,
        [key]: value,
      }
    });
  }
  toggleEditChapter() {
    const {editMode, chapterDetails} = this.state;
    if (editMode) {
      const {chapterDetails:chapterRequest, updateChapter} = this.props;
      if (chapterRequest.get().name !== chapterDetails.Name) {
        updateChapter.sendRequest({
          id: chapterRequest.get().id,
          name: chapterDetails.Name,
        })
          .then(() => chapterRequest.sendRequest(chapterRequest.get().id));
      }
    }
    this.setState({editMode: !editMode});
  }

  render() {
    const {classes, chapterDetails:chapterRequest} = this.props;
    const {editMode, chapterDetails} = this.state;

    if (!chapterRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Chapter details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <ChapterBreadcrumb chapterId={chapterRequest.get().id} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit Chapter Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditChapter()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            Chapter Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Name', value: chapterDetails.Name},
              {name: 'Created', value: chapterDetails.Created, readOnly: true},
              {name: 'Updated', value: chapterDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Lessons</Typography>
          <Paper>
            <ChapterLessonsTable chapterId={chapterRequest.get().id} />
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Assessment Groups</Typography>
          <Paper>
            <ChapterAssessmentGroupsTable chapterId={chapterRequest.get().id} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const ChapterDetailsView = withClient({
  hooks: {
    chapterDetails: chapterDetails((state, props) => state.chapters.selected),
    updateChapter: updateChapter((state, props) => state.chapters.selected),
  },
})(withStyles(styles)(ChapterDetails));
