import React from 'react';
import { connect } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import reducer, {
  requestClassifications,
  setClassificationOptions,
  setClassificationsError,
  selectClassification,
  resetSelection,
  requestStandardsForClassification,
} from 'reducers/classificationsSelect';

class ClassificationsSelect extends React.Component {
  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     gotStandards: false,
  //     gotClassifications: false,
  //   }
  // }

  componentDidMount() {
    console.log("MOUNTED");
    console.log(this.props);
    this.props.requestClassifications();

  }

  // componentDidUpdate() {
  //   console.log("UPDATED");
  //   console.log(this.props);
  //   if (
  //     this.props.standards.results &&
  //     this.props.standards.page &&
  //     this.props.standards.page.completed &&
  //     this.props.standards.page.completed === true &&
  //     !this.state.gotStandards &&
  //     !this.state.gotClassifications
  //   ) {
  //     this.setState({
  //       gotStandards: true,
  //       gotClassifications: true
  //     }, () => {
  //       this.props.requestClassifications();
  //     });
  //   }
  // }

  handleChange = (e) => {
    this.props.selectClassification(e.target.value);
  };

  renderOptions = () => {
    return this.props.classificationsSelect.options.map((option, index) => {
      return (
        <MenuItem value={option.id} key={index}>
          {option.name}
        </MenuItem>
      );
    });
  };

  render() {
    return (
      <div>
        <Select
          value={this.props.classificationsSelect.selected}
          onChange={this.handleChange}
          inputProps={{
            name: 'classifications',
          }}
        >
          <MenuItem value={''} selected>All</MenuItem>
          {this.renderOptions()}
        </Select>
        <FormHelperText>Classifications</FormHelperText>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  classificationsSelect: state.classificationsSelect,
  standards: state.tables.standards.page.data,
  // classifications: state.tables.classifications,
});

const mapDispatchToProps = dispatch => ({
  requestClassifications: () => dispatch(requestClassifications()),
  // setClassificationOptions: () => dispatch(setClassificationOptions()),
  // setClassificationsError: () => dispatch(setClassificationsError()),
  selectClassification: classificationId => dispatch(selectClassification(classificationId)),
  // resetSelection: () => dispatch(resetSelection),
  // requestStandardsForClassification: () => dispatch(requestStandardsForClassification),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationsSelect);