import { combineReducers } from 'redux';
import image from './Image';
import lessonContentContainerElement from './LessonContentContainerElement';
import lessonContentContainerLink from './LessonContentContainerLink';
import classifications from './Classifications';
import standards from './Standards';
import menuItems from './MenuItem';
import menus from './Menu';
import mergeConfirmation from './MergeConfirmation';

export default combineReducers({
  lessonContentContainerElement,
  lessonContentContainerLink,
  image,
  classifications,
  standards,
  menuItems,
  menus,
  mergeConfirmation,
});
