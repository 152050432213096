import {createSelector} from "reselect";

import Roles from "../constants/Roles";

// ACTION TYPES
export const USER_DISCOVERED = 'USER_DISCOVERED';

// ACTION CREATORS
export function setUserDiscovered(payload) {
  return { type: USER_DISCOVERED, payload };
}

// INITIAL STATE
const initialState = {
  JWT: null,
  user: null,
  roles: []
};

// SELECTORS
// Is user in role -- create an array of selectors, one for each role type
const getRoles = state => state.profile.roles;
const isInRole = {};
Roles.asArray().forEach(({id}) => {
  isInRole[id] = createSelector([getRoles], roles => roles.indexOf(id) > -1);
});
// isUserInRole() finds the right selector and passes the state down the line
export const isUserInRole = (state, role) => {
  if (!role) return false;
  if (isInRole[role] != null) return isInRole[role](state);
  if (role.id != null) return isInRole[role.id](state);
  return false;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_DISCOVERED:
      const user = action.payload.decodedJWT;
      return {
        ...state,
        JWT: action.payload.JWT,
        user,
        roles: user.realm_access.roles
      };
    default:
      return state;
  }
};
