import React from 'react';
import {connect} from "react-redux";
import QueueAnim from 'rc-queue-anim';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconAdd from '@material-ui/icons/Add';

import StandardsForClassificationTable from 'components/Tables/PagedTable/StandardsForClassificationTable/index';
import StandardModal from 'components/Dialogs/Standards';
import {openStandardDialog} from "reducers/Dialogs/Standards";

const ClassificationStandardsList = ({selectedClassification, openStandardDialog}) => (
  <section className={"container-fluid chapter"}>
    <StandardModal classificationId={selectedClassification} />
    <QueueAnim type={"bottom"} className={"ui-animate"}>
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              <Tooltip title="Create New Standard">
                <Fab color="primary" style={{'marginRight':'20px'}}>
                  <IconAdd onClick={openStandardDialog} />
                </Fab>
              </Tooltip>
              Standards
            </h2>
            <StandardsForClassificationTable />
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);

const mapStateToProps = state => ({
  selectedClassification: state.classificationsSelect.selected
});

const mapDispatchToProps = dispatch => ({
  openStandardDialog: () => dispatch(openStandardDialog()),
});

export const ClassificationStandardsListView = connect(mapStateToProps, mapDispatchToProps)(ClassificationStandardsList);
