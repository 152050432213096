import {selectorFactory} from "./selectorFactory";

const createObserver = (options, store, handler) => {
  let lastState = null;
  let props = options.props;
  if (!options.displayName) options.displayName = '[OBSERVER]';
  const getState = selectorFactory(store.dispatch, options);

  const handleChange = () => {
    const nextState = getState(store.getState(), props);
    if (nextState !== lastState) {
      lastState = nextState;
      handler(nextState);
    }
  };

  const setProps = newProps => {
    props = newProps;
    handleChange();
  };

  handleChange();
  const unsubscribe = store.subscribe(handleChange);
  return {
    setProps,
    unsubscribe
  };
};

export {
  createObserver
}
