import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {CLASSIFICATION_SAVE, CLASSIFICATION_DELETE} from "../../classifications";

const ClassificationsTable = PagedTable(
  'bookMenus',
  (state) => apiClient.getBookMenus(state.books.selected),
  (state, action) => {
    const metaData = action.metadata || {};
    if((action.type === '@@CLIENT/REQUEST_SUCCESS' && metaData.name === 'createMenuForBook') || (action.requestType === 'DELETE_MENU' && action.type === 'CLIENT_REQUEST_SUCCESS' )){
        return invalidateState(state);
    } else {
      return state;
    }
  },
);

export default ClassificationsTable;