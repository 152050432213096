import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";

import Autocomplete from '.';

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = ({name}, {query, isHighlighted}) => {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {name}
      </div>
    </MenuItem>
  );
};

class AutocompleteLessonContentContainer extends React.Component {
  static propTypes = {
    containers: PropTypes.arrayOf(PropTypes.object)
  };
  static defaultProps = {
    containers: []
  };
  state = {
    value: '',
    waiting: false,
    suggestions: []
  };

  onSuggestionsFetchRequested = ({value}) => {
    const {containers} = this.props;
    value = value.toLowerCase();
    this.setState({
      suggestions: containers.filter(pr => pr.name.toLowerCase().startsWith(value)),
      waiting: false,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      waiting: false,
    });
  };

  onChange = (event, eventProps) => {
    const {onChange} = this.props;
    this.setState({ value: eventProps.newValue });
    if (onChange) onChange(event, eventProps);
  };

  render() {
    const {value: propsValue, onChange: propsOnChange, ...other} = this.props;
    const {value, waiting, suggestions} = this.state;

    return (<Autocomplete
      value={propsValue || value}
      waiting={waiting}
      suggestions={suggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      onChange={this.onChange}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      {...other}
    />);
  }
}

export default AutocompleteLessonContentContainer;