import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FaceIcon from '@material-ui/icons/Face';
import ForwardIcon from '@material-ui/icons/Forward';
import { Link } from 'react-router-dom';


const base = (window && window.logoutRedirect) ? window.logoutRedirect : '/login';

const ImgIconButtonStyle = {
  width: '60px',
  height: '60px'
};

const listItemStyle = {
  paddingLeft: '50px' // 36 + 16, algin with sub list
};

class NavRightList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null
    }
  }

  toggleMenu(ev) {
    if (ev) {
      this.setState({menuAnchor: ev.currentTarget});
    } else {
      this.setState({menuAnchor: null});
    }
  }

  render() {
    if (this.props.currentUser && this.props.currentUser.preferred_username) {
      const {menuAnchor} = this.state;

      return (
        <ul className="list-unstyled float-right">
          <li style={{marginRight: '10px'}}>
            <div>
              <Button color="secondary" onClick={ev => this.toggleMenu(ev)}>
                <FaceIcon />
                {this.props.currentUser.preferred_username}
              </Button>
              <Menu open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                onClose={() => this.toggleMenu()}>
                <MenuItem>
                  <Link to={'/login'}>
                    <ForwardIcon />
                    Log Out
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          </li>

        </ul>
      );
    }

    return (<span>NO USER</span>);
  }
}

module.exports = NavRightList;
