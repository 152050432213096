import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import {closeMergeConfirmationDialog} from "reducers/Dialogs/MergeConfirmation";

import {withClient, createLoadedSelector} from "reducers/client";
import {manual, aliasFor, mergeLessonContentContainers, lessonContentContainerDetails, lessonContainers, lessonContentContainerElements} from "reducers/client/requestTypes";
import {clearRequest} from "reducers/client/actions";

import {LessonContentContainerReference} from "components/ContentReference";
import MergeInformationTable from 'components/Tables/MergeInformationTable';

class MergeContainersConfirmationDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      lessonContentContainerIds: PropTypes.arrayOf(PropTypes.string),
      selection: PropTypes.object,
    }),
    mergeLessonContentContainers: PropTypes.object,
    mainSelectedContainer: PropTypes.object,
    secondSelectedContainer: PropTypes.object,
    mainLessonContentContainers: PropTypes.object,
    secondLessonContentContainers: PropTypes.object,
    mainSelectedContainerElements: PropTypes.object,
    secondSelectedContainerElements: PropTypes.object,
    mappedCloseDialog: PropTypes.func.isRequired,
    history: PropTypes.object,
    staticContext: PropTypes.object,
  };

  handleConfirm = () => {
    const {mergeLessonContentContainers, mappedCloseDialog, history, data} = this.props;

    mergeLessonContentContainers.sendRequest(data)
      .then(() => {
        const {
          mainLessonContentContainers: lesson0,
          secondLessonContentContainers: lesson1,
          mainSelectedContainerElements: container0,
          secondSelectedContainerElements: container1
        } = this.props;
        // Refresh data that may be affected by the merge
        lesson0.clear();
        lesson1.clear();
        this.refreshElements(container0);
        this.refreshElements(container1);
        clearRequest('mergeHistory', container0.getParams());
        clearRequest('mergeHistory', container1.getParams());
        mappedCloseDialog();
        history.push(`/app/lessonContentContainers/${data.lessonContentContainerIds[0]}/editor/${Object.keys(data.selection)[0]}`);
      });
  };
  handleClose = () => {
    const {mappedCloseDialog} = this.props;
    mappedCloseDialog();
  };

  refreshElements(containerElements) {
    const {clearRequest} = this.props;
    containerElements.sendRequest().then(result => {
      result.forEach(element => {
        clearRequest('elementParents', element.id);
      });
    });
  }

  render() {
    const {
      isOpen,
      data,
      mergeLessonContentContainers,
      mainSelectedContainer,
      secondSelectedContainer,
      mainLessonContentContainers,
      secondLessonContentContainers,
      mainSelectedContainerElements,
      secondSelectedContainerElements,
      mappedCloseDialog,
      clearRequest,
      history,
      staticContext,
      ...other
    }  = this.props;
    return (<Dialog open={isOpen} onClose={this.handleClose} fullWidth maxWidth="md" {...other}>
      <DialogTitle>Confirm merge</DialogTitle>
      <MergeInformationTable
        mainId={mainSelectedContainer.getParams()}
        mergeId={secondSelectedContainer.getParams()}
        mainElements={mainSelectedContainerElements.isLoaded() ? mainSelectedContainerElements.get() : []}
        mergeElements={secondSelectedContainerElements.isLoaded() ? secondSelectedContainerElements.get() : []}
        selection={data && data.selection}
        referenceComponent={({contentableId, ...props}) =>
          <LessonContentContainerReference containerId={contentableId} {...props} />
        }
      />
      <DialogActions>
        <Button onClick={this.handleClose} color="default" disabled={mergeLessonContentContainers.isLoading()}>
          Cancel
        </Button>
        <Button onClick={this.handleConfirm} color="primary" disabled={mergeLessonContentContainers.isLoading()}>
        Confirm
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = state => ({
  isOpen: state.dialogs.mergeConfirmation.isOpen,
  data: state.dialogs.mergeConfirmation.data,
});
const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeMergeConfirmationDialog()),
  clearRequest: (name, params) => dispatch(clearRequest(name, params)),
});

const getContainerId = index => state => {
  const {data} = state.dialogs.mergeConfirmation;
  return data && data.lessonContentContainerIds && data.lessonContentContainerIds[index];
};
const getLessonId = index => createLoadedSelector('lessonContentContainerDetails', getContainerId(index), container => container.lessonId);
export default withClient({
  hooks: {
    mergeLessonContentContainers: mergeLessonContentContainers,
    mainSelectedContainer: aliasFor('lessonContentContainerDetails')(lessonContentContainerDetails(getContainerId(0))),
    secondSelectedContainer: aliasFor('lessonContentContainerDetails')(lessonContentContainerDetails(getContainerId(1))),
    mainLessonContentContainers: manual(aliasFor('lessonContainers')(lessonContainers(getLessonId(0)))),
    secondLessonContentContainers: manual(aliasFor('lessonContainers')(lessonContainers(getLessonId(1)))),
    mainSelectedContainerElements: manual(aliasFor('lessonContentContainerElements')(lessonContentContainerElements(getContainerId(0)))),
    secondSelectedContainerElements: manual(aliasFor('lessonContentContainerElements')(lessonContentContainerElements(getContainerId(1)))),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MergeContainersConfirmationDialog)));
