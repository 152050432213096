import React from 'react';
import {connect} from 'react-redux';

class ValidateContentReferences extends React.Component {
  state = {
    valid: true
  };

  componentDidMount() {
    this.checkReferences();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkReferences();
  }
  checkReferences() {
    const {references} = this.props;
    let valid = true;
    references.forEach(ref => {
      if (!valid) return;
      let [contentable, contentableId] = ref.split('::');
      let clientState = this.props[contentable];
      if (clientState) {
        let req = clientState.find(r => r.metadata.params === contentableId);
        if (req && req.error) valid = false;
      }
    });
    if (valid !== this.state.valid) this.setState({valid});
  }

  render() {
    const {children} = this.props;
    const {valid} = this.state;
    if (valid) return children;
    return null;
  }
}

const mapStateToProps = state => ({
  lessonContentContainer: state.client.lessonContentContainerDetails,
  tab: state.client.tabDetails,
});

export default connect(mapStateToProps)(ValidateContentReferences);