import React from 'react';

import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import IconClose from '@material-ui/icons/Close';

import ContainerAssociatedLessonsTable from '../PagedTable/ContainerAssociatedLessonsTable';
import ContainerAssociatedContainersTable from '../PagedTable/ContainerAssociatedContainersTable';

import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";

class AssociatedContentTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
    };
  };

  handleSelectTab = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  renderLessonsOrContainers = () => {
    return (
      this.state.selectedTab === 0 ?
        <ContainerAssociatedLessonsTable /> :
        <ContainerAssociatedContainersTable />
    );
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        style={{
          width: '80%',
          left: '10%',
        }}
      >
        <div>
          <Fab color={"primary"}>
            <IconClose onClick={this.props.close} />
          </Fab>
          <AppBar position={"static"}>
            <Tabs value={this.state.selectedTab} onChange={this.handleSelectTab} indicatorColor={'primary'}>
              <Tab label={"Lessons"} />
              <Tab label={"Containers"} />
            </Tabs>
          </AppBar>
          {this.renderLessonsOrContainers()}
        </div>

      </Modal>
    );
  }
}

export default AssociatedContentTable;