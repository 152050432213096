import React from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  TextField,
  MenuItem,
  Tooltip,
  Fab
} from "@material-ui/core";
import IconLock from '@material-ui/icons/Lock';
import IconLockOpen from '@material-ui/icons/LockOpen';
import IconEyeOpen from '@material-ui/icons/Visibility';
import IconEyeClosed from '@material-ui/icons/VisibilityOff';
import IconReplace from '@material-ui/icons/SwapHoriz';

import {renderField,renderSelect,renderCheckbox} from "components/Forms/fields";

import {withClient} from "reducers/client";
import {mediaDetails, mediaAltTexts} from "reducers/client/requestTypes";
import {createDialogStateSelector} from "reducers/dialog";

const FORM_NAME = 'imageAttrs';
const formValues = getFormValues(FORM_NAME);

const styles = theme => ({
  linkedFieldContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  linkedFieldCheckbox: {
    marginLeft: theme.spacing(1),
  },
  associatedCheckbox: {
    paddingTop: theme.spacing(1)
  },
  floatingButton: {
    position: 'absolute',
    right: 0,
    top: 0
  }
});

const WrappedField = React.forwardRef(({name, type, component, label, ...innerProps}, ref) => (
  <Field
    ref={ref}
    name={name}
    type={type}
    component={component}
    label={label}
    props={innerProps}
  />
));
WrappedField.displayName = "WrappedField";

class ImageAttrs extends React.Component {

  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
    this.state = {
      imageWidth: null,
      imageHeight: null,
    };

    this.widthField = ({input: {onChange}}) => {
      this.onImageWidthChange = onChange;
      return false;
    };
    this.heightField =({input: {onChange}}) => {
      this.onImageHeightChange = onChange;
      return false;
    };
  }

  componentDidUpdate() {
    const {imageWidth, imageHeight} = this.state;
    if (this.imageRef.current) {
      const img = this.imageRef.current;
      let anyChange = false;
      if (imageWidth !== img.naturalWidth) {
        this.onImageWidthChange(img.naturalWidth);
        anyChange = true;
      }
      if (imageHeight !== img.naturalHeight) {
        this.onImageHeightChange(img.naturalHeight);
        anyChange = true;
      }
      if (anyChange) {
        this.setState({
          imageWidth: img.naturalWidth,
          imageHeight: img.naturalHeight,
        })
      }
    } else if (imageWidth || imageHeight) {
      this.setState({
        imageWidth: null,
        imageHeight: null,
      })
    }
  }

  // handleClickEditAltText = () => {
  //   const {history, mediaDetails} = this.props;
  //   history.push(`/app/medias/${mediaDetails.getParams()}`);
  // };

  render() {
    const { classes, formValues, mediaDetails, mediaAltTexts, onClickReplace } = this.props;
    if (!formValues) return false;
    console.log(formValues);

    let altText = '';
    let presentationOnly = false;
    if (mediaDetails.isLoaded()) {
      presentationOnly = mediaDetails.get().presentationOnly;
    }
    if (presentationOnly) {
      altText = "(PRESENTATION ONLY)";
    } else if (mediaAltTexts.isLoaded()) {
      const selectedAltText = mediaAltTexts.get().find(t => t.languageTag === 'EN');
      if (selectedAltText) altText = selectedAltText.altText;
    }

    const {aspect} = this.props.formValues;
    let aspectLabel;
    if (aspect) aspectLabel = <IconLock />;
    else aspectLabel = <IconLockOpen />;

    return (<div className="row">
      <div className="col-sm-6">
        <form onSubmit={() => {}}>
          <TextField
            fullWidth
            multiline
            disabled
            value={altText}
            InputProps={{
              startAdornment: presentationOnly ? <IconEyeClosed /> : <IconEyeOpen />,
              // endAdornment: (
              //   <InputAdornment position="end">
              //     <IconButton onClick={this.handleClickEditAltText}>
              //       <IconEdit/>
              //     </IconButton>
              //   </InputAdornment>
              // )
            }}
          />
          <div className={classes.linkedFieldContainer}>
            <div>
              <Field name="baseWidth" type="text" component={this.widthField} />
              <Field name="width" type="text" component={renderField} label="Width" />
              <Field name="baseHeight" type="text" component={this.heightField} />
              <Field name="height" type="text" component={renderField} label="Height" />
            </div>
            <div className={classes.linkedFieldCheckbox}>
              <Tooltip title="Lock aspect ratio">
                <WrappedField name="aspect" type="checkbox" component={renderCheckbox} label={aspectLabel} />
              </Tooltip>
            </div>
          </div>
          <Field name="border" type="text" component={renderField} label="Border" fullWidth />
          <Field name="horizontalPadding" type="text" component={renderField} label="Horizontal Padding" />
          <Field name="verticalPadding" type="text" component={renderField} label="Vertical Padding" />
          <Field name="align" type="text" component={renderSelect} label="Alignment" fullWidth>
            <MenuItem value="">Inline</MenuItem>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="right">Right</MenuItem>
          </Field>
          <Field
            name="vAlign"
            type="text"
            component={renderSelect}
            label="Vertical Alignment"
            fullWidth
            disabled={Boolean(formValues.align)}
          >
            <MenuItem value="">Inherit</MenuItem>
            <MenuItem value="baseline">Baseline</MenuItem>
            <MenuItem value="top">Top</MenuItem>
            <MenuItem value="text-top">Text Top</MenuItem>
            <MenuItem value="middle">Middle</MenuItem>
            <MenuItem value="text-bottom">Text Bottom</MenuItem>
            <MenuItem value="bottom">Bottom</MenuItem>
          </Field>
        </form>
      </div>
      <div className="col-sm-6">
        {mediaDetails.isLoaded() && (
          <img style={{width: '100%'}} src={mediaDetails.get().publicUrl} ref={this.imageRef}/>
        )}
        <Fab
          className={classes.floatingButton}
          color="primary"
          variant="extended"
          onClick={onClickReplace}
        >
          <IconReplace />
          Replace
        </Fab>
      </div>
    </div>);
  }
}

const mapStateToProps = state => ({
  formValues: formValues(state),
});

const ImageAttrsForm = reduxForm({
  form: FORM_NAME,
})(ImageAttrs);

const getDialogState = createDialogStateSelector('prosemirror-image');
const getMediaId = state => getDialogState(state).mediaId;
export default withClient({
  hooks: {
    mediaDetails: mediaDetails(getMediaId),
    mediaAltTexts: mediaAltTexts(getMediaId)
  }
})(connect(
  mapStateToProps
)(withRouter(withStyles(styles)(ImageAttrsForm))));
