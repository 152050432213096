import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {
  Radio,
  Typography,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import IconLink from '@material-ui/icons/Link';

import {getElementTypeByName} from 'constants/ElementTypes';

import {withClient} from "reducers/client";
import {lessonContainers} from "reducers/client/requestTypes";


const styles = theme => ({
  blacklist: {
    color: '#b0b0b0',
    cursor: 'not-allowed',
  },
  containerElement: {
    ...theme.typography.button,
    display: 'inline-flex',
    padding: '6px 8px',
    color: theme.palette.primary.main,
    '$blacklist &': {
      color: '#b0b0b0',
    }
  }

});

class BrowserLessonContentContainerList extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    blacklist: PropTypes.arrayOf(PropTypes.string),
    lessonContainers: PropTypes.object,
    lessonId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.string,
  };
  static defaultProps = {
    blacklist: [],
  };

  render() {
    const {classes, blacklist, lessonContainers, selected, onClick} = this.props;
    if (lessonContainers.isLoaded()) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>
                Container
              </TableCell>
              <TableCell>
                Content
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessonContainers.get().map(p => {
              const isBlacklisted = blacklist.includes(p.id);
              const className = isBlacklisted ? classes.blacklist : '';
              return (
                <TableRow
                  key={p.id}
                  hover={!isBlacklisted}
                  onClick={ev => !isBlacklisted && onClick(ev, p)}
                  className={className}
                  selected={selected === p.id}
                >
                  <TableCell padding="checkbox">
                    <Radio checked={selected === p.id} disabled={isBlacklisted} />
                  </TableCell>
                  <TableCell className={className}>
                    {p.name}
                  </TableCell>
                  <TableCell>
                    {p.elements.map(e => {
                      const elementType = getElementTypeByName(e.elementType);
                      if (e.links.length > 0) {
                        return (
                          <Tooltip key={e.id} title={e.links.map(l => `${l.bookName} ${l.name}`).join(', ')}>
                            <div className={classes.containerElement}>
                              {elementType.displayName || elementType.name}
                              <IconLink />
                            </div>
                          </Tooltip>
                        );
                      }
                      return (
                        <div key={e.id} className={classes.containerElement}>
                          {elementType.displayName || elementType.name}
                        </div>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    return null;
  }
}

export default withClient({
  hooks: {
    lessonContainers: lessonContainers((state, props) => props.lessonId)
  }
})(withStyles(
  styles
)(BrowserLessonContentContainerList));
