import React, {Component} from 'react';
import { Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {renderField} from "components/Forms/fields";
import Button from '@material-ui/core/Button';
import {deleteStandard} from "../../../reducers/standards";


class StandardForm extends Component {

  onSubmit = (values) => {
    const standard = this.props.standard || {};
    values.id = standard.id;
    values.classificationId = this.props.classificationId;
    if(typeof values.grades === 'string'){
      values.grades = values.grades.split(',');
    }
    return this.props.save(values)
  };

  deleteClicked = () => {
    const standard = this.props.standard || {};
    this.props.deleteStandard(standard.id);
    this.props.onClose();
  };

  render(){
    const { pristine, submitting, handleSubmit, standard } = this.props;
    return (
      <div>
        <h2 className="article-title">{this.props.standard ? 'Edit Standard': 'Create Standard'}</h2>
        {/*<i className="nav-icon material-icons" onClick={this.deleteClicked} style={{position: 'absolute', top: 15, right: 15, color: '#c1c1c1', cursor: 'pointer'}}>delete</i>*/}
        <div className="box box-default">
          <div className="box-body">
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field name="title" type="text" placeholder="Title" component={renderField} label="Title" fullWidth />
              <Field name="shortTitle" type="text" placeholder="Short Title" component={renderField} label="Short Title" fullWidth />
              <Field name="asnIdentifier" type="text" placeholder="ASN Identifier" component={renderField} label="ASN Identifier" fullWidth />
              <Field name="type" type="text" placeholder="Type" component={renderField} label="Type" fullWidth />
              <Field name="grades" type="text" placeholder="Grades" component={renderField} label="Grades" fullWidth />
              <Field name="description" type="textfield" placeholder="Standard Name" component={renderField} label="Description" fullWidth />
              <div>
                <br />
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>Save</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const CreateStandardForm = reduxForm({
  form: 'createStandard',
  onSubmitSuccess: (result, dispatch, props) => {
    props.onClose()
  },
})(StandardForm);

const mapStateToProps = state => ({
  formData: state.form.createStandard,
  standard: state.dialogs.standards.data,
  initialValues: state.dialogs.standards.data,
});

const mapDispatchToProps = dispatch => ({
  saveStandard: (standard) => {
    dispatch(saveStandard(standard));
  },
  deleteStandard: (id) => dispatch(deleteStandard(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateStandardForm);
