import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {ClassificationListView} from "views/classification/ClassificationList";
import {ClassificationRoutes} from './classification';

export const ClassificationsRoutes = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}`} to={`${match.path}/list`} />
    <Route path={`${match.path}/list`} component={ClassificationListView} />
    <Route path={`${match.path}/:classificationId`} component={ClassificationRoutes} />
    <Redirect to="/404" />
  </Switch>
);
