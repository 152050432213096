import * as React from "react";
import {connect} from "react-redux";

import * as DialogActions from './actions';
import {createDialogSelector} from "./selectors";

export default options => BaseComponent => {
  // Get options or defaults
  const {
    dialog,
    initialState
  } = options;

  // Component with injected client request data
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const {__registerDialog} = this.props;
      // console.log(this.constructor.displayName, ' :: MOUNT ', dialog);
      __registerDialog(initialState);
    }
    componentWillUnmount() {
      const {__unregisterDialog} = this.props;
      // console.log(this.constructor.displayName, ' :: UNMOUNT ', dialog);
      __unregisterDialog();
    }

    render() {
      const {
        outerProps,
        outerRef,
        dialog,
      } = this.props;
      if (!dialog) return null;
      return <BaseComponent
        {...outerProps}
        dialog={dialog}
        ref={outerRef}
      />;
    }
  }
  // Forward the React ref from the wrapped component to the base component
  const forwardRef = (props, ref) => <WrappedComponent {...props} outerRef={ref} />;
  WrappedComponent.displayName =
    forwardRef.displayName = `reduxDialog(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  // Create selectors for all client hooks
  const dialogSelector = createDialogSelector(dialog);

  // Get the state of all requested client hooks
  const mapStateToProps = (state) => ({
    dialogState: dialogSelector(state),
  });
  // Get mapped action creators for all requested client hooks
  const mapDispatchToProps = (dispatch) => ({
    __registerDialog: initialState => dispatch(DialogActions.registerDialog(dialog, initialState)),
    __unregisterDialog: () => dispatch(DialogActions.unregisterDialog(dialog)),
    open: data => dispatch(DialogActions.dialogOpen(dialog, data)),
    close: () => dispatch(DialogActions.dialogClose(dialog)),
    setState: state => dispatch(DialogActions.dialogSetState(dialog, state)),
  });
  const mergeProps = (stateProps, dispatchProps, outerProps) => {
    const {dialogState} = stateProps;
    const {__registerDialog, __unregisterDialog, ...actions} = dispatchProps;
    const dialog = dialogState ? {...dialogState, actions} : null;
    return {
      outerProps,
      __registerDialog,
      __unregisterDialog,
      dialog
    };
  };

  // Connect wrapped component to Client reducer
  return connect(mapStateToProps, mapDispatchToProps, mergeProps, {forwardRef: true})(React.forwardRef(forwardRef));
};