import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from '../../util/pagedTable';

const ElementControlRequirementStatusesTable = PagedTable(
    'elementControlRequirementStatuses',
    state => {
      // TODO WBD does this work?
      console.log('in reducer', state);
      if (
        !state.elements.selected ||
        !state.elements.details ||
        !state.elements.details[state.elements.selected] ||
        !state.elements.details[state.elements.selected].data ||
        !state.elements.details[state.elements.selected].data.currentVersionId
      ) {
        return {};
      }

      return apiClient.getControlRequirementStatusesForContentVersion(state.elements.details[state.elements.selected].data.currentVersionId)
    },
    (state, action) => {
      return state;
    },
);

export default ElementControlRequirementStatusesTable;