import {defaults, getRequestKey, RequestState} from "./util";
import {createSelector} from "./selectors";
import {clearRequest, registerRequest, sendRequest} from "./actions";
import {shallowEqual} from "react-redux";

const getRequestFunction = (clientHook, params, ...content) => {
  if (clientHook) {
    if (typeof clientHook.request === 'function') {
      return clientHook.request(params, ...content);
    }
    return clientHook.request;
  }
};

// Create a set of actions that can be called on each hook
export const createActions = (alias, hook, options, dispatch) => {
  const {hookName, transform: hookTransform} = hook;
  const requestName = hookName || alias;
  const transform = Object.assign({}, options.transform, hookTransform);

  let state = {};
  let props = {};
  const send = (...content) => {
    const params = getRequestKey(hook, state, props);
    let func;
    if (params) {
      func = getRequestFunction(hook, params, ...content);
    } else if (content && content.length > 0) {
      func = getRequestFunction(hook, ...content);
    }
    if (func) {
      return dispatch(sendRequest(requestName, transform, func, params));
    }
  };
  const clear = () => {
    const params = getRequestKey(hook, state, props);
    return dispatch(clearRequest(requestName, params));
  };

  // Actions that will be copied onto the RequestState object
  const actions = {
    sendRequest: send,
    clear,
  };
  // Use Object.defineProperty to make the 'injectProps' function non-enumerable
  // So that it won't get copied on the the RequestState object.
  Object.defineProperty(actions, 'injectState', {
    value: (newState, newProps) => {
      state = newState;
      props = newProps;
    }
  });

  return actions;
};

export const selectorFactory = (dispatch, options) => {
  // Get options or defaults
  const clientHooks = options.hooks;
  const getState = options.getState || defaults.getState;
  options.transform = Object.assign({}, defaults.transform, options.transform);

  const clientSelectors = {};
  const clientActions = {};

  let containsErrors = false;
  let isInitialized = false;
  const init = (state, props) => {
    isInitialized = true;
    const clientState = getState(state);
    // Create selectors and register all client hooks
    Object.keys(clientHooks).forEach(alias => {
      try {
        const {hookName, key} = clientHooks[alias];
        // Check each hook to make sure it is well-formed
        if (typeof key === 'function' && key(state, props) !== key(state,props)) {
          containsErrors = true;
          console.error(`${options.displayName} ERROR: key is not a pure function for hooks.${alias}`);
        } else {
          // Create a selector to get the current state of the hook
          clientSelectors[alias] = createSelector(hookName || alias, key, getState);
          // Register any unregistered hooks
          if (!clientState[hookName || alias]) dispatch(registerRequest(hookName || alias));
          // Create a set of actions for each hook
          clientActions[alias] = createActions(alias, clientHooks[alias], options, dispatch);
          clientActions[alias].injectState(state,props);
        }
      } catch(err) {
        containsErrors = true;
        console.error(`${options.displayName} ERROR in hooks.${alias}`,err);
      }
    });
  };

  let cachedHooks = {};
  let cachedOwnProps = null;
  let cachedProps = null;
  const getProps = (state, props) => {
    const propsChanged = !shallowEqual(cachedOwnProps, props);
    const hookValues = {};
    let renderRequired = false;

    Object.keys(clientHooks).forEach(alias => {
      // Update client actions' state
      clientActions[alias].injectState(state,props);
      // Get current hook state
      hookValues[alias] = clientSelectors[alias](state, props);
      // Null-safe any hooks that aren't registered this cycle
      if (!hookValues[alias]) hookValues[alias] = new RequestState();
      // Update any cache values that have changed
      if (hookValues[alias] !== cachedHooks[alias]) {
        cachedHooks[alias] = hookValues[alias];
        Object.assign(cachedHooks[alias], clientActions[alias]);
        renderRequired = true;
      }
      // Send requests to any hooks that are set to auto-fetch
      if (clientHooks[alias].auto && hookValues[alias].isFetchRequired()) hookValues[alias].sendRequest();
    });

    if (!cachedProps || renderRequired) {
      // Override the cache
      cachedProps = {...props};
      // Spread the hooks onto the props object
      Object.keys(clientHooks).forEach(alias => {
        cachedProps[alias] = hookValues[alias];
      });
    } else if (propsChanged) {
      // If component's own props changed, trigger a re-render
      cachedProps = {...props, ...cachedHooks};
    }

    cachedOwnProps = props;
    return cachedProps;
  };

  return (state, props) => {
    if (!isInitialized) init(state, props);
    if (containsErrors) return props;
    return getProps(state, props);
  };
};
