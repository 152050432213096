import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import {connect} from 'react-redux';

import imageBrowser, {deleteSelectedMedias} from "reducers/LegacyTables/imageBrowser";

const styles = theme => ({
  root: {
  },
  header: {

  },
  item: {
    display: 'inline-block',
    height: `${180}px`,
    padding: `${8}px`
  },
  itemButton: {
    width: `${100}%`,
    height: `${100}%`,
    padding: `${8}px`,
    boxShadow: '0 1px 2px #888888'
  },
  itemImage: {
    maxWidth: `${100}%`,
    maxHeight: `${100}%`,
  },
  itemLabel: {
    position: 'absolute',
    bottom: `${0}`,
    width: `${100}%`,
    lineHeight: `${24}px`,
    padding: `${8}px`,
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  itemSelected: {
    backgroundColor: theme.palette.secondary.main
  }
});

class ImageBrowser extends React.Component {
  componentDidMount() {
    const {changePageSize, changeSearchField} = this.props;
    changePageSize(16);
    changeSearchField('fileName');
    this.tryGetPage();
  }
  componentDidUpdate() { this.tryGetPage(); }
  tryGetPage() {
    const { requestPage, pageRequest } = this.props;
    const {valid} = this.props.tableState;
    if (!valid && !pageRequest.pending) {
      requestPage();
    }
  }

  handleClick = (event, item) => {
    const { selected } = this.props.tableState;
    if (item !== null) {
      if (selected[0] !== item.id) {
        this.props.clearSelection();
        this.props.addSelection(item.id);
      }
    } else {
      if (selected.length > 0) {
        this.props.clearSelection();
      }
    }
    if (this.props.onClick) {
      this.props.onClick(event,item);
    }
    event.stopPropagation();
  };

  isSelected = (id, selected) => selected.indexOf(id) !== -1;

  render() {
    let data = this.props.data || [];
    // make data an array (it gets defaulted to empty object)
    if(!data.length){
      data = []
    }
    const { classes, totalCount, searchPending, columns = 4 } = this.props;
    const { selected } = this.props.tableState;
    const { sort, pageSize, page, search } = this.props.tableState.pagination;

    console.log('ImageBroswer.render()', this.props.pageRequest, data);

    let content = (<div className={classes.root} onClick={ev => this.handleClick(ev, null)}>
      {searchPending && <LinearProgress />}
      {data.map(media => {
        const isSelected = this.isSelected(media.id, selected);
        return (
          <div key={media.id} className={classes.item} style={{width:`${Math.floor(100/columns)}%`}}>
            <ButtonBase
              onClick={ev => this.handleClick(ev, media)}
              className={classnames(classes.itemButton, {[classes.itemSelected]: isSelected})}>
              <img
                src={media.publicUrl}
                alt={media.fileName}
                className={classes.itemImage}
              />
              <div className={classes.itemLabel}>{media.fileName}</div>
            </ButtonBase>
          </div>
        );
      })}
    </div>);

    return content;
  }
}

const WithStylesImageBrowser = withStyles(styles)(ImageBrowser);

const {Selectors, Actions} = imageBrowser;
const mapStateToProps = state => ({
  pageRequest: Selectors.getPageRequest(state),
  searchPending: state.search.images.searchResults.pending,
  data: state.search.images.searchResults.data,
  totalCount: Selectors.getTotalCount(state),
  tableState: Selectors.getState(state)
});
const mapDispatchToProps = dispatch => ({
  requestPage: () => dispatch(Actions.requestPage()),
  changePage: page => dispatch(Actions.changePage(page)),
  changePageSize: pageSize => dispatch(Actions.changePageSize(pageSize)),
  changeSort: column => dispatch(Actions.changeSort(column)),
  changeSearch: value => dispatch(Actions.changeSearch(value)),
  changeSearchField: value => dispatch(Actions.changeSearchField(value)),

  addSelection: values => dispatch(Actions.addSelection(values)),
  removeSelection: values => dispatch(Actions.removeSelection(values)),
  toggleSelection: values => dispatch(Actions.toggleSelection(values)),
  clearSelection: () => dispatch(Actions.clearSelection()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithStylesImageBrowser);
