import React from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import IconAdd from '@material-ui/icons/Add';
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import IconCallSplit from '@material-ui/icons/CallSplit';
import IconMedia from '@material-ui/icons/Image';

import {formatDate} from "constants/Date";
import {withClient} from "reducers/client";
import {lessonDetails, updateLesson} from "reducers/client/requestTypes";

import {LessonBreadcrumb} from 'components/Breadcrumb';
import LessonElementsTable from 'components/Tables/LessonElementsTable';
import LessonContainersTable from 'components/Tables/LessonContainersTable';
import LessonAssociatedContentTable from "components/Tables/LessonAssociatedContentTable";

import DetailsTable from "components/Tables/DetailsTable";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: `${100}px`,
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

const ContentTable = props => (
  <article className="article">
    <h2 className="article-title">
      <Tooltip title={props.tooltip}>
        <Fab color="primary" style={{'marginRight':'20px'}}>
          <IconAdd onClick={props.onClickCreateButton} />
        </Fab>
      </Tooltip>
      {props.title}
    </h2>
    {props.children}
  </article>);

class LessonDetails extends React.Component {
  state = {
    showAssociated: false,
    editMode: false,
    lessonDetails: {},
  };

  componentDidMount() {
    const {lessonDetails:lessonRequest} = this.props;
    if (lessonRequest.isLoaded()) {
      const lessonDetails = lessonRequest.get();
      this.setState({
        lessonDetails: {
          'Name': lessonDetails.name,
          'Created': formatDate(lessonDetails.createdAt),
          'Updated': formatDate(lessonDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {lessonDetails:lessonRequest} = this.props;
    const {lessonDetails:prevRequest} = prevProps;

    if (lessonRequest.isLoaded() && lessonRequest.hasChanged(prevRequest)) {
      const lessonDetails = lessonRequest.get();
      this.setState({
        lessonDetails: {
          'Name': lessonDetails.name,
          'Created': formatDate(lessonDetails.createdAt),
          'Updated': formatDate(lessonDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      lessonDetails: {
        ...this.state.lessonDetails,
        [key]: value,
      }
    });
  }
  toggleEditLesson() {
    const {editMode, lessonDetails} = this.state;
    if (editMode) {
      const {lessonDetails:lessonRequest, updateLesson} = this.props;
      if (lessonRequest.get().name !== lessonDetails.Name) {
        updateLesson.sendRequest({
          id: lessonRequest.get().id,
          name: lessonDetails.Name,
        })
          .then(() => lessonRequest.sendRequest(lessonRequest.get().id));
      }
    }
    this.setState({editMode: !editMode});
  }
  showAssociatedContent = () => {
    this.setState({
      showAssociated: !this.state.showAssociated,
    });
  };
  goToLessonMedia = () => {
    this.props.history.push(`/app/lessons/${this.props.lessonDetails.getParams()}/media`);
  };

  render() {
    const { lessonDetails:lessonRequest, classes } = this.props;
    const {editMode, lessonDetails} = this.state;

    if (!lessonRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Lesson Container details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <LessonAssociatedContentTable open={this.state.showAssociated} close={this.showAssociatedContent} />
        {/*<div className={classes.buttonBox}>*/}
        {/*  <Tooltip title={"Show Associated Content"}>*/}
        {/*    <Fab color={"primary"} className={classes.button}>*/}
        {/*      <IconCallSplit onClick={e => this.showAssociatedContent()} />*/}
        {/*    </Fab>*/}
        {/*  </Tooltip>*/}
        {/*</div>*/}
        <div className={classes.section}>
          <LessonBreadcrumb lessonId={lessonRequest.get().id}/>
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit Lesson Details"}>
              <Fab color="primary" style={{'marginRight': '20px'}} onClick={() => this.toggleEditLesson()}>
                {editMode ? <IconSave/> : <IconEdit/>}
              </Fab>
            </Tooltip>
            Lesson Container
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Name', value: lessonDetails.Name},
              {name: 'Created', value: lessonDetails.Created, readOnly: true},
              {name: 'Updated', value: lessonDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Button variant="contained" color="primary" onClick={this.goToLessonMedia}>
            <IconMedia />
            Images
          </Button>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Pages</Typography>
          <Paper>
            <LessonElementsTable lessonId={lessonRequest.get().id} />
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Content Containers</Typography>
          <Paper>
            <LessonContainersTable lessonId={lessonRequest.get().id} />
          </Paper>
        </div>
      </div>
    );

  }
}

export const LessonDetailsView = withClient({
  hooks: {
    lessonDetails: lessonDetails((state, props) => state.lessons.selected),
    updateLesson: updateLesson((state, props) => state.lessons.selected),
  },
})(withStyles(styles)(LessonDetails));
