import React from 'react';
import {connect} from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import IconMenu from '@material-ui/icons/Menu';
import {withStyles} from '@material-ui/core/styles';

import {sidebarModes} from "constants/Prosemirror";
import {asArray} from "constants/util/map";
import {setSidebarMode} from "reducers/Prosemirror";

import {withClient} from "reducers/client";
import {validateHTML} from "reducers/client/requestTypes";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

const styles = theme => ({
  iconBadge: {
    top: '10px',
    right: '10px',
  },
  menuBadge: {
    top: '50%',
    right: '15px',
  }
});

class ProsemirrorSidebarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      contentVersionId: null,
    }
  }

  componentDidMount() {
    const {validateHTML, prosemirror} = this.props;
    validateHTML.sendRequest(prosemirror.getHTML());
    this.setState({contentVersionId: prosemirror.getContentVersion().id });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {validateHTML, prosemirror} = this.props;
    if (prosemirror.getContentVersion().id !== this.state.contentVersionId) {
      validateHTML.sendRequest(prosemirror.getHTML());
      this.setState({contentVersionId: prosemirror.getContentVersion().id });
    }
  }

  handleClick = ev => {
    this.setState({menuAnchor: ev.currentTarget});
  };
  handleClose = ev => {
    this.setState({menuAnchor: null});
  };
  handleMenu = mode => ev => {
    const {setSidebarMode} = this.props;
    setSidebarMode(mode);
    this.handleClose();
  };

  render() {
    const {
      classes,
      docChanged,
      sidebarMode,
      setSidebarMode,
      buttonProps,
      menuProps,
      validateHTML,
      prosemirror,
      ...innerProps
    } = this.props;
    const {menuAnchor} = this.state;

    let notes = [];
    if (validateHTML.isLoaded()) {
      notes = validateHTML.get().filter(note => note.warningLevel === "Error");
    }

    return (
      <div {...innerProps}>
        <Tooltip title="Views">
          <Badge classes={{badge:classes.iconBadge}} badgeContent={notes.length} color="error">
            <IconButton onClick={this.handleClick} {...buttonProps}>
              <IconMenu/>
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.handleClose}
          {...menuProps}
        >
          {asArray(sidebarModes).map(m => {
            const IconComponent = m.icon;
            let menuItem = (
              <MenuItem
                key={m.id}
                onClick={this.handleMenu(m)}
                selected={sidebarMode === m.id}
                disabled={m.disableWhenDirty && docChanged}
              >
                <ListItemIcon><IconComponent/></ListItemIcon>
                <ListItemText>{m.name}</ListItemText>
              </MenuItem>
            );
            if (m.badgeContent) {
              let content = null;
              if (m.badgeContent === 'wcag') content = notes.length;
              return (
                <Badge classes={{badge:classes.menuBadge}} key={m.id} badgeContent={content} color="error">
                  {menuItem}
                </Badge>
              );
            }
            return menuItem;
          })}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  docChanged: state.prosemirror.docChanged,
  sidebarMode: state.prosemirror.sidebarMode
});
const mapDispatchToProps = dispatch => ({ setSidebarMode: mode => dispatch(setSidebarMode(mode)) });

export default withProsemirror(withClient({
  hooks: {
    validateHTML
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(
  styles
)(ProsemirrorSidebarMenu))));