// ACTION TYPES
export const TABBED_VIEW_SELECT = 'TABBED_VIEW_SELECT';

// ACTION CREATORS
export function selectTabbedView(tabbedViewId) {
  return {type: TABBED_VIEW_SELECT, payload: tabbedViewId};
}

const initialState = {
  selected: null,
};

export default (state = initialState, action) => {
  if (action.type === TABBED_VIEW_SELECT) {
    return {
      ...state,
      selected: action.payload
    };
  }
  return state;
}
