const locale = 'en-US';

const dateOptions = {};
export const formatDate = dateString => new Date(dateString).toLocaleDateString(locale, dateOptions);

const timeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};
export const formatTime = dateString => new Date(dateString).toLocaleTimeString(locale, timeOptions);
