const book = {name: "Book"};
const standard = {name: "Standard"};
const approval = {name: "Approval Status"};
const shared = {name: "Shared Content"};
const filename = {name: "Filename"};
const questionIdGreaterThan = {name: "questionIdGreaterThan"};
const questionIdLessThan = {name: "questionIdLessThan"};

export default {
  book,
  standard,
  approval,
  shared,
  filename,
  questionIdGreaterThan,
  questionIdLessThan
};

export const ProblemFilterTypes = [book, standard, approval, shared];
export const ImageFilterTypes = [book, standard, approval, shared, filename];
export const AssessmentProblemFilterTypes = [questionIdGreaterThan, questionIdLessThan, book];
