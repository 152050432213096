import React from 'react';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {SectionBreadcrumb} from "components/Breadcrumb";
import DetailsTable from 'components/Tables/DetailsTable';
import SectionElementsTable from 'components/Tables/SectionElementsTable';
import SectionTabbedViewsTable from 'components/Tables/SectionTabbedViewsTable';
import {withClient} from "reducers/client";
import {sectionDetails, updateSection} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class SectionDetails extends React.Component {
  state = {
    editMode: false,
    sectionDetails: {},
  };

  componentDidMount() {
    const {sectionDetails:sectionRequest} = this.props;
    if (sectionRequest.isLoaded()) {
      const sectionDetails = sectionRequest.get();
      this.setState({
        sectionDetails: {
          'Name': sectionDetails.name,
          'Type': sectionDetails.type,
          'Created': formatDate(sectionDetails.createdAt),
          'Updated': formatDate(sectionDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {sectionDetails:sectionRequest} = this.props;
    const {sectionDetails:prevRequest} = prevProps;

    if (sectionRequest.isLoaded() && sectionRequest.hasChanged(prevRequest)) {
      const sectionDetails = sectionRequest.get();
      this.setState({
        sectionDetails: {
          'Name': sectionDetails.name,
          'Type': sectionDetails.type,
          'Created': formatDate(sectionDetails.createdAt),
          'Updated': formatDate(sectionDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      sectionDetails: {
        ...this.state.sectionDetails,
        [key]: value,
      }
    });
  }
  toggleEditSection() {
    const {editMode, sectionDetails} = this.state;
    if (editMode) {
      const {sectionDetails:sectionRequest, updateSection} = this.props;
      if (sectionRequest.get().name !== sectionDetails['Name'] ||
        sectionRequest.get().type !== sectionDetails['Type']) {
        updateSection.sendRequest({
          id: sectionRequest.get().id,
          name: sectionDetails['Name'],
          type: sectionDetails['Type'],
        })
          .then(() => sectionRequest.sendRequest());
      }
    }
    this.setState({editMode: !editMode});
  }

  render() {
    const {classes, sectionDetails:sectionRequest} = this.props;
    const {editMode, sectionDetails} = this.state;

    if (!sectionRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Section details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <SectionBreadcrumb sectionId={sectionRequest.getParams()} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit Section Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditSection()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            Section Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Name', value: sectionDetails['Name']},
              {name: 'Type', value: sectionDetails['Type']},
              {name: 'Created', value: sectionDetails.Created, readOnly: true},
              {name: 'Updated', value: sectionDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Elements</Typography>
          <Paper>
            <SectionElementsTable sectionId={sectionRequest.getParams()} />
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Tabbed Views</Typography>
          <Paper>
            <SectionTabbedViewsTable sectionId={sectionRequest.getParams()} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const SectionDetailsView = withClient({
  hooks: {
    sectionDetails: sectionDetails((state, props) => state.sections.selected),
    updateSection: updateSection((state, props) => state.sections.selected),
  },
})(withStyles(styles)(SectionDetails));
