import uuid from 'uuid/v4';
import {and, canInsert, exists} from '../util';
import schema from '../../schema';
import icons, {withPencil} from '../../icons';

import MenuGroup from '../components/MenuGroup';
import MenuInsertTable from '../components/MenuInsertTable';
import MenuButton from '../components/MenuButton';

import {insertMathQuill} from "../../plugins/keys/mathQuill";
import {promptForURL} from "../util";
import React from "react";

const anchor = (<MenuButton
  enabled={canInsert(schema.nodes.anchor)}
  command={(state, dispatch) => {
    dispatch(state.tr.replaceSelectionWith(schema.nodes.anchor.create({name:''})));
  }}
  title="Insert Anchor">
  {icons.anchor}
</MenuButton>);
const hr = (<MenuButton
  enabled={canInsert(schema.nodes.horizontal_rule)}
  command={(state, dispatch) => {
    dispatch(state.tr.replaceSelectionWith(schema.nodes.horizontal_rule.create()));
  }}
  title="Insert Horizontal Line">
  {icons.grip_horizontal}
</MenuButton>);
const image = (<MenuButton
  enabled={canInsert(schema.nodes.image)}
  command={(state, dispatch) => dispatch({external: true, method: 'image', state})}
  title="Insert image">
  {icons.image}
</MenuButton>);
const mathQuill = (<MenuButton
  enabled={canInsert(schema.nodes.mathQuill)}
  command={(state, dispatch) => dispatch({external: true, method: 'mathquill', state})}
  title="Insert MathQuill">
  {icons.pen_fancy}
</MenuButton>);
const desmos = (<MenuButton
  enabled={canInsert(schema.nodes.desmos)}
  command={(state, dispatch) => dispatch({external: true, method: 'desmos', state})}
  title="Insert Desmos Graph">
  {icons.arrows_alt}
</MenuButton>);
const embed = (<MenuButton
  enabled={canInsert(schema.nodes.embed)}
  command={(state, dispatch) => dispatch({external: true, method: 'embed', state})}
  title="Insert Embedded URL">
  {icons.external_link}
</MenuButton>);
const homeworkLink = (<MenuButton
  enabled={canInsert(schema.nodes.homeworkLink)}
  command={(state, dispatch) => dispatch(state.tr.replaceSelectionWith(schema.nodes.homeworkLink.create()))}
  title="Insert Homework Link">
  {withPencil(icons.link)}
</MenuButton>);
const homeworkHint = (<MenuButton
  enabled={canInsert(schema.nodes.homeworkHint)}
  command={(state, dispatch) => {
    const content = schema.text('Hint:');
    const hh = schema.nodes.homeworkHint.createAndFill({id: uuid()}, content);
    dispatch(state.tr.replaceSelectionWith(hh));
  }}
  title="Insert Homework Hint">
  {icons.check_square}
</MenuButton>);
const homeworkHintTarget = (<MenuButton
  enabled={and(canInsert(schema.nodes.homeworkHintTarget), exists(schema.nodes.homeworkHint))}
  command={(state, dispatch) => dispatch({external: true, method: 'homework', state})}
  title="Insert Homework Hint Toggle">
  {icons.question_circle}
</MenuButton>);
const sequenceContainer = (<MenuButton
  enabled={canInsert(schema.nodes.homeworkSequenceContainer)}
  command={(state, dispatch) => {
    const seq = schema.nodes.homeworkSequenceContainer.createAndFill();
    dispatch(state.tr.replaceSelectionWith(seq));
  }}
  title="Insert Sequence Container">
  {icons.object_group}
</MenuButton>);
// const table = (<MenuButton
//   enabled={canInsert(schema.nodes.table)}
//   command={insertTable}
//   title="Insert table">
//   {icons.insert_table}
// </MenuButton>);
const table = <MenuInsertTable />;
const columns = (<MenuButton
  enabled={canInsert(schema.nodes.columnSet)}
  command={(state, dispatch) => dispatch({external: true, method: 'columns', state})}
  title="Insert columns">
  {icons.columns[0]}
</MenuButton>);

export const lessonInsert = (<MenuGroup key="insert">
  {anchor}
  {hr}
  {image}
  {mathQuill}
  {desmos}
  {embed}
  {table}
  {columns}
</MenuGroup>);
export const containerInsert = (<MenuGroup key="insert">
  {anchor}
  {hr}
  {image}
  {mathQuill}
  {desmos}
  {embed}
  {homeworkLink}
  {homeworkHint}
  {homeworkHintTarget}
  {sequenceContainer}
  {table}
  {columns}
</MenuGroup>);
export const assessmentInsert = (<MenuGroup key="insert">
  {anchor}
  {hr}
  {image}
  {mathQuill}
  {table}
</MenuGroup>);

