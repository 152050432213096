import { apiClient } from 'constants/Clients';
import StandardsTable from './LegacyTables/standards';


// ACTION TYPES
export const
    REQUEST_CLASSIFICATIONS = 'REQUEST_CLASSIFICATIONS',
    SET_CLASSIFICATION_OPTIONS = 'SET_CLASSIFICATION_OPTIONS',
    SET_CLASSIFICATIONS_ERROR = 'SET_CLASSIFICATIONS_ERROR',
    SELECT_CLASSIFICATION = 'SELECT_CLASSIFICATION',
    RESET_SELECTION = 'RESET_SELECTION'
;

// INITIAL STATE
const initialState = {
  selected: '',
  options: [],
  valid: false,
  error: null,
};

// ACTIONS
export function requestClassifications() {
  return dispatch => {
    dispatch(fetchingClassifications());
    return apiClient.getClassifications()
      .exec(function(err, response) {
        if (err) {
          dispatch(setClassificationsError(err));
        }
        else {
          console.log("RESPONSE");
          console.log(response);
          dispatch(setClassificationOptions(response));
        }
      });
  }
}

export function fetchingClassifications() {
  return {
    type: REQUEST_CLASSIFICATIONS
  }
}

export function requestStandardsForClassification(classificationId) {
  return dispatch => {
    return apiClient.getStandardsForClassification(classificationId)
      .exec((err, response) => {
        if (err) {
          dispatch(setClassificationsError(err));
        }
        else {
          dispatch(StandardsTable.Actions.changePage(response));
        }
      });
  }
}

export function setClassificationOptions(payload) {
  return {
    type: SET_CLASSIFICATION_OPTIONS,
    payload,
  }
}

export function setClassificationsError(payload) {
  return {
    type: SET_CLASSIFICATIONS_ERROR,
    payload,
  }
}

export function selectClassification(option) {
  return {
    type: SELECT_CLASSIFICATION,
    payload: option,
  }
}

export function resetSelection() {
  return {
    type: RESET_SELECTION
  }
}

// REDUCER
const classificationsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case REQUEST_CLASSIFICATIONS:
      return {
        ...state,
        valid: true,
      };
    case SET_CLASSIFICATION_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case SET_CLASSIFICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SELECT_CLASSIFICATION:
      return {
        ...state,
        selected: action.payload,
      };
    case RESET_SELECTION:
      return {
        ...state,
        selected: '',
      };
    default:
      return state;
  }
};

export default classificationsReducer;