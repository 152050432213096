import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {withClient} from "reducers/client";

import MergeElementsRow from './MergeElementsRow';

const styles = theme => ({
  root: {

  },
});

const getCombinedElements = (mainElements, mergeElements) => {
  let elements = [];
  let selection = {};
  mainElements.forEach(e => {
    elements.push({
      elementType: e.elementType,
      mainElement: e,
      mergeElement: null
    });
    selection[e.elementType] = e.id;
  });
  mergeElements.forEach(e => {
    let record = elements.find(el => el.elementType === e.elementType);
    if (!record) {
      record = {
        elementType: e.elementType,
        mainElement: null,
        mergeElement: e,
      };
      selection[e.elementType] = e.id;
      // Insert in order
      if (elements.length === 0) {
        elements.push(record);
      } else {
        let index;
        for (index = 0; index < elements.length; ++index) {
          if (e.elementType < elements[index].elementType) break;
        }
        elements.splice(index, 0, record);
      }
    } else {
      record.mergeElement = e;
    }
  });
  return {elements, selection};
};

export const makeMergeElements = options => {
  class MergeElements extends React.Component {
    static propTypes = {
      classes: PropTypes.objectOf(PropTypes.string).isRequired,
      mergeId: PropTypes.string.isRequired,
      mainElements: PropTypes.arrayOf(PropTypes.object).isRequired,
      mergeElements: PropTypes.object,
      elementType: PropTypes.string,
      selection: PropTypes.object,
      onChangePreview: PropTypes.func,
      onChangeSelection: PropTypes.func,
    };

    state = {
      elements: []
    };

    componentDidMount() {
      const {mainElements, mergeElements, mergeId} = this.props;
      if (mergeElements.isLoaded() && mergeElements.getParams() === mergeId) {
        const {elements, selection} = getCombinedElements(mainElements, mergeElements.get());
        this.setState({elements});
        const {onChangeSelection} = this.props;
        if (typeof onChangeSelection === 'function') onChangeSelection(selection);
      }
    }
    componentDidUpdate(prevProps) {
      const {mainElements, mergeElements} = this.props;
      const {mergeElements:prevElements} = prevProps;
      if (mergeElements.isLoaded() && mergeElements.hasChanged(prevElements)) {
        const {elements, selection} = getCombinedElements(mainElements, mergeElements.get());
        this.setState({elements});
        const {onChangeSelection} = this.props;
        if (typeof onChangeSelection === 'function') onChangeSelection(selection);
      }
    }

    handleClick = elementType => ev => {
      const {onChangePreview} = this.props;
      if (typeof onChangePreview === 'function') onChangePreview(elementType, ev);
    };
    handleChange = elementType => value => {
      const {selection, onChangeSelection} = this.props;
      if (typeof onChangeSelection === 'function') {
        onChangeSelection({
          [elementType]: selection[elementType] === value ? '' : value
        });
      }
    };

    render() {
      const {classes, mergeElements, elementType, selection} = this.props;
      const {elements} = this.state;

      let handleClick = this.handleClick;

      if (mergeElements && mergeElements.isLoaded()) {
        return (<div className={classes.root}>
          {elements.map(element => {
            return (<MergeElementsRow
              key={element.elementType}
              mainElementId={element.mainElement ? element.mainElement.id : ''}
              mergeElementId={element.mergeElement ? element.mergeElement.id : ''}
              elementType={element.elementType}
              isSelected={elementType === element.elementType}
              value={selection[element.elementType]}
              onChange={this.handleChange(element.elementType)}
              onClick={this.handleClick(element.elementType)}
            />);
          })}
        </div>);
      }
      return null;
    }
  }

  return withClient({
    hooks: { mergeElements: options.request }
  })(withStyles(
    styles
  )(MergeElements));
};


