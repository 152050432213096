import React, {Component} from 'react';
import { Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {renderField} from "components/Forms/fields";
import Button from '@material-ui/core/Button';
import {deleteClassification} from "../../../reducers/classifications";


class ClassificationForm extends Component {

  onSubmit = (values) => {
    const classification = this.props.classification || {};
    values.id = classification.id;
    return this.props.save(values)
  };

  deleteClicked = () => {
    const classification = this.props.classification || {};
    this.props.deleteClassification(classification.id);
    this.props.onClose();
  };

  render(){
    const { pristine, submitting, handleSubmit, classification } = this.props;
    return (
      <div>
        <h2 className="article-title">{this.props.classification ? 'Edit Classification': 'Create Classification'}</h2>
        <i className="nav-icon material-icons" onClick={this.deleteClicked} style={{position: 'absolute', top: 15, right: 15, color: '#c1c1c1', cursor: 'pointer'}}>delete</i>
        <div className="box box-default">
          <div className="box-body">
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field name="name" type="text" placeholder="Classification Name" component={renderField} label="Name" fullWidth />
              <Field name="description" type="textfield" placeholder="Classification Name" component={renderField} label="Description" fullWidth />
              <Field name="url" type="text" placeholder="Url for Classification" component={renderField} label="Url" fullWidth />
              <div>
                <br />
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>Save</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const CreateClassificationForm = reduxForm({
  form: 'createClassification',
  onSubmitSuccess: (result, dispatch, props) => {
    props.onClose()
  },
})(ClassificationForm);

const mapStateToProps = state => ({
  formData: state.form.createClassification,
  classification: state.dialogs.classifications.data,
  initialValues: state.dialogs.classifications.data,
});

const mapDispatchToProps = dispatch => ({
  saveClassification: (classification) => {
    dispatch(saveClassification(classification));
  },
  deleteClassification: (id) => dispatch(deleteClassification(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateClassificationForm);
