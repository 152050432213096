import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from "../../util/pagedTable";

const AssociatedContainersTable = PagedTable(
  'containerAssociatedContainers',
  state => apiClient.getAssociatedlessonContentContainersForlessonContentContainer(state.lessonContentContainers.selected),
  (state, action) => {
    return state;
  }
);

export default AssociatedContainersTable;