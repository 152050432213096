import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from "../../util/pagedTable";

const elementAssociatedContainersTable = PagedTable(
    'elementAssociatedContainers',
    state => apiClient.getAssociatedProblemsForElement(state.elements.selected),
    (state, action) => {
      return state;
    }
);

export default elementAssociatedContainersTable;