export const createDialogSelector = dialogId => state => {
  return state.dialog[dialogId];
};
export const createDialogIsOpenSelector = dialogId => state => {
  let dialogState = state.dialog[dialogId];
  if (dialogState) return dialogState.isOpen;
};
export const createDialogDataSelector = dialogId => state => {
  let dialogState = state.dialog[dialogId];
  if (dialogState) return dialogState.data;
};
export const createDialogStateSelector = dialogId => state => {
  let dialogState = state.dialog[dialogId];
  if (dialogState) return dialogState.state;
};
