export default class Map {
  constructor(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  asArray() {
    const array = [];
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        array.push(this[key]);
      }
    }
    return array;
  }
};

export function asArray(map) {
  const array = [];
  for (const key in map) {
    if (map.hasOwnProperty(key)) {
      array.push(map[key]);
    }
  }
  return array;
}