import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {MediaSearchView} from "views/media/MediaSearch";
import {MediaRoutes} from './media';

export const MediasRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/search`} component={MediaSearchView} />
    <Route path={`${match.path}/:mediaId`} component={MediaRoutes} />
    <Redirect to="/404" />
  </Switch>
);
