import {apiClient} from "constants/Clients";

export const gradeDetails = key => ({
  request: (gradeId) => apiClient.getGrade(gradeId),
  key,
  auto: true,
});
export const gradeStandards = key => ({
  request: (gradeId) => apiClient.getClassificationStandardForGrade(gradeId),
  key,
  auto: true,
});