import React from 'react';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {BookBreadcrumb} from "components/Breadcrumb";
import DetailsTable from 'components/Tables/DetailsTable';
import BookChaptersTable from 'components/Tables/BookChaptersTable';
import BookSectionsTable from 'components/Tables/BookSectionsTable';
import BookGlossaryItemsTable from 'components/Tables/BookGlossaryItemsTable';
import {withClient} from "reducers/client";
import {bookDetails, updateBook} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class BookDetails extends React.Component {
  state = {
    editMode: false,
    bookDetails: {},
  };

  componentDidMount() {
    const {bookDetails:bookRequest} = this.props;
    if (bookRequest.isLoaded()) {
      const bookDetails = bookRequest.get();
      this.setState({
        bookDetails: {
          'Short Name': bookDetails.name,
          'Display Name': bookDetails.displayName,
          'Color': bookDetails.metadata && bookDetails.metadata.color,
          'Created': formatDate(bookDetails.createdAt),
          'Updated': formatDate(bookDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {bookDetails:bookRequest} = this.props;
    const {bookDetails:prevRequest} = prevProps;

    if (bookRequest.isLoaded() && bookRequest.hasChanged(prevRequest)) {
      const bookDetails = bookRequest.get();
      this.setState({
        bookDetails: {
          'Short Name': bookDetails.name,
          'Display Name': bookDetails.displayName,
          'Color': bookDetails.metadata && bookDetails.metadata.color,
          'Created': formatDate(bookDetails.createdAt),
          'Updated': formatDate(bookDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      bookDetails: {
        ...this.state.bookDetails,
        [key]: value,
      }
    });
  }
  toggleEditBook() {
    const {editMode, bookDetails} = this.state;
    if (editMode) {
      const {bookDetails:bookRequest, updateBook} = this.props;
      const book = bookRequest.get();
      if (book.name !== bookDetails['Short Name'] ||
        book.displayName !== bookDetails['Display Name'] ||
        (book.metadata && book.metadata.color) !== bookDetails['Color']) {
        updateBook.sendRequest({
          id: book.id,
          name: bookDetails['Short Name'],
          displayName: bookDetails['Display Name'],
          metadata: {
            ...book.metadata,
            color: bookDetails['Color']
          }
        })
          .then(() => bookRequest.sendRequest());
      }
    }
    this.setState({editMode: !editMode});
  }
  showEditMenus() {
    this.props.history.push(`/app/books/${this.props.bookDetails.getParams()}/menus`)
  }
  showEditStylesheet() {
    this.props.history.push(`/app/books/${this.props.bookDetails.getParams()}/styles`)
  }
  openImageEditor() {
    this.props.history.push(`/app/books/${this.props.bookDetails.getParams()}/cover`)
  }
  openTitlePageEditor() {
    if(this.props.bookDetails && this.props.bookDetails.state && this.props.bookDetails.state.data && this.props.bookDetails.state.data.titlePageElementId){
      this.props.history.push(`/app/elements/${this.props.bookDetails.state.data.titlePageElementId}/editor`)
    }
  }
  render() {
    const {classes, bookDetails:bookRequest} = this.props;
    const {editMode, bookDetails} = this.state;

    if (!bookRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Book details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <BookBreadcrumb bookId={bookRequest.get().id} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit Book Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditBook()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            Book Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Short Name', value: bookDetails['Short Name']},
              {name: 'Display Name', value: bookDetails['Display Name']},
              {name: 'Color', value: bookDetails['Color']},
              {name: 'Created', value: bookDetails.Created, readOnly: true},
              {name: 'Updated', value: bookDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
          <br />
          <Button variant="contained" color="primary" onClick={() => this.showEditMenus()}>
            Menus
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.showEditStylesheet()}>
            Custom Styles
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.openImageEditor()}>
            Cover Media
          </Button>
          <Button variant="contained" color="primary" onClick={() => this.openTitlePageEditor()}>
            Title Page
          </Button>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Chapters</Typography>
          <Paper>
            <BookChaptersTable bookId={bookRequest.getParams()} />
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Sections</Typography>
          <Paper>
            <BookSectionsTable bookId={bookRequest.getParams()} />
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Glossary Items</Typography>
          <Paper>
            <BookGlossaryItemsTable bookId={bookRequest.getParams()} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const BookDetailsView = withClient({
  hooks: {
    bookDetails: bookDetails((state, props) => state.books.selected),
    updateBook: updateBook((state, props) => state.books.selected),
  },
})(withStyles(styles)(BookDetails));
