import React from "react";
import classnames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {BUTTON, ACTIVE} from "components/Prosemirror/menu/components/styles";

const styles = {
  BUTTON,
  ACTIVE
};

const Button = ({classes, prosemirror, title, active, enabled, command, children, visible}) =>
(!visible || visible(prosemirror.state)) ? (
  <button
    className={classnames({
      [classes.BUTTON]: true,
      [classes.ACTIVE]: active && active(prosemirror.state)
    })}
    title={title}
    disabled={enabled && !enabled(prosemirror.state)}
    onMouseDown={(e) => {
      e.preventDefault();
      command(prosemirror.state, prosemirror.dispatch.toProsemirror, prosemirror.view);
    }}
  >{typeof children === 'function' ? children(prosemirror) : children}</button>
) : null;

export default withStyles(styles)(withProsemirror(Button));