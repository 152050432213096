import React, {useEffect} from 'react';

import {
  makeStyles,
  TablePagination,
  TextField,
  LinearProgress,
  withStyles,
} from "@material-ui/core";

import {withClient} from "reducers/client";
import {mediaByTagsAndType} from "reducers/client/requestTypes";
import {paging, reduxTable, createPaginationSelector} from "reducers/table";
import ButtonBase from "@material-ui/core/ButtonBase";
import classnames from "classnames";

import {MediaGrid} from "components/Media/MediaGrid";
import {MediaTags} from "components/Media/MediaTags";

const styles = {
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  tagSearch: {
    flex: 'auto'
  },
  paginationRoot: {
    flex: `0 0 400px`,
  }
};

class MediaBrowserBase extends React.Component {
  state = {
    medias: [],
    totalCount: 0
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {mediaByTags} = this.props;
    if (mediaByTags.isLoaded()) this.updateState();
  }
  componentDidUpdate(prevProps) {
    const {mediaByTags} = this.props;
    const {mediaByTags:prevMediaByTags} = prevProps;
    if (mediaByTags.isLoaded() && mediaByTags.hasChanged(prevMediaByTags)) this.updateState();
  }

  updateState() {
    const {table: {pagination, actions}} = this.props;
    const {results: medias, totalCount} = this.props.mediaByTags.get();
    this.setState({ medias, totalCount });
    if (pagination.page * pagination.pageSize > totalCount) {
      actions.setPage(Math.floor(totalCount / pagination.pageSize));
    }
  }

  handleChangeTags = tags => {
    this.props.table.actions.setMetadata({tags});
  };
  handleChangePage = (ev, page) => {
    this.props.table.actions.setPage(page);
  };
  handleChangeRowsPerPage = ev => {
    this.props.table.actions.setPageSize(ev.target.value);
  };


  render() {
    const {classes, table: {pagination}, mediaByTags, onClick} = this.props;
    const {medias, totalCount} = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.tagSearch}>
            <MediaTags
              fullWidth
              value={pagination.metadata.tags}
              onChange={this.handleChangeTags}
            />
          </div>
          <TablePagination
            component="div"
            className={classes.paginationRoot}
            padding="none"
            count={totalCount}
            page={pagination.page}
            labelRowsPerPage="Images per page:"
            rowsPerPage={pagination.pageSize}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
        <div>
          {mediaByTags.isLoading() ? (
            <LinearProgress />
          ) : (
            <MediaGrid medias={medias} columns={5} onClick={onClick} />
          )}
        </div>
      </div>
    );
  }
}

const getPagination = createPaginationSelector('MediaBrowser');
const getTags = state => getPagination(state).metadata.tags;
export const MediaBrowser = reduxTable({
  table: 'MediaBrowser',
  initialState: {metadata: {tags: []}}
})(withClient({
  hooks: {
    mediaByTags: paging('MediaBrowser')(mediaByTagsAndType('IMAGE')(getTags)),
  }
})(withStyles(styles)(MediaBrowserBase)));
