export function* mapSequence(fn, list) {
  // Yield the result of fn on every item in list
  for (let i = 0; i < list.length; ++i) {
    yield fn(list[i]);
  }
}

export function* sequence(list) {
  // Yield every item in list
  for (let i = 0; i < list.length; ++i) {
    yield list[i];
  }
}

export default sequence;