import React from 'react';

import Config from 'constants/Config';

export const ReportsView = props => (
  <div
    style={{
      position: 'absolute',
      left: '64px',
      top: '60px',
      right: 0,
      bottom: '44px',
    }}
  >
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}
      src={Config.binkUrl}
    />
  </div>
)