import {PLUGIN_INTERFACE} from "components/Prosemirror/plugins/interface";
import {onDoubleClick} from "components/Prosemirror/plugins/nodeViews/util";
import {log} from "constants/Config";

let count = 0;

// https://stackoverflow.com/a/27728417
const getYoutubeId = url => {
  let m = url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/);
  return m && m[1];
};

export default class CpmEmbedView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;

    if (log.prosemirror) {
      console.group(`[PROSEMIRROR] CONSTRUCT CpmEmbedView [${this.id}] _________________________`);
      console.log('node:', node);
      console.groupEnd();
    }

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('div');
    this.dom.setAttribute('class','c3po-embed');
    this.setStyleAttributes(this.node.attrs);

    onDoubleClick(this.dom, () => {
      this.outerView.dispatch({
        external: true,
        method: 'embed',
        nodeView: {
          node: this.node,
          view: this.outerView,
          getPos: this.getPos
        }
      });
    });
  }

  selectNode() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] CpmEmbedView [${this.id}] :: selectNode()`);
    this.dom.classList.add('ProseMirror-selectednode');
  }
  deselectNode() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] CpmEmbedView [${this.id}] :: deselectNode()`);
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  setStyleAttributes(attrs) {
    console.log(attrs);
    if (this.innerDom) this.dom.removeChild(this.innerDom);

    let videoId = attrs.src && getYoutubeId(attrs.src);
    if (videoId) {
      if (log.prosemirror) {
        console.group(`[PROSEMIRROR] CpmEmbedView [${this.id}] :: setStyleAttributes() _________________________`);
        console.log('youtube:', videoId);
        console.groupEnd();
      }
      this.innerDom = document.createElement('img');
      this.innerDom.setAttribute('src',`https://img.youtube.com/vi/${videoId}/default.jpg`);
    } else if (/vimeo/i.exec(attrs.src) !== null) {
      this.innerDom = document.createElement('img');
      fetch(`https://vimeo.com/api/oembed.json?url=${attrs.src}`)
        .then(response => response.json())
        .then(json => {
          if (log.prosemirror) {
            console.group(`[PROSEMIRROR] CpmEmbedView [${this.id}] :: setStyleAttributes() _________________________`);
            console.log('vimeo:', json);
            console.groupEnd();
          }
          this.innerDom.setAttribute('src',json.thumbnail_url);
        });
    } else {
      this.innerDom = document.createElement('iframe');
      this.innerDom.setAttribute('src',attrs.src);

      if (attrs.fullscreen) {
        this.innerDom.setAttribute('allow','fullscreen');
        this.innerDom.setAttribute('allowFullscreen', '');
      }
    }

    if (attrs.width > 0) { this.innerDom.style.width = `${attrs.width}px`; }
    else { this.innerDom.style.width = '100%'; }
    if (attrs.height > 0) { this.innerDom.style.height = `${attrs.height}px`; }
    else { this.innerDom.style.height = '300px'; }
    this.dom.appendChild(this.innerDom);
  }

  update(node) {
    if (node.type.name !== 'embed') return false;
    if (log.prosemirror) {
      console.group(`[PROSEMIRROR] UPDATE CpmEmbedView [${this.id}] _________________________`);
      console.log('node:', node);
      console.groupEnd();
    }

    this.node = node;
    this.setStyleAttributes(this.node.attrs);
    return true;
  }

  destroy() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] DESTROY CpmEmbedView [${this.id}]`);
  }

  stopEvent() { return false; }
  ignoreMutation() { return true; }
}
