import React, {Component} from 'react';
import {AppBar, Tab, Tabs, CircularProgress, Paper, Snackbar} from "@material-ui/core";
import ElementStandardsTable from 'components/Tables/PagedTable/ElementStandardsTable';

import {connect} from "react-redux";
import {withClient} from "reducers/client";
import {
  assessmentProblemDetails,
  assessmentProblemElements,
  elementAddStandardToPublishedVersion
} from "reducers/client/requestTypes";
import elementTypes from "constants/ElementTypes";
import withStyles from "@material-ui/core/styles/withStyles";


import {
  selectAssessmentProblemElement
} from "reducers/assessmentProblems";
import reducer from "reducers/LegacyTables/elementAddStandards";
import classnames from "classnames";
import {AssessmentProblemBreadcrumb} from "components/Breadcrumb";
import ClassificationStandardBrowser from 'components/Browser/ClassificationStandardBrowser';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});


class AssessmentProblemStandards extends Component {

  state = {
    tabValue: 'list',
    snackbarMessage: '',
  };

  componentDidMount(){
    this.props.changeAddStandardsSearchField('title')
  }

  handleChangeTab = (e, tabValue) => {
    this.setState({tabValue})
  };
  handleAddStandard = (standards) => {
    const {elementAddStandardToPublishedVersion} = this.props;
    const standardTitles = standards.map(s => s.title).join(', ');
    this.setState({snackbarMessage: `Added ${standardTitles}`});
    Promise.all(standards.map(standard => elementAddStandardToPublishedVersion.sendRequest(standard.id)));
  };
  handleSnackbarClose = (ev, reason) => {
    if (reason === 'timeout') {
      this.setState({snackbarMessage: ''});
    }
  };

  renderTabContent = () => {
    if(this.state.tabValue === 'list'){
      return <ElementStandardsTable elementId={this.props.elementId}/>
    } else {
      const {elementAddStandardToPublishedVersion} = this.props;
      return <ClassificationStandardBrowser
        disabled={elementAddStandardToPublishedVersion.isLoading()}
        onChange={this.handleAddStandard}
      />
    }
  };

  render(){
    const elementType = this.props.match.params.elementType || elementTypes.assessmentproblem.name;
    const assessmentProblemId = this.props.match.params.assessmentProblemID;
    let content = <CircularProgress/>;
    const {elementId, assessmentProblemElements, selectAssessmentProblemElement, classes} = this.props;
    if (assessmentProblemElements.isLoaded()) {
      let detailElement = assessmentProblemElements.get().find(ele => ele.elementType === elementType);
      selectAssessmentProblemElement(detailElement.id)
    }
    if(elementId){
      content = this.renderTabContent()
    }

    return (
      <div className={classes.root}>
        <div className={classnames(classes.navBox, classes.flexColumns)}>
          <AssessmentProblemBreadcrumb assessmentProblemId={assessmentProblemId} />
        </div>
        <div className={classes.section}>
          <Paper>
            <AppBar position={'static'}>
              <Tabs value={this.state.tabValue} onChange={this.handleChangeTab}>
                <Tab label="List" value={"list"} />
                <Tab label="Add" value={"add"} />
              </Tabs>
            </AppBar>
            {content}
          </Paper>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(this.state.snackbarMessage)}
          onClose={this.handleSnackbarClose}
          key={this.state.snackbarMessage}
          message={this.state.snackbarMessage}
          autoHideDuration={5000}
        />
      </div>
    )
  }
}

const { Selectors, Actions } = reducer;

const mapStateToProps = () => {
  return (state, props) => ({
    elementId: state.assessmentProblems.selectedAssessmentProblemElement
  });
};

const mapDispatchToProps = dispatch => ({
  selectAssessmentProblemElement: (payload) => dispatch(selectAssessmentProblemElement(payload)),
  changeAddStandardsSearchField: (payload) => dispatch(Actions.changeSearchField(payload))
});


const getAssessmentProblemId = (state, props) => state.assessmentProblems.selected;
const getAssessmentProblemElementId = (state, props) => state.assessmentProblems.selectedAssessmentProblemElement;
export const AssessmentProblemStandardsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withClient({
  hooks: {
    assessmentProblemDetails: assessmentProblemDetails(getAssessmentProblemId),
    assessmentProblemElements: assessmentProblemElements(getAssessmentProblemId),
    elementAddStandardToPublishedVersion: elementAddStandardToPublishedVersion(getAssessmentProblemElementId)
  }
})(withStyles(styles)(AssessmentProblemStandards)));

