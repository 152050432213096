import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Prompt} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";

import {withClient} from "reducers/client";
import {
  DEFAULT_KEY,
  updateMedia,
  createMediaAltText,
  updateMediaAltText,
  deleteMediaAltText,
  addMediaTags,
  removeMediaTags
} from "reducers/client/requestTypes";

import ElementMediasTableRow from './ElementMediasTableRow';
import {AllLanguages, AllLanguageKeys} from "constants/Language";

const styles = theme => ({
  itemButton: {},
  select: {
    margin: `0 ${theme.spacing(1)}px`
  }
});

const mediaHasChanged = (src, dest) => {
  // Compare two media states and return true if there are any differences
  if (src.presentationOnly !== dest.presentationOnly) return true;
  if (src.mediaAltTexts.length !== dest.mediaAltTexts.length) return true;
  const srcAltTexts = src.mediaAltTexts;
  const destAltTexts = dest.mediaAltTexts;
  // Compare each alt text for both medias. Order does not matter.
  for (let altTextIndex = 0; altTextIndex < srcAltTexts.length; ++altTextIndex) {
    const altText = srcAltTexts[altTextIndex];
    let complementaryAltText = destAltTexts[altTextIndex];
    if (altText.languageTag !== complementaryAltText.languageTag) {
      complementaryAltText = destAltTexts.find(t => t.languageTag === altText.languageTag);
    }
    if (altText.altText !== complementaryAltText.altText) return true;
  }

  if (src.tags.length !== dest.tags.length) return true;
  // Compare each tag for both medias. Order does not matter.
  for (let tagIndex = 0; tagIndex < src.tags.length; ++tagIndex) {
    if (!dest.tags.some(t => t.tag === src.tags[tagIndex].tag)) return true;
    if (!src.tags.some(t => t.tag === dest.tags[tagIndex].tag)) return true;
  }
  return false;
};
const getMediaRequiredActions = (src, dest) => {
  let requiredActions = [];
  if (src.presentationOnly !== dest.presentationOnly) requiredActions.push({type:'media',action:'UPDATE',payload:src});
  AllLanguages.forEach(language => {
    let srcText = src.mediaAltTexts.find(t => t.languageTag === language.value);
    let destText = dest.mediaAltTexts.find(t => t.languageTag === language.value);
    if (srcText && !destText) requiredActions.push({type:'altText',action:'CREATE',payload: srcText,mediaId:src.id});
    if (!srcText && destText) requiredActions.push({type:'altText',action:'DELETE',payload: destText.id,mediaId:src.id});
    if (srcText && destText) {
      if (srcText.altText !== destText.altText) {
        requiredActions.push({type:'altText',action:'UPDATE',payload: srcText,mediaId:src.id});
      }
    }
  });
  let createTags = src.tags.filter(tag => !tag.id).map(tag => tag.tag);
  if (createTags.length > 0) requiredActions.push({type:'tag',action:'CREATE',payload: createTags,mediaId:src.id});
  let deleteTags = dest.tags.filter(tag => !src.tags.some(t => t.id === tag.id)).map(tag => tag.tag);
  if (deleteTags.length > 0) requiredActions.push({type:'tag',action:'DELETE',payload: deleteTags,mediaId:src.id});
  return requiredActions;
};

class ElementMediasTableBase extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    medias: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      publicUrl: PropTypes.string,
      presentationOnly: PropTypes.bool,
      mediaAltTexts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        languageTag: PropTypes.oneOf(AllLanguageKeys),
        altText: PropTypes.string,
      })),
      tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        tag: PropTypes.string
      }))
    })).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    waiting: PropTypes.bool,
  };
  static defaultProps = {
    onChange: () => {},
    disabled: false,
    waiting: false
  };

  state = {
    medias: [],
    language: AllLanguageKeys[0]
  };

  componentDidMount() {
    const {classes, medias} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    this.updateStateFromProps(medias);
  }
  componentDidUpdate(prevProps) {
    const {medias} = this.props;
    const {medias:prevMedias} = prevProps;
    if (medias !== prevMedias) {
      this.updateStateFromProps(medias);
    }
  }

  updateStateFromProps(mediaData) {
    const medias = mediaData.map(m => ({
      id: m.id,
      publicUrl: m.publicUrl,
      fileName: m.fileName,
      presentationOnly: m.presentationOnly,
      mediaAltTexts: m.mediaAltTexts.map(t => ({
        id: t.id,
        languageTag: t.languageTag,
        altText: t.altText
      })),
      tags: m.tags.map(t => ({
        id: t.id,
        tag: t.tag
      }))
    }));
    this.setState({ medias });
  }

  isWaiting() {
    const {
      updateMedia,
      createMediaAltText,
      updateMediaAltText,
      deleteMediaAltText,
      addMediaTags,
      removeMediaTags,
      waiting
    } = this.props;
    return waiting ||
      updateMedia.isLoading() ||
      createMediaAltText.isLoading() ||
      updateMediaAltText.isLoading() ||
      deleteMediaAltText.isLoading() ||
      addMediaTags.isLoading() ||
      removeMediaTags.isLoading();
  }
  pendingChanges() {
    const {medias} = this.state;
    const originalMedias = this.props.medias;
    if (!medias || !originalMedias) return false;
    if (originalMedias.length !== medias.length) return false;
    for (let i = 0; i < medias.length; ++i) {
      if (mediaHasChanged(medias[i], originalMedias[i])) {
        return true;
      }
    }
    return false;
  }

  handleChange = (index, changes) => {
    const {medias} = this.state;
    const updatedMedias = medias.slice(0,index).concat([{
      ...medias[index],
      ...changes,
    }]).concat(medias.slice(index + 1));
    this.setState({ medias: updatedMedias });
  };

  handleClickSave = () => {
    const {medias} = this.state;
    const originalMedias = this.props.medias;
    if (!medias || !originalMedias) return false;
    let requiredActions = [];
    for (let i = 0; i < medias.length; ++i) {
      requiredActions.push(...getMediaRequiredActions(medias[i], originalMedias[i]));
    }
    this.commitActionsToServer(requiredActions).then(this.props.onChange);
  };
  handleChangeLanguage = event => {
    this.setState({language: event.target.value})
  };

  async commitActionsToServer(actions) {
    const {
      updateMedia,
      createMediaAltText,
      updateMediaAltText,
      deleteMediaAltText,
      addMediaTags,
      removeMediaTags,
    } = this.props;
    for (let i = 0; i < actions.length; ++i) {
      const action = actions[i];
      console.log('action '+i, action);
      if (action.type === 'media') {
        await updateMedia.sendRequest(action.payload);
      } else if (action.type === 'altText') {
        switch (action.action) {
          case 'CREATE': await createMediaAltText.sendRequest(action.mediaId, action.payload); break;
          case 'UPDATE': await updateMediaAltText.sendRequest(action.mediaId, action.payload); break;
          case 'DELETE': await deleteMediaAltText.sendRequest(action.mediaId, action.payload); break;
        }
      } else if (action.type === 'tag') {
        switch (action.action) {
          case 'CREATE': await addMediaTags.sendRequest(action.mediaId, action.payload); break;
          case 'DELETE': await removeMediaTags.sendRequest(action.mediaId, action.payload); break;
        }
      }
    }
  }

  render() {
    const {medias, language} = this.state;
    if (!medias) return false;
    const {classes, disabled} = this.props;
    const waiting = this.isWaiting();
    return (<Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell>Image</TableCell>
          <TableCell>
            Alt Text
            <Select value={language} onChange={this.handleChangeLanguage} className={classes.select}>
              {AllLanguages.map(({name, value}) => <MenuItem key={value} value={value}>{name}</MenuItem>)}
            </Select>
          </TableCell>
          <TableCell>Presentation Only</TableCell>
          <TableCell>Tags</TableCell>
        </TableRow>
        {waiting && (<TableRow style={{height:0}}>
          <TableCell colSpan={4} padding="none"><LinearProgress/></TableCell>
        </TableRow>)}
      </TableHead>
      <TableBody>
        {medias.map((media,index) => (
          <ElementMediasTableRow
            key={media.id}
            media={media}
            language={language}
            index={index}
            disabled={Boolean(waiting || disabled)}
            onChange={this.handleChange}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={4} style={{textAlign:"center"}}>
            <Prompt
              when={this.pendingChanges()}
              message="You have unsaved changes on this page. Are you sure you want to leave? Your changes will not be saved."
            />
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleClickSave}
              disabled={disabled || this.isWaiting() || !this.pendingChanges()}>
              Save
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>);
  }
}

export const ElementMediasTable = withClient({
  hooks: {
    updateMedia: updateMedia(DEFAULT_KEY),
    createMediaAltText: createMediaAltText(),
    updateMediaAltText: updateMediaAltText(),
    deleteMediaAltText: deleteMediaAltText(),
    addMediaTags: addMediaTags(),
    removeMediaTags: removeMediaTags(),
  },
})(withStyles(styles)(withRouter(ElementMediasTableBase)));
