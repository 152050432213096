import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
import {REQUEST_SUCCESS} from "../../client/actions";
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {ASSESSMENT_PROBLEM_SELECT_ELEMENT} from "../../assessmentProblems";

const ElementStandardsTable = PagedTable(
  'elementStandards',
  (state, props) => apiClient.getClassificationStandardsForElementPublishedVersion(props.elementId || state.elements.selected),
  (state, action) => {
    if (action.type === "DIALOG_ELEMENT_STANDARDS_OPEN") {
      return invalidateState(state);
    }
    if (action.type === "ASSESSMENT_PROBLEM_SELECT_ELEMENT") {
      return invalidateState(state);
    }

    // need to invalidate upon new standard creation
    if (action.type === REQUEST_SUCCESS) {
      let requestType = action.metadata && action.metadata.name;
      if (requestType === "elementCreateStandard" || requestType === "updateStandard" || requestType === 'elementRemoveStandardFromPublishedVersion' || requestType === 'elementAddStandardToPublishedVersion') {
        return invalidateState(state);
      }
    }
    return state
  },
);

export default ElementStandardsTable;