import IconBook from '@material-ui/icons/Book';
import IconPencil from '@material-ui/icons/Create';
import IconWrench from '@material-ui/icons/Build';
import IconFullscreen from '@material-ui/icons/Fullscreen';
import IconCompare from '@material-ui/icons/Compare';
import IconHistory from '@material-ui/icons/History';
import IconCode from '@material-ui/icons/Code';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

export const displayModes = {
  DISPLAY_EBOOKS: {
    id: 'DISPLAY_EBOOKS',
    name: 'eBooks',
    className: 'displayEbooks',
    icon: IconBook,
  },
  DISPLAY_HOMEWORK: {
    id: 'DISPLAY_HOMEWORK',
    name: 'Homework Help',
    className: 'displayHomework',
    icon: IconPencil,
  },
  DISPLAY_ALL: {
    id: 'DISPLAY_ALL',
    name: 'Debug',
    className: 'displayAll',
    icon: IconWrench,
  }
};

export const sidebarModes = {
  NONE: {
    id: 'NONE',
    name: 'Fullscreen Editor',
    icon: IconFullscreen,
    disableWhenDirty: false,
  },
  COMPARE: {
    id: 'COMPARE',
    name: 'Comparison View',
    icon: IconCompare,
    disableWhenDirty: false,
  },
  MARKUP: {
    id: 'MARKUP',
    name: 'Markup',
    icon: IconCode,
    disableWhenDirty: false,
  },
  WCAG: {
    id: 'WCAG',
    name: 'WCAG 2.0 Compliance Status',
    icon: IconCheckCircle,
    disableWhenDirty: false,
    badgeContent: 'wcag'
  },
  // HISTORY: {
  //   id: 'HISTORY',
  //   name: 'History',
  //   icon: IconHistory,
  //   disableWhenDirty: true,
  // },
  // COMPLIANCE: {
  //   id: 'COMPLIANCE',
  //   name: 'Approval Status',
  //   icon: IconCheckCircle,
  //   disableWhenDirty: true,
  // },
};