import React from 'react';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import {ElementBreadcrumb} from 'components/Breadcrumb';
import ElementVersionsTable from 'components/Tables/PagedTable/ElementVersionsTable';

import elementVersions from "reducers/LegacyTables/elementVersions";
import {withClient} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";
import ContentPreview from "components/Editor/Views/ContentPreview";
import {renderHomework} from "components/Prosemirror/render";

const gutter = 30;

const styles = theme => ({
  root: {
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${gutter}px`,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`,
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: `${gutter}px`
  },
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${550}px`
  }
});

class ElementVersions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initialSet: false };
  }

  componentDidMount() {
    const {setTableSort} = this.props;
    setTableSort('createdAt');
    this.tryGetDetails();
  }
  componentDidUpdate() {
    this.tryGetDetails();
  }

  tryGetDetails() {
    const {elementDetails:elementRequest} = this.props;
    if (elementRequest.isLoaded() && !this.state.initialSet) {
      const {setSelectedContentVersion} = this.props;
      setSelectedContentVersion(elementRequest.get().currentVersionId);
      this.setState({ initialSet: true });
    }
  }

  showElementEditor = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/editor`);
    }
  };

  getCurrentContent() {
    const {contentVersions} = this.props;
    const {selected} = this.props.tableState;

    if (selected && selected.length && contentVersions && contentVersions.length) {
      const selectedVersion = contentVersions.find(v => v.id === selected[0]);
      if (selectedVersion && selectedVersion.html) {
        return {__html: selectedVersion.html };
      }
    }
    return {__html: ''};
  }
  getSelectedVersionId() {
    const {selected} = this.props.tableState;
    if (selected) return selected[0];
  }

  render() {
    const {elementDetails:elementRequest, classes} = this.props;

    if (!elementRequest.isLoaded()) {
      return <div />;
    }

    const elementDetails = elementRequest.get();
    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <Tooltip title="Back">
            <Fab color="primary" className={classes.button}>
              <ArrowBack onClick={this.showElementEditor} />
            </Fab>
          </Tooltip>
          <ElementVersionsTable elementId={elementDetails.id} />
        </div>
        <h2 className="article-title">Element '{elementDetails.title}' versions</h2>
        <div>
          <ElementBreadcrumb elementId={elementDetails.id} />
        </div>
        <div className={classes.versionsContainer}>
          {/*<div className={classes.previewPane} dangerouslySetInnerHTML={this.getCurrentContent()} />*/}
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={renderHomework} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contentVersions: elementVersions.Selectors.getPageContent(state),
  tableState: elementVersions.Selectors.getState(state)
});

const mapDispatchToProps = dispatch => ({
  setSelectedContentVersion: versionID => dispatch(elementVersions.Actions.setSelection(versionID)),
  setTableSort: column => dispatch(elementVersions.Actions.changeSort(column)),
});

export const ElementVersionsView = connect(mapStateToProps, mapDispatchToProps)(
  withClient({
    hooks: {
      elementDetails: elementDetails((state, props) => state.elements.selected),
    }
  })(withStyles(styles)(ElementVersions)));
