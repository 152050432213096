import React from 'react';
import PropTypes from "prop-types";

import {makeMergeElements} from "./MergeElements";
import {
  aliasFor,
  lessonContentContainerElements,
  tabElements,
} from "reducers/client/requestTypes";

const MergeLessonContentContainerElements = makeMergeElements({
  request: aliasFor('lessonContentContainerElements')(lessonContentContainerElements((state, props) => props.mergeId)),
});
MergeLessonContentContainerElements.propTypes = {
  mergeId: PropTypes.string.isRequired
};
MergeLessonContentContainerElements.displayName = 'MergeLessonContentContainerElements';

const MergeTabElements = makeMergeElements({
  request: aliasFor('tabElements')(tabElements((state, props) => props.mergeId)),
});
MergeTabElements.propTypes = {
  mergeId: PropTypes.string.isRequired
};
MergeTabElements.displayName = 'MergeTabElements';

export {
  MergeLessonContentContainerElements,
  MergeTabElements
}