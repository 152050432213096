import React from "react";

import MenuGroup from '../components/MenuGroup';
import MenuSelect from '../components/MenuSelect';

import schema from '../../schema';
import {ALIGNMENTS} from "../table";
import {selectionInside} from '../util';
import {setColumnAlignment, getColumnAlignment, removeColumnSet} from "../../commands/column";
import MenuButton from "components/Prosemirror/menu/components/MenuButton";
import icons from "components/Prosemirror/icons";

export default (<MenuGroup key="columns" visible={selectionInside(schema.nodes.columnSet)}>
  <MenuSelect
    style={{fontSize: `${110}%`}}
    columns={3}
    command={alignment => setColumnAlignment(alignment)}
    selected={getColumnAlignment}
    options={ALIGNMENTS}
    title="Column Alignment"
  />
  <MenuButton
    command={removeColumnSet}
    title="Convert to Single Column">
    {icons.ban}
  </MenuButton>
</MenuGroup>);
