import React from 'react';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Tabs, Tab, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import {setEditorDisplayMode} from 'reducers/Prosemirror/index';
import {displayModes} from "constants/Prosemirror";
import {asArray} from "constants/util/map";

const styles = theme => ({
  select: {
    marginLeft: theme.spacing(1),
    minWidth: 160,
  }
});

const ProsemirrorDisplayModeToggle = ({classes, displayMode, setEditorDisplayMode, ...other}) => (
  <div {...other}>
    View:
    <Select
      className={classes.select}
      value={displayMode}
      onChange={(event) => setEditorDisplayMode(event.target.value)}
    >
      {asArray(displayModes).map(m => {
        const IconComponent = m.icon;
        return (
          <MenuItem
            key={m.id}
            value={m.id}
          >
            <IconComponent /> {m.name}
          </MenuItem>
        );
      })}
    </Select>
  </div>
);

const mapStateToProps = state => ({ displayMode: state.prosemirror.displayMode });
const mapDispatchToProps = dispatch => ({ setEditorDisplayMode: mode => dispatch(setEditorDisplayMode(mode)) });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProsemirrorDisplayModeToggle));