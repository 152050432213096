import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const ASSESSMENT_GROUP_SELECT = 'ASSESSMENT_GROUP_SELECT';
export const ASSESSMENT_GROUP_LIST_FOR_CHAPTER = 'ASSESSMENT_GROUP_LIST_FOR_CHAPTER';

// CHILD REDUCERS
const listByChapter = MultiRequest(ASSESSMENT_GROUP_LIST_FOR_CHAPTER);

// ACTION CREATORS
export function selectAssessmentGroup(assessmentGroupID) {
  return {type: ASSESSMENT_GROUP_SELECT, payload: assessmentGroupID};
}

export function requestAssessmentGroupListForChapter(chapterId) {
  const req = apiClient.getChapterAssessmentGroups(chapterId);
  req.limit(9999);
  return listByChapter.sendRequest(req, {key: chapterId});
}

// SELECTORS
const getChapterAssessmentGroupListRequests = state => state.assessmentGroups.listByChapter;
export const makeChapterAssessmentGroupListSelector = chapterIdResolver =>
  makeRequestSelector(chapterIdResolver, getChapterAssessmentGroupListRequests);

export default combineReducers({
  selected: (state = null, action) => (action.type === ASSESSMENT_GROUP_SELECT ? action.payload : state),
  listByChapter
});
