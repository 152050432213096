// ACTION TYPES
export const REGISTER_REQUEST = '@@CLIENT/REGISTER_REQUEST';
export const SEND_REQUEST = '@@CLIENT/SEND_REQUEST';
export const CLEAR_REQUEST = '@@CLIENT/CLEAR_REQUEST';
export const REQUEST_SUCCESS = '@@CLIENT/REQUEST_SUCCESS';
export const REQUEST_ERROR = '@@CLIENT/REQUEST_ERROR';

export const registerRequest = name => ({type: REGISTER_REQUEST, payload: {name}});

export const sendRequest = (name, transform, clientRequest, params) => (dispatch, getState) => new Promise((resolve, reject) => {
  // console.log('sendRequest()', name, transform, clientRequest, params);
  dispatch({ type: SEND_REQUEST, metadata: {name, params} });

  clientRequest.exec((err, results, response) => {
    if (err) {
      const payload = transform.error(err, results, response);
      dispatch({ type: REQUEST_ERROR, metadata: {name, params}, payload });
      reject(payload);
    } else {
      const payload = transform.response(err, results, response);
      dispatch({ type: REQUEST_SUCCESS, metadata: {name, params}, payload });
      resolve(payload);
    }
  });
});

export const clearRequest = (name, params) => ({ type: CLEAR_REQUEST, metadata: {name, params}});
