import { combineReducers } from 'redux';

import { apiClient } from '../constants/Clients';
import ClientRequest from './util/clientRequest';
import MultiRequest, { makeRequestSelector } from './util/multiRequest';

// Action Types
export const STANDARD_SELECT = 'STANDARD_SELECT';
export const STANDARD_DETAILS = 'STANDARD_DETAILS';
export const STANDARD_SAVE = 'STANDARD_SAVE';

// Child Reducers
const details = MultiRequest(STANDARD_DETAILS);
const save = ClientRequest(STANDARD_SAVE);

// Action Creators
export function selectStandard(standardId) {
  return {type: STANDARD_SELECT, payload: standardId};
}

export function requestStandardDetails(standardId) {
  return details.sendRequest(apiClient.getClassificationStandard(standardId), { key: standardId });
}

export function saveStandard(data){
  let req;
  // If there is an ID this is an update.  Else create a new one
  if (data.id) { req = apiClient.updateClassificationStandard(data); }
  else { req = apiClient.createStandardForClassification(data.classificationId, data); }
  return save.sendRequest(req);
}

// Selectors
const getSelected = state => state.standards.selected;
const getStandardDetailRequests = state => state.standards.details;
export const getSelectedStandard = makeRequestSelector(getSelected, getStandardDetailRequests);
export const makeStandardSelector = standardIdResolver =>
  makeRequestSelector(standardIdResolver, getStandardDetailRequests);

export default combineReducers({
  selected: (state = null, action) => (action.type === STANDARD_SELECT ? action.payload : state),
  details,
});