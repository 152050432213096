import React from 'react';

import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core/styles';

import {formatDate} from "constants/Date";
import {CategoryBreadcrumb} from "components/Breadcrumb";
import DetailsTable from 'components/Tables/DetailsTable';
import CategoryBooksTable from 'components/Tables/CategoryBooksTable';
import {withClient} from "reducers/client";
import {categoryDetails, updateCategory} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  }
});

class CategoryDetails extends React.Component {
  state = {
    editMode: false,
    categoryDetails: {},
  };

  componentDidMount() {
    const {categoryDetails:categoryRequest} = this.props;
    if (categoryRequest.isLoaded()) {
      const categoryDetails = categoryRequest.get();
      this.setState({
        categoryDetails: {
          'Short Name': categoryDetails.shortName,
          'Display Name': categoryDetails.displayName,
          'Description': categoryDetails.description,
          'Created': formatDate(categoryDetails.createdAt),
          'Updated': formatDate(categoryDetails.updatedAt)
        }
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {categoryDetails:categoryRequest} = this.props;
    const {categoryDetails:prevRequest} = prevProps;

    if (categoryRequest.isLoaded() && categoryRequest.hasChanged(prevRequest)) {
      const categoryDetails = categoryRequest.get();
      this.setState({
        categoryDetails: {
          'Short Name': categoryDetails.shortName,
          'Display Name': categoryDetails.displayName,
          'Description': categoryDetails.description,
          'Created': formatDate(categoryDetails.createdAt),
          'Updated': formatDate(categoryDetails.updatedAt)
        }
      })
    }
  }

  handleDetailsChange(key, value) {
    console.log(this.state, key, value);

    this.setState({
      categoryDetails: {
        ...this.state.categoryDetails,
        [key]: value,
      }
    });
  }
  toggleEditCategory() {
    const {editMode, categoryDetails} = this.state;
    if (editMode) {
      const {categoryDetails:categoryRequest, updateCategory} = this.props;
      if (categoryRequest.get().shortName !== categoryDetails['Short Name'] ||
        categoryRequest.get().displayName !== categoryDetails['Display Name'] ||
        categoryRequest.get().description !== categoryDetails.Description) {
        updateCategory.sendRequest({
          id: categoryRequest.get().id,
          shortName: categoryDetails['Short Name'],
          displayName: categoryDetails['Display Name'],
          description: categoryDetails.Description,
        })
          .then(() => categoryRequest.sendRequest());
      }
    }
    this.setState({editMode: !editMode});
  }

  render() {
    const {classes, categoryDetails:categoryRequest} = this.props;
    const {editMode, categoryDetails} = this.state;

    if (!categoryRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Category details...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <CategoryBreadcrumb categoryId={categoryRequest.getParams()} />
          <Typography variant="h3" gutterBottom>
            <Tooltip title={editMode ? "Save Changes" : "Edit Category Details"}>
              <Fab color="primary" style={{'marginRight':'20px'}} onClick={() => this.toggleEditCategory()}>
                {editMode ? <IconSave /> : <IconEdit />}
              </Fab>
            </Tooltip>
            Category Information
          </Typography>
          <Paper>
            <DetailsTable editMode={editMode} onChange={(key, value) => this.handleDetailsChange(key, value)} fields={[
              {name: 'Short Name', value: categoryDetails['Short Name']},
              {name: 'Display Name', value: categoryDetails['Display Name']},
              {name: 'Description', value: categoryDetails.Description},
              {name: 'Created', value: categoryDetails.Created, readOnly: true},
              {name: 'Updated', value: categoryDetails.Updated, readOnly: true},
            ]}/>
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>Books</Typography>
          <Paper>
            <CategoryBooksTable categoryId={categoryRequest.getParams()} />
          </Paper>
        </div>
      </div>
    );
  }
}

export const CategoryDetailsView = withClient({
  hooks: {
    categoryDetails: categoryDetails((state, props) => state.categories.selected),
    updateCategory: updateCategory((state, props) => state.categories.selected),
  },
})(withStyles(styles)(CategoryDetails));
