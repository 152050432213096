import {selectionInside} from '../menu/util';
import {findAncestor, findNodesInRange, matchTypes} from "../util";
import schema from '../schema';
import {TextSelection} from "prosemirror-state";
import {Slice} from 'prosemirror-model';

const isColumn = matchTypes(schema.nodes.column);
export const getColumnAlignment = state => {
  const $column = findAncestor(state.selection.$anchor, isColumn);
  if ($column) {
    const {alignment, vAlign} = $column.nodeAfter.attrs;
    return `${alignment}/${vAlign}`;
  }
  return '';
};

export const setColumnAlignment = options => {
  const [alignment, vAlign] = options.split('/');
  return (state, dispatch) => {
    if (!selectionInside(schema.nodes.columnSet)) return false;
    const {tr,selection} = state;
    if (dispatch) {
      const columns = findNodesInRange(selection.$anchor, selection.$head, isColumn);
      columns.forEach($column => {
        tr.setNodeMarkup($column.pos, null, {
          ...$column.nodeAfter.attrs,
          alignment,
          vAlign,
        });
      });
      dispatch(tr);
    }
    return true;
  };
};

const isColumnSet = matchTypes(schema.nodes.columnSet);
export const removeColumnSet = (state, dispatch) => {
  if (!selectionInside(schema.nodes.columnSet)) return false;
  const {tr,selection} = state;
  const $set = findAncestor(selection.$anchor, isColumnSet);
  if ($set) {
    let {anchor, head} = state.selection;
    --anchor; --head; // Offset selection for the Column Set itself
    let contents;
    $set.nodeAfter.forEach((node, offset, index) => {
      // Grab contents from inside column
      if (contents) contents = contents.append(node.content);
      else contents = node.content;
      // Offset selection so that it is preserved
      if ($set.pos + offset < anchor) --anchor;
      if ($set.pos + offset < head) -- head;
    });
    const finalContents = new Slice(contents,0,0);
    const above = $set.parent;
    const index = $set.index();
    if (!above.canReplace(index,index,finalContents)) return false;
    const from = $set.pos;
    const to = $set.pos + $set.nodeAfter.nodeSize;
    if (dispatch) {
      let tr = state.tr.replace(from, to, finalContents);
      tr.setSelection(TextSelection.create(tr.doc, anchor, head));
      dispatch(tr.scrollIntoView());
    }
  }
  return true;
};
