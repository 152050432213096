export const BUTTON = {
  width: `${32}px`,
  background: 'transparent',
  border: 'none',
  'font-size': 'inherit',
  cursor: 'pointer',
  color: '#fff',
  'border-radius': 0,
  padding: `5px 0`,
  textAlign: 'center',

  '&:hover': {
    color: '#666',
    background: '#fff'
  },
  '&:disabled': {
    color: '#fff',
    opacity: 0.4,
    background: 'transparent',
    cursor: 'default'
  }
};
export const ACTIVE = {
  opacity: 1,
  color: '#ddd',
  'box-shadow': `inset 0 0 ${3}px ${2}px #fff`
};
