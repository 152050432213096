import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import DesmosDialogForm from "./form";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {reduxDialog} from "reducers/dialog";

const selector = formValueSelector('embedDialog');

class EmbedDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };

  handleSubmit = () => {
    const { prosemirror, formData, dialog: {data} } = this.props;

    if (data && data.pos) {
      prosemirror.updateNodeAttrs(formData, data.pos);
    } else {
      prosemirror.insertNode('embed', formData);
    }

    this.handleClose();
  };

  render() {
    const { dialog: {isOpen, data} } = this.props;

    let initialValues = {};
    if (data && data.attrs) {
      initialValues.src = data.attrs.src;
      if (data.attrs.width > 0) initialValues.width = data.attrs.width;
      if (data.attrs.height > 0) initialValues.height = data.attrs.height;
      initialValues.fullscreen = data.attrs.fullscreen;
    } else {
      initialValues.fullscreen = true;
    }

    return (<Dialog open={isOpen} disableRestoreFocus>
      <DialogTitle>Embedded Content Properties</DialogTitle>
      <DialogContent>
        <DesmosDialogForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions>
        <Button onClick={e => this.handleClose(e)} color="primary">
          Cancel
        </Button>
        <Button onClick={e => this.handleSubmit(e)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = (state, props) => ({
  formData: selector(state, 'src', 'width', 'height', 'fullscreen'),
});


export default connect(
  mapStateToProps
)(reduxDialog({
  dialog: 'prosemirror-embed'
})(withProsemirror(EmbedDialog)));
