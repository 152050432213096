import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import ColumnLayoutSelect from './LayoutSelect';

import {reduxDialog} from "reducers/dialog";

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

class ColumnsDialog extends React.Component {
  handleClose() {
    const {dialog: {actions}} = this.props;
    actions.close();
  }
  handleSubmit() {
    const {prosemirror, dialog: {actions, data, state: {selected}}} = this.props;
    if (data != null) {
      prosemirror.updateColumns(selected, data.pos);
    } else {
      prosemirror.insertColumns(selected);
    }
    actions.close();
  }
  handleChangeLayout(ev, layoutID) {
    const {dialog: {actions, state: {selected}}} = this.props;
    if (layoutID === selected) {
      actions.setState({selected: null});
    } else {
      actions.setState({selected: layoutID});
    }
  }

  render() {
    const { dialog: {isOpen, state: {selected}} } = this.props;

    let actions;
    let content;

    actions = (
      <DialogActions>
        <Button onClick={e => this.handleClose(e)}>Cancel</Button>
        <Button color="primary" onClick={e => this.handleSubmit(e)}>Submit</Button>
      </DialogActions>);
    content = (<DialogContent>
      <ColumnLayoutSelect selected={selected} onChange={(ev, layoutID) => this.handleChangeLayout(ev, layoutID)} />
    </DialogContent>);

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
      <DialogTitle>Select Column Layout</DialogTitle>
      {content}
      {actions}
    </Dialog>);
  }
}



export default reduxDialog({
  dialog: 'prosemirror-columns',
  initialState: {selected: null}
})(withProsemirror(ColumnsDialog));
