import React from 'react';
import PropTypes from 'prop-types';

import {withClient} from "reducers/client";
import {contentVersionMedias} from "reducers/client/requestTypes";

import {ElementMediasTable} from 'components/Media/ElementMediasTable';

class ContentVersionMediasTable extends React.Component {
  static propTypes = {
    contentVersionMedias: PropTypes.object.isRequired,

    contentVersionId: PropTypes.string.isRequired,
  };

  state = { medias: [] };

  componentDidMount() {
    const {contentVersionMedias} = this.props;
    if (contentVersionMedias.isLoaded()) {
      this.setState({medias: contentVersionMedias.get()});
    }
  }
  componentDidUpdate(prevProps) {
    const {contentVersionMedias} = this.props;
    const {contentVersionMedias:prevMedias} = prevProps;
    if (contentVersionMedias.isLoaded() && contentVersionMedias.hasChanged(prevMedias)) {
      this.setState({medias: contentVersionMedias.get()});
    }
  }

  handleChange = () => {
    const {contentVersionMedias} = this.props;
    contentVersionMedias.sendRequest();
  };

  render() {
    const {medias} = this.state;
    if (!medias) return false;
    const {contentVersionMedias} = this.props;
    return (
      <ElementMediasTable
        medias={medias}
        waiting={contentVersionMedias.isLoading()}
        onChange={this.handleChange}
      />
    );
  }
}

export default withClient({
  hooks: {
    contentVersionMedias: contentVersionMedias((state, props) => props.contentVersionId),
  },
})(ContentVersionMediasTable);
