import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from 'classnames';

import * as Dialogs from 'components/Dialogs/Prosemirror';
import ProsemirrorSnackbar from 'components/Prosemirror/components/ProsemirrorSnackbar';

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {log} from "constants/Config";

import * as nodeStyles from '../styles/nodes.css';
import * as prosemirrorStyles from '../styles/prosemirror.css';
import * as ebookStyles from '../styles/ebooks.css';

const styles = theme => ({
  elementEditor: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#f8f8f8',
    minHeight: 0,
  },
  editorContent: {
    overflowY: 'scroll',
    flex: '1',
    backgroundColor: '#ffffff',
  },

  errorBlock: {
    margin: `${10}px 0`,
    borderStyle: 'solid',
    borderWidth: `${1}px`,
    borderColor: theme.palette.error.main,
    borderRadius: `${10}px`,
    padding: `${10}px`,
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  errorDetail: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  errorTitle: {
    margin: `${4}px`
  },
  errorMessage: {
    margin: `${4}px`
  },
});

class ProsemirrorEditor extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contentClassName: PropTypes.string,
    prosemirror: PropTypes.object.isRequired,
    contentableId: PropTypes.string.isRequired,
  };

  createView = (node) => {
    const {prosemirror, contentClassName} = this.props;
    if (log.prosemirror) {
      console.group('[PROSEMIRROR] EDITOR CREATE VIEW _________________________');
      console.log('node:', node);
      console.groupEnd();
    }
    if (node) {
      prosemirror.connect(node, {
        attributes: {
          class: classnames('ProseMirror',contentClassName)
        }
      });
    } else if (prosemirror.isConnected()) {
      prosemirror.disconnect();
    }
  };

  render() {
    const {classes, className, contentableId} = this.props;
    return (
      <div className={classnames(classes.elementEditor,className)}>
        <div ref={this.createView} className={classes.editorContent}/>
        <Dialogs.SaveDialog contentableId={contentableId} />
        <Dialogs.LessonContentContainerDialog />
        <Dialogs.ImageDialog />
        <Dialogs.LessonTitleImageDialog />
        <Dialogs.MathquillDialog />
        <Dialogs.MathpixDialog />
        <Dialogs.MathmlDialog />
        <Dialogs.ColumnsDialog />
        <Dialogs.TableDialog />
        <Dialogs.HomeworkDialog />
        <Dialogs.DesmosDialog />
        <Dialogs.EmbedDialog />
        <Dialogs.LinkDialog />
        {/*<div>{prosemirror.debug()}</div>*/}
        <ProsemirrorSnackbar />
      </div>
    );
  }
}

export default withProsemirror(withStyles(styles)(ProsemirrorEditor));
