import {apiClient} from "constants/Clients";

export const contentVersionApprovalStatus = key => ({
  request: contentVersionId => apiClient.getApprovalStatusForContentVersion(contentVersionId),
  key,
  auto: true,
});
export const contentVersionRender = key => ({
  request: contentVersionId => apiClient.renderContentVersion(contentVersionId),
  key,
  auto: true,
});
export const contentVersionRequirementStatus = key => ({
  request: contentVersionId => apiClient.getControlRequirementStatusesForContentVersion(contentVersionId),
  key,
  auto: true,
});
export const validateContentVersion = key => ({
  request: contentVersionId => apiClient.validateContentVersion(contentVersionId),
  key,
});
export const validateHTML = {
  request: html => apiClient.validateHtmlContent(html),
};
export const contentVersionMedias = key => ({
  request: contentVersionId => apiClient.getMediaForContentVersion(contentVersionId)
    .limit(100),
  //.setSortFields(['elementType'])
  //.setOrderByAsc(),
  key,
  auto: true,
});
