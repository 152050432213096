import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";


const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 180,
  }
});

const FilterQuestionIdLessThan = props => (
  <FormControl className={props.classes.root}>
    <TextField
      value={props.filterState.questionIdLessThan}
      onChange={event => props.onChange('questionIdLessThan', event.target.value)}
    />
  </FormControl>
);

export default withStyles(styles)(FilterQuestionIdLessThan);