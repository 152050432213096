import {apiClient} from "constants/Clients";

export const assessmentGroupDetails = key => ({
  request: assessmentGroupId => apiClient.getAssessmentGroup(assessmentGroupId),
  key,
  auto: true,
});
export const updateAssessmentGroup = key => ({
  request: (assessmentGroupId, assessmentGroupInfo) => apiClient.updateAssessmentGroup({...assessmentGroupInfo, id: assessmentGroupId}),
  key,
});
export const deleteAssessmentGroup = key => ({
  request: assessmentGroupId => apiClient.deleteAssessmentGroup(assessmentGroupId),
  key,
});
export const assessmentGroupProblems = key => ({
  request: groupId => apiClient.getAssessmentProblemsForAssessmentGroup(groupId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const assessmentGroupProblemOrder = key => ({
  request: (groupId, assessmentProblemIdArray) => apiClient.setAssesssmentProblemOrderForAssessmentGroup(groupId, assessmentProblemIdArray),
  key
});
export const assessmentGroupCreateProblem = key => ({
  request: (groupId, problemInfo) => apiClient.createAssessmentProblemForAssessmentGroup(groupId, problemInfo),
  key
});
