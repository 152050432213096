import * as React from "react";
import {connect} from "react-redux";

import * as TableActions from './actions';
import {createPaginationSelector} from "./selectors";

export default options => BaseComponent => {
  // Get options or defaults
  const {
    table,
    initialState
  } = options;

  // Component with injected client request data
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const {__registerTable} = this.props;
      // console.log(this.constructor.displayName, ' :: MOUNT ', table);
      __registerTable(initialState);
    }
    componentWillUnmount() {
      const {__unregisterTable} = this.props;
      // console.log(this.constructor.displayName, ' :: UNMOUNT ', table);
      __unregisterTable();
    }

    render() {
      const {
        outerProps,
        outerRef,
        table,
      } = this.props;
      if (!table.pagination) return null;
      return <BaseComponent
        {...outerProps}
        table={table}
        ref={outerRef}
      />;
    }
  }
  // Forward the React ref from the wrapped component to the base component
  const forwardRef = (props, ref) => <WrappedComponent {...props} outerRef={ref} />;
  WrappedComponent.displayName =
    forwardRef.displayName = `reduxTable(${BaseComponent.displayName || BaseComponent.name || 'Component'})`;

  // Create selectors for all client hooks
  const paginationSelector = createPaginationSelector(table);

  // Get the state of all requested client hooks
  const mapStateToProps = (state, props) => ({
    pagination: paginationSelector(state, props),
  });
  // Get mapped action creators for all requested client hooks
  const mapDispatchToProps = (dispatch, props) => ({
    __registerTable: initialState => dispatch(TableActions.registerTable(table, initialState)),
    __unregisterTable: () => dispatch(TableActions.unregisterTable(table)),
    setPage: pageNumber => dispatch(TableActions.tableSetPage(table, pageNumber)),
    setPageSize: pageSize => dispatch(TableActions.tableSetPageSize(table, pageSize)),
    setSearch: search => dispatch(TableActions.tableSetSearch(table, search)),
    setSearchColumns: columns => dispatch(TableActions.tableSetSearchColumns(table, columns)),
    setMetadata: metadata => dispatch(TableActions.tableSetMetadata(table, metadata)),
    toggleSort: column => dispatch(TableActions.tableToggleSort(table, column))
  });
  const mergeProps = (stateProps, dispatchProps, outerProps) => {
    const {pagination} = stateProps;
    const {__registerTable, __unregisterTable, ...actions} = dispatchProps;
    const table = {pagination, actions};
    return {
      outerProps,
      __registerTable,
      __unregisterTable,
      table
    };
  };

  // Connect wrapped component to Client reducer
  return connect(mapStateToProps, mapDispatchToProps, mergeProps, {forwardRef: true})(React.forwardRef(forwardRef));
};