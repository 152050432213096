import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';

import FilterBook from './Filters/FilterBook';
import FilterStandard from './Filters/FilterStandard';
import FilterApprovalStatus from './Filters/FilterApprovalStatus';
import FilterFilename from './Filters/FilterFilename';

import {makeFilterSelector, setFilterProperty, removeFilter} from "reducers/Search/images";

import FilterTypes from 'constants/FilterTypes';
import {asArray} from "constants/util/map";

const filterTypes = asArray(FilterTypes);

const styles = theme => ({
  root: {
    display: 'flex'
  },
  deleteIcon: {
    margin: theme.spacing(1),
    fontSize: 'small'
  },
  filterType: {
    margin: theme.spacing(1),
    paddingTop: `${1}em`,
    width: 140,
  },
});

class SearchFilter extends React.Component {
  handleFilterSettingChange = (setting, value) => {
    const {filterId, setFilterProperty} = this.props;
    setFilterProperty(filterId, setting, value);
  };

  handleFilterRemove = event => {
    const {filterId, removeFilter} = this.props;
    removeFilter(filterId);
  };

  render() {
    const {classes, filterId, filterState} = this.props;

    let FilterOptions = false;

    switch (filterState.type) {
      case FilterTypes.book.name: FilterOptions = FilterBook; break;
      case FilterTypes.standard.name: FilterOptions = FilterStandard; break;
      case FilterTypes.approval.name: FilterOptions = FilterApprovalStatus; break;
      case FilterTypes.filename.name: FilterOptions = FilterFilename; break;
    }

    return (<div className={classes.root}>
      <IconButton
        aria-label="Delete"
        className={classes.deleteIcon}
        onClick={this.handleFilterRemove}
      >
        <IconClose />
      </IconButton>
      <span className={classes.filterType}>
        {filterState.type}
      </span>
      {FilterOptions && <FilterOptions
        filterId={filterId}
        filterState={filterState}
        onChange={this.handleFilterSettingChange} />}
    </div>);
  }
}

const mapStateToProps = () => {
  const getFilter = makeFilterSelector((state, props) => props.filterId);
  return (state, props) => ({
    filterState: getFilter(state, props),
  });
};

const mapDispatchToProps = dispatch => ({
  setFilterProperty: (filterId, property, value) => dispatch(setFilterProperty(filterId, property, value)),
  removeFilter: filterId => dispatch(removeFilter(filterId)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchFilter));