import React from "react";
import classnames from 'classnames';

import withStyles from "@material-ui/core/styles/withStyles";

import {Dropdown} from "components/Prosemirror/icons";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {BUTTON, ACTIVE} from "components/Prosemirror/menu/components/styles";

const styles = theme => ({
  BUTTON,
  ACTIVE,
  root: {
    position: 'relative'
  },
  menu: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: `${-7}px`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    zIndex: 10
  },
  hidden: {
    display: 'none'
  }
});

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleButtonClick(event) {
    event.preventDefault();
    this.setState({ menuOpen: true });

    const closeMenu = ev => {
      this.setState({menuOpen: false});
      document.removeEventListener('mousedown',closeMenu,false);
    }
    document.addEventListener('mousedown',closeMenu, false);
  }

  render() {
    const {classes, prosemirror, title, active, enabled, icon, children} = this.props;
    const {menuOpen} = this.state;

    return (
      <span className={classes.root}>
        <button
          className={classnames({
            [classes.BUTTON]: true,
            [classes.ACTIVE]: active && active(prosemirror.state)
          })}
          title={title}
          disabled={enabled && !enabled(prosemirror.state)}
          onMouseDown={ev => this.handleButtonClick(ev)}
        ><Dropdown>{icon}</Dropdown></button>
        <div
          className={classnames({
            [classes.menu]: true,
            [classes.hidden]: !menuOpen
          })}>
          {children}
        </div>
      </span>
    );
  }
}

export default withStyles(styles)(withProsemirror(Select));

