import {setBlockType} from 'prosemirror-commands';

import {commonAncestor} from "../util";
import schema from '../schema';

export const getTextBlockType = state => {
  const $ancestor = commonAncestor(state.selection.$from,state.selection.$to);
  if ($ancestor.nodeAfter.isTextblock) {
    if ($ancestor.nodeAfter.type.name === 'heading') {
      return 'h' + $ancestor.nodeAfter.attrs.level;
    }
    return $ancestor.nodeAfter.type.name;
  }
  return null;
};

export const setTextBlockType = type => {
  switch (type) {
    case 'h1': return setBlockType(schema.nodes.heading,{level:1});
    case 'h2': return setBlockType(schema.nodes.heading,{level:2});
    case 'h3': return setBlockType(schema.nodes.heading,{level:3});
    case 'h4': return setBlockType(schema.nodes.heading,{level:4});
    case 'h5': return setBlockType(schema.nodes.heading,{level:5});
    case 'h6': return setBlockType(schema.nodes.heading,{level:6});
    default: return setBlockType(schema.nodes[type]);
  }
};
