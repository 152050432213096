import React from 'react';
import {connect} from 'react-redux';

import {isUserInRole} from "../../reducers/profile";

const Restrict = props => {
  if (props.isInRole) return props.children;
  return false;
};

const mapStateToProps = (state, props) => ({
  isInRole: isUserInRole(state, props.role)
});

export default connect(mapStateToProps)(Restrict);