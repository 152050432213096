import {combineReducers} from 'redux';

// ACTION TYPES
const LESSON_CONTENT_CONTAINER_SELECT = 'LESSON_CONTENT_CONTAINER_SELECT';

// ACTION CREATORS
export function selectLessonContentContainer(lessonContentContainerID) {
  return {type: LESSON_CONTENT_CONTAINER_SELECT, payload: lessonContentContainerID};
}

export default combineReducers({
  selected: (state = null, action) => (action.type === LESSON_CONTENT_CONTAINER_SELECT ? action.payload : state),
});
