import {onDoubleClick} from "../util";
import {log} from "../../../../../constants/Config";

export default class ImageMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] create CpmMediaView#${this.outerView.id}::ImageMediaView`, this.media);
    }

    this.dom = document.createElement('img');
    this.dom.setAttribute('src',media.publicUrl);
    this.outerView.dom.appendChild(this.dom);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.dispatch({method: 'image'});
    });
  }

  destroy() {
    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] destroy CpmMediaView#${this.outerView.id}::ImageMediaView`, this.media);
    }
    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
  }
}