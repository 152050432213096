import {
  IconButton,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core";
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import Language from "constants/Language";
import React from "react";
import PropTypes from "prop-types";

const MediaAltTextsTableRow = props => {
  const {
    classes,
    altText,
    index,
    adding,
    waiting,
    pendingChanges,
    editingId,
    edit,
    onEdit,
    onClickEdit,
    onClickSave,
    onClickDelete,
  } = props;

  return (<TableRow>
    <TableCell>
      {Language[altText.languageTag].name}
    </TableCell>
    <TableCell>
      {editingId === index ? (
        <TextField
          fullWidth
          value={edit.altText}
          onChange={ev => onEdit('altText', ev)}
          disabled={waiting}
          autoFocus
        />
      ) : (
        altText.altText
      )}
    </TableCell>
    <TableCell padding="none" align="right">
      {editingId === index ? (
        <React.Fragment>
          <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
            <IconDelete fontSize="small"/>
          </IconButton>
          <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
            {pendingChanges ? (
              <IconSave fontSize="small"/>
            ):(
              <IconCancel fontSize="small"/>
            )}
          </IconButton>
        </React.Fragment>
      ) : !adding && editingId === -1 && (
        <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
          <IconEdit fontSize="small"/>
        </IconButton>
      )}
    </TableCell>
  </TableRow>);
};
MediaAltTextsTableRow.propTypes = {
  classes: PropTypes.object.isRequired,

  altText: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  adding: PropTypes.bool.isRequired,
  waiting: PropTypes.bool.isRequired,
  pendingChanges: PropTypes.bool.isRequired,
  editingId: PropTypes.number.isRequired,
  edit: PropTypes.object.isRequired,

  onEdit: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export {MediaAltTextsTableRow}
