import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {GlossaryItemDetailsView} from "views/glossaryItem/GlossaryItemDetails";

import {selectGlossaryItem} from "reducers/glossary";

export const GlossaryItemRoutes = ({match: {url, path, params: {glossaryItemId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectGlossaryItem(glossaryItemId))
  }, [glossaryItemId]);

  const selectedGlossaryItem = useSelector(state => state.glossary.selected);
  if (!selectedGlossaryItem) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={GlossaryItemDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
