import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from "../../util/pagedTable";

const StandardLessonsTable = PagedTable(
  'standardLessons',
  state => apiClient.getClassificationStandardsLessons(state.standards.selected),
  (state, action) => {
    return state;
  }
);

export default StandardLessonsTable;