import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from "../../util/pagedTable";

const AssociatedContainersTable = PagedTable(
  'lessonAssociatedContainers',
  state => apiClient.getAssociatedProblemsForLesson(state.lessons.selected),
  (state, action) => {
    return state;
  }
);

export default AssociatedContainersTable;