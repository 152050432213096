import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";

import {ElementBreadcrumb} from 'components/Breadcrumb';

import ContentVersionMediasTable from 'components/ContentVersion/ContentVersionMediasTable';
import LinearProgress from "@material-ui/core/LinearProgress";


const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: theme.spacing(3),
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

class ElementMedia extends React.Component {
  showElementEditor = () => {
    const {
      history,
      elementDetails
    } = this.props;
    history.push(`/app/elements/${elementDetails.getParams()}/editor`);
  };

  render() {
    const {
      classes,
      elementDetails,
    } = this.props;

    if (!elementDetails.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Element media...</h2>
      </div>);
    }

    return (
      <div className={classes.root}>
        <ElementBreadcrumb elementId={elementDetails.getParams()} />
        <div className={classes.buttonBox}>
          <Tooltip title="Element Editor">
            <Fab color="primary" className={classes.button}>
              <IconEdit onClick={this.showElementEditor} />
            </Fab>
          </Tooltip>
        </div>

        <ContentVersionMediasTable contentVersionId={elementDetails.get().publishedVersionId} />
      </div>
    );
  }
}

export const ElementMediaView = withClient({
  hooks: {
    elementDetails: elementDetails((state, props) => state.elements.selected),
  },
})(withStyles(styles)(ElementMedia));
