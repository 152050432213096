import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {deleteMenu} from "../../../../reducers/menus";

class DeleteMenuButton extends Component {

  clicked = () => {
    console.log(this.props)
      this.props.deleteMenu(this.props.menu.id)
  };


  render(){
    return (
      <Button color="primary" onClick={this.clicked}>Delete</Button>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  deleteMenu: (id) => dispatch(deleteMenu(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMenuButton)