import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {assessmentProblemElements} from "reducers/client/requestTypes";

import {AssessmentProblemBreadcrumb} from 'components/Breadcrumb';

import ContentVersionMediasTable from 'components/ContentVersion/ContentVersionMediasTable';
import LinearProgress from "@material-ui/core/LinearProgress";
import {getElementTypeByName} from "../../constants/ElementTypes";
import {Redirect} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: theme.spacing(3),
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
});

class AssessmentProblemMedia extends React.Component {
  showAssessmentProblemEditor = () => {
    const {
      history,
      assessmentProblemElements,
      match: {
        params: {elementType}
      }
    } = this.props;
    history.push(`/app/assessmentProblems/${assessmentProblemElements.getParams()}/editor/${elementType}`);
  };
  handleChangeElementType = (ev, value) => {
    const { history, match: {path, url, params} } = this.props;
    let newPath = path.replace(':assessmentProblemId',params.assessmentProblemId).replace(':elementType', value);
    if (url !== newPath) history.push(newPath);
  };

  render() {
    const {
      classes,
      assessmentProblemElements:elementsRequest,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;

    if (!elementsRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Assessment Problem media...</h2>
      </div>);
    }

    const assesmentProblemElements = elementsRequest.get();
    if (!elementType) {
      return <Redirect to={`${url}/${assesmentProblemElements[0].elementType}`} />;
    }

    const selectedElement = assesmentProblemElements.find(el => el.elementType === elementType);

    return (
      <div className={classes.root}>
        <AssessmentProblemBreadcrumb assessmentProblemId={elementsRequest.getParams()} />
        <div className={classes.buttonBox}>
          <Tooltip title="Tab Editor">
            <Fab color="primary" className={classes.button}>
              <IconEdit onClick={this.showAssessmentProblemEditor} />
            </Fab>
          </Tooltip>
        </div>
        <AppBar position="static">
          <Tabs value={elementType} onChange={this.handleChangeElementType}>
            {assesmentProblemElements.map(element => {
              const elementType = getElementTypeByName(element.elementType);
              return (
                <Tab
                  key={element.elementType}
                  value={element.elementType}
                  label={elementType ? elementType.displayName || elementType.name : element.elementType}
                />
              )
            })}
          </Tabs>
        </AppBar>
        <ContentVersionMediasTable contentVersionId={selectedElement.publishedVersionId} />
      </div>
    );
  }
}

const getAssessmentProblemId = (state, props) => state.assessmentProblems.selected;
export const AssessmentProblemMediaView = withClient({
  hooks: {
    assessmentProblemElements: assessmentProblemElements(getAssessmentProblemId),
  },
})(withStyles(styles)(AssessmentProblemMedia));
