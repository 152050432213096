import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {withStyles} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import AddMenuItemDialog from "components/Dialogs/MenuItems/AddMenuItem";
import {openMenuItemDialog, setMenuItemDialogData} from "reducers/Dialogs/MenuItem";
import {connect} from 'react-redux';
import IconHandle from '@material-ui/icons/DragHandle';
import {setActiveMenuItem} from 'reducers/menus';
import {bookDetails, bookMenus, deleteMenuItem, menuItemsForMenu, menuRender } from "reducers/client/requestTypes";
import {withClient} from "reducers/client";

const styles = theme => ({
  card: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  dragHandle: {
    cursor: "move"
  },
});



class MenuItem extends Component {
  state = {
    expanded: false
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  openMenuItemDialog = (menuItem) => {
    this.props.setMenuItemDialogData({menuId: menuItem.menuId, parentMenuItemId: menuItem.id});
    this.props.openMenuItemDialog()
  };

  handleDelete = () => {
    const {deleteMenuItem, menuItem, menuItemsForMenu, menuRender} = this.props;
    this.props.deleteMenuItem.sendRequest(menuItem.id)
      .then((results) => {
        menuItemsForMenu.sendRequest();
        menuRender.sendRequest();
      })
  };

  render(){
    const {classes, menuItem} = this.props;

    const children = menuItem.childMenuItems || [];
    return (
      <Card style={{marginLeft: 20}}>
        <CardHeader
          action={ <div>
            {/*<IconHandle className={classes.dragHandle}/>*/}
            <IconButton aria-label="Trash Menu Item" onClick={this.handleDelete}>
              <IconDelete />
            </IconButton>
            <IconButton aria-label="Edit Menu Item" onClick={()=>this.props.setActiveMenuItem(menuItem)}>
              <IconEdit />
            </IconButton>
            {
              !menuItem.parentMenuItemId &&
              <IconButton onClick={() => this.openMenuItemDialog(menuItem)} >
                <IconAdd />
              </IconButton>
            }
          </div>
          }
          title={menuItem.displayName}
          subheader={menuItem.type}
        />
        <CardActions className={classes.actions} disableActionSpacing>
          {/*<IconButton aria-label="Add to favorites">*/}
            {/*<FavoriteIcon />*/}
          {/*</IconButton>*/}
          {/*<IconButton aria-label="Share">*/}
            {/*<ShareIcon />*/}
          {/*</IconButton>*/}

          {children.length > 0 &&
          <IconButton
            className={`${classes.expand} ${this.state.expanded ? classes.expandOpen : ''}`}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
          }
        </CardActions>

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.children}
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  openMenuItemDialog: () => dispatch(openMenuItemDialog()),
  setMenuItemDialogData: (payload) => dispatch(setMenuItemDialogData(payload)),
  setActiveMenuItem: (payload) => dispatch(setActiveMenuItem(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withClient({
  hooks: {
    bookMenus: bookMenus((state, props) => state.books.selected),
    deleteMenuItem: deleteMenuItem(),
    menuItemsForMenu: menuItemsForMenu((state, props) => state.menus.selected),
    menuRender: menuRender((state, props) => state.menus.selected),
  },
})(withStyles(styles)(MenuItem)))