import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {BooksRoutes} from './books';

export const RedirectRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/books`} component={BooksRoutes} />
    <Redirect to="/404" />
  </Switch>
);
