import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Fab,
  CircularProgress
} from '@material-ui/core';
import {
  Undo,
  ExpandMore,
  ExpandLess,
  Add,
  Remove,
} from '@material-ui/icons';

import {formatTime} from "constants/Date";
import {LessonContentContainerReference, TabReference, ElementReference} from "components/ContentReference";
import ValidateContentReferences from 'components/ContentReference/ValidateContentReferences';
import MergeHistoryTableExpansion from './MergeHistoryTableExpansion';

const styles = theme => ({
  rowFaded: {
    '& > td': {
      color: '#c0c0c0'
    }
  },
  contentReference: {
    marginLeft: '8px',
    padding: '6px 16px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    fontWeight: 500,
    '&:first-child': {
      marginLeft: 0
    }
  },
});

const unique = (v,i,arr) => arr.indexOf(v) === i;

class MergeHistoryTableRow extends React.Component {
  state = {
    expanded: false,
  };

  handleClickExpand = () => {
    this.setState({expanded: !this.state.expanded});
  };

  handleRef = (table, id) => ref => {
    console.log(table,id,ref);
  };

  render() {
    const {classes, item, onClickUndo, waiting} = this.props;
    const {expanded} = this.state;
    const classesOverride = {root: classes.contentReference};
    let refs = item.operations.map(op => `${op.contentable}::${op.contentableId}`).filter(unique).sort();
    return (
      <React.Fragment>
        <TableRow classes={{root: (item.isUndo || item.isUndone) ? classes.rowFaded : ''}}>
          <TableCell padding="checkbox">
            <IconButton onClick={this.handleClickExpand}>
              {expanded ?
                <ExpandLess fontSize="small"/> :
                <ExpandMore fontSize="small"/>
              }
            </IconButton>
          </TableCell>
          <TableCell>{formatTime(item.createdAt)}</TableCell>
          <TableCell>{item.author}</TableCell>
          <TableCell>{item.comment}</TableCell>
          <TableCell>{refs.map(ref => {
            let [table, id] = ref.split('::');
            switch(table) {
              case 'lessonContentContainer':
                return <LessonContentContainerReference key={ref} classes={classesOverride} containerId={id} />;
              case 'tab':
                return <TabReference key={ref} classes={classesOverride} tabId={id} />;
              default:
                return null;
            }
          })}</TableCell>
          {onClickUndo && (
            <TableCell padding="checkbox">
              {waiting ? (
                <CircularProgress />
              ):(
                <ValidateContentReferences references={refs}>
                  <Fab size="small" onClick={onClickUndo}>
                    <Undo />
                  </Fab>
                </ValidateContentReferences>
              )}
            </TableCell>
          )}
        </TableRow>
        <tr>
          <td colSpan={6}>
            <MergeHistoryTableExpansion item={item} expanded={expanded} />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MergeHistoryTableRow);