import {BOOK_SELECT} from "./books";
import {createSelector} from "reselect";
import ClientRequest, {RequestState} from "./util/clientRequest";
import {ASSESSMENT_PROBLEM_DELETE} from "./assessmentProblems";
import {apiClient} from "../constants/Clients";

const initialSettings = {
  activeMenuItem: {},
  selected: null
};

// ACTION TYPES
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';
export const MENU_SELECT = 'MENU_SELECT';
export const DELETE_MENU = 'DELETE_MENU';


// ACTION CREATORS
export const setActiveMenuItem = (menuItem) => {
  return {type: SET_ACTIVE_MENU_ITEM, payload: menuItem}
};

export function selectMenu(menuId) {
  return {type: MENU_SELECT, payload: menuId};
}

const deleteMenuClientRequest = ClientRequest(DELETE_MENU);

export function deleteMenu(id) {
  let req = apiClient.deleteMenu(id);
  return deleteMenuClientRequest.sendRequest(req)
}

// SELECTORS


export default (state = initialSettings, action) => {
  switch(action.type){
    case SET_ACTIVE_MENU_ITEM:
      return {...state, activeMenuItem: action.payload};
    case MENU_SELECT:
      return {...state, selected: action.payload};
    default:
      return state
  }
};