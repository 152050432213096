import React from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconDots from '@material-ui/icons/MoreHoriz';

import {formatDate} from 'constants/Date';
import {withClient} from "reducers/client";
import {categoryBooks, categoryBookOrder, categoryCreateBook, updateBook, deleteBook} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import ConfirmationDialog from "components/Dialogs/Confirmation";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class CategoryBooksTable extends React.Component {
  state = {
    books: [],
    adding: false,
    editingId: -1,
    edit: {
      name: '',
      displayName: '',
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {categoryBooks} = this.props;
    if (categoryBooks.isLoaded()) {
      this.setState({ books: categoryBooks.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {categoryBooks} = this.props;
    const {categoryBooks:prevBooks} = prevProps;
    if (categoryBooks.isLoaded() && categoryBooks.hasChanged(prevBooks)) {
      this.setState({ books: categoryBooks.get() });
    }
  }

  isWaiting() {
    const {
      categoryBooks,
      categoryBookOrder,
      categoryCreateBook,
      updateBook,
      deleteBook,
    } = this.props;
    return categoryBooks.isLoading() ||
      categoryBookOrder.isLoading() ||
      categoryCreateBook.isLoading() ||
      updateBook.isLoading() ||
      deleteBook.isLoading();
  }
  pendingChanges() {
    const {books, adding, editingId, edit} = this.state;
    if (adding) {
      return edit.name.length > 0 &&
        edit.displayName.length > 0;
    }
    if (books[editingId]) {
      return books[editingId].name !== edit.name ||
        books[editingId].displayName !== edit.displayName;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: '',
          displayName: '',
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.books[editingId].name,
        displayName: this.state.books[editingId].displayName
      },
    });
  };
  handleClickSave = () => {
    const {categoryCreateBook, updateBook, categoryBooks} = this.props;
    const {books, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        categoryCreateBook.sendRequest(edit)
          .then(() => categoryBooks.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateBook.sendRequest(books[editingId].id, edit)
          .then(() => categoryBooks.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteBook, categoryBooks} = this.props;
    const {books, editingId} = this.state;
    deleteBook.sendRequest(books[editingId].id)
      .then(() => categoryBooks.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        return true;
      } else if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    // Click directly on row = navigate
    this.props.history.push(`/app/books/${this.state.books[index].id}/details`);
    return false;
  };
  handleChangeOrder = updatedList => {
    const books = this.props.categoryBooks.get();
    this.props.categoryBookOrder.sendRequest(updatedList.map(c => books[c].id))
      .then(() => this.props.categoryBooks.sendRequest());
  };

  renderBook = ({item:book, ...props}) => {
    const {classes} = this.props;
    const {books, adding, editingId, edit} = this.state;
    const waiting = this.isWaiting();
    return (<TableRow hover {...props}>
      <TableCell padding="checkbox">
        <IconHandle className={classes.dragHandle}/>
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.name}
            onChange={ev => this.handleEdit('name', ev)}
            disabled={waiting}
            autoFocus
          />
        ) : (
          book.name
        )}
      </TableCell>
      <TableCell>
        {editingId === props.index ? (
          <TextField
            value={edit.displayName}
            onChange={ev => this.handleEdit('displayName', ev)}
            disabled={waiting}
          />
        ) : (
          book.displayName
        )}
      </TableCell>
      <TableCell>
        {formatDate(book.createdAt)}
      </TableCell>
      <TableCell>
        {formatDate(book.updatedAt)}
      </TableCell>
      <TableCell padding="none" align="right">
        {editingId === props.index ? (
          <React.Fragment>
            {/*<IconButton onClick={this.handleClickDelete} className={classes.itemButton} disabled={waiting}>*/}
              {/*<IconDelete fontSize="small"/>*/}
            {/*</IconButton>*/}
            <IconButton onClick={this.handleClickSave} className={classes.itemButton} disabled={waiting}>
              {this.pendingChanges() ? (
                <IconSave fontSize="small"/>
              ):(
                <IconCancel fontSize="small"/>
              )}
            </IconButton>
          </React.Fragment>
        ) : !adding && editingId === -1 && (
          <IconButton onClick={() => this.handleClickEdit(props.index)} className={classes.itemButton} disabled={waiting}>
            <IconEdit fontSize="small"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>);
  };

  render() {
    const {books, adding, editingId, edit, confirmationOpen} = this.state;
    if (!books) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Book"
        message={`Are you sure you want to delete Book ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"/>
            <TableCell>Short Name</TableCell>
            <TableCell>Display Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={5} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={this.renderBook}
          items={books}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell padding="checkbox"/>
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={edit.displayName}
                  onChange={ev => this.handleEdit('displayName', ev)}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={this.props.classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={6} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    categoryBooks: categoryBooks((state, props) => props.categoryId),
    categoryBookOrder: categoryBookOrder((state, props) => props.categoryId),
    categoryCreateBook: categoryCreateBook((state, props) => props.categoryId),
    updateBook: updateBook(),
    deleteBook: deleteBook(),
  },
})(withStyles(styles)(withRouter(CategoryBooksTable)));