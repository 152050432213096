import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentProblemEditorView} from "views/assessmentProblem/AssessmentProblemEditor";
import {AssessmentProblemMediaView} from "views/assessmentProblem/AssessmentProblemMedia";
import {AssessmentProblemVersionsView} from "views/assessmentProblem/AssessmentProblemVersions";
import {AssessmentProblemStandardsView} from "views/assessmentProblem/AssessmentProblemStandards";

import {selectAssessmentProblem} from "reducers/assessmentProblems";

export const AssessmentProblemRoutes = ({match: {url, path, params: {assessmentProblemId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectAssessmentProblem(assessmentProblemId))
  }, [assessmentProblemId]);

  const selectedAssessmentProblem = useSelector(state => state.assessmentProblems.selected);
  if (!selectedAssessmentProblem) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/editor/:elementType`} component={AssessmentProblemEditorView} />
      <Route path={`${path}/editor`} component={AssessmentProblemEditorView} />
      <Route path={`${path}/media/:elementType`} component={AssessmentProblemMediaView} />
      <Route path={`${path}/media`} component={AssessmentProblemMediaView} />
      <Route path={`${path}/versions/:elementType`} component={AssessmentProblemVersionsView} />
      <Route path={`${path}/versions`} component={AssessmentProblemVersionsView} />
      <Route path={`${path}/standards/:elementType`} component={AssessmentProblemStandardsView} />
      <Redirect to="/404" />
    </Switch>
  );
};
