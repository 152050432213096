import React from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  IconButton,
  TextField, Select, MenuItem,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconDots from '@material-ui/icons/MoreHoriz';

import {formatDate} from 'constants/Date';
import {withClient} from "reducers/client";
import {reduxTable, paging} from "reducers/table";
import {
  bookGlossaryItems,
  bookCreateGlossaryItem,
  updateGlossaryItem,
  deleteGlossaryItem
} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import ConfirmationDialog from "components/Dialogs/Confirmation";
import TableSortHeading from 'components/Tables/Components/TableSortHeading';
// import TablePagination from 'components/Tables/Components/TablePagination';
import Tooltip from "@material-ui/core/Tooltip";
import {getValidElementTypesForContentable, getElementTypeByName} from "constants/ElementTypes";

import BookGlossaryItemsTableRow from './BookGlossaryItemsTableRow';

const elementTypes = getValidElementTypesForContentable('glossaryItem');

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
  paginationRoot: {
    width: `400px`,
  }
});

class BookGlossaryItemsTable extends React.Component {
  state = {
    glossaryItems: [],
    totalCount: 0,
    adding: false,
    editingId: -1,
    edit: {
      title: '',
      elementType: '',
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    this.draggableListClasses = {dragging: this.props.classes.dragging};

    const {bookGlossaryItems} = this.props;
    if (bookGlossaryItems.isLoaded()) this.updateState();
  }
  componentDidUpdate(prevProps) {
    const {bookGlossaryItems} = this.props;
    const {bookGlossaryItems:prevGlossaryItems} = prevProps;
    if (bookGlossaryItems.isLoaded() && bookGlossaryItems.hasChanged(prevGlossaryItems)) this.updateState();
  }

  updateState() {
    const {table: {pagination, actions}} = this.props;
    const {results: glossaryItems, totalCount} = this.props.bookGlossaryItems.get();
    this.setState({ glossaryItems, totalCount });
    if (pagination.page * pagination.pageSize > totalCount) {
      actions.setPage(Math.floor(totalCount / pagination.pageSize));
    }
  }

  isWaiting() {
    const {
      bookGlossaryItems,
      bookCreateGlossaryItem,
      updateGlossaryItem,
      deleteGlossaryItem,
    } = this.props;
    return bookGlossaryItems.isLoading() ||
      bookCreateGlossaryItem.isLoading() ||
      updateGlossaryItem.isLoading() ||
      deleteGlossaryItem.isLoading();
  }
  pendingChanges() {
    const {glossaryItems, adding, editingId, edit} = this.state;
    if (adding) {
      return edit.title.length > 0;
    }
    let selected = glossaryItems[editingId];
    if (selected) {
      if (selected.title !== edit.title) return true;
      if (!selected.elements[0]) return true;
      if (selected.elements[0].elementType !== edit.elementType) return true;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          title: '',
          elementType: elementTypes[0].name,
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        title: this.state.glossaryItems[editingId].title || '',
        elementType: this.state.glossaryItems[editingId].elements[0]
          ? this.state.glossaryItems[editingId].elements[0].elementType : '',
      },
    });
  };
  handleClickSave = () => {
    const {bookCreateGlossaryItem, updateGlossaryItem, bookGlossaryItems} = this.props;
    const {glossaryItems, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        bookCreateGlossaryItem.sendRequest(edit)
          .then(() => bookGlossaryItems.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateGlossaryItem.sendRequest(glossaryItems[editingId].id, edit)
          .then(() => bookGlossaryItems.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteGlossaryItem, bookGlossaryItems} = this.props;
    const {glossaryItems, editingId} = this.state;
    deleteGlossaryItem.sendRequest(glossaryItems[editingId].id)
      .then(() => bookGlossaryItems.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleClickRow = (ev, index) => {
    const {adding, editingId} = this.state;
    const waiting = this.isWaiting();
    if (waiting || adding || editingId !== -1) return false;
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger navigation if not clicking on a button
      if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    // this.props.history.push(`/app/glossary/${this.state.glossaryItems[index].id}/details`);
    this.props.history.push(`/app/elements/${this.state.glossaryItems[index].elements[0].id}/editor`);
  };

  render() {
    const {glossaryItems, totalCount, adding, editingId, edit, confirmationOpen} = this.state;
    if (!glossaryItems) return false;
    const {classes, table: {pagination, actions}} = this.props;

    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Glossary Item"
        message={`Are you sure you want to delete Glossary Item ${edit.title}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <TextField
                fullWidth
                label="Filter"
                value={pagination.search}
                onChange={ev => actions.setSearch(ev.target.value)}
              />
            </TableCell>
            <TablePagination
              className={classes.paginationRoot}
              padding="none"
              colSpan={1}
              count={totalCount}
              page={pagination.page}
              rowsPerPage={pagination.pageSize}
              onChangePage={(ev, page) => actions.setPage(page)}
              onChangeRowsPerPage={ev => actions.setPageSize(ev.target.value)}
            />
          </TableRow>
          <TableRow>
            <TableSortHeading
              sortId={'title'}
              onClick={() => { actions.toggleSort('title'); }}
              pagination={pagination}
            >
              Title
            </TableSortHeading>
            <TableSortHeading
              sortId={'elements.elementType,title'}
              onClick={() => { actions.toggleSort('elements.elementType,title'); }}
              pagination={pagination}
            >
              Type
            </TableSortHeading>
            <TableSortHeading
              sortId={'createdAt,title'}
              onClick={() => { actions.toggleSort('createdAt,title'); }}
              pagination={pagination}
            >
              Created
            </TableSortHeading>
            <TableSortHeading
              sortId={'updatedAt,title'}
              onClick={() => { actions.toggleSort('updatedAt,title'); }}
              pagination={pagination}
            >
              Last Updated
            </TableSortHeading>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={5} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <TableBody>
          {glossaryItems.map((glossaryItem, index) => (
            <BookGlossaryItemsTableRow
              key={glossaryItem.id}
              classes={classes}
              glossaryItem={glossaryItem}
              index={index}
              adding={adding}
              waiting={Boolean(waiting)}
              pendingChanges={this.pendingChanges()}
              edit={edit}
              editingId={editingId}
              onEdit={this.handleEdit}
              onClick={ev => this.handleClickRow(ev, index)}
              onClickDelete={this.handleClickDelete}
              onClickSave={this.handleClickSave}
              onClickEdit={this.handleClickEdit}
            />
          ))}
        </TableBody>
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell>
                <TextField
                  value={edit.title}
                  onChange={ev => this.handleEdit('title', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <Select
                  value={edit.elementType}
                  onChange={ev => this.handleEdit('elementType', ev)}
                  disabled={waiting}
                  fullWidth
                >
                  {elementTypes.map(type => (
                    <MenuItem key={type.name} value={type.name}>{type.displayName || type.name}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={this.props.classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={5} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default reduxTable({
  table: 'BookGlossaryItemsTable',
  initialState: {
    searchColumns: ['title']
  }
})(withClient({
  hooks: {
    bookGlossaryItems: paging('BookGlossaryItemsTable')(bookGlossaryItems((state, props) => props.bookId)),
    bookCreateGlossaryItem: bookCreateGlossaryItem((state, props) => props.bookId),
    updateGlossaryItem: updateGlossaryItem(),
    deleteGlossaryItem: deleteGlossaryItem(),
  },
})(withRouter(withStyles(styles)(BookGlossaryItemsTable))));