import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {
  TableCell,
  TableRow,
  TextField,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

import {AllLanguageKeys} from "constants/Language";
import {MediaTags} from "components/Media/MediaTags";

const useStyles = makeStyles({
  minimized: {
    maxWidth: `${300}px`,
    maxHeight: `${72}px`,
    cursor: 'zoom-in',
  },
  maximized: {
    cursor: 'zoom-out',
  }
});

const getArrayWithUpdateOrRemoval = (array, index, item) => {
  let newArray = array.slice(0,index);
  if (item) {
    newArray = newArray.concat([{
      ...array[index],
      ...item,
    }]);
  }
  return newArray.concat(array.slice(index + 1));
};

const ElementMediasTableRow = props => {
  const {
    media,
    language,
    index,
    disabled,
    onChange,
    ...other
  } = props;
  const classes = useStyles();
  const [maximized, setMaximized] = useState(false);

  // Find alt text for currently selected language
  const altTextIndex = media.mediaAltTexts.findIndex(t => t.languageTag === language);
  let selectedAltText = media.mediaAltTexts[altTextIndex];

  const handleClickImage = useCallback(() => {
    setMaximized(!maximized);
  }, [maximized]);
  const handleChangeText = useCallback(ev => {
    const {mediaAltTexts} = media;
    // Find previous alt text value if it exists
    const altTextIndex = mediaAltTexts.findIndex(t => t.languageTag === language);
    // Replace or remove the alt text in the array
    let updatedAltTexts = getArrayWithUpdateOrRemoval(mediaAltTexts, altTextIndex, ev.target.value && {
      languageTag: language,
      altText: ev.target.value
    });
    onChange(index, {mediaAltTexts: updatedAltTexts});
  }, [media.mediaAltTexts, language, index, onChange]);
  const handleChangePresentationOnly = useCallback(ev => {
    onChange(index, {presentationOnly: ev.target.checked});
  }, [index, onChange]);
  const handleChangeTags = useCallback(newTags => {
    let tags = newTags.map(tagName => {
      let match = media.tags.find(t => t.tag === tagName);
      return match || {tag: tagName};
    });
    onChange(index, {tags});
  }, [index, onChange, media.tags]);

  return (
    <TableRow {...other}>
      <TableCell padding="none">
        <img
          src={media.publicUrl}
          alt=""
          className={maximized ? classes.maximized : classes.minimized}
          onClick={handleClickImage}
        />
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          multiline
          value={selectedAltText ? selectedAltText.altText : ''}
          onChange={handleChangeText}
          disabled={disabled || media.presentationOnly}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={media.presentationOnly}
          onChange={handleChangePresentationOnly}
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <MediaTags
          fullWidth
          value={media.tags.map(t => t.tag)}
          onChange={handleChangeTags}
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};
ElementMediasTableRow.propTypes = {
  media: PropTypes.shape({
    publicUrl: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    presentationOnly: PropTypes.bool.isRequired,
    mediaAltTexts: PropTypes.arrayOf(PropTypes.shape({
      languageTag: PropTypes.oneOf(AllLanguageKeys).isRequired,
      altText: PropTypes.string,
    })).isRequired,
  }).isRequired,
  language: PropTypes.oneOf(AllLanguageKeys).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default React.memo(ElementMediasTableRow);