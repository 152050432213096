import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {BookMenuEditorView} from "views/book/menu/BookMenuEditor";

import {selectMenu} from "reducers/menus";

export const BookMenuRoutes = ({match: {url, path, params: {menuID}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectMenu(menuID))
  }, [menuID]);

  const selectedMenu = useSelector(state => state.menus.selected);
  if (!selectedMenu) return null;

  return (
    <Switch>
      <Route path={`${path}`} component={BookMenuEditorView} />
      <Redirect to="/404" />
    </Switch>
  );
};
