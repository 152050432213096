import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {ElementEditorView} from "views/element/ElementEditor";
import {ElementMediaView} from "views/element/ElementMedia";
import {ElementVersionsView} from "views/element/ElementVersions";
import {ElementAccessibilityView} from "views/element/ElementAccessibility";

import {selectElement} from "reducers/elements";

export const ElementRoutes = ({match: {url, path, params: {elementId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectElement(elementId))
  }, [elementId]);

  const selectedElement = useSelector(state => state.elements.selected);
  if (!selectedElement) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/editor`} component={ElementEditorView}/>
      <Route path={`${path}/media`} component={ElementMediaView}/>
      <Route path={`${path}/versions`} component={ElementVersionsView}/>
      <Route path={`${path}/accessibility`} component={ElementAccessibilityView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
