import ClientRequest, {RequestState} from "./clientRequest";
import {createSelector} from "reselect";

// Higher order reducer (type | options) => Reducer
// SEE ClientRequest
// MultiRequest is a thin wrapper around a series of ClientRequests
// associated with keys so that multiple requests can operate and
// be cached in parallel
export default options => {
  // Get the request type
  const requestType = typeof options === 'string' ? options : options.requestType;
  // Set up a list of 'channels' for requests
  let channels = {};

  // Reducer
  const reducer = (state = {}, action) => {
    if (!action) return state;
    if (action.requestType === requestType) {
      const {key} = action.metadata;
      const newState = channels[key](state[key], action);
      if (newState !== state[key]) {
        return {
          ...state,
          [key]: newState
        }
      }
    }
    return state;
  };

  // Attach an action creator that can be called to send requests
  // clientRequest: c3po-client-js::pagedRequest
  // metadata: Object? {key: Number | String}
  // Returns: Redux Thunk
  reducer.sendRequest = (clientRequest, metadata) => {
    if (!metadata) metadata = {key: 0};
    else if (!metadata.key) metadata.key = 0;
    const {key} = metadata;

    if (!channels[key]) {
      channels[key] = ClientRequest(options);
    }
    return channels[key].sendRequest(clientRequest, metadata);
  };

  // Attach an action creator that can be called to clear request state
  // key: Number | String
  // Returns: Redux Action
  reducer.clear = (key) => {
    if (!channels[key]) {
      channels[key] = ClientRequest(options);
    }
    return channels[key].clear({key});
  };

  return reducer;
};

const selectRequest = (id, requests) => {
  let request = null;
  if (id && requests[id]) request = requests[id];
  return request;
};

export const makeRequestSelector = (idResolver, requestsResolver) => {
  const internalSelector = createSelector([idResolver, requestsResolver], selectRequest);
  return createSelector([idResolver, internalSelector], (id, request) => new RequestState(id, request));
};