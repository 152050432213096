const initialSettings = {
  isOpen: false,
  data: null
};

// ACTION TYPES
export const DIALOG_MENU_OPEN = 'DIALOG_MENU_OPEN';
export const DIALOG_MENU_CLOSE = 'DIALOG_MENU_CLOSE';
export const DIALOG_MENU_SET_DATA = 'DIALOG_MENU_SET_DATA';

// ACTION CREATORS
export const openMenuDialog = () => ({type: DIALOG_MENU_OPEN});
export const closeMenuDialog = () => ({type: DIALOG_MENU_CLOSE});
export const setMenuDialogData = data => ({type: DIALOG_MENU_SET_DATA, data});

export default (state = initialSettings, action) => {
  switch (action.type) {
    case DIALOG_MENU_OPEN: return {...state, isOpen: true};
    case DIALOG_MENU_CLOSE: return {...state, isOpen: false, data: null};
    case DIALOG_MENU_SET_DATA: return {...state, data: action.data};
    default: return state;
  }
};
