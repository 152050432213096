import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentGroupDetailsView} from "views/assessmentGroup/AssessmentGroupDetails";

import {selectAssessmentGroup} from "reducers/assessmentGroups";

export const AssessmentGroupRoutes = ({match: {url, path, params: {assessmentGroupId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectAssessmentGroup(assessmentGroupId))
  }, [assessmentGroupId]);

  const selectedAssessmentGroup = useSelector(state => state.assessmentGroups.selected);
  if (!selectedAssessmentGroup) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={AssessmentGroupDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
