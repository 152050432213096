import React from "react";
import {withStyles} from '@material-ui/core/styles';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import WithFetch from "components/WithFetch";

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 180,
  }
});

const defaultRender = option => option.name;
const emptyOption = { id: '', value: '', name: '---'};

const RemoteSelect = ({request, fetch, label, value, onChange, renderOption = defaultRender, classes}) => (
  <WithFetch request={request} fetch={fetch}>
    {options => (
      <FormControl className={classes.root}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={ev => onChange(ev.target.value)}>
          {[emptyOption].concat(options).map(option =>
            <MenuItem key={option.id} value={option.id}>{renderOption(option)}</MenuItem>
          )}
        </Select>
      </FormControl>
    )}
  </WithFetch>
);

export default withStyles(styles)(RemoteSelect);