import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {LessonContentContainerSearchView} from "views/lessonContentContainer/LessonContentContainerSearch";
import {LessonContentContainerRoutes} from './lessonContentContainer';

export const LessonContentContainersRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/search`} component={LessonContentContainerSearchView} />
    <Route path={`${match.path}/:lessonContentContainerId`} component={LessonContentContainerRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
