import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ElementRoutes} from './element/index';

export const ElementsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:elementId`} component={ElementRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
