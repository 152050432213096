import React from 'react';
import QueueAnim from 'rc-queue-anim';
import {connect} from 'react-redux';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconAdd from '@material-ui/icons/Add';

import ClassificationsTable from 'components/Tables/PagedTable/ClassificationsTable';
import ClassificationModal from 'components/Dialogs/Classifications';
import {openClassificationDialog} from "reducers/Dialogs/Classifications";

const ClassificationList = ({openClassificationDialog}) =>  (
  <section className={"container-fluid chapter"}>
    <ClassificationModal />
    <QueueAnim type={"bottom"} className={"ui-animate"}>
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              <Tooltip title="Create New Classification">
                <Fab color="primary" style={{'marginRight':'20px'}}>
                  <IconAdd onClick={openClassificationDialog} />
                </Fab>
              </Tooltip>
              Classifications
            </h2>
            <ClassificationsTable />
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  openClassificationDialog: () => dispatch(openClassificationDialog()),
});

export const ClassificationListView = connect(mapStateToProps, mapDispatchToProps)(ClassificationList);
