import {apiClient} from 'constants/Clients';

import PagedTable, {invalidateState} from "../../util/pagedTable";
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";

import {BOOK_DELETE, deleteBooks} from "../../books";

const BooksTable = PagedTable('books',
  () => apiClient.getBooks(),
  (state, action) => {
    if (action && action.type === CLIENT_REQUEST_SUCCESS && action.requestType === BOOK_DELETE) {
      return invalidateState(state);
    }
    return state;
  }
);

export function deleteSelectedBooks() {
  return (dispatch, getState) => {
    const {selected} = BooksTable.Selectors.getState(getState());
    if (selected.length > 0) {
      deleteBooks(selected)(dispatch);
    }
  }
}

export default BooksTable;