import React, {useCallback, useState} from "react";
import {
  Chip,
  IconButton,
  TextField,
  LinearProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import IconSave from '@material-ui/icons/Save';

import {withClient} from "reducers/client";
import {mediaTags, addMediaTags, removeMediaTags} from "reducers/client/requestTypes";

const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(1),
  },
  tagArea: {
    '& > *': {
      margin: theme.spacing(0.5),
    }
  }
}));

const MediaTagEditorBase = ({mediaTags, addMediaTags, removeMediaTags}) => {
  const classes = useStyles();
  const [newTagName, setNewTagName] = useState('');
  const handleChangeNewTagName = useCallback(ev => setNewTagName(ev.target.value), []);
  const handleSaveNewTag = useCallback(() => {
    addMediaTags.sendRequest([newTagName])
      .then(() => mediaTags.sendRequest());
    setNewTagName('');
  }, [newTagName]);
  const handleTagInputKeyPress = useCallback(ev => {
    if(ev.keyCode == 13) handleSaveNewTag();
  });
  const waiting = mediaTags.isLoading() || addMediaTags.isLoading() || removeMediaTags.isLoading();

  return (
    <div className={classes.root}>
      {waiting && <LinearProgress />}
      <div className={classes.tagArea}>
        {mediaTags.isLoaded() && mediaTags.get().map(tag => (
          <Chip
            key={tag.tag}
            label={tag.tag}
            onDelete={() => removeMediaTags.sendRequest([tag.tag]).then(() => mediaTags.sendRequest())}
          />
        ))}
      </div>
      <div>
        <TextField
          label="Add a tag"
          value={newTagName}
          onChange={handleChangeNewTagName}
          disabled={addMediaTags.isLoading()}
          inputProps={{onKeyDown:handleTagInputKeyPress}}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSaveNewTag}>
                <IconSave />
              </IconButton>
            )
          }}
        />
      </div>
    </div>
  );
};

const getMediaId = (state, props) => props.mediaId;
export const MediaTagEditor = withClient({
  hooks: {
    mediaTags: mediaTags(getMediaId),
    addMediaTags: addMediaTags(getMediaId),
    removeMediaTags: removeMediaTags(getMediaId),
  }
})(MediaTagEditorBase);