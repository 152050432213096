import React from "react";
import classnames from 'classnames';

import {withStyles} from "@material-ui/core/styles";

import icons, {Dropdown} from "components/Prosemirror/icons";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {BUTTON, ACTIVE} from "components/Prosemirror/menu/components/styles";

import MenuPopout from "./MenuPopout";

import {insertTable} from "../../commands/table";

const styles = theme => ({
  BUTTON,
  ACTIVE,
  root: {
    position: 'relative'
  },
  boxIcon: {
    display: 'block',
    margin: 'auto',
    width: `${17}px`,
    height: `${17}px`,
    border: `1px solid white`,
    '$BUTTON:hover &': {
      borderColor: 'black',
    }
  },
  selected: {
    backgroundColor: 'white',
    '& $boxIcon': {
      borderColor: 'black',
    }
  },
  info: {
    gridColumn: '1 / span 6',
    color: 'white',
    textAlign: 'center',
  }
});

const includedInSelection = (i, hoverOption) => i < hoverOption && i % 6 <= hoverOption % 6;

class MenuInsertTable extends React.Component {
  state = {
    anchorEl: null,
    hoverOption: -1,
  };

  handleButtonClick(event) {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });

    const closeMenu = ev => {
      this.setState({anchorEl: null});
      document.removeEventListener('mousedown',closeMenu,false);
    };
    document.addEventListener('mousedown',closeMenu, false);
  }

  handleOptionClick(event, value) {
    const {prosemirror} = this.props;
    event.preventDefault();
    this.setState({ anchorEl: false });
    insertTable(value % 6 + 1, Math.floor(value / 6) +  1)(prosemirror.state, prosemirror.dispatch.toProsemirror, prosemirror.view);
    // toggleCellBorders(value)(prosemirror.state, prosemirror.dispatch.toProsemirror, prosemirror.view);
  }
  handleOptionEnter(event, value) {
    this.setState({hoverOption: value});
  }
  handleOptionExit(event) {
    this.setState({hoverOption: -1})
  }

  render() {
    const {classes, title} = this.props;
    const {anchorEl, hoverOption} = this.state;

    return (
      <span className={classes.root}>
        <button
          className={classnames(classes.BUTTON)}
          title={title}
          onMouseDown={ev => this.handleButtonClick(ev)}
        >
          <Dropdown>
            {icons.insert_table}
          </Dropdown>
        </button>
        <MenuPopout anchorEl={anchorEl} columns={6}>
          {Array(6*6).fill().map((option, i) => (
            <button
              key={i}
              className={classnames({
                [classes.BUTTON]: true,
                [classes.selected]: includedInSelection(i, hoverOption),
              })}
              onMouseDown={ev => this.handleOptionClick(ev, i)}
              onPointerEnter={ev => this.handleOptionEnter(ev, i)}
            >
              <i className={classes.boxIcon} />
            </button>
          ))}
          <div className={classes.info}>
            {hoverOption >= 0 ? `${hoverOption % 6 + 1} \u00d7 ${Math.floor(hoverOption / 6) + 1}` : '...'}
          </div>
        </MenuPopout>
      </span>
    );
  }
}

export default withStyles(styles)(withProsemirror(MenuInsertTable));

