import React from 'react';
import config from 'constants/Config';
import Button from '@material-ui/core/Button';
import QueueAnim from 'rc-queue-anim';

const onClickLoginSSOButton = () => {
  window.location = (window && window.ssoRedirect) ?
    window.ssoRedirect :
    config.ssoRedirect;
};

const Login = () => (
  <section className="container-fluid">
    <div className="body-inner">
      <div className="card bg-white">
        <div className="card-content">
          <section className="logo text-center">
            <img src="/assets/images/cpm-logo.png" className="login-logo" />
            <h4>CPM C3PO</h4>
          </section>
          <hr />
          <div className="text-center">
            <Button onClick={onClickLoginSSOButton}>Login</Button>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const LoginView = () => (
  <div className="login-bg">
    <section className="container-fluid ">
      <div className="page-login">
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <Login />
            </div>
          </QueueAnim>
        </div>
      </div>
    </section>
  </div>
);