import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import classNames from 'classnames';

import Searchbar from '../../../Searchbar/index';

const styles = theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.A700,
        backgroundColor: theme.palette.secondary.A100,
      }
      : {
        color: theme.palette.secondary.A100,
        backgroundColor: theme.palette.secondary.A700,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    flex: '0 0 auto',
  },
  title: {
    flex: '0 0 auto',
  },
});

class TableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTimeout: null
    };
  }

  render() {
    const { title, select, numSelected, classes, searchValue, actions, onSearch } = this.props;
    const hasSelection = select === 'multiple' && numSelected > 0;

    return (
      <Toolbar
        className={classNames({
          [classes.highlight]: hasSelection,
        })}>
        <div className={classes.title}>
          {hasSelection ? (
            <Typography variant="subtitle1">{numSelected} selected</Typography>
          ) : (
            <Typography variant="h6">{title}</Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {actions && numSelected > 0 ? (
            actions
          ) : (
            <div>
              <Searchbar defaultValue={searchValue} onChange={onSearch} />
            </div>
          )}
        </div>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(TableToolbar);
