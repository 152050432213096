import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from '../../util/pagedTable';

const ContentVersionStandards = PagedTable(
  'contentVersionStandards',
  // TODO WBD find the content version id to pass in here
  state => {
    console.log('in reducer', state);
    if (!validateState(state)) return state;
    return apiClient.getClassificationStandardsForContentVersion(
      state.elements.details[state.elements.selected].data.currentVersionId,
    );
  },
  (state, action) => {
    return state;
  },
);

const validateState = state => {
  // Check that we have the relevant parts of state from prior client requests
  if (!(
    state.elements.selected &&
    state.elements.details &&
    state.elements.details[state.elements.selected] &&
    state.elements.details[state.elements.selected].data &&
    state.elements.details[state.elements.selected].data.currentVersionId
  )) {
    return false;
  }
  return true;
};

export default ContentVersionStandards;