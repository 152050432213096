import React from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Checkbox,
  Select,
  MenuItem, Button,
} from "@material-ui/core";
import IconHandle from "@material-ui/icons/DragHandle";
import IconDelete from "@material-ui/icons/Delete";
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";
import IconCheck from '@material-ui/icons/Check';

import {withClient} from "reducers/client";
import {manual, lessonContainers} from "reducers/client/requestTypes";

import GroupAssessmentProblemElement from './GroupAssessmentProblemElement';

class GroupAssessmentProblemsTableRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    lessonContainers: PropTypes.object.isRequired,

    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    adding: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    pendingChanges: PropTypes.bool.isRequired,
    editingId: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,

    onEdit: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      lessonContainers,
      item: assessmentProblem,
      index,
      adding,
      waiting,
      pendingChanges,
      editingId,
      edit,
      onEdit,
      onClickEdit,
      onClickSave,
      onClickDelete,

      history,
      staticContext,

      ...other
    } = this.props;

    let elements = assessmentProblem.elements || [];
    const isEditingThis = editingId === index;
    const isEditingAnother = !isEditingThis && editingId !== -1;

    return (<React.Fragment>
      <TableRow hover {...other}>
        <TableCell padding="checkbox">
          <IconHandle className={classes.dragHandle}/>
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <TextField
              value={edit.assessmentQuestionId}
              disabled
            />
          ) : (
            assessmentProblem.assessmentQuestionId
          )}
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <TextField
              value={edit.name}
              onChange={ev => onEdit('name', ev)}
              disabled={waiting}
              autoFocus
            />
          ) : (
            assessmentProblem.name
          )}
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <Checkbox
              checked={edit.isTeam}
              onChange={ev => onEdit('isTeam', {target:{value:ev.target.checked}})}
              disabled={waiting}
            />
          ) : (
            assessmentProblem.isTeam && <IconCheck />
          )}
        </TableCell>
        <TableCell>
          {isEditingThis ? (
            <Select
              value={edit.difficultyLevel}
              onChange={ev => onEdit('difficultyLevel', ev)}
              disabled={waiting}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
            </Select>
          ) : (
            assessmentProblem.difficultyLevel
          )}
        </TableCell>
        <TableCell>
          {elements.map(e => {
            return (
              <GroupAssessmentProblemElement
                key={e.id}
                assessmentProblemId={assessmentProblem.id}
                element={e}
                mode="link"
                disabled={adding || editingId !== -1}
              />
            );
          })}
        </TableCell>
        <TableCell padding="none" align="right">
          {isEditingThis ? (
            <React.Fragment>
              <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
                <IconDelete fontSize="small"/>
              </IconButton>
              <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
                {pendingChanges ? (
                  <IconSave fontSize="small"/>
                ):(
                  <IconCancel fontSize="small"/>
                )}
              </IconButton>
            </React.Fragment>
          ) : !adding && !isEditingAnother && (
            <div>
              <Button color="primary" onClick={() => history.push(`/app/assessmentproblems/${assessmentProblem.id}/standards/assessmentproblem`)}>Standards</Button>
              <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
                <IconEdit fontSize="small"/>
              </IconButton>
            </div>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    lessonContainers: manual(lessonContainers((state, props) => props.item.lessonId)),
  }
})(withRouter(GroupAssessmentProblemsTableRow));
