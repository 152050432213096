import Desmos from 'Desmos';

export default (dom, options) => {
  const calculators = dom.getElementsByClassName('desmos-calculator');

  console.log('Desmos render step',calculators);

  for (let i = 0; i < calculators.length; ++i) {
    let type = calculators[i].getAttribute('data-type');
    let innerView;
    switch(type) {
      case 'sci':
        var degreeMode = !!calculators[i].getAttribute('data-degree-mode');
        innerView = Desmos.ScientificCalculator(calculators[i], {
          degreeMode: degreeMode
        });
        break;
      case 'ff':
        innerView = Desmos.FourFunctionCalculator(calculators[i]);
        break;
      case 'graph':
      default:
        innerView = Desmos.GraphingCalculator(calculators[i],{
          administerSecretFolders: false,
          keypad: false,
          expressions: false,
          settingsMenu: false,
          zoomButtons: false,
          lockViewport: true
        });
        break;
    }

    let state = calculators[i].getAttribute('data-state');
    if (state && state !== 'null') {
      innerView.setState(state);
    } else {
      $.getJSON(calculators[i].getAttribute('data-src')).then(res => innerView.setState(res.state));
    }
  }
};
