import {onDoubleClick} from "../util";
import MathJax from 'MathJax';
import {log} from "constants/Config";

export const styleMathpixMediaPlugin = theme => ({
  mpViewBadge: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    marginRight: `${4}px`,
    marginBottom: `${4}px`,
    padding: `0 ${6}px`,
    zIndex: 1,

    border: 'none',
    color: 'white',
    fontFamily: 'initial',
    fontSize: 'initial',
    fontStyle: 'initial',
    fontWeight: 'initial',
    borderRadius: `${12}px`,
    backgroundColor: theme.palette.error.main,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
  },
});

export default class MathpixMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] create CpmMediaView#${this.outerView.id}::MathpixMediaView`, media);
    }

    if (media.mathPixFoundLatex) {
      this.hasJax = true;
      this.dom = document.createElement('script');
      this.dom.setAttribute('type','math/tex');
      this.dom.innerText = media.latex;

      window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));
    } else {
      this.dom = document.createElement('img');
      this.dom.setAttribute('src',media.publicUrl);
    }
    this.outerView.dom.appendChild(this.dom);

    this.badge = document.createElement('div');
    this.badge.setAttribute('class',this.outerView.interface.classes.mpViewBadge);
    this.badge.innerText = "MathPix";
    this.outerView.dom.appendChild(this.badge);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.dispatch({method: 'mathpix'});
    });
  }

  destroy() {
    if (log.prosemirror) {
      console.log(`[PROSEMIRROR] destroy CpmMediaView#${this.outerView.id}::MathpixMediaView`, this.media);
    }
    if (this.hasJax) {
      const math = MathJax.Hub.getAllJax(this.outerView.dom);
      math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));
    }

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
    this.outerView.dom.removeChild(this.badge);
  }
}