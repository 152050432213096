import React from 'react';

import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconError from '@material-ui/icons/Error';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

import {connect} from 'react-redux';
import {
  STATUS_READY,
  STATUS_WAITING,
  STATUS_SUCCESS,
  STATUS_FAILURE
} from 'constants/Dialogs';
import {
  getUploadStatus,
  closeImageDialog,
  uploadImage,
} from 'reducers/Dialogs/Image';

import Button from "@material-ui/core/Button";

import ImageDrop from 'components/Media/ImageDrop';

class ImageUploadDialog extends React.Component {
  state = {
    uploadedImageSrc: ''
  };

  handleClose() {
    const { mappedCloseDialog } = this.props;
    mappedCloseDialog();
  }

  handleSelectFile(file) {
    const {afterImageUpload} = this.props;
    const {mappedUploadImage, mappedCloseDialog} = this.props;
    mappedUploadImage(file).then(result => {
      if(afterImageUpload){
        afterImageUpload(result)
      }
      this.setState({uploadedImageSrc: result.publicUrl});
    });
  }

  render() {
    console.log('ImageUploadDialog.render()');

    const { isOpen, status }  = this.props;

    let content = false;
    let allowCancel = true;
    let closeText = 'Cancel';

    switch(status) {
      case STATUS_READY:
        content = <ImageDrop onSelect={file => this.handleSelectFile(file)} />;
        break;
      case STATUS_SUCCESS:
        content = (<div>
          <p><img src={this.state.uploadedImageSrc} /></p>
          <p><IconCheckCircle /> Successfully uploaded</p>
        </div>);
        closeText = 'Ok';
        break;
      case STATUS_WAITING:
        content = (<div style={{textAlign:'center'}}>
          <CircularProgress size={100} />
        </div>);
        allowCancel = false;
        break;
      case STATUS_FAILURE:
        content = (<div>
          <IconError /> An error occurred
        </div>);
        closeText = 'Ok';
        break;
      default:
        break;
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth>
      <DialogTitle>Upload Image</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={ev => this.handleClose(ev)} color="primary" disabled={!allowCancel}>
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = state => ({
  isOpen: state.dialogs.image.isOpen,
  status: getUploadStatus(state),
});

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeImageDialog()),
  mappedUploadImage: file => dispatch(uploadImage(file))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageUploadDialog);
