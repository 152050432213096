const MAX_DOUBLE_CLICK_TIME = 400;

export function onDoubleClick(dom, callback) {
  let onSecondClick;

  const onFirstClick = (event) => {
    dom.removeEventListener('click', onFirstClick, false);
    dom.addEventListener('click', onSecondClick, false);

    window.setTimeout(() => {
      dom.removeEventListener('click', onSecondClick, false);
      dom.addEventListener('click', onFirstClick, false);
    }, MAX_DOUBLE_CLICK_TIME);
  };

  onSecondClick = (event) => {
    dom.removeEventListener('click', onSecondClick, false);
    dom.addEventListener('click', onFirstClick, false);

    callback.apply(dom, event);
  };

  dom.addEventListener('click', onFirstClick, false);

  return () => {
    dom.removeEventListener('click', onFirstClick, false);
    dom.removeEventListener('click', onSecondClick, false);
  }
}
