const OPEN_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG = 'OPEN_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG';
const CLOSE_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG = 'CLOSE_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG';
const SET_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG_DATA = 'SET_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG_DATA';

export const openLessonContentContainerElementDialog = () => (
  {type: OPEN_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG}
);
export const closeLessonContentContainerElementDialog = () => (
  {type: CLOSE_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG}
);
export const setLessonContentContainerElementDialogData = payload => (
  {type: SET_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG_DATA, payload}
);

const initialSettings = {
  isOpen: false,
  data: null,
};

export default (state = initialSettings, action) => {

  switch (action.type) {
    case OPEN_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG: return {...state, isOpen: true};
    case CLOSE_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG: return {...state, isOpen: false};
    case SET_LESSON_CONTENT_CONTAINER_ELEMENT_DIALOG_DATA: return {...state, data: action.payload};
    default: return state;
  }
};
