import React from 'react';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import {LessonContentContainerBreadcrumb} from 'components/Breadcrumb';
import ElementVersionsTable from 'components/Tables/PagedTable/ElementVersionsTable';
import ContentPreview from 'components/Editor/Views/ContentPreview';
import {renderHomework} from "components/Prosemirror/render";

import elementVersions from "reducers/LegacyTables/elementVersions";
import Tooltip from "@material-ui/core/Tooltip";
import {defaultElementTypeForContentable} from "constants/ElementTypes";
import {createSelector, withClient} from "reducers/client";
import {
  elementDetails,
  lessonContentContainerDetails,
  lessonContentContainerElements,
  updateLessonContentContainer
} from "reducers/client/requestTypes";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const gutter = 30;

const styles = theme => ({
  root: {
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${gutter}px`,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`,
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: `${gutter}px`
  },
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${550}px`
  }
});

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {initialSet: false};
  }

  componentDidMount() {
    const {setTableSort} = this.props;
    setTableSort('createdAt');
    this.tryGetDetails();
  }
  componentDidUpdate() {
    this.tryGetDetails();
  }

  tryGetDetails() {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      if (!this.state.initialSet) {
        const {setSelectedContentVersion} = this.props;
        setSelectedContentVersion(elementDetails.get().currentVersionId);
        this.setState({ initialSet: true });
      }
    }
  }

  showEditor() {
    this.props.history.push(`/app/lessonContentContainers/${this.props.lessonContentContainerDetails.getParams()}/editor`);
  }

  getCurrentContent() {
    const {tableSelection} = this.props;

    if (tableSelection.length) {
      if (tableSelection[0] && tableSelection[0].html) {
        return {__html: tableSelection[0].html };
      }
    }
    return {__html: ''};
  }
  getSelectedVersionId() {
    const {tableSelection} = this.props;

    if (tableSelection && tableSelection[0]) {
      return tableSelection[0].id;
    }
  }

  onSetVersion() {
    this.props.history.push(`/app/lessonContentContainers/${this.props.lessonContentContainerDetails.getParams()}/editor`);
  }

  render() {
    const {elementDetails: elementRequest, lessonContentContainerDetails: containerRequest, match, classes} = this.props;

    if (!elementRequest.isLoaded()) {
      return <div />;
    }

    const lessonContentContainerDetails = containerRequest.get();
    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <Tooltip title="Back">
            <Fab color="primary" className={classes.button}>
              <ArrowBack onClick={e => this.showEditor()} />
            </Fab>
          </Tooltip>
          <ElementVersionsTable elementId={elementRequest.getParams()} onSetVersion={() => this.onSetVersion()} />
        </div>
        <h2 className="article-title">{match.params.elementType} '{lessonContentContainerDetails.name}' versions</h2>
        <div>
          <LessonContentContainerBreadcrumb containerId={containerRequest.getParams()} />
        </div>
        <div className={classes.versionsContainer}>
          {/*<div className={classes.previewPane} dangerouslySetInnerHTML={this.getCurrentContent()} />*/}
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={renderHomework} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </div>
    );
  }
}

const StyledComponent = withStyles(styles)(BaseComponent);

const mapStateToProps = (state, props) => ({
  contentVersions: elementVersions.Selectors.getPageContent(state),
  tableState: elementVersions.Selectors.getState(state),
  tableSelection: elementVersions.Selectors.getSelection(state),
});

const mapDispatchToProps = dispatch => ({
  setSelectedContentVersion: versionID => dispatch(elementVersions.Actions.setSelection(versionID)),
  setTableSort: column => dispatch(elementVersions.Actions.changeSort(column)),
});

const getContainerId = (state, props) => state.lessonContentContainers.selected;
const getElementType = (state, props) => props.match.params.elementType || defaultElementType.name;
const getContainerElements = createSelector('lessonContentContainerElements',getContainerId);
export const LessonContentContainerVersionsView = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getContainerId),
    lessonContentContainerElements: lessonContentContainerElements(getContainerId),
    elementDetails: elementDetails((state, props) => {
      const containerElements = getContainerElements(state, props);
      if (containerElements && containerElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const containerElement = containerElements.get().find(e => e.elementType === elementType);
        if (containerElement) return containerElement.id;
      }
      return null;
    }),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledComponent));
