import {combineReducers} from 'redux';

import books from './books';
import elementVersions from './elementVersions';
import imageBrowser from './imageBrowser';
import standards from './standards';
import standardLessons from './standardLessons';
import standardLessonContentContainers from './standardLessonContentContainers';
import lessonAssociatedLessons from './lessonAssociatedLessons';
import lessonAssociatedContainers from './lessonAssociatedContainers';
import containerAssociatedLessons from './containerAssociatedLessons';
import containerAssociatedContainers from './containerAssociatedContainers';
import elementAssociatedContainers from './elementAssociatedContainers';
import classifications from './classifications';
import standardsForClassification from './standardsForClassification';
import elementControlRequirementStatuses from './elementControlRequirementStatuses';
import contentVersionStandards from './contentVersionStandards';
import bookMenus from './bookMenus';
import groupAssessmentProblems from './groupAssessmentProblems';
import elementStandards from './elementStandards';
import elementAddStandards from './elementAddStandards';

export default combineReducers({
  books,
  classifications,
  lessonAssociatedLessons,
  lessonAssociatedContainers,
  elementVersions,
  imageBrowser,
  containerAssociatedLessons,
  containerAssociatedContainers,
  elementAssociatedContainers,
  standards,
  standardLessons,
  standardLessonContentContainers,
  standardsForClassification,
  elementControlRequirementStatuses,
  contentVersionStandards,
  bookMenus,
  groupAssessmentProblems,
  elementStandards,
  elementAddStandards,
});
