import {apiClient} from "constants/Clients";

export const lessonContentContainerDetails = key => ({
  request: containerId => apiClient.getLessonContentContainer(containerId),
  key,
  auto: true,
});
export const updateLessonContentContainer = key => ({
  request: (containerId, container) => apiClient.updateLessonContentContainer({id: containerId, ...container}),
  key,
});
export const saveLessonContentContainerWithContent = {
  request: data => apiClient.createLessonContentContainerWithContent(data),
};
export const deleteLessonContentContainer = key => ({
  request: containerId => apiClient.deleteLessonContentContainer(containerId),
  key,
});
export const mergeLessonContentContainers = {
  request: mergeSpec =>  apiClient.mergeLessonContentContainers(mergeSpec)
};
export const lessonContentContainerCreateElement = key => ({
  request: (containerId, element) => apiClient.createLessonContentContainerElement(containerId, element),
  key,
});
export const lessonContentContainerElements = key => ({
  request: containerId => apiClient.getLessonContentContainerElements(containerId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const lessonContentContainerAddElement = key => ({
  request: (containerId, elementId) => apiClient.addLessonContentContainerElement(containerId, elementId),
  key,
});
export const lessonContentContainerRemoveElement = key => ({
  request: (containerId, elementId) => apiClient.removeLessonContentContainerElement(containerId, elementId),
  key,
});
export const homeworkResources = key => ({
  request: problemId => apiClient.getHomeworkHelpProblemResources(problemId),
  key,
  auto: true,
});
export const lessonContentContainerReference = key => ({
  request: ({id, elementType}) => apiClient.getLessonContentContainerReferenceForEditorByElementType(id, elementType),
  key,
  auto: true,
});
