import React from "react";
import {
  addColumnBefore,
  addColumnAfter,
  addRowBefore,
  addRowAfter,
  mergeCells,
  splitCell,
  toggleHeaderCell,
  isInTable,
  setCellAttr
} from 'prosemirror-tables';

import MenuGroup from '../components/MenuGroup';
import MenuButton from '../components/MenuButton';
import MenuExpand from '../components/MenuExpand';
import MenuSelect from '../components/MenuSelect';
import MenuTableBorder from '../components/MenuTableBorder';

import schema from '../../schema';
import icons, {highlightColor} from '../../icons';
import {ALIGNMENTS} from "../table";
import {selectionInside, getAttribute} from '../util';
import {setTableAlignment, getCellAlignment} from "../../commands/table";

import {asArray} from "constants/util/map";
import Colors from "constants/Colors";

const tableProperties = (
  <MenuButton
    command={(state, dispatch) => dispatch({external: true, method: 'table'})}
    title="Table Properties">
    {icons.cog}
  </MenuButton>
);
const tableAddCells = (
  <MenuExpand enabled={addColumnAfter} title="Insert rows/columns" icon={icons.plus}>
    <MenuButton
      enabled={addColumnBefore}
      command={addColumnBefore}
      title="Insert column before">
      {icons.table.columnBefore}
    </MenuButton>
    <MenuButton
      enabled={addColumnAfter}
      command={addColumnAfter}
      title="Insert column after">
      {icons.table.columnAfter}
    </MenuButton>
    <MenuButton
      enabled={addRowBefore}
      command={addRowBefore}
      title="Insert row before">
      {icons.table.rowBefore}
    </MenuButton>
    <MenuButton
      enabled={addRowAfter}
      command={addRowAfter}
      title="Insert row after">
      {icons.table.rowAfter}
    </MenuButton>
  </MenuExpand>
);
const tableMergeCells = (
  <MenuButton
    enabled={mergeCells}
    command={mergeCells}
    title="Merge Cells">
    {icons.compress}
  </MenuButton>
);
const tableSplitCell = (
  <MenuButton
    enabled={splitCell}
    command={splitCell}
    title="Split Cell">
    {icons.columns[0]}
  </MenuButton>
);
const tableToggleHeader = (
  <MenuButton
    enabled={toggleHeaderCell}
    command={toggleHeaderCell}
    title="Toggle Header">
    {icons.h_square}
  </MenuButton>
);
const tableSetAlignment = (
  <MenuSelect
    style={{fontSize: `${110}%`}}
    columns={3}
    enabled={isInTable}
    command={alignment => setTableAlignment(alignment)}
    selected={getCellAlignment}
    options={ALIGNMENTS}
    title="Cell Alignment"
  />
);
const tableBackgroundColor = (
  <MenuSelect
    enabled={isInTable}
    command={color => setCellAttr('color', color)}
    selected={getAttribute([schema.nodes.table_cell, schema.nodes.table_header], 'color')}
    options={asArray(Colors).map(c => ({
      ...c,
      icon: c.color ? highlightColor(c.color) : icons.highlight,
    }))}
    title="Background Color"
  />
);

export const tableMenu = (<MenuGroup key="table" visible={selectionInside(schema.nodes.table)}>
  {tableProperties}
  {tableAddCells}
  {tableMergeCells}
  {tableSplitCell}
  {tableToggleHeader}
  {tableSetAlignment}
  <MenuTableBorder />
  {tableBackgroundColor}
</MenuGroup>);

export const assessmentTableMenu = (<MenuGroup key="table" visible={selectionInside(schema.nodes.table)}>
  {tableProperties}
  {tableAddCells}
  {tableMergeCells}
  {tableSplitCell}
  {tableToggleHeader}
  {tableSetAlignment}
  {tableBackgroundColor}
</MenuGroup>);
