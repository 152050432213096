function buildHintTree(dom) {
  var tree = {
    nodes: {},
    root: []
  };
  var waiting = [];

  dom.querySelectorAll('.homeworkHint').forEach(function (element) {
    var checkbox = element.querySelector('input');
    var hint = {
      element: element,
      checkbox: checkbox,
      id: element.getAttribute('hint-id'),
      targets: [],
      children: []
    };
    var parent = element.parentElement;
    while (parent) {
      if (parent.classList.contains('homeworkContainer')) {
        hint.parentID = parent.getAttribute('hint-id');
        break;
      }
      parent = parent.parentElement;
    }

    element.addEventListener('click', onHintClick(hint));

    tree.nodes[hint.id] = hint;
    if (hint.parentID) {
      if (tree.nodes[hint.parentID]) {
        tree.nodes[hint.parentID].children.push(hint);
      } else {
        waiting.push(hint);
      }
    } else {
      tree.root.push(hint);
    }
  });

  var escape = 0;
  while (waiting.length) {
    var next = waiting.shift();
    if (tree.nodes[next.parentID]) {
      tree.nodes[next.parentID].children.push(next);
    } else {
      waiting.push(next);
    }
    ++ escape;
    if (escape > 1000) {
      console.error('Unable to fully build hint tree from DOM. Escaped after 1000 iterations.');
      break;
    }
  }

  return tree;
}

function toggleHint(hint, parentHidden) {
  console.log('toggleHint()', hint.id, parentHidden);
  var hintVisible = hint.checkbox.checked && !parentHidden;
  var i;
  for (i = 0; i < hint.targets.length; ++i) {
    hint.targets[i].style.display = hintVisible ? '' : 'none';
  }
  for (i = 0; i < hint.children.length; ++i) {
    toggleHint(hint.children[i], !hintVisible);
  }
}

function onHintClick(hint) {
  return function (ev) { toggleHint(hint); };
}


export default dom => {
  console.log('Homework Help Processing', dom);
  var i;
  var sequences = dom.querySelectorAll('.sequence-container');
  for (i = 0; i < sequences.length; ++i) {
    var height = 0;
    for (var j = 0; j < sequences[i].children.length; ++j) {
      height = Math.max(height, sequences[i].children[j].offsetHeight);
    }
    sequences[i].style.height = `${height}px`;
  }
  var hints = buildHintTree(dom);
  var containers = dom.querySelectorAll('.homeworkContainer');
  for (i = 0; i < containers.length; ++i) {
    containers[i].style.display = 'none';
    hints.nodes[containers[i].getAttribute('hint-id')].targets.push(containers[i]);
  }
};
