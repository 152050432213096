import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const renderField = ({ input, meta: {touched, error}, ...other }) => (
  <React.Fragment>
    <TextField
      {...input}
      error={!!(touched && error)}
      {...other}
    />
    {touched && error && <span style={{color: 'red'}}>{error}</span>}
    <br />
  </React.Fragment>
);
export const renderSelect = ({ id, input, meta: {touched, error}, label, children, disabled, ...innerProps }) => (
  <div>
    <FormControl {...innerProps}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        style={{'minWidth':'120px'}}
        {...input}
        inputProps={{id}}
        disabled={disabled}
        onChange={event => input.onChange(event.target.value)}
      >
        {children}
      </Select>
    </FormControl>
    <br />

    <div>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);
export const renderCheckbox = ({ input, meta: {touched, error}, label, ...innerProps }) => (
  <React.Fragment>
    <FormControl {...innerProps}>
      <FormControlLabel control={
        <Checkbox checked={Boolean(input.value)} onChange={event => input.onChange(event.target.checked)} />
      } label={label}/>
    </FormControl>
    {touched && error && <span style={{color: 'red'}}>{error}</span>}
    <br />
  </React.Fragment>
);
export const renderRadio = ({ input, label, meta: {touched, error}, children, ...innerProps }) => (
  <div>
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
              {...input}
              onChange={event => input.onChange(event.target.value)}>
        {children}
      </RadioGroup>
    </FormControl>
    <br />

    <div>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);