import React from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import ElementParents from 'components/LinkList/ElementParents';
import SaveElementDialogForm from './form';

import {
  getApprovalStatusForContentVersion,
} from 'reducers/elements';
import {
  showSnackbarSaving,
  showSnackbarSaveSuccess,
  showSnackbarSaveError
} from "reducers/Prosemirror";

import {withClient} from "reducers/client";
import {
  manual,
  elementDetails,
  elementParents,
  elementCreateCurrentVersion,
  elementSplitCurrentVersion,
  contentVersionApprovalStatus,
  updateApprovalStatus,
} from "reducers/client/requestTypes";
import {clearRequest} from "reducers/client/actions";
import {createDialogDataSelector, reduxDialog} from "reducers/dialog";

class SaveElementDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleSubmit = () => {
    const {
      dialog: {
        data: {
          markup,
          html
        }
      },
      elementDetails,
      elementCreateCurrentVersion,
      getApprovalStatusForContentVersion,
      contentVersionApprovalStatus,
      updateApprovalStatus,
      showSnackbarSaving,
      showSnackbarSaveSuccess,
      showSnackbarSaveError,
      formData,
      clearRequest,
    } = this.props;

    if (!elementDetails.isLoaded()) return false;
    const {title, currentVersionId, id: elementId} = elementDetails.get();

    const contentVersion = {
      name: title,
      markup: JSON.stringify(markup),
      html
    };

    showSnackbarSaving();
    elementCreateCurrentVersion.sendRequest(contentVersion)
      .then(contentVersion => {
        showSnackbarSaveSuccess();
        console.log('above handleSave', currentVersionId, contentVersion);
        // Quick hack fix for #165
        clearRequest('elementDetails', elementId);
        console.log('in handleSave', currentVersionId, contentVersion.id);
        // This is still connected to the big approval status button
        getApprovalStatusForContentVersion(contentVersion.id);
        return contentVersionApprovalStatus.sendRequest(contentVersion.id);
      })
      .then(approvalStatus => {
        console.log(approvalStatus, formData);
        return updateApprovalStatus.sendRequest(approvalStatus.id, {
          ...approvalStatus,
          ...formData
        });
      })
      .catch(err => {
        console.error(err);
        showSnackbarSaveError();
      });

    this.handleClose();
  };
  handleSplit = () => {
    const {
      dialog: {
        data: {
          markup,
          html
        }
      },
      elementDetails,
      elementParents,
      getApprovalStatusForContentVersion,

      contentableId,
      showSnackbarSaving,
      showSnackbarSaveSuccess,
      showSnackbarSaveError,
      clearRequest,
      elementSplitCurrentVersion
    } = this.props;

    if (!elementDetails.isLoaded()) return false;

    const contentVersion = {
      name: elementDetails.get().title,
      markup: JSON.stringify(markup),
      html
    };

    console.log(contentableId);
    elementSplitCurrentVersion.sendRequest(contentVersion, false, contentableId)
      .then(contentVersion => {
        if (elementParents.isLoaded()) {
          elementParents.get().parents.forEach(p => {
            switch(p.type) {
              case 'lessonContentContainer': clearRequest('lessonContentContainerElements', p.id); break;
              case 'tab': clearRequest('tabElements', p.id); break;
            }
          })
        }
        showSnackbarSaveSuccess();
        getApprovalStatusForContentVersion(contentVersion.id);
      })
      .catch(err => {
        console.error(err);
        showSnackbarSaveError();
      });

    showSnackbarSaving();
    this.handleClose();
  };

  render() {
    const {
      dialog: {
        isOpen
      },
      elementParents
    } = this.props;

    let isLinked = elementParents.isLoaded() && elementParents.get().parents.filter(p => p.type !== 'book').length > 1;

    return (
      <Dialog open={isOpen} disableRestoreFocus>
        <DialogContent>
          <p>Are you sure you want to publish to eBooks?</p>
          <SaveElementDialogForm />
          {isLinked && (
            <div>
              <p>This will affect the following elements:</p>
              <ElementParents elementId={elementParents.getParams()}/>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={e => this.handleClose(e)}>Cancel</Button>
          {isLinked && (<Button color="secondary" onClick={this.handleSplit}>Save and Unlink</Button>)}
          <Button color="primary" onClick={this.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const formValues = getFormValues('saveElementDialog');

const mapStateToProps = (state, props) => ({
  formData: formValues(state),
});

const mapDispatchToProps = dispatch => ({
  getApprovalStatusForContentVersion: contentVersionId => dispatch(getApprovalStatusForContentVersion(contentVersionId)),
  showSnackbarSaving: () => dispatch(showSnackbarSaving()),
  showSnackbarSaveSuccess: () => dispatch(showSnackbarSaveSuccess()),
  showSnackbarSaveError: () => dispatch(showSnackbarSaveError()),

  clearRequest: (name, params) => dispatch(clearRequest(name, params))
});

const getDialogData = createDialogDataSelector('prosemirror-save');
const getElementId = state => {
  const data = getDialogData(state);
  return data && data.elementId;
};
export default withClient({
  hooks: {
    elementDetails: elementDetails(getElementId),
    elementParents: elementParents(getElementId),
    elementCreateCurrentVersion: elementCreateCurrentVersion(getElementId),
    elementSplitCurrentVersion: elementSplitCurrentVersion(getElementId),
    contentVersionApprovalStatus: manual(contentVersionApprovalStatus()),
    updateApprovalStatus: updateApprovalStatus(),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxDialog({
  dialog: 'prosemirror-save',
  initialState: {}
})(SaveElementDialog)));
