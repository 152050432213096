import React from 'react';
import PropTypes from 'prop-types';

import {
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/Save';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconHandle from '@material-ui/icons/DragHandle';
import IconLink from '@material-ui/icons/Link';

import {formatDate} from 'constants/Date';
import {createLoadedSelector, withClient} from "reducers/client";
import {tabElements, elementParents} from "reducers/client/requestTypes";

import {mapParents} from "util/elementStructureRelations";

class TabbedViewTabsTableRow extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    tabElements: PropTypes.object.isRequired,
    elementParents: PropTypes.object.isRequired,

    item: PropTypes.shape({
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    adding: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    pendingChanges: PropTypes.bool.isRequired,
    editingId: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,

    onEdit: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      tabElements,
      elementParents,

      item,
      index,
      adding,
      waiting,
      pendingChanges,
      editingId,
      edit,

      onEdit,
      onClickDelete,
      onClickSave,
      onClickEdit,

      ...other
    } = this.props;

    let parents = [];
    if (elementParents.isLoaded()) {
      parents = mapParents(elementParents.get().parents);
    }

    return (
      <TableRow hover {...other}>
        <TableCell>
          {editingId === index ? (
            <TextField
              value={edit.name}
              onChange={ev => onEdit('name', ev)}
              disabled={waiting}
              autoFocus
            />
          ) : (
            item.name
          )}
        </TableCell>
        <TableCell>
          {formatDate(item.createdAt)}
        </TableCell>
        <TableCell>
          {formatDate(item.updatedAt)}
        </TableCell>
        <TableCell>
          {parents.length > 1 && (
            <Tooltip title={parents.map(p => `${p.bookName} ${p.name}`).join(', ')}>
              <IconButton>
                <IconLink fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell padding="none" align="right">
          {editingId === index ? (
            <React.Fragment>
              <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
                <IconDelete fontSize="small"/>
              </IconButton>
              <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
                {pendingChanges ? (
                  <IconSave fontSize="small"/>
                ):(
                  <IconCancel fontSize="small"/>
                )}
              </IconButton>
            </React.Fragment>
          ) : !adding && editingId === -1 && (
            <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
              <IconEdit fontSize="small"/>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

const getTabId = (state, props) => props.item.id;
const getElementId = createLoadedSelector('tabElements', getTabId, tabElements => tabElements[0].id);

export default withClient({
  hooks: {
    tabElements: tabElements(getTabId),
    elementParents: elementParents(getElementId)
  }
})(TabbedViewTabsTableRow);