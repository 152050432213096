//homework.c3po.css
//homework.c3po.js

import Desmos from 'Desmos';

const baseResourceUrl = 'https://homeworkcontent.cpm.org/CPM/';

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    let x = a[key];
    let y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

function isJsonString(str) {
  let returnString = null;
  try {
    returnString = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return returnString !== null && returnString !== undefined && returnString !== ""
    && returnString !== "null";
}

function capitaliseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function showOrHideHint(resourceID, checked) {
  if (checked) {
    console.log(`Hint ${resourceID} toggled - ON`);
    let resDiv = $("#" + resourceID);
    let resChildren = $(".sequence-container > .childOf_" + resourceID);
    const parentOrderIndex = resDiv.index();
    resChildren.css('z-index',parentOrderIndex);
    resChildren.show();
    $(".childOf_" + resourceID + ":not(.sequence-container > div)").show();
    resDiv.show();

    let children = $(":checkbox").parent(".childOf_" + resourceID);
    for(let i=0; i < children.length; i++){
      let firstParentResourceID = children[i].id;
      $(".childOf_" + firstParentResourceID).hide();
    }
  } else {
    console.log(`Hint ${resourceID} toggled - OFF`);
    //This will hide all children but the checkboxes will stay checked/// ergo uncheck them
    $(".childOf_" + resourceID).hide();
    $(":checkbox").parent(".childOf_" + resourceID).children(":checkbox").attr("checked", false);
  }
}

export default function displayResourceTree(dom, homeworkData) {
  const resourceURL = baseResourceUrl
    + homeworkData.category + "/"
    + homeworkData.book + "/"
    + capitaliseFirstLetter(homeworkData.chapter) + "/"
    + homeworkData.problem;

  $(dom).empty();
  if(homeworkData.resources.length === 1 && homeworkData.resources[0].contentType === "C3PO-Content"){
    $('<h6>This homework problem is already set to display C3PO content.</h6>').appendTo(dom);
    return;
  }
  let resources = sortByKey(homeworkData.resources, 'verticalSequenceNumber');

  let holdingTank =  [];

  $.each(resources, function(i, d) {
    console.log("Adding Resource - VertOrder: " + d.verticalSequenceNumber + " Label: " + d.id  );

    let resDiv = $("#" + d.id);
    if(resDiv.length === 0){
      if(d.container && d.container != 'undefined') {
        if (d.container.indexOf('-')) {
          if ($("#" + d.container.split('-')[0]).length) {
            // Add resource to column container parent
            createResourceHtml(dom, d, resourceURL);
          } else{
            // Wait for parent to be created
            holdingTank.push(d);
          }
        } else if($("#" + d.container).length ) {
          // Add resource to sequence container parent
          createResourceHtml(dom, d, resourceURL);
        } else {
          // Wait for parent to be created
          holdingTank.push(d);
        }
      } else {
        // Add resource to root
        createResourceHtml(dom, d, resourceURL);
      }
    }else{
      console.log("Resource skipped cause its already been added. Label: " + d.id);
    }
  });

  //Final round of cleanup
  for (let i = 0; i < holdingTank.length; i++) {
    console.log("Looping Into Holding Tank Iteration : " +i );
    const d = holdingTank[i];

    let resDiv = $("#" + d.id);
    if(resDiv.length === 0){
      if(d.container.indexOf('-')){
        if($("#" + d.container.split('-')[0]).length ){
          console.log("Adding resource from holding tank to a row container");
          console.log("Container : " + holdingTank[i].container);
          console.log("Resource ID : " + holdingTank[i].id);
          createResourceHtml(dom, d, resourceURL);
        }
      }else if($("#" + d.container).length) {
        console.log("Adding resource from holding tank to a sequence container");
        console.log("Container : " + d.container);
        console.log("Resource ID : " +  d.id);
        createResourceHtml(dom, d, resourceURL);
      }
    }
  }

  $(".hiddenOnLoad").hide();
}

function baseResourceHtml(resource) {
  return $(
    '<div id="'
    + resource.id
    + '" class="resourceContainer" style="width:100%; text-align:left; display:inline-block; margin-top:'
    + resource.topOffset
    + 'px; margin-left:'
    + resource.leftOffset
    + 'px;"></div>');
}

function getResourceParent(resource) {
  if(resource.container && $("#" + resource.container.split('-')[0]).length){
    //First we will need to get the containers resource value to find out 1) is it a table 2) How many columns it has
    //We are dealing with a table scenario
    if(resource.container.indexOf('-') > 0){
      const columnNum = parseInt(resource.container.split('-')[1]);

      let column = "_column_one";
      if(columnNum === 2){ column = "_column_two"; }
      else if(columnNum === 3){ column = "_column_three"; }
      else if(columnNum === 4){ column = "_column_four"; }

      return $("#" + resource.container.split('-')[0] + column);
    }else if (resource.container && $("#" + resource.container).length){
      //Sequence Container
      return $("#" + resource.container + "_sequence_container");
    }
  }
  return null;
}

function createColumns(resource) {
  let [table, columnCount, columnWidths] = resource.resourceValue.split('-');
  const columns = columnWidths.split('.').map((w, i) => {
    let columnId;
    switch(i) {
      case 0: columnId = 'one'; break;
      case 1: columnId = 'two'; break;
      case 2: columnId = 'three'; break;
      case 3: columnId = 'four'; break;
    }
    let columnWidth;
    switch (w) {
      case '25': columnWidth = 3; break;
      case '33': columnWidth = 4; break;
      case '50': columnWidth = 6; break;
      case '66': columnWidth = 8; break;
      case '75': columnWidth = 9; break;
    }
    let id = `${resource.id}_column_${columnId}`;
    let className = `col-sm-${columnWidth}`;
    return $(`<div id="${id}" class='${className}'></div>`);
  });
  return $(`<div id="${resource.id}_table" class='row'></div>`).append(columns);
}

function createResourceHtml(root, resource, imageUrl) {
  let $resource = baseResourceHtml(resource);
  let $parentResource = getResourceParent(resource) || root;
  $('<br/>').appendTo($parentResource);
  $resource.appendTo($parentResource);

  if(resource.parentResource){ //if it has a parent, hide it, parents should only be checkboxes
    $resource.addClass("hiddenOnLoad");
  }

  if(resource.type === 'sequence-container'){
    let style = '';
    if(resource.width > 0 && resource.height > 0){
      style = `height: ${resource.height}px; width: ${resource.width}px;`;
    }else if(resource.width > 0){
      style = `width: ${resource.width}px;`;
    }else if(resource.height > 0){
      style = `height: ${resource.height}px;`;
    }

    $(`<div style="${style}" id="${resource.id}_sequence_container" class='sequence-container'></div>`)
      .appendTo($resource);
  }
  else if(resource.type === 'resource-container'){
    createColumns(resource).appendTo($resource);
  }
  else if (resource.type === 'image') {
    let style = '';
    if(resource.width > 0 && resource.height > 0){
      style = `height: ${resource.height}px; width: ${resource.width}px;`;
    }else if(resource.width > 0){
      style = `width: ${resource.width}px;`;
    }else if(resource.height > 0){
      style = `height: ${resource.height}px;`;
    }

    $(`<img style="${style}" class="resourceImage" id="${resource.id}_image" src="${imageUrl}/${resource.resourceValue}"/>`)
      .appendTo($resource);
  }
  else if (resource.type === 'latex-formula'){
    if(isJsonString(resource.resourceValue)){
      let latexJsonObj = JSON.parse(resource.resourceValue);
      let style = `background-color:${resource.backGroundColor};`;
      if(resource.width > 0 && resource.height > 0){
        style += `height: ${resource.height}px; width: ${resource.width}px;`;
      }else if(resource.width > 0){
        style += `width: ${resource.width}px;`;
      }else if(resource.height > 0){
        style += `height: ${resource.height}px;`;
      }

      $(`<img style="${style}" class="resourceImage" id="${resource.id}_image" src="${imageUrl}/${latexJsonObj.imageUrl}"/>`)
        .appendTo($resource);
    }else{
      $("<b>Please update! Using old LaTeX functionality!</b>")
        .appendTo($resource);
    }
  }
  else if (resource.type === 'text') {
    let style = `display: inline-block;background-color:${resource.backGroundColor};padding:10px;`;
    $(`<p style="${style}" class='resourceText' id="${resource.id}_text">${resource.resourceValue}</p>`)
      .appendTo($resource);
  }
  else if (resource.type === 'url') {
    let width = "100%";
    let height = "300px";
    if(resource.width > 0){
      width = resource.width + "px";
    }
    if(resource.height > 0){
      height = resource.height + "px";
    }
    let style=`width:${width};height:${height}`;

    if (resource.resourceValue.toLowerCase().indexOf('desmos.com') !== -1) {
      // Init Desmos Calculator
      let calculatorContainer =
        $(`<div class="resourceFrame" style="${style}" id="frame_${resource.id}"></div>`)
          .appendTo($resource);

      let calculatorElement = calculatorContainer.get(0);
      let calculator = Desmos.GraphingCalculator(calculatorElement);
      $.getJSON(resource.resourceValue).then(function (msg) {
        calculator.setState(msg.state);
      });
    } else {
      // Init iframe of external url
      $(`<iframe class='resourceFrame' style="${style}" id="frame_${resource.id}" src="${resource.resourceValue}"></iframe>`)
        .appendTo($resource);
    }
  }
  else if (resource.type === 'boolean') {
    let style = "height:14px;";
    let captionStyle = `padding-left:5px;padding-right:5px;background-color:${resource.backGroundColor};`;
    let caption = capitaliseFirstLetter(resource.caption);
    $(`<input class="resourceBox" style="${style}" id="hideShow_${resource.id}" type="checkbox"/>`)
      .click(function(ev) {showOrHideHint(resource.id, this.checked)})
      .appendTo($resource);
    $(`<span style="${captionStyle}">${caption}</span>`)
      .appendTo($resource);
  }

  let parentalLoopingResource = resource;
  while(parentalLoopingResource.parentResource){ //if it has a parent, hide it, parents should only be checkboxes
    $resource.addClass(`childOf_${parentalLoopingResource.parentResource.id}`);
    parentalLoopingResource = parentalLoopingResource.parentResource;
  }
}
