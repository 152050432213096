import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import ClientRequest from "./util/clientRequest";
import {mapSequence} from "./util/sequence";
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const MEDIA_SELECT = 'MEDIA_SELECT';
export const MEDIA_DETAILS = 'MEDIA_DETAILS';
export const MEDIA_SAVE = 'MEDIA_SAVE';
export const MEDIA_DELETE = 'MEDIA_DELETE';

// CHILD REDUCERS
const details = MultiRequest(MEDIA_DETAILS);
const save = ClientRequest(MEDIA_SAVE);
const deleteMedia = ClientRequest(MEDIA_DELETE);

// ACTION CREATORS
export function selectMedia(mediaId) {
  return {type: MEDIA_SELECT, payload: mediaId};
}
export function requestMediaDetails(mediaId) {
  return details.sendRequest(apiClient.getMedia(mediaId), {key: mediaId});
}
export function createMedia(mediaData) {
  return save.sendRequest(apiClient.createMediaForExistingAsset(mediaData));
}
export function deleteMedias(idList) {
  return deleteMedia.sendSequence(mapSequence(id => apiClient.deleteMedia(id), idList));
}

// SELECTORS
const getMediaDetailRequests = state => state.medias.details;
export const makeMediaSelector = mediaIdResolver => makeRequestSelector(mediaIdResolver, getMediaDetailRequests);

export default combineReducers({
  selected: (state = null, action) => (action.type === MEDIA_SELECT ? action.payload : state),
  details,
  save,
  delete: deleteMedia
});
