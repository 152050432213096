import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import IconDelete from "@material-ui/icons/Delete";
import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";

class ChapterAssessmentGroupsTableRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,

    assessmentGroup: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    adding: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    pendingChanges: PropTypes.bool.isRequired,
    editingId: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,

    onEdit: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  };

  handleClick = event => {
    const {index, onClick} = this.props;
    onClick(event, index);
  };

  render() {
    const {
      classes,
      assessmentGroup,
      index,
      adding,
      waiting,
      pendingChanges,
      editingId,
      edit,
      onEdit,
      onClick,
      onClickEdit,
      onClickSave,
      onClickDelete,
      ...other
    } = this.props;

    return (<TableRow hover onClick={onClick} {...other}>
      <TableCell>
        {editingId === index ? (
          <TextField
            value={edit.name}
            onChange={ev => onEdit('name', ev)}
            disabled={waiting}
            autoFocus
          />
        ) : (
          assessmentGroup.name
        )}
      </TableCell>
      <TableCell padding="none" align="right">
        {editingId === index ? (
          <React.Fragment>
            <IconButton onClick={onClickDelete} className={classes.itemButton} disabled={waiting}>
              <IconDelete fontSize="small"/>
            </IconButton>
            <IconButton onClick={onClickSave} className={classes.itemButton} disabled={waiting}>
              {pendingChanges ? (
                <IconSave fontSize="small"/>
              ):(
                <IconCancel fontSize="small"/>
              )}
            </IconButton>
          </React.Fragment>
        ) : !adding && editingId === -1 && (
          <IconButton onClick={() => onClickEdit(index)} className={classes.itemButton} disabled={waiting}>
            <IconEdit fontSize="small"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>);
  }
}

export default ChapterAssessmentGroupsTableRow;