import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabElementRedirect} from 'views/redirects/TabElement';

export const BookNameRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/tabElement/:elementId`} component={TabElementRedirect} />
    <Redirect to="/404" />
  </Switch>
);
