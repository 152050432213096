import React from 'react';
import PropTypes from 'prop-types';

import ElementTypes from 'constants/ElementTypes';

import ElementParents from './ElementParents';
import {withClient} from "reducers/client";
import {tabElements} from "reducers/client/requestTypes";

const LinkedTabs = ({tabElements, elementType}) => {
  if (!tabElements.isLoaded()) return null;
  const tabElement = tabElements.get().find(e => e.elementType === elementType);
  if (tabElement) return <ElementParents elementId={tabElement.id} />;
  return null;
};

LinkedTabs.propTypes = {
  tabId: PropTypes.string.isRequired,
  elementType: PropTypes.string,
};
LinkedTabs.defaultProps = {
  elementType: ElementTypes.tabbedContent.name,
};

const getTabId = (state, props) => props.tabId;
export default withClient({
  hooks: {
    tabElements: tabElements(getTabId),
  }
})(LinkedTabs);
