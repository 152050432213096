import React from 'react';
import PropTypes from "prop-types";

class HtmlPreview extends React.Component {
  static propTypes = {
    contentRef: PropTypes.object,
    html: PropTypes.string.isRequired,
    postProcess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    if (props.contentRef) this.contentRef = props.contentRef;
    else this.contentRef = React.createRef();
  }

  componentDidMount() {this.renderContent(); }
  componentDidUpdate(prevProps) {
    if (prevProps.html !== this.props.html) this.renderContent();
  }

  renderContent() {
    const dom = this.contentRef.current;
    dom.innerHTML = this.props.html;
    if (this.props.postProcess) {
      this.props.postProcess(dom);
    }
  }

  render() {
    const {
      contentRef,
      html,
      postProcess,
      ...innerProps
    } = this.props;
    return <div {...innerProps} ref={this.contentRef}/>;
  }
}

export default HtmlPreview;