import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from '../../util/pagedTable';

const AssociatedLessonsTable = PagedTable(
  'containerAssociatedLessons',
  state => apiClient.getAssociatedLessonsForlessonContentContainer(state.lessonContentContainers.selected),
  (state, action) => {
    return state;
  }
);

export default AssociatedLessonsTable;