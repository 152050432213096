import Desmos from 'Desmos';
import _ from 'lodash';
import {onDoubleClick} from "components/Prosemirror/plugins/nodeViews/util";
import {log} from "../../../../constants/Config";

export default class DesmosView {
  constructor(node, view, getPos) {
    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('div');
    this.dom.setAttribute('class', 'desmos-calculator');

    const {width, height, type, degreeMode} = this.node.attrs;
    this.dom.style.width = width ? `${width}px` : '';
    this.dom.style.height = height ? `${height}px` : '';

    // Desmos
    switch (type) {
      case 'sci':
        this.innerView = Desmos.ScientificCalculator(this.dom, {
          degreeMode
        });
        break;
      case 'ff':
        this.innerView = Desmos.FourFunctionCalculator(this.dom);
        break;
      case 'graph':
        this.innerView = Desmos.GraphingCalculator(this.dom, {
          administerSecretFolders: true,
          keypad: false,
          expressions: false,
          settingsMenu: false,
          zoomButtons: false,
          lockViewport: true
        });
        break;
    }


    if (node.attrs.state) {
      this.pushState(node.attrs.state);
    } else {
      this.state = node.attrs.state;
      $.getJSON(node.attrs.src).then(res => this.innerView.setState(res.state));
    }

    onDoubleClick(this.dom, () => {
      this.outerView.dispatch({
        external: true,
        method: 'desmos',
        nodeView: {
          node: this.node,
          view: this.outerView,
          getPos: this.getPos
        }
      });
    });
  }

  pushState(state) {
    this.state = state;
    this.innerView.unobserveEvent('change');
    this.innerView.setState(state);
    this.innerView.observeEvent('change',ev => this.saveState());
  }

  saveState() {
    const state = this.innerView.getState();
    if (log.prosemirror) console.log('[PROSEMIRROR] saveState DesmosView', state);
    this.state = state;
    const {doc, tr} = this.outerView.state;
    const attrs = {
      ...this.node.attrs,
      state
    };
    tr.setNodeMarkup(this.getPos(), null, attrs);
    this.outerView.dispatch(tr);
  }

  selectNode() {
    this.dom.classList.add('ProseMirror-selectednode');
  }

  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  update(node) {
    if (node.type.name !== 'desmos') return false;
    const {state} = node.attrs;

    if (state !== this.state) {
      if (log.prosemirror) console.log('[PROSEMIRROR] update DesmosView', state);
      this.pushState(state);
    }

    const {width, height} = node.attrs;
    this.dom.style.width = width ? `${width}px` : '';
    this.dom.style.height = height ? `${height}px` : '';

    this.node = node;
    return true;
  }

  destroy() {
    if (this.innerView) { this.innerView.destroy(); }
  }

  stopEvent() { return false; }
  ignoreMutation() { return true; }
}
