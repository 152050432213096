import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {CLASSIFICATION_SAVE, CLASSIFICATION_DELETE} from "../../classifications";

const ClassificationsTable = PagedTable(
  'classifications',
  () => apiClient.getClassifications(),
  (state, action) => {
    if (action && action.type === CLIENT_REQUEST_SUCCESS && (action.requestType === CLASSIFICATION_SAVE || action.requestType === CLASSIFICATION_DELETE)) {
      return invalidateState(state);
    }
    return state;
  },
);

export default ClassificationsTable;