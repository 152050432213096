import icons from "../icons";
import {asArray} from "constants/util/map";

const alignments = {
  LEFT_TOP: {
    value: 'left/top',
    title: 'Top Left',
    icon: icons.table.align.leftTop,
  },
  CENTER_TOP: {
    value: 'center/top',
    title: 'Top Center',
    icon: icons.table.align.centerTop,
  },
  RIGHT_TOP: {
    value: 'right/top',
    title: 'Top Right',
    icon: icons.table.align.rightTop,
  },
  LEFT_MIDDLE: {
    value: 'left/middle',
    title: 'Middle Left',
    icon: icons.table.align.leftMiddle,
  },
  CENTER_MIDDLE: {
    value: 'center/middle',
    title: 'Center',
    icon: icons.table.align.centerMiddle,
  },
  RIGHT_MIDDLE: {
    value: 'right/middle',
    title: 'Middle Right',
    icon: icons.table.align.rightMiddle,
  },
  LEFT_BOTTOM: {
    value: 'left/bottom',
    title: 'Bottom Left',
    icon: icons.table.align.leftBottom,
  },
  CENTER_BOTTOM: {
    value: 'center/bottom',
    title: 'Bottom Center',
    icon: icons.table.align.centerBottom,
  },
  RIGHT_BOTTOM: {
    value: 'right/bottom',
    title: 'Bottom Right',
    icon: icons.table.align.rightBottom,
  },
};
export const ALIGNMENTS = asArray(alignments);
