import React from 'react';
import PropTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconFileUpload from "@material-ui/icons/Publish";

import {withClient} from "reducers/client";
import {createMediaFromImageUpload} from "reducers/client/requestTypes";

const noop = () => {};

const ImageUploadButton = ({createMediaFromImageUpload, onChange = noop, onComplete = noop, ...props}) => {
  const handleSelectFile = React.useCallback(event => {
    let selected = event.target.files && event.target.files[0];
    onChange(selected);
    if (selected) {
      createMediaFromImageUpload.sendRequest(event.target.files[0]).then(onComplete);
    }
  }, [createMediaFromImageUpload, onChange, onComplete]);

  if (createMediaFromImageUpload.isLoading()) {
    return <CircularProgress />
  }

  let fileInputRef = React.createRef();
  return (<React.Fragment>
    <Button onClick={e => fileInputRef.current.click()} {...props}>
      Upload Image
      <IconFileUpload />
    </Button>
    <input
      style={{display: 'none'}}
      type={'file'}
      ref={fileInputRef}
      onChange={handleSelectFile} />
  </React.Fragment>);
};
ImageUploadButton.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func
};

export default withClient({
  hooks: {
    createMediaFromImageUpload
  }
})(ImageUploadButton);
