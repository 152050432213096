import React from 'react';
import QueueAnim from 'rc-queue-anim';

const errorMessages = {
  404: "Sorry, page not found",
  500: "Sorry, a server error occurred"
};

export const ErrorView = ({errorCode}) => (
  <div className="page-err">
    <QueueAnim type="bottom" className="ui-animate">
      <div className="err-container text-center">
        <div className="err">
          <h1>{errorCode}</h1>
          <h2>{errorMessages[errorCode]}</h2>
        </div>

        <div className="err-body">
          <a href="#/" className="btn btn-raised btn-lg btn-default">
            Go Back to Home Page
          </a>
        </div>
      </div>
    </QueueAnim>
  </div>
);
