import {clean} from './contentReference';
import desmos from './desmos';
import image from './image';
import media from './media';
import mathjax from './mathjax';
import homework from './homework';

export const renderRawHTML = dom => {
  clean(dom);
  image(dom);
  media(dom);
  mathjax(dom);
  return dom;
};

export const renderContentReference = dom => {
  clean(dom);
  // image(dom);
  // media(dom);
  mathjax(dom);
  return dom;
};

export const renderLesson = dom => {
  mathjax(dom);
  // image(dom);
  // media(dom);
  desmos(dom, {});
  return dom;
};

export const renderHomework = dom => {
  mathjax(dom);
  homework(dom);
  // image(dom);
  // media(dom);
  desmos(dom, {});
  return dom;
};