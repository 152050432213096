import {PLUGIN_INTERFACE} from "components/Prosemirror/plugins/interface";
import {requestMediaDetails, makeMediaSelector} from "reducers/medias";

import ImageMediaView from "./image";
import FormulaMediaView from "./formula";
import MathpixMediaView from "./mathpix";
import MathMLMediaView from "./mathml";

import {log} from "constants/Config";
import MediaTypes from 'constants/MediaTypes';
import {NodeSelection} from "prosemirror-state";

let count = 0;

export default class CpmMediaView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;
    if (log.prosemirror) console.log(`[PROSEMIRROR] construct CpmMediaView#${this.id}`, node);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.mediaId = node.attrs['media-id'];
    this.dom = document.createElement('div');
    this.dom.setAttribute('class', 'c3po-media');
    this.dom.setAttribute('data-media-id', this.mediaId);
    this.setStyleAttributes();

    this.mediaSelector = makeMediaSelector(() => this.mediaId);
    this.unsubscribe = this.interface.observeRedux(this.mediaSelector, requestState => this.onMediaUpdated(requestState));
  }

  dispatch(transaction) {
    this.outerView.dispatch({
      external: true,
      nodeView: {
        node: this.node,
        view: this.outerView,
        getPos: this.getPos,
      },
      ...transaction,
    });
  }

  setStyleAttributes() {
    let {align, vAlign, border, width, height, horizontalPadding, verticalPadding, color} = this.node.attrs;

    if (align != null) this.dom.style.cssFloat = align;
    else this.dom.removeAttribute('align');
    if (vAlign != null)  this.dom.style.verticalAlign = vAlign;
    else this.dom.style.verticalAlign = '';

    if (border) { this.dom.style.border = `${border}px solid black`; } else { this.dom.style.border = ''; }

    if (width != null) { this.dom.style.width = `${width}px`; } else { this.dom.style.width = ''; }
    if (height != null) { this.dom.style.height = `${height}px`; } else { this.dom.style.height = ''; }

    if (horizontalPadding || verticalPadding) {
      this.dom.style.padding = `${verticalPadding || 0}px ${horizontalPadding || 0}px`;
    } else {
      this.dom.style.padding = '';
    }

    const nodeColor = /color-(\w+)/.exec(this.dom.getAttribute('class'));
    if (nodeColor && nodeColor[1] !== color) this.dom.classList.remove(nodeColor[0]);
    if (color) this.dom.classList.add(`color-${color}`);
  }

  onMediaUpdated(requestState) {
    if (requestState.id !== this.mediaId) return;
    if (log.prosemirror) console.log(`[PROSEMIRROR] observe Redux CpmMediaView#${this.id}`, this.node, requestState);
    if (requestState.isFetchRequired()) {
      this.interface.dispatch.toRedux(requestMediaDetails(this.mediaId));
    } else if (requestState.isLoaded()) {
      if (this.innerView) this.innerView.destroy();
      this.innerView = null;
      this.dom.innerHTML = '';

      const media = requestState.get();

      switch(media.type) {
        case MediaTypes.IMAGE.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-image');
          this.innerView = new ImageMediaView(this, media);
          break;
        case MediaTypes.MATHPIX.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-mathpix');
          this.innerView = new MathpixMediaView(this, media);
          break;
        case MediaTypes.FORMULA.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-formula');
          this.innerView = new FormulaMediaView(this, media);
          break;
        case MediaTypes.MATHML.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-mathml');
          this.innerView = new MathMLMediaView(this, media);
          break;
        default:
          this.dom.setAttribute('class', 'c3po-media c3po-media-unknown');
          this.dom.innerHTML = `Unknown media type "${media.type}" for media <b>${media.id}</b>`;
          this.innerView = null;
          break;
      }
      this.setStyleAttributes();
    } else if (requestState.isError()) {
      console.error(`[PROSEMIRROR] REDUX ERROR CpmMediaView#${this.id}`, requestState.error);
      this.dom.setAttribute('class', 'c3po-media error');
      this.dom.innerHTML = `Error fetching media <b>${requestState.id}</b>`;
      this.innerView = null;
    }
  }

  selectNode() {
    this.dom.classList.add('ProseMirror-selectednode');
  }
  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  update(node) {
    if (node.type.name !== 'media') return false;
    if (log.prosemirror) console.log(`[PROSEMIRROR] update CpmMediaView#${this.id}`, node);

    const nextId = node.attrs['media-id'];
    if (this.mediaId !== nextId) {
      if (this.innerView) this.innerView.destroy();
      this.innerView = null;

      this.mediaId = nextId;
      this.onMediaUpdated(this.mediaSelector(this.interface.getReduxState(), {id:this.mediaId}));
    }

    this.node = node;
    this.setStyleAttributes();
    return true;
  }

  destroy() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] destroy CpmMediaView#${this.id}`);
    if (this.innerView) this.innerView.destroy();
    this.innerView = null;
    this.unsubscribe();
  }

  stopEvent() { return false; }
  ignoreMutation() { return true; }
}
