export default {
  IMAGE: {
    name: 'IMAGE',
  },
  VIDEO: {
    name: 'VIDEO',
  },
  FILE: {
    name: 'FILE',
  },
  FORMULA: {
    name: 'FORMULA',
  },
  MATHPIX: {
    name: 'MATHPIX',
  },
  MATHML: {
    name: 'MATHML'
  }
};
