import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentGroupRoutes} from './assessmentGroup/index';

export const AssessmentGroupsRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:assessmentGroupId`} component={AssessmentGroupRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
