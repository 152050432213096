import {setBlockType, chainCommands} from 'prosemirror-commands';
import {isInTable, setCellAttr} from 'prosemirror-tables';
import {wrapInList} from 'prosemirror-schema-list';

import MenuGroup from '../components/MenuGroup';
import MenuButton from '../components/MenuButton';
import MenuSelect from '../components/MenuSelect';

import {toggleBlock, setAlignment} from "../../commands";
import {blockActive, selectionInside, selectionContains, getAttribute, or, not} from '../util';
import {ListTypes, listActive, listOperationEnabled, getListType, setListType} from "../list";
import {getTextBlockType, setTextBlockType} from "../textBlock";

import schema from '../../schema';
import icons from '../../icons';
import React from "react";

const publish = (<MenuButton
  command={(state, dispatch) => { dispatch({external: true, method: 'save', state}); }}
  title="Publish">
  {(prosemirror) => {
    if (prosemirror.getReduxState().prosemirror.docChanged) {
      return icons.cloud_changes;
    }
    return icons.cloud_upload;
  }}
</MenuButton>);
const quickSave = (<MenuButton
  command={(state, dispatch) => { dispatch({external: true, method: 'quickSave', state}); }}
  title="Quick Save">
  {icons.save}
</MenuButton>);
const paragraphBlock = {
  value: 'paragraph',
  title: 'Paragraph',
  icon: icons.paragraph
};
const h = level => ({
  value: `h${level}`,
  title: `Heading ${level}`,
  icon: icons.heading[level]
});
const codeBlock = {
  value: 'code_block',
  title: 'Code Block',
  icon: icons.code
};
const textBlock = (<MenuSelect
  active={or(
    selectionInside(schema.nodes.paragraph),
    selectionInside(schema.nodes.heading),
    selectionInside(schema.nodes.code_block)
  )}
  command={blockType => setTextBlockType(blockType)}
  selected={getTextBlockType}
  options={[paragraphBlock,h(1),h(2),h(3),h(4),h(5),h(6),codeBlock]}
  title="Change Block Type" />);
const alignLeft = {
  value: 'left',
  title: 'Align Left',
  icon: icons.align_left
};
const alignCenter = {
  value: 'center',
  title: 'Align Center',
  icon: icons.align_center
};
const alignRight = {
  value: 'right',
  title: 'Align Right',
  icon: icons.align_right
};
// const align = (<MenuSelect
//   enabled={selectionInside(schema.nodes.paragraph)}
//   command={alignment => setAttributes(schema.nodes.paragraph, {alignment})}
//   selected={getAttribute(schema.nodes.paragraph, 'alignment')}
//   options={[alignLeft,alignCenter,alignRight]}
//   title="Change Text Alignment" />);
const align = (<MenuSelect
  enabled={or(isInTable,selectionInside(schema.nodes.paragraph),selectionInside(schema.nodes.heading))}
  command={alignment => setAlignment(alignment)}
  selected={getAttribute([schema.nodes.paragraph,schema.nodes.heading], 'alignment')}
  options={[alignLeft,alignCenter,alignRight]}
  title="Change Text Alignment" />);
const mathNotes = (<MenuButton
  active={selectionInside(schema.nodes.mathNotes)}
  enabled={toggleBlock(schema.nodes.mathNotes)}
  command={toggleBlock(schema.nodes.mathNotes)}
  title="Change to Math Notes">
  {icons.search}
</MenuButton>);
const contentContainerReference = (<MenuButton
  enabled={not(selectionContains(schema.nodes.contentContainerReference))}
  command={(state, dispatch) => { dispatch({external: true, method: 'contentContainerReference', state}); }}
  title="Insert Content Container Reference">
  {icons.magic}
</MenuButton>);
const list = (<MenuSelect
  enabled={listOperationEnabled}
  active={listActive}
  command={listType => setListType(listType)}
  selected={getListType}
  options={Object.values(ListTypes)}
  title="Insert List" />);
const assessmentCompatibleList = (<MenuSelect
  enabled={listOperationEnabled}
  active={listActive}
  command={listType => setListType(listType)}
  selected={getListType}
  options={[ListTypes.NUMERIC, ListTypes.BULLET]}
  title="Insert List" />);
const lessonTitle = (<MenuButton
  enabled={not(selectionContains(schema.nodes.lessonTitle))}
  command={(state, dispatch) => {
    const title = schema.nodes.lessonTitle.createAndFill();
    dispatch(state.tr.replaceSelectionWith(title));
  }}
  title="Insert Lesson Title Block">
  {icons.newspaper}
</MenuButton>);

export const lessonBlocks = (<MenuGroup key="blocks">
  {publish}
  {quickSave}
  {textBlock}
  {align}
  {mathNotes}
  {contentContainerReference}
  {lessonTitle}
  {list}
</MenuGroup>);
export const containerBlocks = (<MenuGroup key="blocks">
  {publish}
  {quickSave}
  {textBlock}
  {align}
  {list}
</MenuGroup>);
export const assessmentBlocks = (<MenuGroup key="blocks">
  {publish}
  {quickSave}
  {textBlock}
  {align}
  {assessmentCompatibleList}
</MenuGroup>);
