import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconSearch from '@material-ui/icons/Search';
import {inputDebounce} from 'constants/Config';

let pendingSearch = null;

function handleChange(event, onSearch) {
  const { value } = event.target;
  if (pendingSearch) {
    window.clearTimeout(pendingSearch);
  }
  pendingSearch = window.setTimeout(() => {
    onSearch(value);
  },inputDebounce);
}

export default props => (<TextField {...props}
  label="Search"
  onChange={ev => handleChange(ev, props.onChange)}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <IconSearch />
      </InputAdornment>
    ),
  }}/>);