import React from 'react';
import PropTypes from 'prop-types';

import {Button, Tooltip, CircularProgress} from "@material-ui/core";
import {Link} from "react-router-dom";

import {getElementTypeByName} from 'constants/ElementTypes';

import {withClient} from "reducers/client";
import {manual, elementDetails} from "reducers/client/requestTypes";

const getTooltip = html => {
  let plainText = html.replace(/(<[^>]*>|&[^;]*;)/gi, ' ');
  return plainText.slice(0,400) + (plainText.length > 400 ? '...' : '');
};

class GroupAssessmentProblemElement extends React.PureComponent {
  static propTypes = {
    assessmentProblemId: PropTypes.string.isRequired,
    element: PropTypes.shape({
      elementType: PropTypes.string,
    }).isRequired,
    disabled: PropTypes.bool,
  };
  state = {
    tooltip: null,
    delay: null
  };

  componentDidUpdate() {
    const {elementDetails} = this.props;
    if (this.state.tooltip === null && elementDetails.isLoaded()) {
      let html = elementDetails.get().currentVersion &&
        elementDetails.get().currentVersion.html;
      if (html) this.setState({tooltip: getTooltip(html)});
      else this.setState({tooltip: false});
    }
  }

  handleTooltipOpen = () => {
    if (!this.delay && this.props.elementDetails.isFetchRequired()) {
      this.delay = window.setTimeout(() => {
        this.delay = null;
        this.props.elementDetails.sendRequest();
      },500);
    }
  };
  handleTooltipClose = () => {
    if (this.delay) {
      window.clearTimeout(this.delay);
      this.delay = null;
    }
  };

  render() {
    const {assessmentProblemId, element, disabled} = this.props;
    let tooltip = <CircularProgress />;
    if (this.state.tooltip !== null) {
      if (this.state.tooltip) tooltip = this.state.tooltip;
      else tooltip = '';
    }

    let elementType = getElementTypeByName(element.elementType);
    let content;
    if (disabled) {
      return (
        <Button disabled>
          {elementType.displayName || elementType.name}
        </Button>
      );
    } else {
      content = (
        <Link to={`/app/assessmentProblems/${assessmentProblemId}/editor/${elementType.name}`}>
          <Button color="primary" value={assessmentProblemId}>
            {elementType.displayName || elementType.name}
          </Button>
        </Link>
      );

      if (tooltip !== '') {
        return (<Tooltip title={tooltip} onOpen={this.handleTooltipOpen} onClose={this.handleTooltipClose}>
          {content}
        </Tooltip>);
      }
      return content;
    }
  }
}

export default withClient({
  hooks: {
    elementDetails: manual(elementDetails((state, props) => props.element.id)),
  }
})(GroupAssessmentProblemElement);
