import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import ContentVersionStandardsTable from 'components/Tables/PagedTable/ContentVersionStandardsTable';

// Material Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Table
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

// Actions
import {
  createApprovalStatusForContentVersion,
  updateApprovalStatusForContentVersion,
  getApprovalStatusForContentVersion,
  publishElementCurrentVersion,
  requestElementDetails,
} from "../../reducers/elements";

// Constants
import { types } from '../../constants/ApprovalStatuses';

const styles = theme => ({
  root: {
    flexDirection: 'column',
    marginLeft: '2%',
    overflowY: 'scroll',
    width: '48%',
  },
  cell: {
    whiteSpace: 'normal',
    width: '15%',
    wordWrap: 'break-word',
  },
  cellKey: {
    width: '15%',
  },
  cellTag: {
    width: '15%',
  },
  table: {
    tableLayout: 'auto',
  },
  header: {
    margin: '0px',
  },
  paragraph: {
    margin: '0px',
  },
  textField: {
    marginLeft: '10px',
    width: '100%',
  },
  standardsTable: {
    overflow: 'scroll',
  },
  saveButton: {
    marginTop: '15px',
  },
  tablesCard: {
    marginTop: '15px',
  },
  commentsHeader: {
    marginRight: '10px',
  },
  comments: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
});

class AccessibilityCompliance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      approvalStatus: types.AWAITING_REVIEW,
      comments: '',
      fetched: false,
      fetchComplete: false,
      selectedTab: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.validateCurrentId() && !this.state.fetched) {
      const { elements } = this.props;
      const { currentVersionId } = elements.details[elements.selected].data;
      this.setState({
        fetched: true,
      }, () => {
        this.props.getApprovalStatus(currentVersionId)
      });
    }

    if (this.props.approvalStatus.data.id && !this.state.fetchComplete) {
      const approvalStatusInfo = this.props.approvalStatus.data;
      this.setState({
        approvalStatus: approvalStatusInfo.approvalStatus,
        comments: approvalStatusInfo.comment,
        fetchComplete: true,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      fetched: false,
      fetchComplete: false,
    });
  }

  handleCommentsChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectTab = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleSelectApprovalStatus = event => {
    this.setState({
      approvalStatus: event.target.value,
    });
  };

  handleSaveApprovalStatus = () => {
    const { elements, approvalStatus } = this.props;
    const elementId = elements.selected;
    const { currentVersionId } = elements.details[elementId].data;
    const { submitterComment } = approvalStatus.data;
    const approvalStatusInfo = {
      approvalStatus: this.state.approvalStatus,
      contentVersionId: currentVersionId,
      comment: this.state.comments,
      submitterComment: submitterComment,
    };

    if (this.state.approvalStatus === types.APPROVED) {
      this.props.publishElementCurrentVersion(elementId, currentVersionId, approvalStatusInfo)
        .then(() => this.props.requestElementDetails(elementId))
        .then(() => this.props.getApprovalStatus(currentVersionId))
        .then(() => this.props.navigateToEditor(elementId));
    }
    else {
      this.props.updateApprovalStatusForContentVersion(approvalStatusInfo)
        .then(() => this.props.requestElementDetails(elementId))
        .then(() => this.props.getApprovalStatus(currentVersionId))
        .then(() => this.props.navigateToEditor(elementId));
    }
  };

  validateCurrentId = () => {
    // Ensures we have a contentVersionId
    const { elements } = this.props;
    return (
      elements.selected &&
      elements.details &&
      elements.details[elements.selected] &&
      elements.details[elements.selected].data &&
      elements.details[elements.selected].data.currentVersionId
    );
  };

  renderControlRequirementStatuses = () => {
    const { controlRequirementStatuses, classes } = this.props;
    const { data } = controlRequirementStatuses;
    if (!Array.isArray(data)) {
      return (
        <TableRow>
          <TableCell>Loading...</TableCell>
        </TableRow>
      );
    }

    if (data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4}>No relevant errors found for this content version.</TableCell>
        </TableRow>
      );
    }

    return data.map((controlRequirementStatus, index) => {
      return (
        <TableRow key={index}>
          <TableCell
              className={classnames(classes.cell, classes.cellKey)}
          >
            {controlRequirementStatus.wcagKey}
          </TableCell>
          <TableCell
              className={classnames(classes.cell, classes.cellTag)}
          >
            {controlRequirementStatus.relevantElement}
          </TableCell>
          <TableCell
            className={classnames(classes.cell)}
          >
            {controlRequirementStatus.description}
          </TableCell>
          <TableCell
              className={classnames(classes.cell)}
          >
            {controlRequirementStatus.relevantTags}
          </TableCell>
        </TableRow>
      );
    })
  };

  renderSaveApprovalStatusButton = () => {
    const { classes } = this.props;
    if (!this.validateCurrentId()) {
      return (
        <h2 className={"article-title"}>Loading...</h2>
      );
    }

    return (
      <Button
        color={'primary'}
        className={classnames(classes.saveButton)}
        fullWidth={true}
        variant={'raised'}
        onClick={this.handleSaveApprovalStatus}
      >
        {this.state.approvalStatus === types.APPROVED ? 'Save Approval Status and Publish' : 'Save Approval Status'}
      </Button>
    )
  };

  renderTable = () => {
    const { classes } = this.props;
    return (
      <Table
        className={classnames(classes.table)}
      >
        <TableHead>
          <TableRow>
            <TableCell
              className={classnames(classes.cell)}
            >
              WCAG Key
            </TableCell>
            <TableCell
              className={classnames(classes.cell)}
            >
              Relevant Element
            </TableCell>
            <TableCell
              className={classnames(classes.cell)}
            >
              Description
            </TableCell>
            <TableCell
              className={classnames(classes.cell)}
            >
              Relevant Tags
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          className={classnames(classes.body)}
        >
          {this.renderControlRequirementStatuses()}
        </TableBody>
      </Table>
    );
  };

  renderStandards = () => {
    const { classes } = this.props;
    return (!this.validateCurrentId()) ?
      <h2 className={"article-title"}>Loading...</h2> :
      <ContentVersionStandardsTable className={classnames(classes.standardsTable)} />;
  };

  renderStandardsOrCompliance = () => {
    return (
      this.state.selectedTab === 0 ?
        this.renderStandards() :
        this.renderTable()
    );
  };

  render() {
    const { classes } = this.props;
    const { AWAITING_REVIEW, APPROVED, DISAPPROVED, DRAFT } = types;
    return (
      <div className={classes.root}>
        <Card>
          <CardContent>
            <FormLabel className={classnames(classes.commentsHeader)}>
              Submitter comments:
            </FormLabel>
            <div className={classnames(classes.comments)}>
              {this.props.approvalStatus.data.submitterComment}
            </div>
          </CardContent>

          <CardContent>
            <FormLabel className={classnames(classes.commentsHeader)}>
              Approver comments:
            </FormLabel>
            <div className={classnames(classes.comments)}>
              {this.props.approvalStatus.data.comment}
            </div>
          </CardContent>
        </Card>

        <Card className={classnames(classes.tablesCard)}>
          <CardContent>
            <FormControl>
              <FormLabel>Approval Status</FormLabel>
              <RadioGroup
                aria-label={"Approval Status"}
                row={true}
                value={this.state.approvalStatus}
                onChange={this.handleSelectApprovalStatus}
              >
                <FormControlLabel
                  value={APPROVED}
                  control={<Radio />}
                  label={"Approved"}
                />
                <FormControlLabel
                  value={AWAITING_REVIEW}
                  control={<Radio />}
                  label={"Awaiting Review"}
                />
                <FormControlLabel
                  value={DISAPPROVED}
                  control={<Radio />}
                  label={"Disapproved"}
                />
                <FormControlLabel
                  value={DRAFT}
                  control={<Radio />}
                  label={"Draft"}
                />
              </RadioGroup>
            </FormControl>

            <TextField
              className={classnames(classes.textField)}
              label={'Approver Comments'}
              multiline={true}
              placeholder={'Comments'}
              value={this.state.comments}
              onChange={this.handleCommentsChange('comments')}
            />
            {this.renderSaveApprovalStatusButton()}
          </CardContent>
        </Card>

        <Card className={classnames(classes.tablesCard)}>
          <CardContent>
            <FormControl><FormLabel>Compliance</FormLabel></FormControl>
            <Tabs
                value={this.state.selectedTab}
                onChange={this.handleSelectTab}
                indicatorColor={'primary'}
            >
              <Tab label={"Standards"} />
              <Tab label={"WCAG Compliance"} />
            </Tabs>

            {this.renderStandardsOrCompliance()}
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styledAccessibilityCompliance = withStyles(styles)(AccessibilityCompliance);

const mapStateToProps = state => ({
  controlRequirementStatuses: state.elements.complianceDetails,
  elements: state.elements,
  approvalStatus: state.elements.approvalStatusDetails,
  books: state.books.details,
});

const mapDispatchToProps = dispatch => ({
  getApprovalStatus: contentVersionId => dispatch(getApprovalStatusForContentVersion(contentVersionId)),
  createApprovalStatus: (contentVersionId, approvalStatusInfo) => dispatch(createApprovalStatusForContentVersion(contentVersionId, approvalStatusInfo)),
  updateApprovalStatusForContentVersion: approvalStatusInfo => dispatch(updateApprovalStatusForContentVersion(approvalStatusInfo)),
  navigateToEditor: elementId => dispatch(push(`/app/elements/${elementId}/editor`)),
  publishElementCurrentVersion: (elementId, contentVersionId, approvalStatusInfo) => dispatch(publishElementCurrentVersion(elementId, contentVersionId, approvalStatusInfo)),
  requestElementDetails: elementId => dispatch(requestElementDetails(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(styledAccessibilityCompliance);