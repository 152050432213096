import React from 'react';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/elementAssociatedContainers/index';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router-dom';

const columnData = [
  {
    id: 'book',
    numeric: false,
    disablePadding: false,
    label: 'Book',
    content: p => p.book.name.toUpperCase(),
  },
  {
    id: 'container',
    numeric: false,
    disablePadding: true,
    label: 'Container Name',
    content: p => p.name,
  },
  {
    id: 'elementType',
    numeric: false,
    disablePadding: true,
    label: 'Element Type',
    content: p => {
      let extra = '';
      if (p.elements[1]) {
        extra = ' EXTRA';
      }
      return p.elements[0].elementType + extra;
    },
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
    content: p => <Link to={`/app/lessonContentContainers/${p.id}/editor`}><Button color="primary" value={p.id}>Container Details</Button></Link>
  }
];

export default PagedTable({
  columnData,
  reducer,
  select: 'multiple',
});