import {render} from 'react-dom';

import {TextSelection, NodeSelection} from 'prosemirror-state';
import * as MathQuill from 'lib/mathquill/mathquill.js';

import icons from '../../icons';

import mathQuillStyle from 'lib/mathquill/mathquill.css';
import {PLUGIN_INTERFACE} from "../interface";

const MQ = MathQuill.getInterface(2);
let count = 0;

export const styleMathQuillPlugin = theme => ({
  mqViewAnchor: {
    position: 'relative',
    whiteSpace: 'pre',
  },
  mqViewButton: {
    position: 'absolute',
    left: 0,
    marginTop: `${2}px`,
    zIndex: 1,

    cursor: 'pointer',
    border: 'none',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#666',
      background: '#fff'
    },
  },
});

export default class MathQuillView {
  constructor(node, view, getPos) {
    let pm = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;
    console.log(`*** Prosemirror *** construct MathQuillView#${this.id}`, node);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.latex = node.textContent.trim();

    this.dom = document.createElement('span');
    this.dom.setAttribute('class',pm.classes.mqViewAnchor);

    this.input = document.createElement('span');
    this.input.innerText = this.latex;
    // MathQuill
    this.innerView = MQ.MathField(this.input, {
      handlers: {
        edit: () => {
          const value = this.innerView.latex();
          if (value !== this.latex) {
            console.log(`*** Prosemirror *** edit MathQuillView#${this.id}`, '"'+this.latex+'"', '"'+value+'"');
            const {doc, tr} = this.outerView.state;
            this.latex = value;
            const pos = doc.resolve(this.getPos() + 1);
            tr.insertText(value, pos.start(), pos.end());
            this.outerView.dispatch(tr);
            this.optionsButton.style.top = `${this.input.offsetHeight + this.input.offsetTop}px`;
            this.optionsButton.style.left = `${this.input.offsetLeft}px`;
          }
        },
        enter: () => this.exitInnerView(),
        moveOutOf: dir => this.exitInnerView(dir)
      }
    });
    // Focus handlers
    this.input.addEventListener('focusin', ev => {
      const {doc, tr} = this.outerView.state;
      tr.setSelection(NodeSelection.create(doc, this.getPos()));
      this.outerView.dispatch(tr);
    }, true);
    // Kludge to fix Firefox broken cursor behavior
    this.dom.appendChild(document.createElement('span'));
    this.dom.appendChild(this.input);
    this.dom.appendChild(document.createElement('span'));

    // Add additional styles to MathQuill
    this.color = node.attrs.color;
    if (node.attrs.color) {
      this.input.classList.add(`color-${node.attrs.color}`);
    }
    if (node.attrs.fontSize) {
      this.input.style.fontSize = node.attrs.fontSize;
    }
    this.dom.style.display = node.attrs.inline ? '' : 'block';
    this.dom.style.textAlign = node.attrs.inline ? '' : 'center';
    this.dom.style.left = node.attrs.offsetLeft ? `${node.attrs.offsetLeft}px` : '';
    this.dom.style.top = node.attrs.offsetTop ? `${node.attrs.offsetTop}px` : '';

    // Button for additional options
    this.optionsButton = document.createElement('button');
    this.optionsButton.setAttribute('class',pm.classes.mqViewButton);
    render(icons.cog,this.optionsButton);
    this.optionsButton.style.display = 'none';
    this.optionsButton.addEventListener('mousedown',ev => {
      this.outerView.dispatch({
        external: true,
        method: 'mathquill',
        nodeView: {
          node: this.node,
          view: this.outerView,
          getPos: this.getPos
        }
      });
      ev.preventDefault();
    });

    this.dom.appendChild(this.optionsButton);

    // Reflow Mathquill once it's part of the DOM
    window.requestAnimationFrame(() => {
      this.innerView.reflow();
    });
  }

  exitInnerView(dir) {
    const {doc, tr} = this.outerView.state;
    this.innerView.blur();
    this.outerView.focus();
    if (dir === MQ.L) {
      tr.setSelection(TextSelection.create(doc, this.getPos()));
    } else {
      tr.setSelection(TextSelection.create(doc, this.getPos() + this.node.nodeSize));
    }
    this.outerView.dispatch(tr);
  }

  selectNode() {
    this.optionsButton.style.display = '';
    this.optionsButton.style.top = `${this.input.offsetHeight + this.input.offsetTop}px`;
  }
  deselectNode() {
    this.optionsButton.style.display = 'none';
  }

  update(node) {
    console.log('Prosemirror MathQuillView',node);
    if (node.type.name !== 'mathQuill') return false;
    const nodeValue = node.textContent;
    console.log(`*** Prosemirror *** update MathQuillView#${this.id}`, node, nodeValue);
    if (nodeValue !== this.innerView.latex()) {
      this.latex = nodeValue;
      this.innerView.latex(nodeValue);
    }

    if (node.attrs.color) {
      if (this.color) {
        this.input.classList.replace(`color-${this.color}`, `color-${node.attrs.color}`);
      } else {
        this.input.classList.add(`color-${node.attrs.color}`);
      }
    } else if (this.color) {
      this.input.classList.remove(`color-${this.color}`);
    }
    this.color = node.attrs.color;

    if (node.attrs.fontSize) {
      this.input.style.fontSize = node.attrs.fontSize;
    } else {
      this.input.style.fontSize = '';
    }
    this.dom.style.display = node.attrs.inline ? '' : 'block';
    this.dom.style.textAlign = node.attrs.inline ? '' : 'center';
    this.dom.style.left = node.attrs.offsetLeft ? `${node.attrs.offsetLeft}px` : '';
    this.dom.style.top = node.attrs.offsetTop ? `${node.attrs.offsetTop}px` : '';

    this.optionsButton.style.top = `${this.input.offsetHeight + this.input.offsetTop}px`;
    this.optionsButton.style.left = `${this.input.offsetLeft}px`;

    this.node = node;
    return true;
  }

  destroy() {
    console.log(`*** Prosemirror *** destroy MathQuillView#${this.id}`);
    if (this.innerView) { this.innerView.revert(); }
  }

  stopEvent() { return true; }
  ignoreMutation() { return true; }
}
