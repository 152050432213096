import React from 'react';
import {setBlockType, toggleMark} from 'prosemirror-commands';

import MenuGroup from '../components/MenuGroup';
import MenuButton from '../components/MenuButton';
import MenuSelect from "../components/MenuSelect";

import {splitOn, toggleBlock, setAttributes, removeMark} from "../../commands";
import {markActive, selectionNotEmpty, promptForURL, selectionInside, getAttribute} from '../util';
import schema from '../../schema';
import {default as icons, withPlus, withPencil, highlightColor} from "../../icons";

import Colors from 'constants/Colors';
import {asArray} from "constants/util/map";

const font = (<MenuButton
  active={() => true}
  visible={markActive(schema.marks.font)}
  command={removeMark(schema.marks.font)}
  title="Remove font">
  {icons.remove_font}
</MenuButton>);
const em = (<MenuButton
  active={markActive(schema.marks.em)}
  command={toggleMark(schema.marks.em)}
  title="Toggle emphasis">
  {icons.em}
</MenuButton>);
const strong = (<MenuButton
  active={markActive(schema.marks.strong)}
  command={toggleMark(schema.marks.strong)}
  title="Toggle strong">
  {icons.strong}
</MenuButton>);
const underline = (<MenuButton
  active={markActive(schema.marks.underline)}
  command={toggleMark(schema.marks.underline)}
  title="Toggle underline">
  {icons.underline}
</MenuButton>);
const toggleLink = (state, dispatch) => {
  if (markActive(schema.marks.link)(state)) {
    toggleMark(schema.marks.link)(state, dispatch);
    return true;
  }

  const href = promptForURL();
  if (!href) return false;

  let isAbsolute = /^(?:[a-z]+:)?\/\//i.test(href);

  toggleMark(schema.marks.link, {
    href,
    target: isAbsolute ? '_blank' : '_self'
  })(state, dispatch);
  return true;
};
const link = (<MenuButton
  active={markActive(schema.marks.link)}
  enable={selectionNotEmpty}
  command={toggleLink}
  title="Add or remove link">
  {icons.link}
</MenuButton>);
const HIGHLIGHT_NODES = [schema.nodes.paragraph, schema.nodes.homeworkHint, schema.nodes.media];
const highlight = ((<MenuSelect
  enabled={selectionInside(...HIGHLIGHT_NODES)}
  command={color => setAttributes(HIGHLIGHT_NODES, {color})}
  selected={getAttribute(HIGHLIGHT_NODES, 'color')}
  options={asArray(Colors).map(c => ({
    ...c,
    icon: c.color ? highlightColor(c.color) : icons.highlight,
  }))}
  title="Highlight" />));
const ebooks = (<MenuButton
  active={markActive(schema.marks.markEbooksOnly)}
  command={toggleMark(schema.marks.markEbooksOnly)}
  title="Toggle eBooks only content (inline)">
  {icons.book}
</MenuButton>);
const ebooksNode = (<MenuButton
  active={selectionInside(schema.nodes.nodeEbooksOnly)}
  enabled={toggleBlock(schema.nodes.nodeEbooksOnly)}
  command={toggleBlock(schema.nodes.nodeEbooksOnly)}
  title="Toggle eBooks only content (block)">
  {withPlus(icons.book)}
</MenuButton>);
const homework = (<MenuButton
  active={markActive(schema.marks.markHomeworkOnly)}
  command={toggleMark(schema.marks.markHomeworkOnly)}
  title="Toggle Homework Help only content (inline)">
  {icons.pen_square}
</MenuButton>);
const homeworkNode = (<MenuButton
  active={selectionInside(schema.nodes.nodeHomeworkOnly)}
  enabled={toggleBlock(schema.nodes.nodeHomeworkOnly)}
  command={toggleBlock(schema.nodes.nodeHomeworkOnly)}
  title="Toggle Homework Help only content (block)">
  {withPlus(icons.pen_square)}
</MenuButton>);
const splitBlockNode = (<MenuButton
  enabled={splitOn(schema.nodes.nodeHomeworkOnly, schema.nodes.nodeEbooksOnly)}
  command={splitOn(schema.nodes.nodeHomeworkOnly, schema.nodes.nodeEbooksOnly)}
  title="Split eBooks or Homework Block">
  {icons.split_ebooks_homework}
</MenuButton>);
const screenReaderOnlyMark = (<MenuButton
  active={markActive(schema.marks.markScreenReaderOnly)}
  command={toggleMark(schema.marks.markScreenReaderOnly)}
  title="Toggle screen reader only">
  {icons.eye_slash}
</MenuButton>);

export const lessonMarks = (<MenuGroup key="marks">
  {font}
  {em}
  {strong}
  {underline}
  {link}
  {highlight}
  {screenReaderOnlyMark}
</MenuGroup>);
export const containerMarks = (<MenuGroup key="marks">
  {font}
  {em}
  {strong}
  {underline}
  {link}
  {highlight}
  {ebooks}
  {ebooksNode}
  {homework}
  {homeworkNode}
  {splitBlockNode}
  {screenReaderOnlyMark}
</MenuGroup>);
export const assessmentMarks = (<MenuGroup key="marks">
  {font}
  {em}
  {strong}
  {underline}
  {link}
  {highlight}
  {screenReaderOnlyMark}
</MenuGroup>);
