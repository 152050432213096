import React from 'react';

import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    border: '3px dashed #eeeeee',
    padding: '100px 0',
    'text-align': 'center'
  },
  dropText: {
    margin: '4px 0',
    'font-size': '180%'
  },
  miniText: {
    margin: '4px 0'
  }
});

const ImageDrop = ({classes, onSelect}) => {
  let inputRef;

  function handleDrop(event) {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      onSelect(event.dataTransfer.files[0]);
    }
  }

  function handleChange(event) {
    if (event.target.files && event.target.files.length) {
      onSelect(event.target.files[0]);
    }
  }

  return (<Paper
    elevation={0}
    className={classes.root}
    onDragOver={e => e.preventDefault()}
    onDrop={handleDrop}>
    <p className={classes.dropText}>
      Drop an image here
    </p>
    <p className={classes.miniText}>- or -</p>
    <input type="button" value="Choose a File" onClick={e => inputRef.click()} />
    <input style={{display: 'none'}} type={'file'} ref={(e) => { inputRef = e; }} onChange={handleChange} />
  </Paper>);
};

export default withStyles(styles)(ImageDrop);
