// ACTION TYPES
export const REGISTER = '@@TABLE/REGISTER';
export const UNREGISTER = '@@TABLE/UNREGISTER';

export const SET_PAGE = '@@TABLE/SET_PAGE';
export const SET_PAGE_SIZE = '@@TABLE/SET_PAGE_SIZE';
export const SET_SORT = '@@TABLE/SET_SORT';
export const SET_SEARCH = '@@TABLE/SET_SEARCH';
export const SET_SEARCH_COLUMNS = '@@TABLE/SET_SEARCH_COLUMNS';
export const SET_METADATA = '@@TABLE/SET_METADATA';
export const TOGGLE_SORT = '@@TABLE/TOGGLE_SORT';

export const registerTable = (tableId, initialState) => ({ type: REGISTER, payload: tableId, initialState});
export const unregisterTable = (tableId) => ({ type: UNREGISTER, payload: tableId});

export const tableSetPage = (tableId, pageNumber) => ({ type: SET_PAGE, payload: pageNumber, metadata: {tableId} });
export const tableSetPageSize = (tableId, pageSize) => ({ type: SET_PAGE_SIZE, payload: pageSize, metadata: {tableId} });
export const tableSetSort = (tableId, sort) => ({ type: SET_SORT, payload: sort, metadata: {tableId} });
export const tableSetSearch = (tableId, search) => ({ type: SET_SEARCH, payload: search, metadata: {tableId} });
export const tableSetSearchColumns = (tableId, columns) => ({ type: SET_SEARCH_COLUMNS, payload: columns, metadata: {tableId} });
export const tableSetMetadata = (tableId, metadata) => ({ type: SET_METADATA, payload: metadata, metadata: {tableId} });
export const tableToggleSort = (tableId, column) => ({ type: TOGGLE_SORT, payload: column, metadata: {tableId} });
