import {apiClient} from 'constants/Clients';

import PagedTable, {invalidateState, resetState} from "../../util/pagedTable";
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {DIALOG_IMAGE_OPEN, DIALOG_IMAGE_UPLOAD} from '../../Dialogs/Image';

import {MEDIA_DELETE, deleteMedias} from "../../medias";

const ImageBrowser = PagedTable('imageBrowser',
  () => apiClient.getMedias(),
  (state, action) => {
    if (action) {
      if (action.type === DIALOG_IMAGE_OPEN) {
        return resetState(state);
      }
      if (action.type === CLIENT_REQUEST_SUCCESS &&
        (action.requestType === MEDIA_DELETE ||
          action.requestType === DIALOG_IMAGE_UPLOAD)) {
        return invalidateState(state);
      }
    }
    return state;
  }
);

export function deleteSelectedMedias() {
  return (dispatch, getState) => {
    const {selected} = ImageBrowser.Selectors.getState(getState());
    if (selected.length > 0) {
      deleteMedias(selected)(dispatch);
    }
  }
}

export default ImageBrowser;