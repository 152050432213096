import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import ClientRequest from "./util/clientRequest";
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const ELEMENT_SELECT = 'ELEMENT_SELECT';
export const ELEMENT_DETAILS = 'ELEMENT_DETAILS';
export const ELEMENT_SET_CURRENT_VERSION = 'ELEMENT_SET_CURRENT_VERSION';
export const REQUEST_COMPLIANCE_DETAILS = 'REQUEST_COMPLIANCE_DETAILS';
export const REQUEST_APPROVAL_STATUS = 'REQUEST_APPROVAL_STATUS';
export const PUBLISHED_STATUS = 'PUBLISHED_STATUS';

// CHILD REDUCERS
export const details = MultiRequest(ELEMENT_DETAILS);
export const setVersion = ClientRequest(ELEMENT_SET_CURRENT_VERSION);
export const complianceDetails = ClientRequest(REQUEST_COMPLIANCE_DETAILS);
export const approvalStatusDetails = ClientRequest(REQUEST_APPROVAL_STATUS);
export const publishedStatusDetails = ClientRequest(PUBLISHED_STATUS);

// ACTION CREATORS
export function selectElement(elementID) {
  return {type: ELEMENT_SELECT, payload: elementID};
}

export function requestElementDetails(elementID) {
  return details.sendRequest(apiClient.getElement(elementID), {key: elementID});
}

export function requestContentVersionControlRequirementStatuses(contentVersionId) {
  return complianceDetails.sendRequest(
    apiClient.getControlRequirementStatusesForContentVersion(contentVersionId),
    { key: contentVersionId },
  );
}

export function getApprovalStatusForContentVersion(contentVersionId) {
  return approvalStatusDetails.sendRequest(
    apiClient.getApprovalStatusForContentVersion(contentVersionId),
    { key: contentVersionId },
  );
}

export function createApprovalStatusForContentVersion(contentVersionId, approvalStatusInfo) {
  return approvalStatusDetails.sendRequest(
    apiClient.createApprovalStatusForContentVersion(contentVersionId, approvalStatusInfo),
    { key: contentVersionId },
  );
}

export function updateApprovalStatusForContentVersion(approvalStatusInfo) {
  return approvalStatusDetails.sendRequest(
    apiClient.updateApprovalStatusForContentVersion(approvalStatusInfo),
    { key: approvalStatusInfo.contentVersionId },
  );
}

export function requestApprovalForContentVersion(contentVersionId, approvalStatusInfo) {
  return approvalStatusDetails.sendRequest(
    apiClient.requestApprovalForContentVersion(contentVersionId, approvalStatusInfo),
    { key: contentVersionId },
  );
}

export function publishElementCurrentVersion(elementId, contentVersionId, approvalStatusInfo) {
  return publishedStatusDetails.sendRequest(
    apiClient.publishElementCurrentVersion(elementId, contentVersionId, approvalStatusInfo),
    { key: contentVersionId },
  );
}

export function removeStandardFromElementPublishedVersion(elementId, standardId) {
  return publishedStatusDetails.sendRequest(
    apiClient.removeClassificationStandardFromElementPublishedVersion(elementId, standardId)
  );
}

export function setElementCurrentVersion(elementId, contentVersionId) {
  return dispatch =>
    dispatch(setVersion.sendRequest(apiClient.setElementCurrentVersion(elementId, contentVersionId)))
      .then(() => dispatch(details.clear(elementId)));
}

export function setElementCurrentAndPublishedVersion(elementId, contentVersionId) {
  return dispatch => {
    return Promise.all([
        dispatch(
          setVersion.sendRequest(apiClient.setElementCurrentVersion(elementId, contentVersionId))
        ),
        dispatch(
          publishedStatusDetails.sendRequest(apiClient.publishElementCurrentVersion(elementId, contentVersionId)),
          { key: contentVersionId }
        )
      ])
      .then(() => dispatch(details.clear(elementId)));
  };
}

// SELECTORS
const getSelected = state => state.elements.selected;
const getElementDetailRequests = state => state.elements.details;
export const getSelectedElement = makeRequestSelector(getSelected, getElementDetailRequests);

export default combineReducers({
  selected: (state = null, action) => (action.type === ELEMENT_SELECT ? action.payload : state),
  details,
  setVersion,
  complianceDetails,
  approvalStatusDetails,
});
