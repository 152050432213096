import React from 'react';
import {useSelector} from "react-redux";

import {LessonContentContainerBreadcrumb} from 'components/Breadcrumb'
import MergeHistoryTable from 'components/Tables/MergeHistoryTable';

export const LessonContentContainerMergeHistoryView = () => {
  const selectedLessonContentContainer = useSelector(state => state.lessonContentContainers.selected);
  return (
    <div>
      <LessonContentContainerBreadcrumb containerId={selectedLessonContentContainer}/>
      <h1>Merge History</h1>
      <MergeHistoryTable contentableId={selectedLessonContentContainer} />
    </div>
  )
};
