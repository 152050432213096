const date = new Date();
const year = date.getFullYear();

const env = window ? {
  binkUrl: window.binkUrl,
} : {};

const APPCONFIG = {
  brand: 'C3PO',
  user: 'CPM',
  year: year,
  ssoRedirect: 'https://ssotest.cpm.org?redirect_uri=http%3A%2F%2Flocalc3po.cpm.org%3A3100%2F%23%2Fapp%2Flanding',
  // apiBase: 'https://c3po-api-test.cpm.org',
  apiBase: 'http://localsso.cpm.org:3777',
  s3Base: 'https://s3-us-west-2.amazonaws.com',
  productLink: 'https://cpm.org',
  AutoCloseMobileNav: true,                         // true, false. Automagit tically close sidenav on route change (Mobile only)
  color: {
    primary: '#00BCD4',
    success: '#8BC34A',
    info: '#66BB6A',
    infoAlt: '#7E57C2',
    warning: '#FFCA28',
    danger: '#F44336',
    text: '#3D4051',
    gray: '#EDF0F1'
  },
  settings: {
    layoutBoxed: false,                             // true, false
    navCollapsed: true,                            // true, false
    navBehind: false,                               // true, false
    fixedHeader: true,                              // true, false
    sidebarWidth: 'middle',                         // small, middle, large
    colorOption: '14',                              // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'light',                                 // light, gray, dark
  },
  inputDebounce: 500,    // How long (in ms) to wait after text input to send a remote request
  log: {
    redux: false,
    client: false,
    prosemirror: false,
  },
  googleApiKey: 'AIzaSyDMwzgZXZyVd5SOBXLaKcCzg6uN8MzGt84',
  binkUrl: env.binkUrl || 'https://binks-test.cpm.org/_plugin/kibana/app/kibana#/dashboard/f743ed60-87c2-11e9-aec1-bbbcf049be60?embed=true',
};

module.exports = APPCONFIG;
