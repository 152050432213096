import {Plugin, PluginKey, NodeSelection, Selection} from 'prosemirror-state';

import {matchTypes, findAncestor} from "components/Prosemirror/util";
import schema from "components/Prosemirror/schema";

export const PLUGIN_SELECTION = new PluginKey('selection');

const blockSelectableTypes = [
  schema.nodes.mathNotes
];
const isBlockSelectable = matchTypes(blockSelectableTypes);
export default () => {
  return new Plugin({
    key: PLUGIN_SELECTION,

    props: {
      handleTripleClick(view, pos) {
        const {doc, tr} = view.state;
        const $block = findAncestor(doc.resolve(pos), isBlockSelectable);
        if (!$block) return false;
        view.dispatch(tr.setSelection(new NodeSelection($block)));
        return true;
      }
    }
  });
}
