import React, {Component} from 'react';
import {withClient} from "reducers/client";
import {menuRender} from "reducers/client/requestTypes";
import {withStyles} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";

class MenuPreview extends Component {

  render(){
    const {classes, menuRender:renderRequest} = this.props;

    if (!renderRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Book details...</h2>
      </div>);
    }
    return (
      <Paper>
        <div dangerouslySetInnerHTML={{__html: renderRequest.state.data.value.renderedHtml}}/>
      </Paper>
    )
  }
}

export default withClient({
  hooks: {
    menuRender: menuRender((state, props) => state.menus.selected),
  },
})(MenuPreview);

// withStyles(styles)(BookMenu)