import React from 'react';
import PropTypes from 'prop-types';

import {makeBreadcrumb} from "./Breadcrumb";
import {
  aliasFor,
  categoryDetails,
  bookDetails,
  chapterDetails,
  lessonDetails,
  lessonContentContainerDetails,
  sectionDetails,
  tabbedViewDetails,
  tabDetails,
  glossaryItemDetails,
  assessmentGroupDetails,
  assessmentProblemDetails,
  elementParents,
} from "reducers/client/requestTypes";

const CategoryBreadcrumb = makeBreadcrumb({
  request: aliasFor('categoryDetails')(categoryDetails((state, props) => props.categoryId)),
  link: details => `/app/categories/${details.id}/details`,
  title: details => details.shortName,
});
CategoryBreadcrumb.propTypes = {
  categoryId: PropTypes.string.isRequired
};
CategoryBreadcrumb.displayName = 'CategoryBreadcrumb';

const BookBreadcrumb = makeBreadcrumb({
  request: aliasFor('bookDetails')(bookDetails((state, props) => props.bookId)),
  link: details => `/app/books/${details.id}/details`,
  title: details => details.name,
  parents: details => [<CategoryBreadcrumb key={details.categoryId} categoryId={details.categoryId} child={true} />]
});
BookBreadcrumb.propTypes = {
  bookId: PropTypes.string.isRequired
};
BookBreadcrumb.displayName = 'BookBreadcrumb';

const ChapterBreadcrumb = makeBreadcrumb({
  request: aliasFor('chapterDetails')(chapterDetails((state, props) => props.chapterId)),
  link: details => `/app/chapters/${details.id}/details`,
  title: details => details.name,
  parents: details => [<BookBreadcrumb key={details.bookId} bookId={details.bookId} child={true} />]
});
ChapterBreadcrumb.propTypes = {
  chapterId: PropTypes.string.isRequired
};
ChapterBreadcrumb.displayName = 'ChapterBreadcrumb';

const LessonBreadcrumb = makeBreadcrumb({
  request: aliasFor('lessonDetails')(lessonDetails((state, props) => props.lessonId)),
  link: details => `/app/lessons/${details.id}/details`,
  title: details => details.name,
  parents: details => [<ChapterBreadcrumb key={details.chapterId} chapterId={details.chapterId} child={true} />]
});
LessonBreadcrumb.propTypes = {
  lessonId: PropTypes.string.isRequired
};
LessonBreadcrumb.displayName = 'LessonBreadcrumb';

const LessonContentContainerBreadcrumb = makeBreadcrumb({
  request: aliasFor('lessonContentContainerDetails')(lessonContentContainerDetails((state, props) => props.containerId)),
  link: details => `/app/lessonContentContainers/${details.id}/editor`,
  title: details => details.name,
  parents: details => [<LessonBreadcrumb key={details.lessonId} lessonId={details.lessonId} child={true} />]
});
LessonContentContainerBreadcrumb.propTypes = {
  containerId: PropTypes.string.isRequired
};
LessonContentContainerBreadcrumb.displayName = 'LessonContentContainerBreadcrumb';

const SectionBreadcrumb = makeBreadcrumb({
  request: aliasFor('sectionDetails')(sectionDetails((state, props) => props.sectionId)),
  link: details => `/app/sections/${details.id}/details`,
  title: details => details.type,
  parents: details => [<BookBreadcrumb key={details.bookId} bookId={details.bookId} child={true} />]
});
SectionBreadcrumb.propTypes = {
  sectionId: PropTypes.string.isRequired
};
SectionBreadcrumb.displayName = 'SectionBreadcrumb';

const TabbedViewBreadcrumb = makeBreadcrumb({
  request: aliasFor('tabbedViewDetails')(tabbedViewDetails((state, props) => props.tabbedViewId)),
  link: details => `/app/tabbedViews/${details.id}/details`,
  title: details => details.type,
  parents: details => [<SectionBreadcrumb key={details.sectionId} sectionId={details.sectionId} child={true} />]
});
TabbedViewBreadcrumb.propTypes = {
  tabbedViewId: PropTypes.string.isRequired
};
TabbedViewBreadcrumb.displayName = 'TabbedViewBreadcrumb';

const TabBreadcrumb = makeBreadcrumb({
  request: aliasFor('tabDetails')(tabDetails((state, props) => props.tabId)),
  link: details => `/app/tabs/${details.id}/editor`,
  title: details => details.name,
  parents: details => [<TabbedViewBreadcrumb key={details.tabbedViewId} tabbedViewId={details.tabbedViewId} child={true} />]
});
TabBreadcrumb.propTypes = {
  tabId: PropTypes.string.isRequired
};
TabBreadcrumb.displayName = 'TabBreadcrumb';

const GlossaryItemBreadcrumb = makeBreadcrumb({
  request: aliasFor('glossaryItemDetails')(glossaryItemDetails((state, props) => props.glossaryItemId)),
  link: details => `/app/glossary/${details.id}/details`,
  title: details => details.title,
  parents: details => details.books.map(parent => (
    <BookBreadcrumb key={parent.id} bookId={parent.id} child={true} />
  ))
});
GlossaryItemBreadcrumb.propTypes = {
  glossaryItemId: PropTypes.string.isRequired
};
GlossaryItemBreadcrumb.displayName = 'GlossaryItemBreadcrumb';

const AssessmentGroupBreadcrumb = makeBreadcrumb({
  request: aliasFor('assessmentGroupDetails')(assessmentGroupDetails(
    (state, props) => props.assessmentGroupId)
  ),
  link: details => `/app/assessmentGroups/${details.id}/details`,
  title: details => details.name,
  parents: details => [<ChapterBreadcrumb key={details.chapterId} chapterId={details.chapterId} child={true} />]
});
AssessmentGroupBreadcrumb.propTypes = {
  assessmentGroupId: PropTypes.string.isRequired
};
AssessmentGroupBreadcrumb.displayName = 'AssessmentGroupBreadcrumb';

const AssessmentProblemBreadcrumb = makeBreadcrumb({
  request: aliasFor('assessmentProblemDetails')(assessmentProblemDetails(
    (state, props) => props.assessmentProblemId)
  ),
  link: details => `/app/assessmentProblems/${details.id}/editor`,
  title: details => details.name,
  parents: details => details.assessmentGroups.map(parent => (
    <AssessmentGroupBreadcrumb key={parent.id} assessmentGroupId={parent.id} child={true} />
  ))
});
AssessmentProblemBreadcrumb.propTypes = {
  assessmentProblemId: PropTypes.string.isRequired
};
AssessmentProblemBreadcrumb.displayName = 'AssessmentProblemBreadcrumb';

const ElementBreadcrumb = makeBreadcrumb({
  request: aliasFor('elementParents')(elementParents((state, props) => props.elementId)),
  link: details => `/app/elements/${details.id}/editor`,
  title: details => details.elementType,
  parents: details => details.parents.filter(parent => {
      switch (parent.type) {
        case 'lesson': return true;
        case 'lessonContentContainer': return true;
        case 'glossaryItem': return true;
        case 'section': return true;
        case 'tab': return true;
        default: return false;
      }
    }).map(parent => {
      switch (parent.type) {
        case 'lesson': return <LessonBreadcrumb key={parent.id} lessonId={parent.id} child={true} />;
        case 'lessonContentContainer': return <LessonContentContainerBreadcrumb key={parent.id} containerId={parent.id} child={true} />;
        case 'assessmentProblem': return null;
        case 'glossaryItem': return <GlossaryItemBreadcrumb key={parent.id} glossaryItemId={parent.id} child={true} />;
        case 'section': return <SectionBreadcrumb key={parent.id} sectionId={parent.id} child={true} />;
        case 'tab': return <TabBreadcrumb key={parent.id} tabId={parent.id} child={true} />;
        default: return null;
      }
    })
});
ElementBreadcrumb.propTypes = {
  elementId: PropTypes.string.isRequired
};
ElementBreadcrumb.displayName = 'ElementBreadcrumb';


export {
  CategoryBreadcrumb,
  BookBreadcrumb,
  ChapterBreadcrumb,
  LessonBreadcrumb,
  LessonContentContainerBreadcrumb,
  SectionBreadcrumb,
  TabbedViewBreadcrumb,
  TabBreadcrumb,
  GlossaryItemBreadcrumb,
  AssessmentGroupBreadcrumb,
  AssessmentProblemBreadcrumb,
  ElementBreadcrumb,
};