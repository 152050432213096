import React from 'react';
import PropTypes from 'prop-types';

import {withClient} from "reducers/client";
import {contentVersionRender} from "reducers/client/requestTypes";

class ContentPreview extends React.Component {
  static propTypes = {
    contentRef: PropTypes.object,
    contentVersionId: PropTypes.string,
    postProcess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    if (props.contentRef) this.contentRef = props.contentRef;
    else this.contentRef = React.createRef();
    this.state = { currentRenderedContentId: null  };
  }

  componentDidMount() { this.tryRender(); }
  componentDidUpdate() { this.tryRender(); }
  tryRender() {
    const dom = this.contentRef.current;
    const {contentVersionRender:contentRequest} = this.props;
    if (contentRequest.isLoaded() && dom) {
      const contentVersion = contentRequest.get();
      if (contentVersion.id !== this.state.currentRenderedContentId) {
        dom.innerHTML = contentVersion.renderedHtml;
        if (this.props.postProcess) {
          this.props.postProcess(dom);
        }
        this.setState({currentRenderedContentId:contentVersion.id});
      }
    }
  }

  render() {
    const {
      contentRef,
      contentVersionId,
      postProcess,
      contentVersionRender,
      ...innerProps
    } = this.props;
    return <div {...innerProps} ref={this.contentRef}/>;
  }
}

export default withClient({
  hooks: {
    contentVersionRender: contentVersionRender((state, props) => props.contentVersionId),
  }
})(ContentPreview);
