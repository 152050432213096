import React, {useState} from "react";
import propTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconExpandMore from "@material-ui/icons/ExpandMore";

import {getElementTypeByName, ElementTypeNames} from "constants/ElementTypes";

const ElementSelector = ({className, elements, value, onClick}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleClick = elementType => ev => {
    console.log(elementType);

    setMenuAnchor(null);
    onClick(elementType);
  };

  return (
    <React.Fragment>
      <Button
        className={className}
        color="default"
        variant="contained"
        onClick={ev => setMenuAnchor(ev.currentTarget)}
      >
        Content
        <IconExpandMore />
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={ev => setMenuAnchor(null)}
      >
        {elements.map(el => {
          const elType = getElementTypeByName(el.elementType);
          return (
            <MenuItem
              key={el.id}
              disabled={elType.name === value}
              onClick={handleClick(elType.name)}
            >
              {elType.displayName || elType.name}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

ElementSelector.propTypes = {
  className: propTypes.string,
  elements: propTypes.arrayOf(propTypes.shape({
    elementType: propTypes.string.isRequired
  })).isRequired,
  value: propTypes.oneOf(ElementTypeNames).isRequired,
  onClick: propTypes.func.isRequired
};

export {ElementSelector};