import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {AppRoutes} from './app';
import {LoginView} from 'views/common/Login';
import {RedirectRoutes} from './redirect';
import {ErrorView} from "views/common/Error";

export const RootRoutes = (<Switch>
  <Redirect exact from="/" to="/app/landing" />
  <Route path="/app" component={AppRoutes} />
  <Route path="/login" component={LoginView} />
  <Route path="/redirect" component={RedirectRoutes} />
  <Route path="/404" render={() => <ErrorView errorCode={404}/>} />
  <Route path="/500" render={() => <ErrorView errorCode={500}/>} />
  <Redirect to="/404" />
</Switch>);
