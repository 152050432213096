import React from "react";
import PropTypes from 'prop-types';

import {
  TableRow,
  TableCell
} from "@material-ui/core";

import {withClient} from "reducers/client";
import {books} from "reducers/client/requestTypes";

class BrowserBookList extends React.Component {
  static propTypes = {
    books: PropTypes.object,
  };

  render() {
    const {books} = this.props;
    if (books.isLoaded()) {
      return (
        <React.Fragment>
          {books.get().map(b => (
            <TableRow key={b.id}>
              <TableCell>
                {b.name}
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      );
    }
    return "???";
  }
}

export default withClient({
  hooks: {
    books
  }
})(BrowserBookList);