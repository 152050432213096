// Action types for C3PO Client Requests
export const CLIENT_REQUEST_SENT = 'CLIENT_REQUEST_SENT';
export const CLIENT_REQUEST_SUCCESS = 'CLIENT_REQUEST_SUCCESS';
export const CLIENT_REQUEST_FAILURE = 'CLIENT_REQUEST_FAILURE';
export const CLIENT_REQUEST_CLEAR = 'CLIENT_REQUEST_CLEAR';

// Initial settings for Redux state
const initialState = {
  pending: false,
  completed: false,
  data: {},
  error: null
};

// Default options for reducer
const defaults = {
  transformResponse: (err, results, response) => results,
  transformError: (err, results, response) => err
};

// Higher order reducer (type | options) => Reducer
// type: Action Type (default transform functions will be used)
// options.type: Action Type
// options.transformResponse: (err, results, response) => * - what to store in Redux on success
// options.transformError: (err, results, response) => * - what to store in Redux on error
export default (options) => {
  if (typeof options === 'string') {
    options = {...defaults, requestType: options};
  } else {
    options = {...defaults, ...options};
  }
  const {requestType, transformResponse, transformError} = options;

  // Create the reducer to track request status
  const reducer = (state = initialState, action) => {
    if (!action) return state;
    if (action.requestType === requestType) {
      switch (action.type) {
        case CLIENT_REQUEST_SENT:
          return {
            ...state,
            pending: true,
            completed: false
          };
        case CLIENT_REQUEST_SUCCESS:
          return {
            pending: false,
            completed: true,
            data: action.payload,
            error: null
          };
        case CLIENT_REQUEST_FAILURE:
          return {
            pending: false,
            completed: true,
            data: {},
            error: action.payload
          };
        case CLIENT_REQUEST_CLEAR:
          return initialState;
        default:
          return state;
      }
    } else {
      return state;
    }
  };

  // Attach an action creator that can be called to send requests
  // clientRequest: c3po-client-js::pagedRequest
  // Returns: Redux Thunk
  reducer.sendRequest = (clientRequest, metadata) => dispatch => new Promise((resolve, reject) => {
    dispatch({ type: CLIENT_REQUEST_SENT, requestType, metadata });

    clientRequest.exec((err, results, response) => {
      if (err) {
        const payload = transformError(err, results, response);
        dispatch({ type: CLIENT_REQUEST_FAILURE, requestType, payload, metadata });
        reject(payload);
      } else {
        const payload = transformResponse(err, results, response);
        dispatch({ type: CLIENT_REQUEST_SUCCESS, requestType, payload, metadata });
        resolve(payload);
      }
    });
  });

  // Attach an action creator that can be called to send a Sequence of requests
  // A 'success' action will not be sent until all requests are complete.
  // getNext: function* returns c3po-client-js::pagedRequest
  // Returns: Redux Thunk
  reducer.sendSequence = (sequence, metadata) => dispatch => new Promise((resolve, reject) => {
    let next = sequence.next();
    const allResults = [];
    const doNext = (err, results, response) => {
      if (err) {
        // On error
        const payload = transformError(err, results, response);
        dispatch({ type: CLIENT_REQUEST_FAILURE, requestType, payload, metadata });
        return reject(payload);
      } else {
        // On success (of each item)
        const payload = transformResponse(err, results, response);
        allResults.push(payload);
        next = sequence.next(payload);
        if (next.done && !next.value) {
          // All requests were successfully processed
          dispatch({ type: CLIENT_REQUEST_SUCCESS, requestType, payload: allResults, metadata });
          return resolve(allResults);
        }
      }
      // Continue through the list, using this function as a callback
      return next.value.exec(doNext);
    };
    if (next.done) {
      dispatch({ type: CLIENT_REQUEST_SUCCESS, requestType, payload: allResults, metadata });
    } else {
      dispatch({ type: CLIENT_REQUEST_SENT, requestType, metadata });
      next.value.exec(doNext);
    }
  });

  // Attach an action creator that can be called to clear the state of the request
  // Returns: Redux Action
  reducer.clear = metadata => ({type: CLIENT_REQUEST_CLEAR, requestType, metadata});

  return reducer;
};

export class RequestState {
  constructor(id, state) {
    this.id = id;
    this.state = state;
  }

  isLoading() {
    const state = this.state;
    return state && state.pending;
  }

  isLoaded() {
    const state = this.state;
    return state && state.completed && !state.error;
  }

  isError() {
    const state = this.state;
    return state && state.completed && state.error;
  }

  isFetchRequired() {
    const id = this.id;
    const state = this.state;
    return id && (!state || (!state.pending && !state.completed && !state.error));
  }

  get() {
    return this.isLoaded() ? this.state.data : this.isError() ? this.state.error : null;
  }
}