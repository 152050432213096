import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from "components/Dialogs/Confirmation";
import {withClient} from "reducers/client";
import {elementRemoveStandardFromPublishedVersion} from "reducers/client/requestTypes";

class RemoveStandardButton extends Component {

  state = {
    confirmationOpen: false
  };

  clicked = () => {
    this.setState({confirmationOpen: true})
  };

  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false})
  };

  handleConfirm = () => {
    const {elementRemoveStandardFromPublishedVersion} = this.props;
    elementRemoveStandardFromPublishedVersion.sendRequest(this.props.standard.id);
    this.setState({confirmationOpen: false})
  };

  render() {
    return (
      <span>
        <ConfirmationDialog
          title="Remove Standard"
          message={`Are you sure you want to remove the standard ${this.props.standard.title} from this element?`}
          isOpen={this.state.confirmationOpen}
          onClose={this.handleCloseConfirm}
          onConfirm={this.handleConfirm}
        />
        <Button color="primary" onClick={this.clicked}>Remove</Button>
        {/*<Button color="primary" onClick={this.handleConfirm}>Delete</Button>*/}
      </span>
    )
  }
}

export default withClient({
  hooks: {
    // this should not point to assesssmentProblemELement
    elementRemoveStandardFromPublishedVersion: elementRemoveStandardFromPublishedVersion((state, props) => state.elements.selected || state.assessmentProblems.selectedAssessmentProblemElement)
  }
})(RemoveStandardButton)