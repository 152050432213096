import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import ClientRequest from "./clientRequest";

// REQUEST TYPES
const TABLE_PAGE = 'TABLE_PAGE';

// ACTION TYPES
const TABLE_REQUEST_PAGE = 'TABLE_REQUEST_PAGE';

const TABLE_CHANGE_PAGE = 'TABLE_CHANGE_PAGE';
const TABLE_CHANGE_PAGE_SIZE = 'TABLE_CHANGE_PAGE_SIZE';
const TABLE_CHANGE_SORT = 'TABLE_CHANGE_SORT';
const TABLE_CHANGE_SEARCH = 'TABLE_CHANGE_SEARCH';
const TABLE_CHANGE_SEARCH_FIELD = 'TABLE_CHANGE_SEARCH_FIELD';

const TABLE_SET_SELECTION = 'TABLE_SET_SELECTION';
const TABLE_ADD_SELECTION = 'TABLE_ADD_SELECTION';
const TABLE_REMOVE_SELECTION = 'TABLE_REMOVE_SELECTION';
const TABLE_TOGGLE_SELECTION = 'TABLE_TOGGLE_SELECTION';
const TABLE_CLEAR_SELECTION = 'TABLE_CLEAR_SELECTION';

// CHILD REDUCERS
const tablePage = ClientRequest({
  requestType: TABLE_PAGE,
  transformResponse: (err, results, response) => ({ results, totalCount: response.body.total })
});

// INITIAL STATE
const initialState = {
  selected: [],
  pagination: {
    page: 0,
    pageSize: 10,
    sort: {
      by: '',
      asc: false
    },
    search: '',
    searchField: ['name'],
  },
  valid: false,
};

// HELPER FUNCTIONS
function paginate(action, pagination) {
  // Unpack pagination information
  const {page, pageSize, sort, search, searchField} = pagination;
  let searchFields = searchField;

  // Set page & page size
  if (action.offset) {
    action.offset(page * pageSize);
    action.limit(pageSize);
  }

  // Set sort & sort order
  if (sort && sort.by.length > 0) {
    action.setSortFields([sort.by]);
    if (sort.asc) action.setOrderByAsc();
    else action.setOrderByDesc();
  }

  // Place searchFields in array if not already in array
  if (!Array.isArray(searchFields)) {
    searchFields = [searchFields];
  }

  // Set the search filter
  if (search && search.length > 1) {
    // &filter=shortTitle%3D'%258.F.5%25'
    // const searchFilter = `${searchField}='%${search}%'`;
    let searchFilter = searchFields.map(field => {
      return `${field}='%${search}%'`;
    }).join(' OR ');
    action.setFilter(searchFilter);
  }

  return action;
}

function replaceSelection(selection, values) {
  if (selection.length !== values.length) return values;
  for (let i = 0; i < selection.length; ++i) {
    if (selection[i] !== values[i]) return values;
  }
  return selection;
}
function mergeSelection(selection, values) {
  let newSelection = [];
  for (let i = 0; i < values.length; ++i) {
    if (selection.indexOf(values[i]) === -1) newSelection.push(values[i]);
  }
  if (newSelection.length < 1) return selection;
  return selection.concat(newSelection);
}
function spliceSelection(selection, values) {
  const newSelection = selection.filter(v => values.indexOf(v) === -1);
  if (newSelection.length === selection.length) return selection;
  return newSelection;
}
function toggleSelection(selection, values) {
  if (values.length < 1) return selection;
  let addSelection = [];
  let filterValues = [];
  for (let i = 0; i < values.length; ++i) {
    if (selection.indexOf(values[i]) === -1) addSelection.push(values[i]);
    else filterValues.push(values[i]);
  }
  const filterSelection = selection.filter(v => filterValues.indexOf(v) === -1);
  return filterSelection.concat(addSelection);
}

// Mark the table state as invalid -- clears the selection and forces a reload.
// Useful when an operation modifies the data in the table.
export function invalidateState(state) {
  if (state) {
    return {
      ...state,
      selected: [],
      valid: false
    };
  }
  return initialState;
}
// Reset the table state entirely. Useful during navigation operations
// when the user expects to see a new instance of the table.
export function resetState() {
  return initialState;
}

const updatePagination = (state, update) => ({
  ...state,
  selected: [],
  pagination: {
    ...state.pagination,
    ...update
  },
  valid: false,
});

// REDUCER
const baseReducer = (state = initialState, action) => {
  if (!action) return state;
  let selected;
  switch (action.type) {
    case TABLE_REQUEST_PAGE:
      return {
        ...state,
        valid: true
      };

    case TABLE_CHANGE_PAGE:
      if (state.pagination.page === action.payload) return state;
      return updatePagination(state, {page: action.payload});

    case TABLE_CHANGE_PAGE_SIZE:
      if (state.pagination.pageSize === action.payload) return state;
      return updatePagination(state, {pageSize: action.payload});

    case TABLE_CHANGE_SORT:
      const sort = {...state.pagination.sort};
      if (sort.by === action.payload) { sort.asc = !sort.asc; }
      else { sort.by = action.payload; }
      return updatePagination(state, {sort});

    case TABLE_CHANGE_SEARCH:
      if (state.pagination.search === action.payload) return state;
      return updatePagination(state, {search: action.payload});

    case TABLE_CHANGE_SEARCH_FIELD:
      if (state.pagination.searchField === action.payload) return state;
      return updatePagination(state, {searchField: action.payload});

    case TABLE_SET_SELECTION:
      selected = replaceSelection(state.selected, action.payload);
      if (state.selected === selected) return state;
      return {
        ...state,
        selected
      };
    case TABLE_ADD_SELECTION:
      selected = mergeSelection(state.selected, action.payload);
      if (state.selected === selected) return state;
      return {
        ...state,
        selected
      };
    case TABLE_REMOVE_SELECTION:
      selected = spliceSelection(state.selected, action.payload);
      if (state.selected === selected) return state;
      return {
        ...state,
        selected
      };
    case TABLE_TOGGLE_SELECTION:
      selected = toggleSelection(state.selected, action.payload);
      if (state.selected === selected) return state;
      return {
        ...state,
        selected
      };
    case TABLE_CLEAR_SELECTION:
      if (state.selected.length > 0) {
        return {
          ...state,
          selected: []
        };
      }
      return state;
    default:
      return state;
  }
};

// Reset the table state when the router location changes
const checkForRouteChange = (state, action) => {
  if (!action) return state;
  if (action.type === '@@router/LOCATION_CHANGE') {
    const resetTableState = resetState(state.state);
    if (resetTableState !== state.state) {
      return {
        ...state,
        state: resetTableState
      }
    }
  }
  return state;
};

export default (scope, fetch, postReducer) => {
  const subReducers = combineReducers({
    state: baseReducer,
    page: tablePage
  });

  const reducer = (state, action) => {
    if (!action.metadata || action.metadata.scope !== scope) {
      const tempState = checkForRouteChange(subReducers(state), action);
      if (!postReducer) return tempState;
      const newTableState = postReducer(tempState.state, action);
      if (newTableState !== tempState.state) {
        return {
          ...tempState,
          state: newTableState
        }
      }
      return tempState;
    }
    return subReducers(state, action);
  };

  // SELECTORS
  const getScope = state => state.tables[scope];
  const getPageRequest = state => state.tables[scope].page;

  const getPageContent = createSelector([getPageRequest], page => (page && page.data && page.data.results) || []);
  const getTotalCount = createSelector([getPageRequest], page => (page && page.data && page.data.totalCount) || 0);
  const getState = createSelector([getScope], scope => scope.state);

  reducer.Selectors = {
    getPageContent,
    getTotalCount,
    getPageRequest: createSelector([getPageRequest], page => page),
    getState,
    getSelection: createSelector([getPageContent, state => getState(state).selected], (page, selected) => {
      if (selected && selected.length && page && page.length) {
        return selected.map(s => page.find(i => i.id === s));
      }
      return [];
    })
  };

  // ACTION CREATORS
  reducer.Actions = {
    requestPage: props => (dispatch, getState) => {
      dispatch({ type: TABLE_REQUEST_PAGE, metadata: {scope} });
      const state = getState();
      const {pagination} = getScope(state).state;
      return dispatch(tablePage.sendRequest(paginate(fetch(state, props),pagination), {scope}));
    },

    // Pagination
    changePage(page) {
      return { type: TABLE_CHANGE_PAGE, payload: page, metadata: {scope} };
    },
    changePageSize(pageSize) {
      return { type: TABLE_CHANGE_PAGE_SIZE, payload: pageSize, metadata: {scope} };
    },
    changeSort(column) {
      return { type: TABLE_CHANGE_SORT, payload: column, metadata: {scope} };
    },
    changeSearch(value) {
      return { type: TABLE_CHANGE_SEARCH, payload: value, metadata: {scope} };
    },
    changeSearchField(value) {
      // Place searchField value in array if not already in array
      if (!Array.isArray(value)) {
        value = [value];
      }
      return { type: TABLE_CHANGE_SEARCH_FIELD, payload: value, metadata: {scope} };
    },

    // Selection
    setSelection(values) {
      if (!Array.isArray(values)) { values = [values]; }
      return { type: TABLE_SET_SELECTION, payload: values, metadata: {scope}};
    },
    addSelection(values) {
      if (!Array.isArray(values)) { values = [values]; }
      return { type: TABLE_ADD_SELECTION, payload: values, metadata: {scope}};
    },
    removeSelection(values) {
      if (!Array.isArray(values)) { values = [values]; }
      return { type: TABLE_REMOVE_SELECTION, payload: values, metadata: {scope}};
    },
    toggleSelection(values) {
      if (!Array.isArray(values)) { values = [values]; }
      return { type: TABLE_TOGGLE_SELECTION, payload: values, metadata: {scope}};
    },
    clearSelection() {
      return { type: TABLE_CLEAR_SELECTION, metadata: {scope}};
    },
  };

  return reducer;
};
