export const defaults = {
  transform: {
    error: (err, results, response) => err,
    response: (err, results, response) => results,
  },
  getState: state => state.client,
};

export const getRequestKey = (clientHook, state, props) => {
  if (typeof clientHook.key === 'function') return clientHook.key(state, props);
  return clientHook.key;
};

export class RequestState {
  constructor(state, params) {
    this.state = state;
    this.params = params;
  }

  get() {
    return this.isLoaded() ? this.state.data : this.isError() ? this.state.error : null;
  }
  getParams() {
    return this.params;
  }

  isFetchRequired() {
    if (!this.params) return false;
    if (!this.state) return true;
    const {pending, completed, error} = this.state;
    return !pending && !completed && !error;
  }

  isLoading() {
    return this.state && this.state.pending;
  }

  isLoaded() {
    if (!this.state) return false;
    const {pending, completed, error} = this.state;
    return !pending && completed && !error;
  }

  isError() {
    if (!this.state) return false;
    const {pending, completed, error} = this.state;
    return !pending && completed && error;
  }

  equals(other) {
    if (!other) return false;
    if (this.params !== other.params) return false;
    if (this.state !== other.state) return false;
    if (!this.state) return true;
    if (this.state.pending !== other.state.pending) return false;
    if (this.state.completed !== other.state.completed) return false;
    if (this.state.error !== other.state.error) return false;
    return true;
  }
  hasChanged(prevRequest) {
    if (this.isLoaded() && !(prevRequest && prevRequest.isLoaded())) return true;
    if (this.getParams() !== prevRequest.getParams()) return true;
  }
}
