import React from 'react';
import PropTypes from 'prop-types';

import IconWarning from '@material-ui/icons/Warning';

import {withClient, createSelector} from "reducers/client";
import {lessonContentContainerElements, elementDetails} from "reducers/client/requestTypes";

import ContentPreview from "./ContentPreview";
import {defaultElementTypeForContentable} from "constants/ElementTypes";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const LessonContentContainerPreview = ({
  lessonContentContainerElements,
  elementDetails,
  containerId,
  elementType,
  ...innerProps
}) => {
  // console.log(containerId, elementType, elementDetails);
  if (!lessonContentContainerElements.isLoaded()) return null;
  if (!lessonContentContainerElements.get().find(e => e.elementType === elementType)) {
    return (
      <p><IconWarning style={{verticalAlign:'middle'}}/> No "{elementType}" element found.</p>
    );
  }
  if (!elementDetails.isLoaded()) return null;
  return <ContentPreview contentVersionId={elementDetails.get().currentVersionId} {...innerProps} />;
};
LessonContentContainerPreview.propTypes = {
  containerId: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
};

const getContainerId = (state, props) => props.containerId;
const getElementType = (state, props) => props.elementType || defaultElementType.name;
const getLessonContentContainerElements = createSelector('lessonContentContainerElements',getContainerId);
export default withClient({
  hooks: {
    lessonContentContainerElements: lessonContentContainerElements(getContainerId),
    elementDetails: elementDetails((state, props) => {
      const lessonContentContainerElements = getLessonContentContainerElements(state, props);
      if (lessonContentContainerElements && lessonContentContainerElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const containerElement = lessonContentContainerElements.get().find(e => e.elementType === elementType);
        if (containerElement) return containerElement.id;
      }
      return null;
    }),
  }
})(LessonContentContainerPreview);
