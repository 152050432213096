import {map} from "lodash";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

const styles = {
  group: {
    'margin-right': `${5}px`,
    'border-right': `${1}px solid rgba(255,255,255,0.5)`,
    'padding-right': `${5}px`
  }
};

const Group = ({classes, prosemirror, visible, children}) => (!visible || visible(prosemirror.state)) &&
(<span className={classes.group}>
  {children}
</span>);

export default withStyles(styles)(withProsemirror(Group));