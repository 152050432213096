import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';
import IconSelect from '@material-ui/icons/CheckBox';

import {reduxDialog} from "reducers/dialog";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";

class HomeworkDialog extends React.Component {
  handleClose() {
    const {dialog: {actions}} = this.props;
    actions.close();
  }
  handleOptionClick(option) {
    const { dialog: {actions}, prosemirror } = this.props;
    prosemirror.insertHomeworkContainer(option.id);
    actions.close();
  }

  render() {
    const { dialog: {isOpen, data: options} } = this.props;

    if (!options) return false;
    return (
      <Dialog open={isOpen} onClose={() => this.handleClose()} disableRestoreFocus>
        <DialogTitle>Select a Hint</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to create a homework hint container. Select the hint that will
            show/hide this container.
          </DialogContentText>
        </DialogContent>
        <div>
          <List>
            {options.map(option => (
              <ListItem button onClick={() => this.handleOptionClick(option)} key={option.id}>
                <ListItemAvatar>
                  <Avatar>
                    <IconSelect />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText><b>{option.text}</b> {option.id}</ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    );
  }
}


export default reduxDialog({
  dialog: 'prosemirror-homework'
})(withProsemirror(HomeworkDialog));
