import {Plugin, PluginKey} from 'prosemirror-state';
import {log} from 'constants/Config';

export const PLUGIN_INTERFACE = new PluginKey('interface');

export default pm => {
  const plugin = new Plugin({
    key: PLUGIN_INTERFACE,
    filterTransaction(tr) {
      if (log.prosemirror) console.log('[PROSEMIRROR] Transaction', tr);
      if (tr.external) {
        pm.handle(tr);
        return false;
      }
      return true;
    }
  });

  plugin.interface = pm;
  return plugin;
}
