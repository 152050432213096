import {apiClient} from "constants/Clients";

export const chapterDetails = key => ({
  request: chapterId => apiClient.getChapter(chapterId),
  key,
  auto: true,
});
export const updateChapter = key => ({
  request: (chapterId, chapter) => apiClient.updateChapter({id: chapterId, ...chapter}),
  key,
});
export const deleteChapter = key => ({
  request: chapterId => apiClient.deleteChapter(chapterId),
  key,
});
export const chapterCreateLesson = key => ({
  request: (chapterId, lesson) => apiClient.createChapterLesson(chapterId, lesson),
  key,
});
export const chapterLessons = key => ({
  request: chapterId => apiClient.getChapterLessons(chapterId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const chapterLessonOrder = key => ({
  request: (chapterId, lessonIds) => apiClient.setChapterLessonOrder(chapterId, lessonIds),
  key,
});

export const chapterCreateAssessmentGroup = key => ({
  request: (chapterId, assessmentGroup) => apiClient.createChapterAssessmentGroup(chapterId, assessmentGroup),
  key,
});
export const chapterAssessmentGroups = key => ({
  request: chapterId => apiClient.getChapterAssessmentGroups(chapterId)
    .limit(100)
    .setSortFields(['name'])
    .setOrderByAsc(),
  key,
  auto: true,
});

export const chapterAssessmentProblems = key => ({
  request: chapterId => apiClient.getChapterAssessmentProblems(chapterId)
    .limit(100)
    .setSortFields(['assessmentQuestionId'])
    .setOrderByAsc(),
  key,
  auto: true,
});

export const chapterCreateAssessmentProblem = key => ({
  request: (chapterId, assessmentProb) => apiClient.createChapterAssessmentProblem(chapterId, assessmentProb)
    .setQueryParams({createElement: true}),
  key,
});
