import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {BookDetailsView} from "views/book/BookDetails";
import {BookCoverView} from "views/book/BookCover";
import {BookMenuRoutes} from './menu'
import {BookMenuListView} from "views/book/menu/BookMenuList";
import {BookStylesheetView} from "views/book/BookStylesheet";

import {selectBook} from "reducers/books";

export const BookRoutes = ({match: {url, path, params: {bookId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectBook(bookId))
  }, [bookId]);

  const selectedAssessmentProblem = useSelector(state => state.books.selected);
  if (!selectedAssessmentProblem) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={BookDetailsView}/>
      <Route path={`${path}/styles`} component={BookStylesheetView}/>
      <Route path={`${path}/menus/:menuID`} component={BookMenuRoutes}/>
      <Route path={`${path}/menus`} component={BookMenuListView}/>
      <Route path={`${path}/cover`} component={BookCoverView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
