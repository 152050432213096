import * as MathQuill from 'lib/mathquill/mathquill.js';
import schema from "../../schema/index";
import {NodeSelection} from "prosemirror-state";

const MQ = MathQuill.getInterface(2);

function focusMQAt(view, pos, fromRight) {
  const mqNodes = view.domAtPos(pos).node.getElementsByClassName('mq-editable-field');
  for (let i = 0; i < mqNodes.length; ++i) {
    const mq = MQ(mqNodes[0]);
    if (mq != null) {
      mq.focus();
      if (fromRight) { mq.moveToRightEnd(); }
      else { mq.moveToLeftEnd(); }
      const {doc, tr} = view.state;
      view.dispatch(tr.setSelection(NodeSelection.create(doc, doc.resolve(pos).before())));
      return true;
    }
  }
  return false;
}

export function insertMathQuill(state, dispatch, view) {
  const mq = schema.nodes.mathQuill.create();
  const cursorAt = state.selection.from;
  dispatch(state.tr.replaceSelectionWith(mq));
  focusMQAt(view, cursorAt + 1);
}

function arrowHandler(dir) {
  return (state, dispatch, view) => {
    if (state.selection.empty) {
      let side = dir === "left" ? -1 : 1;
      let $head = state.selection.$head;
      let $next = state.doc.resolve($head.pos + side);
      if ($next && $next.parent.type.name === "mathQuill") {
        return focusMQAt(view, $next.pos, dir === "left");
      }
    }
    return false;
  }
}

export default {
  ArrowLeft: arrowHandler("left"),
  ArrowRight: arrowHandler("right"),
  'Mod-m': insertMathQuill
};