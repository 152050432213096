import {onDoubleClick} from './util';

export default class CpmImageView {
  constructor(node, view, getPos) {
    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('img');
    this.dom.setAttribute('class', 'augmented-image');
    this.setAllAttributes();

    onDoubleClick(this.dom, () => {
      this.outerView.dispatch({
        external: true,
        method: 'image',
        nodeView: {
          node: this.node,
          view: this.outerView,
          getPos: this.getPos
        }
      });
    });
  }

  setAttribute(attr, value) {
    if (value != null) {
      this.dom.setAttribute(attr, value);
    } else {
      this.dom.removeAttribute(attr);
    }
  }

  setAllAttributes() {
    let {src, align, alt, border, width, height, horizontalPadding, verticalPadding} = this.node.attrs;

    if (src[0] === '/') {
      src = `https://ebooks-test.cpm.org${src}`;
    }

    this.setAttribute('src', src);
    this.setAttribute('align', align);

    this.setAttribute('alt', alt);
    this.setAttribute('title', alt);

    if (border) { this.dom.style.border = `${border}px solid black`; } else { this.dom.style.border = ''; }

    if (width != null) { this.dom.style.width = `${width}px`; } else { this.dom.style.width = ''; }
    if (height != null) { this.dom.style.height = `${height}px`; } else { this.dom.style.height = ''; }

    if (horizontalPadding || verticalPadding) {
      this.dom.style.padding = `${verticalPadding || 0}px ${horizontalPadding || 0}px`;
    } else {
      this.dom.style.padding = '';
    }
  }

  selectNode() {
    console.log('CpmImageView.selectNode()');
    this.dom.classList.add('ProseMirror-selectednode');
  }

  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  update(node) {
    if (node.type.name !== 'image') return false;
    this.node = node;
    this.setAllAttributes();
    return true;
  }

  destroy() {
  }

  stopEvent() {
    return event.type !== 'mousedown' && !event.type.startsWith('drag');
  }
  ignoreMutation() { return true; }
}
