import React, {Component} from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import {connect} from 'react-redux';
import {closeMenuDialog, setMenuDialogData} from "../../../reducers/Dialogs/Menu";
import MenuForm from './MenuForm';

class MenuModal extends Component {

  renderForm(menu){
    return <MenuForm menu={menu} onClose={this.props.mappedCloseDialog} />
  }

  closeDialog = () => {
    this.props.mappedSetMenuDialogData(null);
    this.props.mappedCloseDialog();
  };

  render(){
    return (
      <Dialog open={this.props.isOpen} onClose={this.closeDialog} maxWidth={'md'} fullWidth>
        <DialogContent>
          <div className="row">
            <div className="col-sm-2">
            </div>
            <div className="col-sm-8">
              {this.renderForm()}
            </div>
            <div className="col-sm-2">
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = () => {
  return (state, props) => ({
    isOpen: state.dialogs.menus.isOpen,
    nodeData: state.dialogs.menus.data,
  });
};

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeMenuDialog()),
  mappedSetMenuDialogData: (payload) => dispatch(setMenuDialogData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal)