export const matchTypes = types => {
  // Return a predicate function matching the given type or types
  if (!Array.isArray(types)) types = [types];
  return node => types.includes(node.type);
};

export function findDescendants(node, predicate) {
  // Find all nodes matching the predicate contained within a given root node
  let results = [];
  node.descendants(node => {
    if (predicate(node)) results.push(node);
  });
  return results;
}

export const findNodesInRange = ($from, $to, predicate) => {
  // Find the common ancestor node that contains the whole range
  const $common = commonAncestor($from, $to);
  // Check whether the range is contained within a single node matching the predicate
  const $ancestor = findAncestor($common, predicate);
  // If so, return that node
  if ($ancestor) return [$ancestor];
  // If not, find all child nodes contained within the range
  const offset = $common.start();
  const found = [];
  $common.parent.nodesBetween($from.pos - offset, $to.pos - offset, (node, pos) => {
    if (predicate(node)) found.push($from.doc.resolve(pos + offset));
  });
  return found;
};

export function commonAncestor($from, $to) {
  // Find a common ancestor for the two resolved positions.
  for (let d = Math.min($from.depth, $to.depth); d > 0; --d) {
    if ($from.before(d) === $to.before(d)) return $from.doc.resolve($from.before(d));
  }
  // Default to the whole document in case something goes wrong.
  return $from.doc.resolve(0);
}

export function findAncestor($pos, predicate) {
  // If the node at the given position matches the predicate, return it
  if ($pos.nodeAfter && predicate($pos.nodeAfter)) return $pos;
  // Go up the document tree, checking the node at every depth.
  for (let d = $pos.depth; d >= 0; --d) {
    if (predicate($pos.node(d))) {
      return $pos.doc.resolve($pos.before(d));
    }
  }
  return null;
}

export function getMarkedRange(doc, mark, pos) {
  // Get the start and end of a marked range given a mark and a position inside that mark's parent
  const $pos = doc.resolve(pos);
  const offset = $pos.start();
  const {content} = $pos.parent;

  // console.log($pos, offset, content);

  let start = 0;
  let end = 0;
  // Iterate over each child of the parent
  for (let i = 0; i < content.childCount; ++i) {
    const node = content.content[i];
    // If the child is marked by the given mark, add its size to the range
    // console.log(i, node, node.marks, mark);
    if (mark.isInSet(node.marks)) {
      if (end === 0) end = start + node.nodeSize;
      else end += node.nodeSize;
    } else {
      // If no marked children have been detected, increment the start of the range pointer
      if (end === 0) start += node.nodeSize;
      // If the end of the marked segment comes before the cursor, discard the marked range and continue searching
      else if (offset + end < pos) { start = end + node.nodeSize; end = 0; }
      // If marked children have already been recorded, we know we have the full range and can exit the loop
      else break;
    }
  }

  return {
    start: offset + start,
    end: offset + end
  };
}

export function getContext(doc, pos) {
  // Get a slice containing the given node, the node before, and the node after
  const $pos = doc.resolve(pos);
  const nodeSize = $pos.nodeAfter.nodeSize;
  const $nodeAfter = doc.resolve(pos + nodeSize);
  return doc.slice(pos - $pos.nodeBefore.nodeSize,pos + nodeSize + $nodeAfter.nodeAfter.nodeSize);
}