import {createSelector} from "reselect";
import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import ClientRequest, {RequestState} from "./util/clientRequest";
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";
import {mapSequence} from "./util/sequence";

// ACTION TYPES
export const ASSESSMENT_PROBLEM_SELECT = 'ASSESSMENT_PROBLEM_SELECT';
export const ASSESSMENT_PROBLEM_DELETE = 'ASSESSMENT_PROBLEM_DELETE';
export const ASSESSMENT_PROBLEM_SELECT_ELEMENT = 'ASSESSMENT_PROBLEM_SELECT_ELEMENT';
// CHILD REDUCERS
const deleteProblem = ClientRequest(ASSESSMENT_PROBLEM_DELETE);

// ACTION CREATORS
export function selectAssessmentProblem(assessmentProblemID) {
  return {type: ASSESSMENT_PROBLEM_SELECT, payload: assessmentProblemID};
}

export function selectAssessmentProblemElement(elementId) {
  return {type: ASSESSMENT_PROBLEM_SELECT_ELEMENT, payload: elementId}
}

export function deleteAssessmentProblem(id) {
  let req = apiClient.deleteAssessmentProblem(id);
  return deleteProblem.sendRequest(req)
}

export default combineReducers({
  selected: (state = null, action) => (action.type === ASSESSMENT_PROBLEM_SELECT ? action.payload : state),
  selectedAssessmentProblemElement: (state = null, action) => (action.type === ASSESSMENT_PROBLEM_SELECT_ELEMENT ? action.payload : state),
});
