import React from 'react';
import {connect} from 'react-redux';
import {getFormValues, getFormSyncErrors} from 'redux-form';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import LinkDialogForm from "./form";
import schema from "components/Prosemirror/schema/index";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {reduxDialog} from "reducers/dialog";
import {hasAnyProperty} from "util/form";

const formValues = getFormValues('linkDialog');
const formErrors = getFormSyncErrors('linkDialog');

class LinkDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };

  handleSubmit = () => {
    const { prosemirror, formData, dialog: {data} } = this.props;
    const attrs = {
      ...data.attrs,
      ...formData,
    };

    if (data.end) {
      prosemirror.updateMarkAttrs(data.start, data.end, schema.marks.link, attrs);
    } else {
      prosemirror.updateNodeAttrs(attrs, data.start);
    }

    this.handleClose();
  };

  render() {
    const { dialog: {isOpen, data}, formErrors } = this.props;
    return (<Dialog open={isOpen} maxWidth="sm" fullWidth disableRestoreFocus onClose={this.handleClose}>
      <DialogTitle>Link Properties</DialogTitle>
      <DialogContent>
        <LinkDialogForm initialValues={data && data.attrs} />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={this.handleSubmit} color="primary" disabled={hasAnyProperty(formErrors)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = (state, props) => ({
  formData: formValues(state),
  formErrors: formErrors(state),
});

export default connect(
  mapStateToProps
)(reduxDialog({
  dialog: 'prosemirror-link'
})(withProsemirror(LinkDialog)));
