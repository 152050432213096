import React from 'react';
import { connect } from 'react-redux';

import Header from 'components/App/Header';
import Sidenav from 'components/App/Sidenav';
import Footer from 'components/App/Footer';
import {setUserDiscovered} from 'reducers/profile';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

class MainApp extends React.Component {
  componentDidMount() {
    if (this.requireAuthentication()) {
      const {history} = this.props;
      history.push('/login');
    }
  }

  requireAuthentication() {
    const {existingJWT, setUserDiscovered} = this.props;
    const ssoCookie = getCookie('cpm-sso-token');

    if (ssoCookie) {
      const decodedJWT = parseJwt(ssoCookie);
      if (existingJWT !== ssoCookie) {
        setUserDiscovered({ JWT: ssoCookie, decodedJWT });
      }
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div className="main-app-container">
        <Sidenav />
        <section id="page-container" className="app-page-container">
          <Header />
          <div className="app-content-wrapper">
            <div className="app-content">
              <div className="full-height">
                {children}
              </div>
            </div>
            <Footer />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  existingJWT: state.profile.JWT
});
const mapDispatchToProps = dispatch => ({
  setUserDiscovered: (payload) => dispatch(setUserDiscovered(payload))
});

export const AppContainer = connect(mapStateToProps,mapDispatchToProps)(MainApp);
