import {displayModes,sidebarModes} from "constants/Prosemirror";

export const SNACKBAR_HIDDEN = 'SNACKBAR_HIDDEN';
export const SNACKBAR_SAVING = 'SNACKBAR_SAVING_IN_PROGRESS';
export const SNACKBAR_SAVE_SUCCESS = 'SNACKBAR_SAVE_SUCCESS';
export const SNACKBAR_SAVE_ERROR = 'SNACKBAR_SAVE_ERROR';

// ACTION TYPES
export const PROSEMIRROR_EDITOR_SET_DOC_CHANGED = 'PROSEMIRROR_EDITOR_SET_DOC_CHANGED';
export const PROSEMIRROR_EDITOR_SET_DISPLAY_MODE = 'PROSEMIRROR_EDITOR_SET_DISPLAY_MODE';
export const PROSEMIRROR_EDITOR_SET_EDIT_MODE = 'PROSEMIRROR_EDITOR_SET_EDIT_MODE';
export const PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE = 'PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE';
export const PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS = 'PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS';

// ACTION CREATORS
export function setEditorDocChanged(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_DOC_CHANGED, payload };
}
export function setEditorDisplayMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_DISPLAY_MODE, payload };
}
export function setEditorMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_EDIT_MODE, payload };
}
export function setSidebarMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE, payload };
}
export function hideSnackbar() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_HIDDEN };
}
export function showSnackbarSaving() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVING };
}
export function showSnackbarSaveSuccess() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVE_SUCCESS };
}
export function showSnackbarSaveError() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVE_ERROR };
}

// INITIAL STATE
const initialState = {
  docChanged: false,
  displayMode: displayModes.DISPLAY_ALL.id,
  editMode: true,
  sidebarMode: sidebarModes.NONE.id,
  snackbarStatus: SNACKBAR_HIDDEN,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case PROSEMIRROR_EDITOR_SET_DOC_CHANGED:
      return {
        ...state,
        docChanged: action.payload
      };
    case PROSEMIRROR_EDITOR_SET_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload.id ? action.payload.id : action.payload
      };
    case PROSEMIRROR_EDITOR_SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload
      };
    case PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE:
      const mode = action.payload.id ? action.payload.id : action.payload;
      if (sidebarModes[mode].disableWhenDirty) {
        if (state.docChanged) {
          return state;
        }
        return {
          ...state,
          editMode: false,
          sidebarMode: mode,
        };
      } else {
        return {
          ...state,
          sidebarMode: mode,
        };
      }
    case PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS:
      return {
        ...state,
        snackbarStatus: action.payload,
      };
    default:
      return state;
  }
};
