import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
// import { CLIENT_REQUEST_SUCCESS } from '../../util/clientRequest';
// import { SELECT_CLASSIFICATION } from '../../classificationsSelect';

const StandardsTable = PagedTable(
  'standards',
  // state => {
  //   if (state.classificationsSelect.selected === '') {
  //     console.log("SELECTED === ''");
  //     return apiClient.getClassificationStandards();
  //   }
  //   else {
  //     console.log("SELECTED IS SELECTED");
  //     return apiClient.getStandardsForClassification(state.classificationsSelect.selected);
  //   }
  // },
  () => apiClient.getClassificationStandards(),
  (state, action) => {
    return state;
  }
);

export default StandardsTable;