import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';

import FilterBook from './Filters/FilterBook';
import FilterStandard from './Filters/FilterStandard';
import FilterApprovalStatus from './Filters/FilterApprovalStatus';
import FilterQuestionIdGreaterThan from "./Filters/FilterQuestionIdGreaterThan";
import FilterQuestionIdLessThan from "./Filters/FilterQuestionIdLessThan";


import FilterTypes from 'constants/FilterTypes';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  deleteIcon: {
    margin: theme.spacing(1),
    fontSize: 'small'
  },
  filterType: {
    margin: theme.spacing(1),
    paddingTop: `${1}em`,
    width: 140,
  },
});

class SearchFilter extends React.Component {
  handleFilterSettingChange = (setting, value) => {
    const {filterId, setFilterProperty} = this.props;
    setFilterProperty(filterId, setting, value);
  };

  handleFilterRemove = event => {
    const {filterId, removeFilter} = this.props;
    removeFilter(filterId);
  };

  render() {
    const {classes, filterId, filterState} = this.props;

    let FilterOptions = false;
    switch (filterState.type) {
      case FilterTypes.book.name: FilterOptions = FilterBook; break;
      case FilterTypes.standard.name: FilterOptions = FilterStandard; break;
      case FilterTypes.approval.name: FilterOptions = FilterApprovalStatus; break;
      case FilterTypes.questionIdGreaterThan.name: FilterOptions = FilterQuestionIdGreaterThan; break;
      case FilterTypes.questionIdLessThan.name: FilterOptions = FilterQuestionIdLessThan; break;
    }

    return (<div className={classes.root}>
      <IconButton
        aria-label="Delete"
        className={classes.deleteIcon}
        onClick={this.handleFilterRemove}
      >
        <IconClose />
      </IconButton>
      <span className={classes.filterType}>
        {filterState.type}
      </span>
      {FilterOptions && <FilterOptions
        filterId={filterId}
        filterState={filterState}
        onChange={this.handleFilterSettingChange} />}
    </div>);
  }
}

const mapStateToProps = () => {

  return (state, props) => {
    const getFilter = props.makeFilterSelector((state, props) => props.filterId);
    return {
      filterState: getFilter(state, props),
    }
  };
};

export default withStyles(styles)(connect(mapStateToProps, null)(SearchFilter));
