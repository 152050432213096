import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import IconDots from "@material-ui/icons/MoreHoriz";

import {withClient} from "reducers/client";
import {
  chapterAssessmentGroups,
  chapterCreateAssessmentGroup,
  updateAssessmentGroup,
  deleteAssessmentGroup
} from "reducers/client/requestTypes";

import ChapterAssessmentGroupsTableRow from './ChapterAssessmentGroupsTableRow';
import ConfirmationDialog from "components/Dialogs/Confirmation";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class ChapterAssessmentGroupsTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    chapterAssessmentGroups: PropTypes.object.isRequired,
    chapterCreateAssessmentGroup: PropTypes.object.isRequired,
    updateAssessmentGroup: PropTypes.object.isRequired,
    deleteAssessmentGroup: PropTypes.object.isRequired,

    chapterId: PropTypes.string.isRequired,
  };

  state = {
    assessmentGroups: [],
    adding: false,
    editingId: -1,
    edit: {
      name: ''
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    const {classes, chapterAssessmentGroups} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    if (chapterAssessmentGroups.isLoaded()) {
      this.setState({ assessmentGroups: chapterAssessmentGroups.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {chapterAssessmentGroups} = this.props;
    const {chapterAssessmentGroups:prevAssessmentGroups} = prevProps;
    if (chapterAssessmentGroups.isLoaded() && chapterAssessmentGroups.hasChanged(prevAssessmentGroups)) {
      this.setState({ assessmentGroups: chapterAssessmentGroups.get() });
    }
  }

  isWaiting() {
    const {
      chapterAssessmentGroups,
      chapterCreateAssessmentGroup,
      updateAssessmentGroup,
      deleteAssessmentGroup,
    } = this.props;
    return chapterAssessmentGroups.isLoading() ||
      chapterCreateAssessmentGroup.isLoading() ||
      updateAssessmentGroup.isLoading() ||
      deleteAssessmentGroup.isLoading();
  }
  pendingChanges() {
    const {assessmentGroups, adding, editingId, edit} = this.state;
    if (adding) return edit.name.length > 0;
    if (assessmentGroups[editingId]) return assessmentGroups[editingId].name !== edit.name;
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };
  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: ''
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.assessmentGroups[editingId].name
      },
    });
  };
  handleClickSave = () => {
    const {chapterCreateAssessmentGroup, updateAssessmentGroup, chapterAssessmentGroups} = this.props;
    const {assessmentGroups, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        chapterCreateAssessmentGroup.sendRequest(edit)
          .then(() => chapterAssessmentGroups.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateAssessmentGroup.sendRequest(assessmentGroups[editingId].id, edit)
          .then(() => chapterAssessmentGroups.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };
  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteAssessmentGroup, chapterAssessmentGroups} = this.props;
    const {assessmentGroups, editingId} = this.state;
    deleteAssessmentGroup.sendRequest(assessmentGroups[editingId].id)
      .then(() => chapterAssessmentGroups.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleClickRow = (ev, index) => {
    const {adding, editingId} = this.state;
    const waiting = this.isWaiting();
    if (waiting || adding || editingId !== -1) return false;
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger navigation if not clicking on a button
      if (t.classList.contains(this.props.classes.itemButton)) {
        return false;
      }
      t = t.parentElement;
    }
    this.props.history.push(`/app/assessmentGroups/${this.state.assessmentGroups[index].id}/details`);
  };

  render() {
    const {assessmentGroups, adding, editingId, edit, confirmationOpen} = this.state;
    if (!assessmentGroups) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete AssessmentGroup"
        message={`Are you sure you want to delete AssessmentGroup ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={2} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <TableBody>
          {assessmentGroups.map((assessmentGroup, index) => (
            <ChapterAssessmentGroupsTableRow
              key={assessmentGroup.id}
              classes={classes}
              assessmentGroup={assessmentGroup}
              index={index}
              adding={adding}
              waiting={Boolean(waiting)}
              pendingChanges={this.pendingChanges()}
              edit={edit}
              editingId={editingId}
              onEdit={this.handleEdit}
              onClick={ev => this.handleClickRow(ev, index)}
              onClickDelete={this.handleClickDelete}
              onClickSave={this.handleClickSave}
              onClickEdit={this.handleClickEdit}
            />
          ))}
        </TableBody>
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={2} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    chapterAssessmentGroups: chapterAssessmentGroups((state, props) => props.chapterId),
    chapterCreateAssessmentGroup: chapterCreateAssessmentGroup((state, props) => props.chapterId),
    updateAssessmentGroup: updateAssessmentGroup(),
    deleteAssessmentGroup: deleteAssessmentGroup(),
  },
})(withStyles(styles)(withRouter(ChapterAssessmentGroupsTable)));