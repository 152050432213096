import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  LinearProgress
} from '@material-ui/core';

import {formatDate} from "constants/Date";

import {withClient} from "reducers/client";
import {manual, mergeHistory, undoMerge, lessonContentContainerDetails, tabDetails} from "reducers/client/requestTypes";
import {clearRequest} from "reducers/client/actions";

import MergeHistoryTableRow from './MergeHistoryTableRow';

class MergeHistoryTable extends React.Component {
  static propTypes = {
    contentableId: PropTypes.string.isRequired,
  };
  state = {
    history: [],
  };

  componentDidMount() {
    const {mergeHistory} = this.props;
    if (mergeHistory.isLoaded()) {
      this.processHistory(mergeHistory.get());
    }
  }
  componentDidUpdate(prevProps) {
    const {mergeHistory} = this.props;
    const {mergeHistory:prevHistory} = prevProps;
    if (mergeHistory.isLoaded() && mergeHistory.hasChanged(prevHistory)) {
      this.processHistory(mergeHistory.get());
    }
  }

  processHistory(historyData) {
    const history = [];
    const undoStack = [];

    historyData.forEach(h => {
      let record = history.find(r => r.id === h.mergeId);
      if (!record) {
        record = {
          id: h.mergeId,
          author: h.author,
          createdAt: h.createdAt,
          comment: h.comment,
          isUndo: h.isUndo,
          operations: []
        };
        history.push(record);
      }
      let op = Object.assign({}, h);
      if (h.isUndo) {
        undoStack.push(op);
      } else {
        let index = undoStack.findIndex(u => u.contentableId === h.contentableId);
        if (index >= 0) {
          record.isUndone = true;
          op.undoneBy = undoStack[index].id;
          undoStack.splice(index, 1);
        }
      }

      record.operations.push(op);
    });

    this.setState({ history });
  }

  handleClickUndo = () => {
    const {lessonContentContainerDetails, tabDetails, mergeHistory, undoMerge, clearRequest} = this.props;
    undoMerge.sendRequest(mergeHistory.getParams())
      .then(() => mergeHistory.sendRequest())
      .then(history => {
        if (history && history.length > 0) {
          let mergeId = history[0].mergeId;
          for (let i = 0; i < history.length; ++i) {
            if (history[i].mergeId !== mergeId) break;
            switch (history[i].contentable) {
              case 'lessonContentContainer':
                clearRequest('lessonContentContainerElements', history[i].contentableId);
                lessonContentContainerDetails.sendRequest(history[i].contentableId).then(container => {
                  clearRequest('lessonContainers', container.lessonId);
                });
                break;
              case 'tab':
                clearRequest('tabElements', history[i].contentableId);
                tabDetails.sendRequest(history[i].contentableId).then(tab => {
                  clearRequest('tabbedViewTabs', tab.tabbedViewId);
                });
                break;
            }
            clearRequest('elementParents', history[i].elementId);
          }
        }
      });
  };

  render() {
    const {mergeHistory, undoMerge} = this.props;
    const {history} = this.state;
    let undoSet = false;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>When</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Merges</TableCell>
            <TableCell padding="checkbox" />
          </TableRow>
          {mergeHistory.isLoading() && (<TableRow style={{height:0}}>
            <TableCell colSpan={6} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <TableBody>
          {history.map((entry, i) => {
            let allowUndo = !(entry.isUndo || entry.isUndone) && !undoSet;
            if (allowUndo) undoSet = true;
            return (
              <MergeHistoryTableRow
                key={entry.id}
                item={entry}
                onClickUndo={allowUndo ? this.handleClickUndo : null}
                waiting={undoMerge.isLoading()}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearRequest: (name, params) => dispatch(clearRequest(name, params))
});

export default withClient({
  hooks: {
    mergeHistory: mergeHistory((state, props) => props.contentableId),
    lessonContentContainerDetails: manual(lessonContentContainerDetails()),
    tabDetails: manual(tabDetails()),
    undoMerge
  }
})(connect(
  null,
  mapDispatchToProps,
)(MergeHistoryTable));