import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {types} from 'constants/ApprovalStatuses';
import {asArray} from "constants/util/map";

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 180,
  }
});

const FilterApprovalStatus = props => (
  <FormControl className={props.classes.root}>
    <InputLabel>Approval Status</InputLabel>
    <Select
      value={props.filterState.approvalStatus}
      onChange={event => props.onChange('approvalStatus', event.target.value)} >
      {asArray(types).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
    </Select>
  </FormControl>
);

export default withStyles(styles)(FilterApprovalStatus);