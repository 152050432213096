// ACTION TYPES
export const REGISTER = '@@DIALOG/REGISTER';
export const UNREGISTER = '@@DIALOG/UNREGISTER';

export const OPEN = '@@DIALOG/OPEN';
export const CLOSE = '@@DIALOG/CLOSE';
export const SET_STATE = '@@DIALOG/SET_STATE';

export const registerDialog = (dialogId, initialState) => ({ type: REGISTER, payload: dialogId, initialState});
export const unregisterDialog = (dialogId) => ({ type: UNREGISTER, payload: dialogId});

export const dialogOpen = (dialogId, data, state) => ({ type: OPEN, payload: {data, state}, metadata: {dialogId} });
export const dialogSetState = (dialogId, state) => ({ type: SET_STATE, payload: state, metadata: {dialogId} });
export const dialogClose = (dialogId) => ({ type: CLOSE, metadata: {dialogId} });
