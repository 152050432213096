// ACTION TYPES
export const TAB_SELECT = 'TAB_SELECT';

// ACTION CREATORS
export function selectTab(tabId) {
  return {type: TAB_SELECT, payload: tabId};
}

const initialState = {
  selected: null,
};

export default (state = initialState, action) => {
  if (action.type === TAB_SELECT) {
    return {
      ...state,
      selected: action.payload
    };
  }
  return state;
}
