import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core';
import {
  ExpandMore as IconExpandMore,
  Remove as IconRemove,
} from '@material-ui/icons';

import {StyleSelectorTypes, StyleDescriptorTypes} from 'constants/StyleTypes';

import StylePropertyListing from './StylePropertyListing';

const styles = theme => ({
  actions: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: `${200}px`,
    margin: theme.spacing(1),
  }
});

const toCamelCase = cssName => {
  const parts = cssName.split('-');
  return parts.reduce((acc, value) => acc + value[0].toUpperCase() + value.slice(1));
};

class StylesheetEntry extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    onExpand: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
  };

  state = {
    styleProps: [],
    reactStyles: {},
    newProperty: {key: '', value: ''},
  };

  componentDidMount() {
    const {data} = this.props;
    this.initStyleProps(data);
  }
  componentDidUpdate(prevProps) {
    const {data} = this.props;

    if (data !== prevProps.data) {
      this.initStyleProps(data);
    }
  }
  initStyleProps(data) {
    const styleProps = [];
    const reactStyles = {};
    const unsupported = [];
    for (let propName in data.declaration) {
      if (data.declaration.hasOwnProperty(propName)) {
        const propType = StyleDescriptorTypes.find(t => t.descriptor === propName);
        if (!propType) { unsupported.push(propName); continue; }
        styleProps.push({
          name: propType.name,
          descriptor: propType.descriptor,
          value: data.declaration[propName]
        });
        if (propType.toCss) {
          let css = propType.toCss(data.declaration[propName]);
          if (typeof css === 'object') {
            for (let prop in css) reactStyles[toCamelCase(prop)] = css[prop];
          } else reactStyles[toCamelCase(propName)] = css;
        }
        else reactStyles[toCamelCase(propName)] = data.declaration[propName];
      }
    }
    if (unsupported.length > 0) {
      console.warn(`Dropped ${unsupported.length} unsupported descriptors from [${data.selector}]: `,unsupported);
      if (this.props.onChange) {
        const declaration = {};
        styleProps.forEach(prop => declaration[prop.descriptor] = prop.value);
        this.props.onChange({
          ...data,
          declaration
        });
      }
    }
    this.setState({styleProps, reactStyles});
  }

  handleChangeNewProperty(key, value) {
    console.log('handleChangeNewProperty()',key,value);
    this.setState({
      newProperty: {
        ...this.state.newProperty,
        [key]: value,
      }
    });
  }
  handleSaveNewProperty() {
    const {data, onChange} = this.props;
    const {newProperty} = this.state;
    if (onChange) {
      onChange({
        ...data,
        declaration: {
          ...data.declaration,
          [newProperty.key]: newProperty.value,
        }
      });
    }
    this.setState({newProperty: {key: '', value: ''}});
  }
  handleDeleteProperty(prop) {
    const {data, onChange} = this.props;
    if (onChange) {
      const declaration = data.declaration;
      delete declaration[prop.descriptor];
      onChange({
        ...data,
        declaration,
      });
    }
  }

  render() {
    const {classes, data, expanded, onExpand, onDelete} = this.props;
    const {styleProps, reactStyles, newProperty} = this.state;
    const styleType = StyleSelectorTypes.find(t => t.selector === data.selector);
    const newDescriptorType = newProperty.key && StyleDescriptorTypes.find(t => t.descriptor === newProperty.key);

    let EditorComponent = null;
    if (newDescriptorType) EditorComponent = newDescriptorType.EditorComponent;

    // console.log('StylesheetEntry.render()', styleType, data);

    return (
      <ExpansionPanel expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary expandIcon={<IconExpandMore />}>
          <div className="displayEbooks">
            {React.createElement(styleType.element, {style: reactStyles}, styleType.name)}
          </div>
        </ExpansionPanelSummary>
        <Table>
          <TableBody>
            {styleProps.map(prop => (
              <StylePropertyListing {...prop} key={prop.descriptor} onDelete={ev => this.handleDeleteProperty(prop)} />
            ))}
            <TableRow>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Select Style Property</InputLabel>
                  <Select
                    value={newProperty.key}
                    onChange={ev => this.handleChangeNewProperty('key', ev.target.value)}
                  >
                    <MenuItem value="">---</MenuItem>
                    {StyleDescriptorTypes.map(type => (
                      <MenuItem key={type.descriptor} value={type.descriptor}>{type.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                {newDescriptorType && (
                  <EditorComponent
                    styleData={data.declaration}
                    label={"Value"}
                    value={newProperty.value}
                    onChange={value => this.handleChangeNewProperty('value', value)}
                  />
                )}
              </TableCell>
              <TableCell padding="none">
                <Button
                  disabled={!newDescriptorType || (newDescriptorType.validate ? !newDescriptorType.validate(newProperty.value) : false)}
                  onClick={ev => this.handleSaveNewProperty()}
                >
                  Save
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
        <ExpansionPanelActions className={classes.actions}>
          <Button size="small" onClick={onDelete}>Delete</Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(StylesheetEntry);
