import React from 'react';
import {Redirect} from 'react-router-dom';

import {withClient} from "reducers/client";
import {elementParents, bookByEbooksKey} from "reducers/client/requestTypes";

const TabElementRedirectBase = props => {
  const {elementParents, bookByEbooksKey} = props;
  if (elementParents.isLoaded() && bookByEbooksKey.isLoaded()) {
    const bookName = bookByEbooksKey.get().name.toLowerCase();
    const {parents} = elementParents.get();
    let book = parents.find(p => p.name.toLowerCase() === bookName);
    let tab = parents.find(p => p.bookId === book.id);

    return <Redirect to={`/app/tabs/${tab.id}/editor`} />;
  }
  if (elementParents.isError() || bookByEbooksKey.isError()) {
    return <div>An error occurred while attempting to find the specified tab.</div>;
  }
  return <div>...</div>;
};

const getElementId = (state, props) => props.match.params.elementId;
const getBookName = (state, props) => props.match.params.bookName;
export const TabElementRedirect = withClient({
  hooks: {
    bookByEbooksKey: bookByEbooksKey(getBookName),
    elementParents: elementParents(getElementId)
  }
})(TabElementRedirectBase);