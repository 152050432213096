import { Plugin } from 'prosemirror-state';
import AnchorView from './anchor';
import MathQuillView from './mathQuill';
import DesmosView from './desmos';
import ContentContainerReferenceView from './contentContainerReference';
import CpmImageView from './image';
import CpmMediaView from './media';
import CpmEmbedView from './embed';
import LessonTitleView from './lessonTitle';

const construct = ClassDef => (node, view, getPos) => new ClassDef(node, view, getPos);

export default () => new Plugin({
  props: {
    nodeViews: {
      anchor: construct(AnchorView),
      mathQuill: construct(MathQuillView),
      desmos: construct(DesmosView),
      cpmProblem: construct(ContentContainerReferenceView),
      contentContainerReference: construct(ContentContainerReferenceView),
      lessonTitle: construct(LessonTitleView),
      image: construct(CpmImageView),
      media: construct(CpmMediaView),
      embed: construct(CpmEmbedView),
    }
  }
});

export {default as TableView} from './table';
