import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {LessonDetailsView} from "views/lesson/LessonDetails";
import {LessonEditorView} from "views/lesson/LessonEditor";
import {LessonMediaView} from "views/lesson/LessonMedia";

import {selectLesson} from "reducers/lessons";

export const LessonRoutes = ({match: {url, path, params: {lessonId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectLesson(lessonId))
  }, [lessonId]);

  const selectedLesson = useSelector(state => state.lessons.selected);
  if (!selectedLesson) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={LessonDetailsView}/>
      <Route path={`${path}/editor/:elementId`} component={LessonEditorView}/>
      <Route path={`${path}/media/:elementType`} component={LessonMediaView}/>
      <Route path={`${path}/media`} component={LessonMediaView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
