import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/bookMenus/index';
import DeleteMenuButton from './DeleteMenuButton';

import _ from 'lodash';

const columnData = [
  {
    id: 'bookMenu',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    content: c => c.name,
  },
  {
    id: 'bookMenuUrl',
    numeric: false,
    disablePadding: true,
    label: 'Type',
    content: c => c.type,
  },
  {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: '',
    content: c => {
      return (
        <div>
          <Link to={`/app/books/${c.bookId}/menus/${c.id}`}><Button color="primary" value={c.id}>Details</Button></Link>
          <DeleteMenuButton menu={c}/>
        </div>
      )
    }
  }
];

const BookMenusTable = PagedTable({
  title: 'BookMenus',
  columnData,
  reducer,
  select: 'multiple',
  actions: true,
});

const { Selectors, Actions } = reducer;

const mapStateToProps = state => ({
  pageRequest: Selectors.getPageRequest(state),
  data: Selectors.getPageContent(state),
  totalCount: Selectors.getTotalCount(state),
  tableState: Selectors.getState(state),
});

const mapDispatchToProps = dispatch => ({
  requestPage: () => dispatch(Actions.requestPage()),
  changePage: page => dispatch(Actions.changePage(page)),
  changePageSize: pageSize => dispatch(Actions.changePageSize(pageSize)),
  changeSort: column => dispatch(Actions.changeSort(column)),
  changeSearch: value => dispatch(Actions.changeSearch(value)),
  changeSearchField: value => dispatch(Actions.changeSearchField(value)),

  setSelection: values => dispatch(Actions.setSelection(values)),
  addSelection: values => dispatch(Actions.addSelection(values)),
  removeSelection: values => dispatch(Actions.removeSelection(values)),
  toggleSelection: values => dispatch(Actions.toggleSelection(values)),
  clearSelection: () => dispatch(Actions.clearSelection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookMenusTable);
