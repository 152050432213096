import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabbedViewDetailsView} from "views/tabbedView/TabbedViewDetails";

import {selectTabbedView} from "reducers/tabbedViews";

export const TabbedViewRoutes = ({match: {url, path, params: {tabbedViewId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectTabbedView(tabbedViewId))
  }, [tabbedViewId]);

  const selectedTabbedView = useSelector(state => state.tabbedViews.selected);
  if (!selectedTabbedView) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={TabbedViewDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
