import React, {Component} from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import {connect} from 'react-redux';
import {closeMenuItemDialog, setMenuItemDialogData} from "../../../reducers/Dialogs/MenuItem";
import AddMenuItemForm from './AddMenuItemForm';

class AddMenuItemDialog extends Component {

  renderForm(data){
    return <AddMenuItemForm data={data} mappedCloseDialog={this.props.mappedCloseDialog}/>
  }

  closeDialog = () => {
    this.props.mappedSetMenuItemDialogData(null);
    this.props.mappedCloseDialog();
  };

  render(){
    const {data} = this.props;
    return (
      <Dialog open={this.props.isOpen} onClose={this.closeDialog} maxWidth={'md'} fullWidth>
        <DialogContent>
          <div className="row">
            <div className="col-sm-2">
            </div>
            <div className="col-sm-8">
              {this.renderForm(data)}
            </div>
            <div className="col-sm-2">
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.dialogs.menuItems.isOpen,
  data: state.dialogs.menuItems.data
});

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeMenuItemDialog()),
  mappedSetMenuItemDialogData: (payload) => dispatch(setMenuItemDialogData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItemDialog)