import {icon} from '@fortawesome/fontawesome-svg-core';
import {faAnchor} from '@fortawesome/free-solid-svg-icons';
import {NodeSelection, TextSelection} from "prosemirror-state";

export default class AnchorView {
  constructor(node, view, getPos) {
    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('div');
    this.dom.setAttribute('class', 'anchor');

    this.input = document.createElement('input');
    this.input.setAttribute('type','text');
    this.input.setAttribute('placeholder','Name');
    this.input.value = node.attrs.name;

    this.dom.addEventListener('mousedown', ev => {
      this.input.select();
      ev.preventDefault();
    });
    this.input.addEventListener('mousedown', ev => ev.stopPropagation());
    this.input.addEventListener('input', ev => {
      console.log('inputEvent()', this.input.value);
      if (this.input.value !== this.node.attrs.name) {
        const {tr} = this.outerView.state;
        tr.setNodeMarkup(this.getPos(), null, {name: this.input.value});
        this.outerView.dispatch(tr);
      }
    });
    this.input.addEventListener('keydown', ev => {
      let exitPos = null;
      if (ev.key === 'ArrowUp') {
        exitPos = this.getPos();
      } else if (ev.key === 'ArrowDown') {
        exitPos = this.getPos() + this.node.nodeSize;
      }

      if (exitPos !== null) {
        this.input.blur();
        this.outerView.focus();

        const {tr, doc} = this.outerView.state;
        tr.setSelection(TextSelection.create(doc, exitPos));
        this.outerView.dispatch(tr);
      }
    });

    this.icon = icon(faAnchor).node[0];

    this.dom.appendChild(this.icon);
    this.dom.appendChild(this.input);
  }

  selectNode() {
    console.log('selectNode()');
    this.input.select();
  }

  update(node) {
    if (node.type.name !== 'anchor') return false;
    console.log('update()');
    const {name} = node.attrs;

    if (name !== this.input.value) {
      this.input.value = name;
      this.node = node;
    }

    return true;
  }

  stopEvent(ev) {
    console.log('stopEvent ? ', ev);
    return !!ev.key || ev.type === 'input';
  }
  ignoreMutation() { return true; }
}
