// Create a compound key that will always return the same object given the same inputs
export const createCompoundKey = keyGenerators => {
  const cache = {};
  const keyNames = Object.keys(keyGenerators);

  return (state, props) => {
    let key = {};
    let cacheIndex = cache;
    for (let i = 0; i < keyNames.length; ++i) {
      const name = keyNames[i];
      const index = keyGenerators[name](state, props);
      key[name] = index;
      if (i < keyNames.length - 1) {
        // If before the last key, create a new cache line
        if (!cacheIndex[index]) cacheIndex[index] = {};
      } else {
        // If at the last key, store the newly created key in the cache
        if (!cacheIndex[index]) cacheIndex[index] = key;
      }
      cacheIndex = cacheIndex[index];
    }
    return cacheIndex;
  }
};
