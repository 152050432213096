import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';

import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {renderRawHTML} from "components/Prosemirror/render";
import HtmlPreview from 'components/Editor/Views/HtmlPreview';

import {getElementTypeByName} from "constants/ElementTypes";

import {withClient} from "reducers/client";
import {
  lessonContainers,
} from "reducers/client/requestTypes";
import LessonContentContainerDialogForm from "components/Dialogs/Prosemirror/LessonContentContainer/createForm";

class LessonContentContainerDialogInsertTab extends React.Component {

  static propTypes = {
    parentElementType: PropTypes.object.isRequired,
    lessonId: PropTypes.string.isRequired,
    htmlContent: PropTypes.string
  };
  state = {
    formContainerOptions: []
  };

  componentDidMount() {
    const {lessonContainers} = this.props;
    if (lessonContainers.isLoaded()) {
      this.setState({
        formContainerOptions: this.getFormOptions(lessonContainers.get())
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {lessonContainers} = this.props;
    const {lessonContainers: prevContainers} = this.props;
    if (lessonContainers.isLoaded() && lessonContainers.hasChanged(prevContainers)) {
      this.setState({
        formContainerOptions: this.getFormOptions(lessonContainers.get())
      });
    }
  }

  getFormOptions(containers) {
    const {parentElementType} = this.props;
    let validChildElementTypes = null;
    if (parentElementType && parentElementType.validChildElementTypes) {
      validChildElementTypes = parentElementType.validChildElementTypes;
    }

    return containers.map(container => {
      let elementTypes = container.elements.map(el => el.elementType);
      if (validChildElementTypes) {
        elementTypes = elementTypes.filter(type => validChildElementTypes.includes(type));
      }
      return {
        id: container.id,
        name: container.name,
        elementTypes
      };
    }).filter(container => container.elementTypes.length > 0);
  }

  render() {
    const {parentElementType, formData, htmlContent} = this.props;
    const {formContainerOptions} = this.state;

    let defaultElementType = null;
    if (parentElementType && parentElementType.validChildElementTypes) {
      defaultElementType = parentElementType.validChildElementTypes[0];
    }
    let selectedElementType = getElementTypeByName(formData && formData.elementType);
    let selectedContainer = null;
    let conflictingElement = null;
    if (formData && formData.containerId) {
      selectedContainer = formContainerOptions.find(p => p.id === formData.containerId);
      conflictingElement = selectedContainer.elementTypes.find(e => e === formData.elementType);
    }

    return (
      <div className="row">
        <div className="col-sm-3">
          <LessonContentContainerDialogForm
            containers={formContainerOptions}
            elementTypes={parentElementType && parentElementType.validChildElementTypes}
            initialValues={{
              elementType: defaultElementType,
            }}
          />
          {selectedContainer && (conflictingElement ? (
            <p style={{color:'red'}}>
              Lesson Content Container <b>{formData.name}</b> already
              has a <b>{selectedElementType.displayName || selectedElementType.name}</b> element!
              If you submit now it will be overwritten.
            </p>
          ) : (
            <p>
              Adding <b>{selectedElementType.displayName || selectedElementType.name}</b> content
              to Lesson Content Container <b>{formData.name}</b>!
            </p>
          ))}
        </div>
        <div className="col-sm-9">
          <h2 className="article-title">Preview</h2>
          {htmlContent && <HtmlPreview html={htmlContent} postProcess={renderRawHTML} />}
        </div>
      </div>
    );
  }
}

const createFormValues = getFormValues('lessonContentContainerDialogCreate');
const mapStateToProps = () => {
  return (state, props) => ({
    formData: createFormValues(state)
  });
};

const getLessonId = (state, props) => props.lessonId;
export default withClient({
  hooks: {
    lessonContainers: lessonContainers(getLessonId),
  }
})(connect(
  mapStateToProps
)(withProsemirror(LessonContentContainerDialogInsertTab)));
