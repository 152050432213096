import React, {Component} from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import {connect} from 'react-redux';
import {closeStandardDialog, setStandardDialogData} from "../../../reducers/Dialogs/Standards";
import {makeStandardSelector, saveStandard, requestStandardDetails} from "../../../reducers/standards";
import StandardForm from './StandardForm';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MapStandardTable from 'components/Tables/PagedTable/MapStandardTable';
import Button from "@material-ui/core/Button";
import {mapStandardContentRelations} from "../../../reducers/classifications";

class StandardModal extends Component {

  state = {
    tabValue: 'add'
  };

  renderForm(standard){
    return <StandardForm
    classificationId={this.props.classificationId}
    standard={standard}
    onClose={this.props.mappedCloseDialog}
    save={(payload) => this.props.mappedSaveStandard(payload)}
    />
  }

  renderMapStandardTable = () => {
      return (
        <div>
          <h2 className="article-title">Map Content to standards</h2>
          <MapStandardTable />
          <Button onClick={this.mapStandardsClicked} variant={'contained'} color="primary">Map Selected Standards</Button>
        </div>
      )
  };

  mapStandardsClicked = () => {
    let payload = {
      standardId: this.props.standard.id,
      standardIds: this.props.tableData.state.selected
    };
    this.props.mappedMapStandardContentRelations(payload);
    this.props.mappedCloseDialog();
  };

  renderTab = () => {
      if(this.state.tabValue === 'add' && this.props.standard){
        return this.renderMapStandardTable()
      } else {
        return this.renderForm()
      }
  };

  closeDialog = () => {
    this.props.mappedSetStandardDialogData(null);
    this.props.mappedCloseDialog();
  };

  handleChangeTab = (event, tabValue) => {
    console.log(tabValue);
    this.setState({tabValue})
  };

  render(){
    return (
      <Dialog open={this.props.isOpen} onClose={this.closeDialog} maxWidth={'md'} fullWidth>
        <DialogContent>
          <div className="row">
            <div className="col-sm-1">
            </div>
            <div className="col-sm-10">
              {this.props.standard &&
                <Tabs
                  value={this.state.tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleChangeTab}
                >
                  <Tab label="Map Standard Content" value={'add'}/>
                  <Tab label="Create New Standard" value={'create'}/>
                </Tabs>
              }
              <div style={{padding:10}}>
                {this.renderTab()}
              </div>
              {/*{this.renderForm()}*/}
              {/*Standards Form.*/}
            </div>
            <div className="col-sm-1">
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = () => {
  const getStandard = makeStandardSelector((state, props) => {
    return props.standardId
  });
  return (state, props) => ({
    isOpen: state.dialogs.standards.isOpen,
    nodeData: state.dialogs.standards.data,
    tableData: state.tables.standards,
    standardRequest: getStandard(state, props),
    standard: state.dialogs.standards.data,
    // saveRequest: state.medias.save,
  });
};

const mapDispatchToProps = dispatch => ({
  mappedCloseDialog: () => dispatch(closeStandardDialog()),
  mappedSetStandardDialogData: (payload) => dispatch(setStandardDialogData(payload)),
  mappedSaveStandard: payload => dispatch(saveStandard(payload)),
  mappedMapStandardContentRelations: paylaod => dispatch(mapStandardContentRelations(paylaod))
  // mappedRequestStandard: standardId => dispatch(requestStandardDetails(standardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandardModal)