import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {CategoryDetailsView} from "views/category/CategoryDetails";

import {selectCategory} from "reducers/categories";

export const CategoryRoutes = ({match: {url, path, params: {categoryId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectCategory(categoryId))
  }, [categoryId]);

  const selectedCategory = useSelector(state => state.categories.selected);
  if (!selectedCategory) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={CategoryDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
