import React from 'react';
import {connect} from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core';

import {MediaBrowser} from "components/Media/MediaBrowser";
import {withProsemirror} from "components/Prosemirror/components/ProsemirrorInterface";
import {reduxDialog} from "reducers/dialog";
import {hasAnyProperty} from "util/form";

import ImageAttrs from './imageAttrs';
import ImageToolbar from './imageToolbar';

class ImageDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleSubmit = () => {
    const {dialog: {actions, data, state}, prosemirror, formData} = this.props;
    if (data && data.pos) {
      prosemirror.updateNodeAttrs({
        ...formData,
        'media-id': state.mediaId
      }, data.pos);
    } else {
      prosemirror.insertNode('media',{
        ...formData,
        'media-id': state.mediaId
      });
    }
    actions.close();
  };

  handleSelectImage = (ev, media) => media && this.props.dialog.actions.setState({mediaId: media.id});
  handleUploadImage = media => this.handleSelectImage(null, media);

  render() {
    const { dialog: {isOpen, data, state}, formErrors } = this.props;

    let title, content, allowSubmit, allowUpload;

    if (state.mediaId) {
      title = 'Edit Image';
      content = <ImageAttrs
        initialValues={{
          ...(data && data.attrs),
          aspect: false
        }}
        onClickReplace={ev => this.handleSelectImage(ev, {})}
      />;
      allowSubmit = !hasAnyProperty(formErrors);
      allowUpload = false;
    } else {
      title = 'Select Image';
      content = <MediaBrowser onClick={this.handleSelectImage} />;
      allowSubmit = false;
      allowUpload = true;
    }

    return (
      <Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
        <DialogTitle>
          <ImageToolbar
            title={title}
            allowUpload={allowUpload}
            onUploadImage={this.handleUploadImage}
          />
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary" disabled={!allowSubmit}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const formValues = getFormValues('imageAttrs');
const formErrors = getFormSyncErrors('imageAttrs');
const mapStateToProps = state => ({
  formData: formValues(state),
  formErrors: formErrors(state)
});

export default connect(
  mapStateToProps,
)(reduxDialog({
  dialog: 'prosemirror-image',
  initialState: {mediaId: null}
})(withProsemirror(ImageDialog)));
