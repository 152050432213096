import {Plugin, PluginKey, NodeSelection} from 'prosemirror-state';

import {matchTypes, findAncestor} from "components/Prosemirror/util";
import {anyBrokenColumns} from "./fixColumns";
import schema from "components/Prosemirror/schema";

export const PLUGIN_COLUMNS = new PluginKey('columns');

const isColumnSet = matchTypes(schema.nodes.columnSet);
export default () => {
  return new Plugin({
    key: PLUGIN_COLUMNS,

    props: {
      handleTripleClick(view, pos) {
        const {doc, tr} = view.state;
        const $columnSet = findAncestor(doc.resolve(pos), isColumnSet);
        if (!$columnSet) return false;
        view.dispatch(tr.setSelection(new NodeSelection($columnSet)));
        return true;
      }
    },

    appendTransaction(transactions, oldState, state) {
      // return normalizeSelection(state, fixTables(state, oldState), allowTableNodeSelection)
      // return fixColumns(state, oldState);

      if (anyBrokenColumns(state, oldState)) {
        console.error("ColumnSet in illegal state after ",transactions,", reverting...");
        const {tr} = state;
        for (let t = transactions.length - 1; t >= 0; --t) {
          const curr = transactions[t];
          for (let s = curr.steps.length - 1; s >= 0; --s) {
            tr.step(curr.steps[s].invert(curr.docs[s]));
          }
        }
        console.log(tr);
        return tr;
      }
    }
  });
}
