import React from 'react';
import QueueAnim from 'rc-queue-anim';
import StandardsTable from 'components/Tables/PagedTable/StandardsTable';
import ClassificationsSelect from 'components/ClassificationsSelect';

export const StandardListView = props => (
  <section className={"container-fluid chapter"}>
    <QueueAnim type={"bottom"} className={"ui-animate"}>
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              Standards
            </h2>
            <ClassificationsSelect />
            <StandardsTable />
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);
