import React from 'react';
import PropTypes from 'prop-types';

import {
  Toolbar,
  Typography,
  IconButton
} from "@material-ui/core";
import IconBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import BrowserClassificationList from './BrowserList/BrowserClassificationList';
import BrowserGradeList from './BrowserList/BrowserGradeList';
import BrowserStandardList from './BrowserList/BrowserStandardList';

const styles = theme => ({
  breadcrumb: {
    margin: theme.spacing(1),
  }
});

class ClassificationStandardBrowser extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    blacklist: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
  };
  state = {
    selectedClassification: null,
    selectedGrade: null,
    selectedStandard: null,
  };

  crumb(crumb) {
    return (
      <Typography
        className={this.props.classes.breadcrumb}
        key={crumb.id}
        variant="button">
        {crumb.name || crumb.title}
      </Typography>
    );
  }
  getBreadcrumb() {
    const {selectedClassification, selectedGrade} = this.state;
    let crumbs = [];
    if (selectedClassification) {
      crumbs.push(
        <IconButton key={'Back'} onClick={this.handleClickBack} disabled={this.props.disabled}>
          <IconBack fontSize="small"/>
        </IconButton>
      );
      crumbs.push(this.crumb(selectedClassification));
      if (selectedGrade) {
        crumbs.push('>');
        crumbs.push(this.crumb(selectedGrade));
      }
    }
    return crumbs;
  }

  handleClick(key, value) {
    this.setState({[key]:value});
  }
  handleStandardClick = (ev, row) => {
    const {onChange} = this.props;
    if (typeof onChange === 'function') onChange([row]);
    this.setState({selectedStandard: row});
  };
  handleClickBack = () => {
    if (this.state.selectedGrade) this.setState({selectedGrade: null});
    else if (this.state.selectedClassification) this.setState({selectedClassification: null});
  };

  render() {
    const {selectedClassification, selectedGrade, selectedStandard} = this.state;
    let activeTable;
    if (selectedGrade) {
      activeTable = (
        <BrowserStandardList
          disabled={this.props.disabled}
          blacklist={this.props.blacklist}
          gradeId={selectedGrade.id}
          onClick={this.handleStandardClick}
          selected={selectedStandard && selectedStandard.id}
        />
      );
    } else if (selectedClassification) {
      activeTable = (
        <BrowserGradeList
          disabled={this.props.disabled}
          classificationId={selectedClassification.id}
          onClick={(ev, row) => this.handleClick('selectedGrade',row)}
        />
      );
    } else {
      return (<BrowserClassificationList
        disabled={this.props.disabled}
        onClick={(ev, row) => this.handleClick('selectedClassification',row)}
      />);
    }

    return (<React.Fragment>
      <Toolbar>
        {this.getBreadcrumb()}
      </Toolbar>
      {activeTable}
    </React.Fragment>);
  }
}

export default withStyles(styles)(ClassificationStandardBrowser);
