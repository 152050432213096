import {reducer, actionTypes} from 'redux-form';

export default reducer.plugin({
  imageAttrs: (state, action) => {
    if (state && state.values) {
      const {baseWidth, baseHeight} = state.values;
      if (state.values.aspect) {
        if (action.type === actionTypes.CHANGE) {
          switch (action.meta.field) {
            case 'aspect': return {
              ...state,
              values: {
                ...state.values,
                height: Math.round(state.values.width / baseWidth * baseHeight),
              }
            };
            case 'width': return {
              ...state,
              values: {
                ...state.values,
                width: action.payload,
                height: Math.round(action.payload / baseWidth * baseHeight),
              }
            };
            case 'height': return {
              ...state,
              values: {
                ...state.values,
                width: Math.round(action.payload * baseWidth / baseHeight),
                height: action.payload,
              }
            };
            default: return state;
          }
        }
      }
    }
    return state;
  },
  lessonContentContainerDialogInsert: (state, action) => {
    if (state && state.values) {
      const {selectedContainer, elementType} = state.values;
      if (action.type === actionTypes.CHANGE && action.meta.field === 'selectedContainer') {
        if (selectedContainer && selectedContainer.elementTypes) {
          if (selectedContainer.elementTypes[0] !== elementType) {
            return {
              ...state,
              values: {
                ...state.values,
                elementType: selectedContainer.elementTypes[0],
              }
            };
          }
          return state;
        }
        return {
          ...state,
          values: {
            ...state.values,
            elementType: null,
          }
        };
      }
    }
    return state;
  }
});