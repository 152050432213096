import {icon} from '@fortawesome/fontawesome-svg-core';
import {faLink, faAsterisk} from '@fortawesome/free-solid-svg-icons';

import {onDoubleClick} from './util';

import {renderContentReference} from "../../render";
import {createObserver} from "reducers/client";
import {lessonContentContainerReference} from "reducers/client/requestTypes";
import {PLUGIN_INTERFACE} from "components/Prosemirror/plugins/interface";

import {getElementTypeByName} from 'constants/ElementTypes';
import {log} from "constants/Config";

let count = 0;

const mapParents = parents => {
  const containers = [];
  const books = {};

  parents.forEach(p => {
    if (p.type === 'lessonContentContainer') containers.push(p);
    else if (p.type === 'book') books[p.id] = p.name;
  });
  return containers.map(p => `${books[p.bookId]} ${p.name}`).join(', ');
};

const contentReferenceToolbar = view => {
  const root = document.createElement('div');
  root.setAttribute('class','contentContainerReference-toolbar');

  const title = document.createElement('span');
  title.setAttribute('class','contentContainerReference-title');
  title.innerText = view.node.attrs.title;

  let type = getElementTypeByName(view.elementType);
  if (type && type.displayName) {
    root.appendChild(document.createTextNode(type.displayName));
  } else {
    root.appendChild(document.createTextNode(view.elementType));
  }

  root.appendChild(title);

  let myLink = null;
  let myDraft = null;

  return {
    dom: root,
    getTitle() { return title.innerText; },
    setTitle(value) { title.innerText = value; },
    setLinks(value) {
      let isShared = value && value.filter(p => p.type !== 'book').length > 1;
      if (isShared && !myLink) {
        myLink = icon(faLink, {title: mapParents(value)});
        root.appendChild(myLink.node[0]);
      } else if (!isShared && myLink) {
        root.removeChild(myLink.node[0]);
      }
    },
    setDraft(value) {
      if (value && !myDraft) {
        myDraft = icon(faAsterisk, {title: "This element has an un-published draft.", styles: {'color':'red'}});
        root.insertBefore(myDraft.node[0], root.firstChild);
      } else if (!value && myDraft) {
        root.removeChild(myDraft.node[0]);
      }
    }
  };
};

const createContentObserver = (props, store, handler) => {
  let cachedProps = {};
  const getReferenceKey = (state, {containerId: id, elementType}) => {
    if (id !== cachedProps.id || elementType !== cachedProps.elementType) {
      cachedProps = {id, elementType};
    }
    return cachedProps;
  };
  return createObserver(
    {
      hooks: {
        lessonContentContainerReference: lessonContentContainerReference(getReferenceKey)
      },
      props
    },
    store,
    handler
  );
};

let hasAttemptedLoad = false;

export default class ContentContainerReferenceView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.containerId = node.attrs.containerId || node.attrs.problemId;
    this.elementType = node.attrs.elementType;
    this.dom = document.createElement('div');
    this.dom.setAttribute('class', 'contentContainerReference');
    this.toolbar = contentReferenceToolbar(this);
    this.containerContentDom = document.createElement('div');
    this.containerContentDom.setAttribute('class', 'contentContainerReference-body');

    if (this.containerId) {
      this.containerContentDom.innerHTML = `Reference <strong>${this.containerId}</strong> loading...`;

      this.clientObserver = createContentObserver(
        {
          containerId: this.containerId,
          elementType: this.elementType,
        },
        this.interface.getReduxStore(),
        clientState => this.onContentUpdated(clientState)
      );

      onDoubleClick(this.containerContentDom, () => {
        this.outerView.dispatch({
          external: true,
          method: 'redirect',
          to: `/app/lessonContentContainers/${this.containerId}/editor/${this.elementType}`
        });
      });
    } else {
      this.containerContentDom.setAttribute('class', 'error');
      this.containerContentDom.innerHTML = 'Error: no container or element linked to this reference';
    }

    this.dom.appendChild(this.toolbar.dom);
    this.dom.appendChild(this.containerContentDom);

    if (log.prosemirror) {
      console.group(`[PROSEMIRROR] CONSTRUCT ContentContainerReferenceView [${this.id}] _________________________`);
      console.log('containerId:', this.containerId);
      console.log('elementType:', this.elementType);
      console.groupEnd();
    }
  }

  onContentUpdated({lessonContentContainerReference}) {
    let params = lessonContentContainerReference.getParams();
    if (!params || params.id !== this.containerId || params.elementType !== this.elementType) return;
    if (lessonContentContainerReference.isLoaded()) {
      let ref = lessonContentContainerReference.get();
      // Set toolbar title
      let title = ref.name;
      let separator = '\u00a0\u00b7\u00a0';
      if (ref.lesson && ref.lesson.name) title = ref.lesson.name + separator + title;
      if (ref.book && ref.book.name) title = ref.book.name.toUpperCase() + separator + title;
      this.toolbar.setTitle(title);
      // Update toolbar icons
      this.toolbar.setDraft(ref.element.hasPendingDraft);
      this.toolbar.setLinks(ref.element.parents);
      // Render
      if (ref.renderedContent != null) {
        this.containerContentDom.innerHTML = ref.renderedContent;
        renderContentReference(this.containerContentDom);
      } else {
        this.containerContentDom.classList.add('error');
        this.containerContentDom.innerHTML = `Error: Container <strong>${this.containerId}</strong> HTML not found`;
      }
    } else if (lessonContentContainerReference.isError()) {
      this.containerContentDom.classList.add('error');
      if (this.containerId) {
        this.containerContentDom.innerHTML =
          `Error: Container <strong>${this.containerId}</strong> could not retrieve HTML from server`;
      } else {
        this.containerContentDom.innerHTML = 'Error: Could not retrieve Container HTML from server';
      }
    }
  }

  selectNode() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] ContentContainerReferenceView [${this.id}] :: selectNode()`);
    this.dom.classList.add('ProseMirror-selectednode');
  }

  deselectNode() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] ContentContainerReferenceView [${this.id}] :: deselectNode()`);
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  update(node) {
    if (node.type.name !== 'cpmProblem' && node.type.name !== 'contentContainerReference') return false;
    // if (this.toolbar.getTitle() !== node.attrs.title) {
    //   this.toolbar.setTitle(node.attrs.title);
    // }
    if (log.prosemirror) console.log(`[PROSEMIRROR] UPDATE ContentContainerReferenceView [${this.id}]`);
    return true;
  }

  destroy() {
    if (log.prosemirror) console.log(`[PROSEMIRROR] DESTROY ContentContainerReferenceView [${this.id}]`);
    if (this.clientObserver) {
      this.clientObserver.unsubscribe();
      this.clientObserver = null;
    }
  }

  stopEvent(ev) { return false; }
  ignoreMutation() { return true; }
}
