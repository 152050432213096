import React from 'react';
import PropTypes from "prop-types";

class WithFetch extends React.Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
    fetch: PropTypes.func,
    renderLoading: PropTypes.func,
    renderError: PropTypes.func,
  };

  componentDidMount() {
    this.tryFetch();
  }

  componentDidUpdate() {
    this.tryFetch();
  }

  tryFetch() {
    const {request, fetch} = this.props;
    if (fetch && request && request.isFetchRequired()) {
      fetch(request.id);
    }
  }

  render() {
    const {request, children, renderLoading, renderError} = this.props;
    if (request && request.isLoaded()) {
      return children(request.get());
    }
    if (request && request.isError() && renderError) {
      return renderError(request.get());
    }
    if (request && request.isLoading() && renderLoading) {
      return renderLoading();
    }
    return false;
  }
}

export default WithFetch;