import React from "react";

import MenuGroup from '../components/MenuGroup';
import MenuSelect from '../components/MenuSelect';

import schema from '../../schema';
import icons, {highlightColor} from '../../icons';
import {selectionInside, getAttribute} from '../util';
import {setAttributes} from "../../commands";

const LIST_NODE_TYPES = [
  schema.nodes.ordered_list,
  schema.nodes.bullet_list
];

const COLUMN_OPTIONS = [1,2,3,4].map(n => ({
  value: n,
  title: `${n} column${n > 1 ? 's' : ''}`,
  icon: icons.columns[n]
}));

const listColumns = (
  <MenuSelect
    command={columns => setAttributes(LIST_NODE_TYPES, {columns})}
    selected={getAttribute(LIST_NODE_TYPES, 'columns')}
    options={COLUMN_OPTIONS}
    title="List Columns"
  />
);

export const listMenu = (<MenuGroup key="list" visible={selectionInside(schema.nodes.ordered_list)}>
  {listColumns}
</MenuGroup>);
