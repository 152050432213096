import {createSelector as reselect} from "reselect";
import {RequestState} from "./util";

export const createSelector = (requestName, key, getState) => {
  let requestResolver = getState ? state => getState(state)[requestName] : state => state.client[requestName];
  let keyResolver = typeof key === 'function' ? key : () => key;
  return reselect([requestResolver, keyResolver], (requestState, resolvedKey) => {
    if (!requestState) return new RequestState(null, resolvedKey);
    return new RequestState(requestState.find(r => r.metadata.params === resolvedKey), resolvedKey);
  });
};

export const createLoadedSelector = (requestName, key, transform, getState) => {
  let requestResolver = createSelector(requestName, key, getState);
  return reselect([requestResolver], requestState => {
    if (requestState && requestState.isLoaded()) return transform(requestState.get());
    return null;
  });
};
