import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {withClient, createLoadedSelector} from "reducers/client";
import {bookDetails, lessonContentContainerDetails, tabDetails, elementDetails, elementParents} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {textTransform: 'uppercase'},
  reference: {},
  bookName: {},
  contentName: {}
});

const LessonContentContainerReferenceBase = ({classes, lessonContentContainerDetails, bookDetails}) => (
  <span className={classes.root}>
    <span className={classes.bookName}>{bookDetails.isLoaded() && bookDetails.get().name}</span>&nbsp;
    <span className={classes.contentName}>{
      lessonContentContainerDetails.isLoaded() ?
        lessonContentContainerDetails.get().name :
        lessonContentContainerDetails.isError() && "(NOT FOUND)"
    }</span>
  </span>
);

const getLessonContentContainerId = (state, props) => props.containerId;
const getBookIdFromContainer = createLoadedSelector(
  'lessonContentContainerDetails',
  getLessonContentContainerId,
  lessonContentContainer => lessonContentContainer.bookId
);
export const LessonContentContainerReference = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getLessonContentContainerId),
    bookDetails: bookDetails(getBookIdFromContainer)
  }
})(withStyles(
  styles
)(LessonContentContainerReferenceBase));



const TabReferenceBase = ({classes, tabDetails, bookDetails}) => (
  <span className={classes.root}>
    <span className={classes.bookName}>{bookDetails.isLoaded() && bookDetails.get().name}</span>&nbsp;
    <span className={classes.contentName}>{
      tabDetails.isLoaded() ?
        tabDetails.get().name :
        tabDetails.isError() && "(TAB NOT FOUND)"
    }</span>
  </span>
);

const getTabId = (state, props) => props.tabId;
const getBookIdFromTab = createLoadedSelector('tabDetails', getTabId, tab => tab.bookId);
export const TabReference = withClient({
  hooks: {
    tabDetails: tabDetails(getTabId),
    bookDetails: bookDetails(getBookIdFromTab)
  }
})(withStyles(
  styles
)(TabReferenceBase));


const ElementReferenceBase = ({classes, elementDetails, children}) => (
  <span className={classes.root}>
    <span className={classes.contentName}>{elementDetails.isLoaded() && elementDetails.get().elementType}</span>
  </span>
);

const getElementId = (state, props) => props.elementId;
export const ElementReference = withClient({
  hooks: {
    elementDetails: elementDetails(getElementId)
  }
})(withStyles(
  styles
)(ElementReferenceBase));


const ElementParentsReferenceBase = ({classes, elementParents, children}) => {
  let subClasses = {
    root: classes.reference,
    bookName: classes.bookName,
    contentName: classes.contentName,
  };
  return (
    <span className={classes.root}>
    {elementParents.isLoaded() && elementParents.get().parents.map((parent, index, array) => {
      let ref;
      switch(parent.type) {
        case 'lessonContentContainer':
          ref = <LessonContentContainerReference classes={subClasses} containerId={parent.id} />;
          break;
        case 'tab':
          ref = <TabReference classes={subClasses} tabId={parent.id} />;
          break;
        default:
          ref = null;
      }
      if (ref) {
        if (index < array.length - 1) return <React.Fragment key={parent.id} >{ref} </React.Fragment>;
        return <React.Fragment key={parent.id} >{ref}</React.Fragment>;
      }
      return null;
    })}
  </span>
  );
};

export const ElementParentsReference = withClient({
  hooks: {
    elementParents: elementParents(getElementId)
  }
})(withStyles(
  styles
)(ElementParentsReferenceBase));