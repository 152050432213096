import ClientRequest from "../../util/clientRequest";
import {combineReducers} from "redux";
import {apiClient} from "constants/Clients";
import {createSelector} from "reselect";

import {defaultElementTypeForContentable} from "constants/ElementTypes";
import FilterTypes from 'constants/FilterTypes';

const defaultElementType = defaultElementTypeForContentable.assessmentProblem;

// ACTION TYPES
export const SEARCH_ASSESSMENT_PROBLEMS_ADD_FILTER = 'SEARCH_ASSESSMENT_PROBLEMS_ADD_FILTER';
export const SEARCH_ASSESSMENT_PROBLEMS_SET_FILTER_PROPERTY = 'SEARCH_ASSESSMENT_PROBLEMS_SET_FILTER_PROPERTY';
export const SEARCH_ASSESSMENT_PROBLEMS_REMOVE_FILTER = 'SEARCH_ASSESSMENT_PROBLEMS_REMOVE_FILTER';
export const SEARCH_ASSESSMENT_PROBLEMS = 'SEARCH_ASSESSMENT_PROBLEMS';

// CHILD REDUCERS
const searchResults = ClientRequest(SEARCH_ASSESSMENT_PROBLEMS);

// UTIL
const addFilter = (params, filterType, filterValue) => {
  if (params[filterType]) {
    params[filterType] += ',' + filterValue;
  } else {
    params[filterType] = filterValue;
  }
};

// ACTION CREATORS
export function searchAssessmentProblems(searchString, searchFilters) {
  let params = {
    htmlContent: searchString,
    contentType: 'assessmentProblem'
  };

  searchFilters.forEach(filter => {
    if (filter.type === FilterTypes.shared.name) { addFilter(params, 'sharedContent', "true"); }
    else if (filter.chapterId) { addFilter(params, 'chapterIds', filter.chapterId); }
    else if (filter.bookId) { addFilter(params, 'bookIds', filter.bookId); }
    else if (filter.classificationId) { addFilter(params, 'classificationIds', filter.classificationId); }
    else if (filter.standardId) { addFilter(params, 'standardIds', filter.standardId); }
    else if (filter.questionIdGreaterThan) { addFilter(params, 'questionIdGreaterThan', filter.questionIdGreaterThan); }
    else if (filter.questionIdLessThan) { addFilter(params, 'questionIdLessThan', filter.questionIdLessThan); }
  });

  let req = apiClient.searchElements()
    .limit(10)
    .setQueryParams(params);
  return searchResults.sendRequest(req, {key: searchString});
}

export function addAssessmentProblemSearchFilter(filterType) {
  return {type: SEARCH_ASSESSMENT_PROBLEMS_ADD_FILTER, filterType};
}
export function setFilterProperty(filterId, property, value) {
  return {type: SEARCH_ASSESSMENT_PROBLEMS_SET_FILTER_PROPERTY, filterId, property, value};
}
export function removeFilter(filterId) {
  return {type: SEARCH_ASSESSMENT_PROBLEMS_REMOVE_FILTER, filterId};
}

// SELECTORS
const getAllFilters = state => state.search.assessmentProblems.filters;
export const makeFilterSelector = filterIdResolver =>
  createSelector([filterIdResolver, getAllFilters], (filterId, filters) => filters[filterId]);

// INITIAL STATE
const initialState = {
  type: '',
  bookId: '',
  chapterId: '',
  lessonId: '',
  classificationId: '',
  standardId: '',
  approvalStatus: ''
};

const filters = (state = [], action) => {
  switch (action.type) {
    case SEARCH_ASSESSMENT_PROBLEMS_ADD_FILTER:
      return state.concat({
        ...initialState,
        type: action.filterType
      });
    case SEARCH_ASSESSMENT_PROBLEMS_SET_FILTER_PROPERTY:
      let filterState = {
        ...state[action.filterId],
        [action.property]: action.value
      };
      // Clear lower-level values
      if (action.property === 'bookId') { filterState.lessonId = ''; filterState.chapterId = ''; }
      if (action.property === 'chapterId') { filterState.lessonId = ''; }
      if (action.property === 'classificationId') { filterState.standardId = ''; }

      return state.slice(0, action.filterId)
        .concat(filterState, state.slice(action.filterId + 1));
    case SEARCH_ASSESSMENT_PROBLEMS_REMOVE_FILTER:
      return state.slice(0, action.filterId)
        .concat(state.slice(action.filterId + 1));
    default: return state;
  }
};

export default combineReducers({
  filters,
  searchResults
});