import {
  cyan,
  grey,
  green
} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  fontFamily: 'Roboto, sans-serif',
  borderRadius: 2,
  palette: {
    primary: cyan,
    secondary: green,
    error: grey
    // primary1Color: cyan[700],
    // primary2Color: cyan[700],
    // primary3Color: grey[600],
    // accent1Color: green[600],
    // accent2Color: green[400],
    // accent3Color: green[200],
    // textColor: 'rgba(255,255,255,.7)',
    // secondaryTextColor: fade(white, 0.54),
    // alternateTextColor: '#3c3c3c',
    // canvasColor: '#3c3c3c',
    // borderColor: fade(white, 0.15),
    // disabledColor: fade(white, 0.3),
    // pickerHeaderColor: fade(white, 0.12),
    // clockCircleColor: fade(white, 0.12),
  },
});
