import React from 'react';
import {connect} from "react-redux";

import {IconButton, TableCell, TableRow} from "@material-ui/core";
import {Remove as IconRemove} from "@material-ui/icons";

import {StyleDescriptorTypes} from "constants/StyleTypes";
import {GoogleFont} from "components/GoogleFonts/Cache";
import {requestGoogleFonts} from "reducers/googleFonts";

class StylePropertyListing extends React.Component {
  componentDidMount() {
    const {googleFonts, requestGoogleFonts} = this.props;
    if (!googleFonts.completed && !googleFonts.pending && !googleFonts.error) {
      requestGoogleFonts();
    }
  }

  preloadFont(fontName) {
    const {items:googleFonts} = this.props.googleFonts.fonts;
    console.log('preloadFont()', fontName);
    if (googleFonts && googleFonts.some(({family}) => family === fontName)) {
      return <GoogleFont font={fontName}/>;
    }
    return null;
  }

  render() {
    const {name, descriptor, value, onDelete} = this.props;
    const type = descriptor && StyleDescriptorTypes.find(t => t.descriptor === descriptor);
    let display = type && (type.display ? type.display(value) : type.toCss ? type.toCss(value) : value);

    return (
      <TableRow>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {descriptor === 'font-family' && value && value.googleFont && this.preloadFont(value.googleFont.family)}
          {display}
        </TableCell>
        <TableCell padding="none">
          <IconButton onClick={onDelete}><IconRemove fontSize="small"/></IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

const mapStateToProps = state => ({
  googleFonts: state.googleFonts,
});
const mapDispatchToProps = dispatch => ({
  requestGoogleFonts: () => dispatch(requestGoogleFonts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StylePropertyListing);
