import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconAdd from "@material-ui/icons/Add";

const styles = theme => ({
  root: {
    flex: 0,
    whiteSpace: 'nowrap',
    marginLeft: `${8}px`
  }
});

class SearchAddFilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null
    };
  }

  handleClick = (event) => {
    this.setState({menuAnchor: event.currentTarget});
  };

  handleClickFilterType = filterType => {
    this.props.onAddFilter(filterType);
    this.handleCloseFilterMenu();
  };

  handleCloseFilterMenu = event => {
    this.setState({menuAnchor: null});
  };

  filterExists = filterType => {
    const {filters} = this.props;
    return filters.some(f => f.type === filterType);
  };

  render() {
    const {classes, filterTypes} = this.props;
    const {menuAnchor} = this.state;
    return (
      <div className={classes.root}>
        <Button variant="outlined" onClick={this.handleClick}>
          <IconAdd />
          Add Filter
        </Button>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.handleCloseFilterMenu}
        >
          {filterTypes.map(type =>
            <MenuItem
              disabled={this.filterExists(type.name)}
              key={type.name}
              onClick={() => this.handleClickFilterType(type.name)}>
              {type.name}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(SearchAddFilterButton);