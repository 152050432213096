import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  LinearProgress,
  IconButton,
  TextField, Checkbox, Select, MenuItem, Button
} from "@material-ui/core";
import IconAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import IconDots from "@material-ui/icons/MoreHoriz";

import {withClient} from "reducers/client";
import {
  assessmentGroupProblems,
  assessmentGroupProblemOrder,
  assessmentGroupCreateProblem,
  updateAssessmentProblem,
  deleteAssessmentProblem
} from "reducers/client/requestTypes";

import DraggableList from "../../DraggableList/index";
import GroupAssessmentProblemsTableRow from './GroupAssessmentProblemsTableRow';
import ConfirmationDialog from "components/Dialogs/Confirmation";

const styles = theme => ({
  root: {
    borderCollapse: 'separate',
    '& > thead > tr > th': {
      position: 'sticky',
      top: '60px',
      background: 'white',
      zIndex: '1'
    }
  },
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class GroupAssessmentProblemsTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    assessmentGroupProblems: PropTypes.object.isRequired,
    assessmentGroupProblemOrder: PropTypes.object.isRequired,
    assessmentGroupCreateProblem: PropTypes.object.isRequired,
    updateAssessmentProblem: PropTypes.object.isRequired,
    deleteAssessmentProblem: PropTypes.object.isRequired,

    assessmentGroupId: PropTypes.string.isRequired,
  };

  state = {
    assessmentProblems: [],
    adding: false,
    editingId: -1,
    edit: {
      name: '',
      number: '',
      elements: [],
      linkedContainer: null
    },
    confirmationOpen: false,
  };

  componentDidMount() {
    const {classes, assessmentGroupProblems} = this.props;
    this.draggableListClasses = {dragging: classes.dragging};

    if (assessmentGroupProblems.isLoaded()) {
      this.setState({ assessmentProblems: assessmentGroupProblems.get() });
    }
  }
  componentDidUpdate(prevProps) {
    const {assessmentGroupProblems} = this.props;
    const {assessmentGroupProblems:prevAssessmentProblems} = prevProps;
    if (assessmentGroupProblems.isLoaded() && assessmentGroupProblems.hasChanged(prevAssessmentProblems)) {
      this.setState({ assessmentProblems: assessmentGroupProblems.get() });
    }
    if (this.state.editingId >= this.state.assessmentProblems.length) {
      this.setState({
        editingId: this.state.assessmentProblems.length - 1
      });
    }
  }

  isWaiting() {
    const {
      assessmentGroupProblems,
      assessmentGroupProblemOrder,
      assessmentGroupCreateProblem,
      updateAssessmentProblem,
      deleteAssessmentProblem,
    } = this.props;
    return assessmentGroupProblems.isLoading() ||
      assessmentGroupProblemOrder.isLoading() ||
      assessmentGroupCreateProblem.isLoading() ||
      updateAssessmentProblem.isLoading() ||
      deleteAssessmentProblem.isLoading();
  }
  pendingChanges() {
    const {assessmentProblems, adding, editingId, edit} = this.state;
    if (adding) {
      return true;
    }
    if (assessmentProblems[editingId]) {
      return assessmentProblems[editingId].name !== edit.name ||
        assessmentProblems[editingId].isTeam !== edit.isTeam ||
        assessmentProblems[editingId].difficultyLevel !== edit.difficultyLevel;
    }
    return false;
  }

  handleEdit = (name, ev) => {
    this.setState({
      edit: {
        ...this.state.edit,
        [name]: ev.target.value,
      }
    })
  };

  handleClickAdd = () => {
    const {editingId} = this.state;
    if (editingId === -1) {
      this.setState({
        adding: true,
        edit: {
          name: '',
          isTeam: false,
          difficultyLevel: 0,
          assessmentQuestionId: '',
        },
      });
    }
  };
  handleClickEdit = (editingId) => {
    this.setState({
      editingId,
      edit: {
        name: this.state.assessmentProblems[editingId].name,
        isTeam: this.state.assessmentProblems[editingId].isTeam,
        difficultyLevel: this.state.assessmentProblems[editingId].difficultyLevel,
        assessmentQuestionId: this.state.assessmentProblems[editingId].assessmentQuestionId,
      },
    });
  };
  handleClickSave = () => {
    const {assessmentGroupCreateProblem, updateAssessmentProblem, assessmentGroupProblems} = this.props;
    const {assessmentProblems, adding, editingId, edit} = this.state;
    if (adding) {
      if (this.pendingChanges()) {
        let problemData = {...edit};
        if (problemData.linkedContainer) {
          problemData.elementIds = problemData.linkedContainer.elements.map(e => e.id);
          delete problemData.linkedContainer;
        }
        assessmentGroupCreateProblem.sendRequest(problemData)
          .then(() => assessmentGroupProblems.sendRequest())
          .then(() => this.setState({adding: false}));
      } else {
        this.setState({adding: false});
      }
    } else {
      if (this.pendingChanges()) {
        updateAssessmentProblem.sendRequest(assessmentProblems[editingId].id, edit)
          .then(() => assessmentGroupProblems.sendRequest())
          .then(() => this.setState({editingId: -1}));
      } else {
        this.setState({editingId: -1});
      }
    }
  };
  handleClickDelete = () => {
    this.setState({confirmationOpen: true});
  };

  handleCloseConfirm = () => {
    this.setState({confirmationOpen: false});
  };
  handleConfirm = () => {
    this.setState({confirmationOpen: false});
    const {deleteAssessmentProblem, assessmentGroupProblems} = this.props;
    const {assessmentProblems, editingId} = this.state;
    deleteAssessmentProblem.sendRequest(assessmentProblems[editingId].id)
      .then(() => assessmentGroupProblems.sendRequest())
      .then(() => this.setState({editingId: -1}));
  };

  handleDragStart = (ev, index) => {
    let t = ev.target;
    while (t && t !== ev.currentTarget) {
      // Only trigger drag if clicking on the handle
      if (t.classList.contains(this.props.classes.dragHandle)) {
        return true;
      }
      t = t.parentElement;
    }
    return false;
  };
  handleChangeOrder = updatedList => {
    const assessmentProblems = this.props.assessmentGroupProblems.get();
    this.props.assessmentGroupProblemOrder.sendRequest(updatedList.map(c => assessmentProblems[c].id))
      .then(() => this.props.assessmentGroupProblems.sendRequest());
  };

  render() {
    const {assessmentProblems, adding, editingId, edit, confirmationOpen} = this.state;
    if (!assessmentProblems) return false;
    const {classes} = this.props;
    const waiting = this.isWaiting();
    const disableDrag = waiting || adding || editingId !== -1;
    return (<React.Fragment>
      <ConfirmationDialog
        title="Delete Lesson Content Container"
        message={`Are you sure you want to delete Lesson Content Container ${edit.name}?`}
        isOpen={confirmationOpen}
        onClose={this.handleCloseConfirm}
        onConfirm={this.handleConfirm}
      />
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"/>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Difficulty</TableCell>
            <TableCell>Elements</TableCell>
            <TableCell padding="none"/>
          </TableRow>
          {waiting && (<TableRow style={{height:0}}>
            <TableCell colSpan={7} padding="none"><LinearProgress/></TableCell>
          </TableRow>)}
        </TableHead>
        <DraggableList
          classes={this.draggableListClasses}
          component={TableBody}
          itemComponent={GroupAssessmentProblemsTableRow}
          itemProps={{
            classes,
            adding: Boolean(adding),
            waiting: Boolean(waiting),
            pendingChanges: Boolean(this.pendingChanges()),
            editingId,
            edit,
            onEdit: this.handleEdit,
            onClickEdit: this.handleClickEdit,
            onClickSave: this.handleClickSave,
            onClickDelete: this.handleClickDelete,
          }}
          items={assessmentProblems}
          onDragStart={this.handleDragStart}
          onChange={this.handleChangeOrder}
          disabled={disableDrag}
        />
        <TableFooter>
          {adding ? (
            <TableRow hover>
              <TableCell padding="checkbox"/>
              <TableCell />
              <TableCell>
                <TextField
                  value={edit.name}
                  onChange={ev => this.handleEdit('name', ev)}
                  disabled={waiting}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={edit.isTeam}
                  onChange={ev => this.handleEdit('isTeam', {target:{value:ev.target.checked}})}
                  disabled={waiting}
                />
              </TableCell>
              <TableCell>
                <Select
                  value={edit.difficultyLevel}
                  onChange={ev => this.handleEdit('difficultyLevel', ev)}
                  disabled={waiting}>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </TableCell>
              <TableCell />
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleClickSave} className={classes.itemButton}>
                  {this.pendingChanges() ? (
                    <IconSave fontSize="small"/>
                  ):(
                    <IconCancel fontSize="small"/>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ):(
            <TableRow hover={editingId === -1} onClick={this.handleClickAdd}>
              <TableCell colSpan={7} align="center">
                {editingId === -1 ? (
                  <IconAdd />
                ):(
                  <IconDots />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </React.Fragment>);
  }
}

export default withClient({
  hooks: {
    assessmentGroupProblems: assessmentGroupProblems((state, props) => props.assessmentGroupId),
    assessmentGroupProblemOrder: assessmentGroupProblemOrder((state, props) => props.assessmentGroupId),
    assessmentGroupCreateProblem: assessmentGroupCreateProblem((state, props) => props.assessmentGroupId),
    updateAssessmentProblem: updateAssessmentProblem(),
    deleteAssessmentProblem: deleteAssessmentProblem()
  },
})(withStyles(styles)(withRouter(GroupAssessmentProblemsTable)));
