import React from 'react';
import PropTypes from 'prop-types';

import {
  Toolbar,
  Typography,
  IconButton
} from "@material-ui/core";
import IconBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import BrowserBookList from './BrowserList/BrowserBookList';
import BrowserCategoryList from './BrowserList/BrowserCategoryList';
import BrowserSectionList from './BrowserList/BrowserSectionList';
import BrowserTabbedViewList from './BrowserList/BrowserTabbedViewList';
import BrowserTabList from './BrowserList/BrowserTabList';
import BooksTable from 'components/Tables/PagedTable/BooksTable2';

const styles = theme => ({
  breadcrumb: {
    margin: theme.spacing(1),
  }
});

class TabBrowser extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    blacklist: PropTypes.arrayOf(PropTypes.string),
  };
  state = {
    selectedBook: null,
    selectedSection: null,
    selectedTabbedView: null,
    selectedTabId: null,
  };

  crumb(crumb) {
    return (
      <Typography className={this.props.classes.breadcrumb} key={crumb.id} variant="button">
        {crumb.name || crumb.type}
      </Typography>
    );
  }
  getBreadcrumb() {
    const {selectedBook, selectedSection, selectedTabbedView} = this.state;
    let crumbs = [];
    if (selectedBook) {
      crumbs.push(<IconButton key={'Back'} onClick={this.handleClickBack}><IconBack fontSize="small"/></IconButton>);
      crumbs.push(this.crumb(selectedBook));
      if (selectedSection) {
        crumbs.push('>');
        crumbs.push(this.crumb(selectedSection));
        if (selectedTabbedView) {
          crumbs.push('>');
          crumbs.push(this.crumb(selectedTabbedView));
        }
      }
    }
    return crumbs;
  }

  handleClick(key, value) {
    this.setState({[key]:value});
  }
  handleTabClick = (ev, row) => {
    const {onChange} = this.props;
    const {selectedBook} = this.state;
    if (typeof onChange === 'function') onChange([{
      ...row,
      bookId:selectedBook.id,
      bookName: selectedBook.name
    }]);
    this.setState({selectedTabId: row.id});
  };
  handleClickBack = () => {
    if (this.state.selectedTabbedView) this.setState({selectedTabbedView: null});
    else if (this.state.selectedSection) this.setState({selectedSection: null});
    else if (this.state.selectedBook) this.setState({selectedBook: null});
  };

  render() {
    const {selectedBook, selectedSection, selectedTabbedView, selectedTabId} = this.state;
    let activeTable;
    if (selectedTabbedView) {
      activeTable = (
        <BrowserTabList
          blacklist={this.props.blacklist}
          tabbedViewId={selectedTabbedView.id}
          onClick={this.handleTabClick}
          selected={selectedTabId}
        />
      );
    } else if (selectedSection) {
      activeTable = (
        <BrowserTabbedViewList
          sectionId={selectedSection.id}
          onClick={(ev, row) => this.handleClick('selectedTabbedView',row)}
        />
      );
    } else if (selectedBook) {
      activeTable = (
        <BrowserSectionList
          bookId={selectedBook.id}
          onClick={(ev, row) => this.handleClick('selectedSection',row)}
        />
      );
    } else {
      return <BooksTable onClick={(ev, row) => this.handleClick('selectedBook',row)} />;
    }

    return (<React.Fragment>
      <Toolbar>
        {this.getBreadcrumb()}
      </Toolbar>
      {activeTable}
    </React.Fragment>);
  }
}

export default withStyles(styles)(TabBrowser);
