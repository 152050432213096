import React from 'react';

const context = React.createContext(() => {});
const {Provider, Consumer} = context;

const fontURI = text => encodeURI(text.replace(' ','+'));
const getLink = (fontName, fontProps) => {
  if (fontProps.style) {
    if (Array.isArray(fontProps.style)) fontName += ':'+fontProps.style.join(',');
    else fontName += `:${fontProps.style}`;
  }
  let url = `https://fonts.googleapis.com/css?family=${fontURI(fontName)}&display=swap`;
  if (fontProps.text) url += `&text=${fontURI(fontProps.text)}`;
  return url;
};

export class GoogleFontCache extends React.Component {
  constructor(props) {
    super(props);
    this.fonts = {};
  }

  registerFont(fontName, fontProps) {
    fontProps = fontProps || {};
    const font = this.fonts[fontName];
    let loadRequired = false;
    if (font) {
      if (font.text) {
        if (!fontProps.text) {
          document.head.removeChild(font.link);
          loadRequired = true;
        } else if (font.text !== fontProps.text) {
          document.head.removeChild(font.link);
          loadRequired = true;
        }
      }
    } else loadRequired = true;

    if (loadRequired) {
      console.log('GoogleFontCache.registerFont()',fontName,fontProps);
      const fontLink = document.createElement('link');
      fontLink.setAttribute('rel','stylesheet');
      fontLink.setAttribute('href',getLink(fontName, fontProps));
      document.head.appendChild(fontLink);
      this.fonts[fontName] = {
        link: fontLink,
        text: fontProps.text,
      }
    }
  }

  render() {
    return <Provider value={(...args) => this.registerFont(...args)}>{this.props.children}</Provider>
  }
}

export const withFont = (font, fontProps) => Component => props => (
  <Consumer>
    {registerFont => {
      registerFont(font, fontProps);
      return <Component {...props} />
    }}
  </Consumer>
);

export const GoogleFont = ({font, children, ...fontProps}) => (
  <Consumer>
    {registerFont => {
      registerFont(font, fontProps);
      return <span style={{fontFamily:font}}>{children}</span>;
    }}
  </Consumer>
);
