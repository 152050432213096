const OPEN_MERGE_CONFIRMATION_DIALOG = 'OPEN_MERGE_CONFIRMATION_DIALOG';
const CLOSE_MERGE_CONFIRMATION_DIALOG = 'CLOSE_MERGE_CONFIRMATION_DIALOG';
const SET_MERGE_CONFIRMATION_DIALOG_DATA = 'SET_MERGE_CONFIRMATION_DIALOG_DATA';

export const openMergeConfirmationDialog = () => ({type: OPEN_MERGE_CONFIRMATION_DIALOG});
export const closeMergeConfirmationDialog = () => ({type: CLOSE_MERGE_CONFIRMATION_DIALOG});
export const setMergeConfirmationDialogData = payload => ({type: SET_MERGE_CONFIRMATION_DIALOG_DATA, payload});

const initialSettings = {
  isOpen: false,
  data: null,
};

export default (state = initialSettings, action) => {

  switch (action.type) {
    case OPEN_MERGE_CONFIRMATION_DIALOG: return {...state, isOpen: true};
    case CLOSE_MERGE_CONFIRMATION_DIALOG: return {...state, isOpen: false};
    case SET_MERGE_CONFIRMATION_DIALOG_DATA: return {...state, data: action.payload};
    default: return state;
  }
};
