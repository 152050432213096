import {apiClient} from 'constants/Clients';
import {
  STATUS_READY,
  STATUS_WAITING,
  STATUS_SUCCESS,
  STATUS_FAILURE
} from 'constants/Dialogs';
import ClientRequest from "../../util/clientRequest";
import {createSelector} from "reselect";

const initialSettings = {
  isOpen: false,
  status: STATUS_READY,
  data: null
};

// ACTION TYPES
export const DIALOG_IMAGE_OPEN = 'DIALOG_IMAGE_OPEN';
export const DIALOG_IMAGE_CLOSE = 'DIALOG_IMAGE_CLOSE';
export const DIALOG_IMAGE_SET_DATA = 'DIALOG_IMAGE_SET_DATA';
export const DIALOG_IMAGE_SELECT = 'DIALOG_IMAGE_SELECT';

export const DIALOG_IMAGE_UPLOAD = 'DIALOG_IMAGE_UPLOAD';

// CHILD REDUCERS
const upload = ClientRequest(DIALOG_IMAGE_UPLOAD);

// ACTION CREATORS
export const openImageDialog = () => ({type: DIALOG_IMAGE_OPEN});
export const closeImageDialog = () => ({type: DIALOG_IMAGE_CLOSE});
export const setImageDialogData = data => ({type: DIALOG_IMAGE_SET_DATA, data});

export function uploadImage(file) {
  const req = apiClient.createMedia({})
    .setQueryParams({type: 'IMAGE'})
    .attachFile(file.name, file);
  return upload.sendRequest(req);
}

export function selectImage(mediaId) {
  return { type: DIALOG_IMAGE_SELECT, payload: mediaId };
}

// SELECTORS
const getUploadRequest = state => state.dialogs.image.upload;
export const getUploadStatus = createSelector([getUploadRequest], request => {
  if (!request) return STATUS_READY;
  if (request.pending) return STATUS_WAITING;
  if (request.error) return STATUS_FAILURE;
  if (request.completed) return STATUS_SUCCESS;
  return STATUS_READY;
});

export default (state = initialSettings, action) => {
  const uploadState = state.upload;
  const newState = upload(state.upload, action);
  if (uploadState !== newState) {
    return {...state, upload: newState};
  }

  switch (action.type) {
    case DIALOG_IMAGE_OPEN: return {...state, isOpen: true, upload: upload.clear()};
    case DIALOG_IMAGE_CLOSE: return {...state, isOpen: false};
    case DIALOG_IMAGE_SET_DATA: return {...state, data: action.data};
    case DIALOG_IMAGE_SELECT:
      const data = state.data || {};
      return {
        ...state,
        data: {
          ...data,
          attrs: {
            ...data.attrs,
            'media-id': action.payload
          }
        }
      };
    default: return state;
  }
};
