import React from 'react';

import {TabBreadcrumb} from 'components/Breadcrumb'
import MergeHistoryTable from 'components/Tables/MergeHistoryTable';

import {withClient} from "reducers/client";
import {tabDetails} from "reducers/client/requestTypes";

class TabMergeHistory extends React.Component {
  render() {
    const {tabDetails} = this.props;
    return (
      <div>
        <TabBreadcrumb tabId={tabDetails.getParams()}/>
        <h1>Merge History</h1>
        <MergeHistoryTable contentableId={tabDetails.getParams()} />
      </div>
    )
  }
}

export const TabMergeHistoryView = withClient({
  hooks: {
    tabDetails: tabDetails((state, props) => state.tabs.selected),
  }
})(TabMergeHistory);