import React from "react";
import PropTypes from "prop-types";

import MathJaxLib from 'MathJax';

import APPCONFIG from 'constants/Config';

export default class MathJax extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.mathRef = React.createRef();
    this.mathJax = null;
    this.queue = MathJaxLib.Callback.Queue();
    this.pending = null;
  }

  componentDidMount() {
    this.tryGetJax();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.mathRef.current) {
      if (!this.mathJax) this.tryGetJax();
      else if (prevProps.text !== this.props.text) {
        if (this.pending) window.clearTimeout(this.pending);
        if (this.queue.pending !== 0) {
          this.pending = window.setTimeout(() => {
            this.queue.Push(["Text",this.mathJax,this.props.text]);
            this.pending = null;
          }, APPCONFIG.inputDebounce);
        } else {
          this.queue.Push(["Text",this.mathJax,this.props.text]);
        }
      }
    }
  }

  tryGetJax() {
    const jaxable = MathJaxLib.Hub.isJax(this.mathRef.current);
    if (jaxable === -1) {
      this.mathRef.current.innerText = this.props.text;
      MathJaxLib.Hub.Queue(["Typeset",MathJaxLib.Hub,this.mathRef.current, () => {
        this.mathJax = MathJaxLib.Hub.getAllJax(this.mathRef.current)[0];
      }]);
    } else if (jaxable === 1) {
      this.mathJax = MathJaxLib.Hub.getAllJax(this.mathRef.current)[0];
    }
  }

  render() {
    return <script ref={this.mathRef} type={this.props.type} />;
  }
}
