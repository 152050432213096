import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import RemoveStandardButton from './RemoveStandardButton';
import PagedTable from '../Base';
import reducer from '../../../../reducers/LegacyTables/elementStandards';


import _ from 'lodash';


const columnData = [
  {
    id: 'title',
    numeric: false,
    label: 'Title',
    content: c => c.title,
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
    content: c => c.type,
  },
  {
    id: 'sourceUrl',
    numeric: false,
    label: 'Source URL',
    content: c => c.sourceUrl,
  },
  {
    id: 'buttons',
    numeric: false,
    label: 'Actions',
    content: c => <RemoveStandardButton standard={c} />,
  },
];


const Index = PagedTable({
  title: 'ElementStandards',
  columnData,
  reducer,
  actions: true,
});

const { Selectors, Actions } = reducer;

const mapStateToProps = state => ({
  pageRequest: Selectors.getPageRequest(state),
  data: Selectors.getPageContent(state),
  totalCount: Selectors.getTotalCount(state),
  tableState: Selectors.getState(state),
});

const mapDispatchToProps = dispatch => ({
  requestPage: () => dispatch(Actions.requestPage()),
  changePage: page => dispatch(Actions.changePage(page)),
  changePageSize: pageSize => dispatch(Actions.changePageSize(pageSize)),
  changeSort: column => dispatch(Actions.changeSort(column)),
  changeSearch: value => dispatch(Actions.changeSearch(value)),
  changeSearchField: value => dispatch(Actions.changeSearchField(value)),

  setSelection: values => dispatch(Actions.setSelection(values)),
  addSelection: values => dispatch(Actions.addSelection(values)),
  removeSelection: values => dispatch(Actions.removeSelection(values)),
  toggleSelection: values => dispatch(Actions.toggleSelection(values)),
  clearSelection: () => dispatch(Actions.clearSelection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
