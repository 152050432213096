import { combineReducers } from 'redux';
import {reducer as client} from './client';
import {reducer as table} from './table';
import {reducer as dialog} from './dialog';
import form from './forms';
import settings from './settings';
import profile from './profile';
import categories from './categories';
import books from './books';
import sections from './sections';
import glossary from './glossary';
import tabbedViews from './tabbedViews';
import tabs from './tabs';
import chapters from './chapters';
import lessons from './lessons';
import lessonContentContainers from './lessonContentContainers';
import elements from './elements';
// import contentVersions from './contentVersions';
import classifications from './classifications';
import classificationsSelect from './classificationsSelect';
import standards from './standards';
import medias from './medias';
import dialogs from './Dialogs';
import tables from './LegacyTables';
import search from './Search';
import prosemirror from './Prosemirror';
import menus from './menus';
import googleFonts from './googleFonts';
import assessmentProblems from './assessmentProblems';
import assessmentGroups from './assessmentGroups';

const reducers = {
  client,
  table,
  dialog,
  form,
  settings,
  profile,
  categories,
  books,
  sections,
  glossary,
  tabbedViews,
  tabs,
  chapters,
  lessons,
  lessonContentContainers,
  elements,
  // contentVersions,
  classifications,
  classificationsSelect,
  standards,
  medias,
  dialogs,
  tables,
  search,
  prosemirror,
  menus,
  googleFonts,
  assessmentProblems,
  assessmentGroups,
};

export default router => combineReducers({
  router,
  ...reducers
});
