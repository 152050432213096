import { apiClient } from 'constants/Clients';

import PagedTable, { invalidateState } from '../../util/pagedTable';
import {REQUEST_SUCCESS} from "../../client/actions";
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {ASSESSMENT_PROBLEM_DELETE} from "../../assessmentProblems";

const GroupAssessmentProblemsTable = PagedTable(
  'groupAssessmentProblems',
  (state, props) => apiClient.getAssessmentProblemsForAssessmentGroup(props.assessmentGroupId || state.assessmentGroups.selected),
  (state, action) => {
    if (action.type === "ASSESSMENT_GROUP_SELECT") {
      return invalidateState(state);
    }

    // need to invalidate upon new assessmentProblem creation
    if (action.type === REQUEST_SUCCESS) {
      let requestType = action.metadata && action.metadata.name;
      if (requestType === "assessmentGroupCreateAssessmentProblem" || requestType === "updateAssessmentProblem") {
        return invalidateState(state);
      }
    }
    // need to invalidate upon assessmentProblem deletion
    if(action.requestType === ASSESSMENT_PROBLEM_DELETE && action.type === CLIENT_REQUEST_SUCCESS){
      return invalidateState(state);
    } else {
      return state;
    }
  },
);

export default GroupAssessmentProblemsTable;