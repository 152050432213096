import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: `${1}px solid ${theme.palette.primary.dark}`,
    padding: `3px`,
    display: 'flex',
    'flex-wrap': 'wrap',
    'align-items': 'baseline'
  }
});

const ProsemirrorMenu = ({classes, children}) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(ProsemirrorMenu);