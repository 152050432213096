import {apiClient} from "constants/Clients";
import MediaTypes from "constants/MediaTypes";
import MathJax from 'MathJax';

let PENDING_MEDIA_ON_PAGE = 0;
let MATHPIX_ON_PAGE = 0;

const copyAttrs = (src, dest) => {
  for (let i = 0; i < src.attributes.length; ++i) {
    dest.setAttribute(src.attributes[i].name, src.attributes[i].value);
  }
  return dest;
};

const imageMedia = (dom, media) => {
  let img = document.createElement('img');
  copyAttrs(dom, img);
  img.setAttribute('data-media-id',dom.getAttribute('media-id'));
  if (media.publicUrl) {
    img.setAttribute('src',media.publicUrl.toString());
  }

  return img;
};
const mathpixMedia = (dom, media) => {
  let outerDom = document.createElement('div');
  copyAttrs(dom, outerDom);
  outerDom.setAttribute('class','c3po-media c3po-media-mathpix');

  if (media.renderAsLatex) {
    let math = document.createElement('script');
    math.setAttribute('type','math/tex');
    math.innerText = media.latex;

    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,math]));
    outerDom.appendChild(math);
  } else {
    let img = document.createElement('img');
    img.setAttribute('src',media.publicUrl.toString());
    outerDom.appendChild(img);
  }

  let badge = document.createElement('div');
  badge.setAttribute('class','c3po-media-mathpix-badge');
  badge.innerText = 'MathPix';
  outerDom.appendChild(badge);
  return outerDom;
};
const formulaMedia = (dom, media) => {
  let math = document.createElement('script');
  math.setAttribute('type','math/tex');
  math.innerText = media.latex;

  ++MATHPIX_ON_PAGE;
  return math;
};
const unknownMedia = (dom, media) => {
  let div = document.createElement('div');
  div.innerHTML = `Unknown media type for media <b>${media.id}</b>`;
  return div;
};

const onGetMedia = (dom, outerDom) => (err, results, response) => {
  if (err) {
    dom.classList.add('error');
  } else {
    let mediaDom;
    switch (results.type) {
      case MediaTypes.IMAGE.name: mediaDom = imageMedia(dom, results); break;
      case MediaTypes.MATHPIX.name: mediaDom = mathpixMedia(dom, results); break;
      case MediaTypes.FORMULA.name: mediaDom = formulaMedia(dom, results); break;
      default: mediaDom = unknownMedia(dom, results); break;
    }

    dom.parentNode.replaceChild(mediaDom, dom);
  }

  --PENDING_MEDIA_ON_PAGE;
  if (PENDING_MEDIA_ON_PAGE === 0 && MATHPIX_ON_PAGE > 0) {
    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub]));
  }
};

export default function media(dom) {
  MATHPIX_ON_PAGE = 0;

  dom.querySelectorAll('c3po-media').forEach(function (element) {
    if (element.getElementsByTagName('img').length === 0 &&
      element.getElementsByTagName('script').length === 0) {
      console.log('Media Processing', element.getAttribute('media-id'));
      ++PENDING_MEDIA_ON_PAGE;
      apiClient.getMedia(element.getAttribute('media-id')).exec(onGetMedia(element, dom));
    }
  });
}