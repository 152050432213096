import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'inline-block',
    height: `${180}px`,
    padding: `${8}px`
  },
  itemButton: {
    width: `${100}%`,
    height: `${100}%`,
    padding: `${8}px`,
    boxShadow: '0 1px 2px #888888'
  },
  itemImage: {
    maxWidth: `${100}%`,
    maxHeight: `${100}%`,
  },
  itemLabel: {
    position: 'absolute',
    bottom: `${0}`,
    width: `${100}%`,
    lineHeight: `${24}px`,
    padding: `${8}px`,
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const MediaGrid = ({medias, onClick, columns}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {medias.map(media => (
        <div key={media.id} className={classes.item} style={{width:`${Math.floor(100/columns)}%`}}>
          <ButtonBase
            onClick={ev => onClick(ev, media)}
            className={classes.itemButton}>
            <img
              src={media.publicUrl}
              alt={media.fileName}
              className={classes.itemImage}
            />
            <div className={classes.itemLabel}>{media.fileName}</div>
          </ButtonBase>
        </div>
      ))}
    </div>
  );
};
MediaGrid.propTypes = {
  medias: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    publicUrl: PropTypes.string,
    fileName: PropTypes.string
  })).isRequired,
  onClick: PropTypes.func,
  columns: PropTypes.number,
};
MediaGrid.defaultProps = {
  onClick: () => {},
  columns: 4,
};

export {MediaGrid};
