import React from 'react';
import { connect } from 'react-redux';
import {Prompt} from 'react-router-dom';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from '@material-ui/core/Fab';
import CircularProgress from "@material-ui/core/CircularProgress";

import IconHistory from '@material-ui/icons/History';
import IconListAlt from '@material-ui/icons/ListAlt';
import IconImage from '@material-ui/icons/Image';

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import elementTypes from 'constants/ElementTypes';
import {displayModes,sidebarModes} from "constants/Prosemirror";
import {assessmentMenu} from 'components/Prosemirror/menu/spec';

import {AssessmentProblemBreadcrumb} from 'components/Breadcrumb';

import {renderHomework} from "components/Prosemirror/render";

import ProsemirrorInterface from 'components/Prosemirror/components/ProsemirrorInterface';
import ProsemirrorEditor from 'components/Prosemirror/components/ProsemirrorEditor';
import ProsemirrorMenu from 'components/Prosemirror/components/ProsemirrorMenu';
import ProsemirrorEditModeToggle from 'components/Prosemirror/components/ProsemirrorEditModeToggle';
import ProsemirrorSidebarMenu from 'components/Prosemirror/components/ProsemirrorSidebarMenu';
import ContentPreview from 'components/Editor/Views/ContentPreview';
import MarkupPreview from "components/Editor/Views/MarkupPreview";


import {withClient, createSelector} from "reducers/client";
import {assessmentProblemDetails, assessmentProblemElements, elementDetails} from "reducers/client/requestTypes";

import PublishStatusIndicator from "components/Editor/PublishStatusIndicator"

import WcagReport from "components/Editor/Views/WcagReport";
import AssessmentProblemNavigation from "components/Navigation/AssessmentProblemNavigation";

const sideNavWidth = 250;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${20}px`,
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s cubic-bezier(0, 0, 0.2, 1)',
    '.nav-collapsed &': {
      left: `${collapsedWidth}px`,
    }
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`
  },
  button: {
    margin: theme.spacing(1),
  },
  navBox: {
    marginTop: `${30}px`
  },
  spacer: {
    width: theme.spacing(1),
  },
  flexColumns: {
    display: 'flex',
    alignItems: 'center',
  },
  editorBox: {
    flex: 1
  },
  previewBox: {
    padding: '16px',
    overflowY: 'scroll',
  },
  compare: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  pane: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${4}px`,
    overflow: 'hidden',
    transition: 'width 0.3s cubic-bezier(0, 0, 0.2, 1)',
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff',
  },
  paneTitle: {
    flex: `0 0 ${48}px`,
    lineHeight: `${48}px`,
    textAlign: 'center',
    fontSize: '18pt',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    backgroundColor: '#f8f8f8',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
    zIndex: 1,
    verticalAlign: 'middle',
  },
  flexLeft: {
    flex: 1,
    textAlign: 'left',
  },
  flexCenter: {
    flex: 1,
    textAlign: 'center',
  },
  flexRight: {
    flex: 1,
    textAlign: 'right',
  },
});

class AssessmentProblemEditor extends React.Component {
  state = {
    showAssociated: false,
  };

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {assessmentProblemDetails} = this.props;
      let assessmentProblemId = assessmentProblemDetails.getParams();
      this.props.history.push(`/app/assessmentProblems/${assessmentProblemId}/${path}`);
    }
  }
  showAssociatedContent = () => {
    this.setState({
      showAssociated: !this.state.showAssociated,
    });
  };

  renderEditor = elementData => {
    const {assessmentProblemDetails, editMode, displayMode, sidebarMode, classes} = this.props;
    const currentVersion = elementData &&
      elementData.currentVersion;

    if (currentVersion) {
      return (<ProsemirrorInterface currentVersion={currentVersion}>
        <div className={classes.compare}>
          <div className={classes.pane}>
            <div className={classnames(classes.paneTitle, classes.flexColumns)}>
              <ProsemirrorEditModeToggle className={classes.flexLeft} />
              <div className={classes.flexCenter}>{sidebarMode === sidebarModes.COMPARE.id && 'C3PO'}</div>
              <ProsemirrorSidebarMenu className={classes.flexRight} />
            </div>
            {editMode ? (
              <React.Fragment>
                <ProsemirrorMenu>{assessmentMenu}</ProsemirrorMenu>
                <ProsemirrorEditor contentClassName={displayMode} contentableId={assessmentProblemDetails.getParams()} />
              </React.Fragment>
            ) : (
              <ContentPreview
                contentVersionId={currentVersion.id}
                className={classnames(classes.previewBox, displayMode)}
                postProcess={renderHomework}
              />
            )}
          </div>
          {sidebarMode === sidebarModes.MARKUP.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>Markup</div>
            <div className={classnames(classes.previewBox)}>
              <MarkupPreview/>
            </div>
          </div>}
          {/*{sidebarMode === sidebarModes.HISTORY.id && <div>*/}
          {/*  /!*<WithFetch request={contentRequest}>{this.renderHistory}</WithFetch>*!/*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
          {/*{sidebarMode === sidebarModes.COMPLIANCE.id && <div>*/}
          {/*  <h6>This view is not yet implemented.</h6>*/}
          {/*</div>}*/}
          {sidebarMode === sidebarModes.WCAG.id && <div className={classes.pane}>
            <div className={classes.paneTitle}>WCAG 2.0 Compliance Status</div>
            <WcagReport/>
          </div>}
        </div>
      </ProsemirrorInterface>);
    } else {
      return <span style={{color:'red'}}>Unable to resolve content</span>;
    }
  };

  render() {
    const {
      sidebarMode,
      docChanged,
      classes,
      assessmentProblemDetails,
      assessmentProblemElements,
      elementDetails,
    } = this.props;

    const elementType = this.props.match.params.elementType || elementTypes.assessmentproblem.name;
    let assessmentProblemId = assessmentProblemDetails.getParams();
    if (!assessmentProblemId) return false;
    let editorContent = <CircularProgress/>;
    let contentVersionId = '';
    if (elementDetails.isLoaded()) {
      editorContent = this.renderEditor(elementDetails.get());
      contentVersionId = elementDetails.get().currentVersionId;
    } else if (!assessmentProblemElements.isLoading() && !elementDetails.isLoading()) {
      editorContent = this.renderEditor(null);
    }

    return (
      <div className={classes.root}>
        <Prompt
          when={docChanged}
          message="You have unsaved changes on this page. Are you sure you want to leave? Your changes will not be saved."
        />
        <div className={classes.buttonBox}>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <Tooltip title="Images Overview">
            <Fab color="primary" className={classes.button}>
              <IconImage onClick={e => this.navigateTo(`media/${elementType}`)} />
            </Fab>
          </Tooltip>
          <Tooltip title="Version History">
            <Fab color="primary" className={classes.button}>
              <IconHistory onClick={e => this.navigateTo(`versions/${elementType}`)} />
            </Fab>
          </Tooltip>
          {/*<Tooltip title={"Show Associated Content"}>*/}
          {/*<Fab color={"primary"} className={classes.button}>*/}
          {/*<IconList onClick={this.showAssociatedContent} />*/}
          {/*</Fab>*/}
          {/*</Tooltip>*/}
          <Tooltip title={"View/Edit Associated Standards"}>
            <Fab color={"primary"} className={classes.button}>
              <IconListAlt onClick={() => this.navigateTo(`standards/${elementType}`)} />
            </Fab>
          </Tooltip>
          {/*<Tooltip title={"Merge with another Assessment Problem"}>*/}
          {/*<Fab color={"primary"} className={classes.button}>*/}
          {/*<IconCallMerge onClick={() => this.navigateTo(`merge`)} />*/}
          {/*</Fab>*/}
          {/*</Tooltip>*/}
          {/*<Tooltip title="Show Accessibility Compliance">*/}
          {/*<Fab color="primary" className={classes.button}>*/}
          {/*<IconCheckCircle onClick={() => this.navigateTo('accessibility')} />*/}
          {/*</Fab>*/}
          {/*</Tooltip>*/}
        </div>
        <div className={classnames(classes.navBox, classes.flexColumns)}>
          <AssessmentProblemNavigation
            elementType={elementType}
            assessmentProblemId={assessmentProblemId}
          />
        </div>
        {editorContent}
      </div>);
  }
}

const mapStateToProps = (state, props) => ({
  displayMode: displayModes[state.prosemirror.displayMode].className,
  editMode: state.prosemirror.editMode,
  sidebarMode: state.prosemirror.sidebarMode,
  docChanged: state.prosemirror.docChanged,
});

const getAssessmentProblemId = (state, props) => state.assessmentProblems.selected;
const getElementType = (state, props) => props.match.params.elementType || elementTypes.assessmentproblem.name;
const getAssessmentProblemElements = createSelector('assessmentProblemElements',getAssessmentProblemId);
export const AssessmentProblemEditorView = withClient({
  hooks: {
    assessmentProblemDetails: assessmentProblemDetails(getAssessmentProblemId),
    assessmentProblemElements: assessmentProblemElements(getAssessmentProblemId),
    elementDetails: elementDetails((state, props) => {
      const assessmentProblemElements = getAssessmentProblemElements(state, props);
      if (assessmentProblemElements && assessmentProblemElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const assessmentProblemElement = assessmentProblemElements.get().find(e => e.elementType === elementType);
        if (assessmentProblemElement) return assessmentProblemElement.id;
      }
      return null;
    }),
  }
})(connect(
  mapStateToProps,
)(withStyles(styles)(
  AssessmentProblemEditor
)));
