import {TextSelection} from 'prosemirror-state';
import {
  deleteColumn,
  deleteRow,
  deleteTable,
  isInTable,
  selectionCell,
  CellSelection,
  TableMap,
  setCellAttr
} from 'prosemirror-tables';
import schema from "../schema";

export const insertTable = (columns, rows) => (state, dispatch) => {
  const {tr} = state;
  const cellNodes = [schema.nodes.table_cell.createAndFill()];
  for (let i = 1; i < columns; ++i) cellNodes.push(cellNodes[0]);
  const rowNodes = [schema.nodes.table_row.createAndFill(null, cellNodes)];
  for (let i = 1; i < rows; ++i) rowNodes.push(rowNodes[0]);
  const table = schema.nodes.table.createAndFill(null, rowNodes);

  tr.replaceSelectionWith(table);
  tr.setSelection(TextSelection.create(tr.doc, state.selection.from + 3)); // Step inside table, table row, table cell
  dispatch(tr);
};

export const deleteTableSelection = (state, dispatch) => {
  const {selection} = state;
  if (!selection.$anchorCell) return false;
  if (selection.isRowSelection()) {
    if (selection.isColSelection()) {
      return deleteTable(state, dispatch);
    } else {
      return deleteRow(state, dispatch);
    }
  } else if (selection.isColSelection()) {
    return deleteColumn(state, dispatch);
  }
  return false;
};

const outside = (pos, dir, $table) => {
  const tableMap = TableMap.get($table.parent);
  let cellPos, border;
  switch (dir) {
    case 0: cellPos = tableMap.nextCell(pos - $table.pos, 'horiz', -1); border = 2; break; // LEFT
    case 1: cellPos = tableMap.nextCell(pos - $table.pos, 'vert', -1); border = 3; break; // TOP
    case 2: cellPos = tableMap.nextCell(pos - $table.pos, 'horiz', 1); border = 0; break; // RIGHT
    case 3: cellPos = tableMap.nextCell(pos - $table.pos, 'vert', 1); border = 1; break; // BOTTOM
  }
  if (cellPos) {
    const $cell = $table.parent.resolve(cellPos);
    const {attrs} = $cell.nodeAfter;
    let borders = attrs.borders.slice();
    borders[border] = '?';
    return {
      pos: cellPos + $table.pos,
      attrs,
      borders,
      anyInactive: attrs.borders[border] === 0,
    };
  }
  return null;
};

const cell = ($pos, $table, options, selectionRect) => {
  const tableMap = TableMap.get($table.parent);
  const pos = $pos.pos;
  const node = $pos.nodeAfter;
  const affectedCells = [];
  let anyInactive = false;
  let borders = node.attrs.borders.slice();
  
  let cellRect = null;
  if (selectionRect) cellRect = tableMap.findCell(pos - $table.pos);
  const left = !cellRect || (cellRect.left === selectionRect.left);
  const right = !cellRect || (cellRect.right === selectionRect.right);
  const top = !cellRect || (cellRect.top === selectionRect.top);
  const bottom = !cellRect || (cellRect.bottom === selectionRect.bottom);

  if (options.lines.left && left) {
    anyInactive = anyInactive || borders[0] === 0;
    borders[0] = '?';
    affectedCells.push(outside(pos, 0, $table));
  } else if (options.lines.center && !left) {
    anyInactive = anyInactive || borders[0] === 0;
    borders[0] = '?';
  }
  if (options.lines.right && right) {
    anyInactive = anyInactive || borders[2] === 0;
    borders[2] = '?';
    affectedCells.push(outside(pos, 2, $table));
  } else if (options.lines.center && !right) {
    anyInactive = anyInactive || borders[2] === 0;
    borders[2] = '?';
  }
  if (options.lines.top && top) {
    anyInactive = anyInactive || borders[1] === 0;
    borders[1] = '?';
    affectedCells.push(outside(pos, 1, $table));
  } else if (options.lines.middle && !top) {
    anyInactive = anyInactive || borders[1] === 0;
    borders[1] = '?';
  }
  if (options.lines.bottom && bottom) {
    anyInactive = anyInactive || borders[3] === 0;
    borders[3] = '?';
    affectedCells.push(outside(pos, 3, $table));
  } else if (options.lines.middle && !bottom) {
    anyInactive = anyInactive || borders[3] === 0;
    borders[3] = '?';
  }

  if (borders.includes('?')) {
    return affectedCells.filter(el => el != null).concat({
      pos: $pos.pos,
      attrs: node.attrs,
      borders,
      anyInactive,
    });
  }
  return [];
};

export const toggleCellBorders = options => (state, dispatch) => {
  if (!isInTable(state)) return false;
  const {tr,doc,selection} = state;
  let $cell = selectionCell(state);
  let $table = doc.resolve($cell.start(-1));
  let affectedCells;

  if (selection instanceof CellSelection) {
    const tableMap = TableMap.get($table.parent);
    const selectionRect =
      tableMap.rectBetween(selection.$anchorCell.pos - $table.pos, selection.$headCell.pos - $table.pos);
    affectedCells = [];
    selection.forEachCell((node, pos) => {
      affectedCells = affectedCells.concat(cell(doc.resolve(pos), $table, options, selectionRect));
    });
  } else {
    affectedCells = cell($cell, $table, options);
  }
  if (affectedCells.length < 1) return false;
  if (dispatch) {
    let active = affectedCells.some(cell => cell.anyInactive) ? 1 : 0;
    affectedCells.forEach(cell => {
      tr.setNodeMarkup(cell.pos, null, {
        ...cell.attrs,
        borders: cell.borders.map(b => b === '?' ? active : b),
      });
    });
    dispatch(tr);
  }
  return true;
};

export const getColumnWidth = state => {
  const {selection} = state;

  if (selection instanceof CellSelection) {
    let matchingWidth = 0;
    selection.forEachCell((node, pos) => {
      if (matchingWidth === -1) return false;
      let widths = node.attrs.colwidth;
      if (widths && widths.length === 1) {
        if (matchingWidth === 0) matchingWidth = widths[0];
        else if (matchingWidth !== widths[0]) {
          matchingWidth = -1;
          return false;
        }
      } else {
        matchingWidth = -1
      }
    });
    if (matchingWidth > 0) return matchingWidth;
  } else {
    let widths = selectionCell(state).nodeAfter.attrs.colwidth;
    if (widths && widths.length === 1) return widths[0];
  }

  return '';
};

export const setColumnWidth = width => (state, dispatch) => {
  console.log('setColumnWidth',state,dispatch);

  if (!isInTable(state)) return false;
  const {tr,selection} = state;

  let columnSelection;
  if (selection instanceof CellSelection) {
    columnSelection = CellSelection.colSelection(selection.$anchorCell, selection.$headCell);
  } else {
    columnSelection = CellSelection.colSelection(selectionCell(state));
  }

  if (dispatch) {
    columnSelection.forEachCell((node, pos) => {
      tr.setNodeMarkup(pos, null, {
        ...node.attrs,
        colwidth: [width]
      });
    });
    dispatch(tr);
  }
  return true;
};

export const getCellAlignment = state => {
  const $cell = selectionCell(state);
  if ($cell) {
    const {alignment, vAlign} = $cell.nodeAfter.attrs;
    return `${alignment}/${vAlign}`;
  }
  return '';
};

export const setTableAlignment = options => {
  const [alignment, vAlign] = options.split('/');
  return (state, dispatch) => {
    if (!isInTable(state)) return false;
    const {tr,selection} = state;
    if (dispatch) {
      if (selection instanceof CellSelection) {
        selection.forEachCell((node, pos) => {
          tr.setNodeMarkup(pos, null, {
            ...node.attrs,
            alignment,
            vAlign,
          });
        });
      } else {
        const $cell = selectionCell(state);
        tr.setNodeMarkup($cell.pos, null, {
          ...$cell.nodeAfter.attrs,
          alignment,
          vAlign,
        })
      }
      dispatch(tr);
    }
    return true;
  };
};