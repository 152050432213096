import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {StandardDetailsView} from "views/standard/StandardDetails";

import { selectStandard } from 'reducers/standards';

export const StandardRoutes = ({match: {url, path, params: {standardId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectStandard(standardId))
  }, [standardId]);

  const selectedStandard = useSelector(state => state.standards.selected);
  if (!selectedStandard) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={StandardDetailsView} />
      <Redirect to={"/404"} />
    </Switch>
  );
};
