import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress';

import Autosuggest from 'react-autosuggest';

const styles = theme => ({
  root: {
    minWidth: 180,
  },
  container: {
    paddingTop: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

function renderInputComponent(inputProps) {
  const { fullWidth, classes, waiting, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <div>
      <TextField
        fullWidth={fullWidth}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
      {waiting && <LinearProgress variant="query" />}
    </div>
  );
}

const Autocomplete = ({fullWidth, classes, className, style, label, value, waiting, onChange, ...autosuggestProps}) => (
  <div className={classnames(classes.root, className)} style={style}>
    <Autosuggest
      renderInputComponent={renderInputComponent}
      inputProps={{
        fullWidth,
        classes,
        label,
        value,
        waiting,
        onChange
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
      shouldRenderSuggestions={() => true}
      {...autosuggestProps}
    />
  </div>
);

export default withStyles(styles)(Autocomplete);
