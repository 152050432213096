import React from 'react';
import PropTypes from 'prop-types';

import ElementParents from './ElementParents';
import {withClient} from "reducers/client";
import {lessonContentContainerElements} from "reducers/client/requestTypes";

const LinkedLessonContentContainers = ({lessonContentContainerElements, elementType}) => {
  if (!lessonContentContainerElements.isLoaded()) return null;
  const element = lessonContentContainerElements.get().find(e => e.elementType === elementType);
  if (element) return <ElementParents elementId={element.id} />;
  return null;
};

LinkedLessonContentContainers.propTypes = {
  containerId: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
};

export default withClient({
  hooks: {
    lessonContentContainerElements: lessonContentContainerElements((state, props) => props.containerId),
  }
})(LinkedLessonContentContainers);
