import { inputRules, InputRule, wrappingInputRule, textblockTypeInputRule, smartQuotes, emDash, ellipsis } from 'prosemirror-inputrules';

import { listRules } from "./list";
import schema from '../../schema/index';

export default inputRules({
  rules: [
    ...smartQuotes,
    ...listRules,

    // --- horizontal line
    new InputRule(
      /^(--|—)-$/,
      (state, match, start, end) => {
        const $start = state.doc.resolve(start);
        return state.tr.replaceWith($start.before(), $start.after(), schema.nodes.horizontal_rule.create())
      }
    ),

    ellipsis,
    emDash,

    // > blockquote
    wrappingInputRule(
      /^\s*>\s$/,
      schema.nodes.blockquote
    ),

    // ``` code block
    textblockTypeInputRule(
      /^```$/,
      schema.nodes.code_block
    ),

    // # heading
    // textblockTypeInputRule(
    //   new RegExp('^(#{1,6})\\s$'),
    //   schema.nodes.heading,
    //   match => ({ level: match[1].length })
    // )
  ]
});
