import React, {useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
  Grow,
  FormControlLabel,
  Switch,
  Tooltip,
  Fab,
  withStyles,
} from '@material-ui/core';
import IconBack from '@material-ui/icons/ArrowBack'

import {formatDate} from "constants/Date";

import {withClient} from "reducers/client";
import {mediaDetails, updateMedia} from "reducers/client/requestTypes";

import {MediaAltTextsTable} from "components/Tables/MediaAltTextsTable";
import {MediaTagEditor} from "components/Media/MediaTagEditor";

import {useCachedClientRequest} from "hooks/useCachedClientRequest";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    position: 'absolute',
    top: `${65}px`,
    right: theme.spacing(3),
    'z-index': 5,
  },
  button: {
    margin: theme.spacing(1),
    'z-index': 10,
  },
  image: {
    maxWidth: `${300}px`,
    maxHeight: `${72}px`,
    cursor: 'zoom-in',
  },
  minimize: {
    cursor: 'zoom-out',
  },
  tagArea: {
    padding: theme.spacing(1),
  }
});

const MediaDetails = ({mediaDetails, updateMedia, classes}) => {
  const [maximized, setMaximized] = useState(false);
  const history = useHistory();
  const handleMaximizeImage = useCallback(() => setMaximized(true));
  const handleMinimizeImage = useCallback(() => setMaximized(false));
  const handleChangePresentationOnly = useCallback(ev => {
    return updateMedia.sendRequest({
      ...mediaDetails.get(),
      presentationOnly: ev.target.checked
    }).then(() => mediaDetails.sendRequest());
  });
  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const cachedMediaDetails = useCachedClientRequest(mediaDetails);

  if (!cachedMediaDetails) return false;

  const mediaDetailsTable = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Image</TableCell>
          <TableCell>File Name</TableCell>
          <TableCell>Created</TableCell>
          <TableCell>Updated</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <img
              className={classes.image}
              onClick={handleMaximizeImage}
              src={cachedMediaDetails.publicUrl}
              alt=""
            />
          </TableCell>
          <TableCell>{cachedMediaDetails.fileName}</TableCell>
          <TableCell>{formatDate(cachedMediaDetails.createdAt)}</TableCell>
          <TableCell>{formatDate(cachedMediaDetails.updatedAt)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <div className={classes.root}>
      <Dialog
        open={maximized}
        onClose={handleMinimizeImage}
        classes={{scrollPaper: classes.minimize}}
        TransitionComponent={Grow}
      >
        <DialogContent>
          <img
            className={classes.minimize}
            onClick={handleMinimizeImage}
            src={cachedMediaDetails.publicUrl}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <div className={classes.buttonBox}>
        <Tooltip title="Go Back">
          <Fab color="primary" className={classes.button} onClick={handleGoBack}>
            <IconBack />
          </Fab>
        </Tooltip>
      </div>
      <div className={classes.section}>
        <Typography variant="h3" gutterBottom>Media Information</Typography>
        <Paper>
          {mediaDetailsTable}
        </Paper>
      </div>
      <div className={classes.section}>
        <div>
          <Typography variant="h3" gutterBottom>Alt Texts</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={cachedMediaDetails.presentationOnly}
                onChange={handleChangePresentationOnly}
                disabled={updateMedia.isLoading()}
              />
            }
            label="Presentation Only"
          />
        </div>
        <Paper>
          <MediaAltTextsTable mediaId={mediaDetails.getParams()}/>
        </Paper>
      </div>
      <div className={classes.section}>
        <Typography variant="h3" gutterBottom>Tags</Typography>
        <Paper>
          <MediaTagEditor mediaId={mediaDetails.getParams()}/>
        </Paper>
      </div>
    </div>
  );
};

const getMediaId = state => state.medias.selected;

export const MediaDetailsView = withClient({
  hooks: {
    mediaDetails: mediaDetails(getMediaId),
    updateMedia: updateMedia(getMediaId)
  }
})(withStyles(styles)(MediaDetails));
