import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSave,
  faCloudUploadAlt,
  faAsterisk,
  faBold,
  faItalic,
  faFont,
  faCode,
  faSuperscript,
  faSubscript,
  faUnderline,
  faStrikethrough,
  faLink,
  faParagraph,
  faHeading,
  faQuoteLeft,
  faListOl,
  faListUl,
  faImage,
  faTable,
  faUndo,
  faRedo,
  faOutdent,
  faAngleUp,
  faMagic,

  faAlignLeft,
  faAlignCenter,
  faAlignRight,

  faCaretDown,

  faStickyNote,
  faCalculator,
  faFileCode,
  faCheckSquare,
  faEyeSlash,
  faLayerGroup,
  faObjectGroup,
  faSearch,
  faCaretSquareRight,
  faCaretSquareDown,
  faCompress,
  faColumns,
  faHSquare,
  faPencilAlt,
  faBook,
  faPenSquare,
  faPlus,
  faPlusCircle,
  faArrowsAlt,
  faPenFancy,
  faCut,
  faCog,
  faCircle,
  faBan,
  faBars,
  faSquare,
  faExternalLinkSquareAlt,
  faGripLines,
  faGripLinesVertical,
  faAnchor,
  faNewspaper,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

const AddonUpRight = props => (<span className="fa-layers fa-fw">
  {React.cloneElement(props.children, {transform: 'left-2'})}
  <FontAwesomeIcon icon={props.icon} transform="shrink-6 up-8 right-8"/>
</span>);
const AddonDownRight = props => (<span className="fa-layers fa-fw">
  {props.children}
  <FontAwesomeIcon icon={props.icon} transform="shrink-6 down-8 right-10"/>
</span>);

const split_ebooks_homework = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faCut} transform="up-2"/>
  <FontAwesomeIcon icon={faBook} transform="shrink-6 down-8 left-6"/>
  <FontAwesomeIcon icon={faPencilAlt} transform="shrink-6 down-8 right-6"/>
</span>);

const save_changes = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faSave} />
  <FontAwesomeIcon icon={faCircle} style={{color:'tomato'}} transform="shrink-6 up-8 right-8"/>
</span>);
const cloud_changes = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faCloudUploadAlt} />
  <FontAwesomeIcon icon={faCircle} style={{color:'tomato'}} transform="shrink-6 up-8 right-8"/>
</span>);

const highlight = <FontAwesomeIcon icon={faFont} mask={faSquare} transform="shrink-6" />;
export const highlightColor = color => (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faCircle} transform="grow-8" style={{color}} />
  <FontAwesomeIcon icon={faFont} transform="shrink-4" />
</span>);

const remove_font = (<span className="fa-layers fa-fw">
  <span className="fa-layers-text" style={{
    fontFamily:'serif',
    fontWeight:900,
  }}>
    F
  </span>
  <FontAwesomeIcon icon={faBan} transform="shrink-6 down-8 right-10"/>
</span>);

const table = {
  columnBefore: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLinesVertical} transform="grow-4 right-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 left-4" />
  </span>),
  columnAfter: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLinesVertical} transform="grow-4 left-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-4" />
  </span>),
  rowBefore: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLines} transform="grow-4 down-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 up-4" />
  </span>),
  rowAfter: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLines} transform="grow-4 up-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 down-4" />
  </span>),
  align: {
    leftTop: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2 up-2" mask={faSquare} />),
    centerTop: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7 up-2" mask={faSquare} />),
    rightTop: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2 up-2" mask={faSquare} />),
    leftMiddle: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2" mask={faSquare} />),
    centerMiddle: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7" mask={faSquare} />),
    rightMiddle: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2" mask={faSquare} />),
    leftBottom: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2 down-2" mask={faSquare} />),
    centerBottom: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7 down-2" mask={faSquare} />),
    rightBottom: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2 down-2" mask={faSquare} />),
  }
};

const heading = level => (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faHeading} transform="left-2 up-2" />
  <span className="fa-layers-text" style={{
    fontWeight:900,
    transform: "translate(0.25em, -1.0em) scale(0.75)"
  }}>{level}</span>
</span>);
const column = number => (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faColumns} transform="left-2 up-2" />
  <span className="fa-layers-text" style={{
    fontWeight:900,
    transform: "translate(0.35em, -1.0em) scale(0.75)"
  }}>{number}</span>
</span>);

export const withPlus = icon => <AddonUpRight icon={faPlusCircle}>{icon}</AddonUpRight>;
export const withPencil = icon => <AddonDownRight icon={faPencilAlt}>{icon}</AddonDownRight>;
export const Dropdown = ({children}) => <AddonDownRight icon={faCaretDown}>{children}</AddonDownRight>;

export default {
  save: <FontAwesomeIcon icon={faSave} />,
  save_changes,
  cloud_upload: <FontAwesomeIcon icon={faCloudUploadAlt} />,
  cloud_changes,
  em: <FontAwesomeIcon icon={faItalic} />,
  italic: <FontAwesomeIcon icon={faItalic} />,
  strong: <FontAwesomeIcon icon={faBold} />,
  bold: <FontAwesomeIcon icon={faBold} />,
  font: <FontAwesomeIcon icon={faFont} />,
  code: <FontAwesomeIcon icon={faCode} />,
  subscript: <FontAwesomeIcon icon={faSubscript} />,
  superscript: <FontAwesomeIcon icon={faSuperscript} />,
  underline: <FontAwesomeIcon icon={faUnderline} />,
  strikethrough: <FontAwesomeIcon icon={faStrikethrough} />,
  link: <FontAwesomeIcon icon={faLink} />,
  paragraph: <FontAwesomeIcon icon={faParagraph} />,
  blockquote: <FontAwesomeIcon icon={faQuoteLeft} />,
  code_block: <FontAwesomeIcon icon={faCode} />,
  ordered_list: <FontAwesomeIcon icon={faListOl} />,
  bullet_list: <FontAwesomeIcon icon={faListUl} />,
  image: <FontAwesomeIcon icon={faImage} />,
  insert_table: <FontAwesomeIcon icon={faTable} />,
  footnote: <FontAwesomeIcon icon={faAsterisk} />,
  undo: <FontAwesomeIcon icon={faUndo} />,
  redo: <FontAwesomeIcon icon={faRedo} />,
  lift: <FontAwesomeIcon icon={faOutdent} />,
  join_up: <FontAwesomeIcon icon={faAngleUp} />,

  list: {
    ordered_numeric: <FontAwesomeIcon icon={faListOl} />,
    ordered_lower_alpha: <b style={{fontSize:'120%'}}>a</b>,
    ordered_upper_alpha: <b style={{fontSize:'120%'}}>A</b>,
    ordered_lower_roman: <b><i style={{fontFamily:'serif',fontSize:'120%'}}>i</i></b>,
    ordered_upper_roman: <b style={{fontFamily:'serif',fontSize:'120%'}}>I</b>,
    bullet: <FontAwesomeIcon icon={faListUl} />,
  },
  heading: [<FontAwesomeIcon icon={faHeading} />].concat([1,2,3,4,5,6].map(heading)),
  table,

  align_left: <FontAwesomeIcon icon={faAlignLeft} />,
  align_center: <FontAwesomeIcon icon={faAlignCenter} />,
  align_right: <FontAwesomeIcon icon={faAlignRight} />,

  sticky_note: <FontAwesomeIcon icon={faStickyNote} />,
  calculator: <FontAwesomeIcon icon={faCalculator} />,
  file_code: <FontAwesomeIcon icon={faFileCode} />,
  check_square: <FontAwesomeIcon icon={faCheckSquare} />,
  eye_slash: <FontAwesomeIcon icon={faEyeSlash} />,
  layer_group: <FontAwesomeIcon icon={faLayerGroup}/>,
  object_group: <FontAwesomeIcon icon={faObjectGroup}/>,
  search: <FontAwesomeIcon icon={faSearch} />,
  pencil_alt: <FontAwesomeIcon icon={faPencilAlt}/>,
  caret_square_right: <FontAwesomeIcon icon={faCaretSquareRight} />,
  caret_square_down: <FontAwesomeIcon icon={faCaretSquareDown} />,
  columns: [<FontAwesomeIcon icon={faColumns} />].concat([1,2,3,4].map(column)),
  compress: <FontAwesomeIcon icon={faCompress} />,
  h_square: <FontAwesomeIcon icon={faHSquare} />,
  magic: <FontAwesomeIcon icon={faMagic} />,
  book: <FontAwesomeIcon icon={faBook}/>,
  pen_square: <FontAwesomeIcon icon={faPenSquare}/>,
  plus: <FontAwesomeIcon icon={faPlus}/>,
  plus_circle: <FontAwesomeIcon icon={faPlusCircle}/>,
  arrows_alt: <FontAwesomeIcon icon={faArrowsAlt}/>,
  pen_fancy: <FontAwesomeIcon icon={faPenFancy}/>,
  cog: <FontAwesomeIcon icon={faCog}/>,
  ban: <FontAwesomeIcon icon={faBan}/>,
  bars: <FontAwesomeIcon icon={faBars}/>,
  external_link: <FontAwesomeIcon icon={faExternalLinkSquareAlt}/>,

  highlight,
  split_ebooks_homework,

  grip_horizontal: <FontAwesomeIcon icon={faGripLines}/>,
  grip_vertical: <FontAwesomeIcon icon={faGripLinesVertical}/>,
  anchor: <FontAwesomeIcon icon={faAnchor} />,
  newspaper: <FontAwesomeIcon icon={faNewspaper} />,
  remove_font,
  question_circle: <FontAwesomeIcon icon={faQuestionCircle} />,
};

