import React from 'react';

import TextField from "@material-ui/core/TextField";

import FontEditor from 'components/Stylesheet/FontEditor';
import FontVariantEditor from 'components/Stylesheet/FontVariantEditor';

const heading = n => ({
  name: `Heading ${n}`,
  selector: `h${n}`,
  element: `h${n}`
});

export const StyleSelectorTypes = [
  {
    name: "Body Text",
    selector: "p",
    element: "p"
  },
  heading(1),
  heading(2),
  heading(3),
  heading(4),
  heading(5),
  heading(6),
];

const DefaultEditorComponent = ({label, value, onChange}) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={ev => onChange(ev.target.value)}
  />
);

export const StyleDescriptorTypes = [
  {
    name: "Font",
    descriptor: "font-family",
    EditorComponent: FontEditor,
    toCss: value => {
      if (value.googleFont) return `"${value.googleFont.family}", ${value.webFont}`;
      else return value.webFont;
    },
    validate: value => value.webFont != null && value.webFont !== ''
  },
  {
    name: "Font Variant",
    descriptor: "font-variant",
    EditorComponent: FontVariantEditor,
    toCss: value => {
      let css = {};
      if (value.weight) css['font-weight'] = value.weight;
      if (value.italic) css['font-style'] = 'italic';
      console.log('font-variant',css);
      return css;
    },
    display: value => {
      let display = '';
      if (!value.weight) {
        display = value.italic ? 'Italic' : 'Regular';
      } else {
        display = `${value.weight}${value.italic ? ' Italic' : ''}`;
      }
      if (value.loadAll) return `${display} (Preloading all variants)`;
      return display;
    }
  },
  {
    name: "Font Size",
    descriptor: "font-size",
    EditorComponent: DefaultEditorComponent
  },
  {
    name: "Font Color",
    descriptor: "color",
    EditorComponent: DefaultEditorComponent
  },
  // {
  //   name: "Font Weight",
  //   descriptor: "font-weight",
  //   EditorComponent: DefaultEditorComponent
  // },
  // {
  //   name: "Font Style",
  //   descriptor: "font-style",
  //   EditorComponent: DefaultEditorComponent
  // }
];

export const webFonts = [
  {
    name: "Times New Roman",
    family: '"Times New Roman", Times, serif',
  },
  {
    name: "Cambria",
    family: 'Cambria, serif',
  },
  {
    name: "Didot",
    family: 'Didot, serif',
  },
  {
    name: "Georgia",
    family: 'Georgia, serif',
  },
  {
    name: "Arial",
    family: 'Arial, sans-serif',
  },
  {
    name: "Calibri",
    family: 'Calibri, sans-serif',
  },
  {
    name: "Helvetica",
    family: 'Helvetica, sans-serif',
  },
  {
    name: "Verdana",
    family: 'Verdana, sans-serif',
  },
  {
    name: "Comic Sans MS",
    family: '"Comic Sans MS", cursive, sans-serif',
  },
  {
    name: "Courier New",
    family: '"Courier New", Courier, monospace',
  }
];
