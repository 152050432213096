import {createSelector} from "reselect";
import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import ClientRequest, {RequestState} from "./util/clientRequest";
import {mapSequence} from './util/sequence';

// ACTION TYPES
export const BOOK_SELECT = 'BOOK_SELECT';
export const BOOK_LIST = 'BOOK_LIST';
export const BOOK_SAVE = 'BOOK_SAVE';
export const BOOK_DELETE = 'BOOK_DELETE';

// CHILD REDUCERS
const list = ClientRequest(BOOK_LIST);
const save = ClientRequest(BOOK_SAVE);
const deleteBook = ClientRequest(BOOK_DELETE);

// ACTION CREATORS
export function selectBook(bookID) {
  return {type: BOOK_SELECT, payload: bookID};
}

export function requestBookList() {
  const req = apiClient.getBooks();
  req.limit(9999);
  return list.sendRequest(req);
}
export function saveBook(data) {
  let req;
  // If there is an ID this is an update.  Else create a new one
  if (data.id) { req = apiClient.updateBook(data); }
  else { req = apiClient.createBook(data); }
  return save.sendRequest(req);
}
export function deleteBooks(idList) {
  return deleteBook.sendSequence(mapSequence(id => apiClient.deleteBook(id), idList));
}

// SELECTORS
const getBookListRequest = state => state.books.list;
export const getBookList = createSelector([getBookListRequest], books => new RequestState(1, books));

export default combineReducers({
  selected: (state = null, action) => (action.type === BOOK_SELECT ? action.payload : state),
  list,
  save,
  delete: deleteBook
});
