import React from "react";
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    position: 'absolute',
    margin: 'auto',
    top: `${-7}px`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    zIndex: 200
  },
  columns: {
    display: 'grid',
  }
});

class MenuPopout extends React.Component {
  constructor(props) {
    super(props);
    this.documentBody = document.getElementsByTagName('body')[0];
    this.rootElement = document.createElement('div');
    this.rootElement.setAttribute('class',props.classes.root);
  }

  componentDidMount() {
    const {style, columns, classes, anchorEl} = this.props;
    if (anchorEl) {
      this.showSelf();
    }
    if (style) {
      Object.keys(style).forEach(prop => {
        this.rootElement.style[prop] = style[prop];
      });
    }
    if (columns > 1) {
      this.rootElement.setAttribute('class',classnames(
        classes.root,
        classes.columns
      ));
      this.rootElement.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
    }
  }
  componentDidUpdate(prevProps) {
    const {style, columns, classes, anchorEl} = this.props;
    if (anchorEl && !prevProps.anchorEl) {
      this.showSelf();
    } else if (!anchorEl && prevProps.anchorEl) {
      this.hideSelf();
    }
    if (style !== prevProps.style) {
      Object.keys(style).forEach(prop => {
        if (style[prop] !== prevProps.style[prop])
          this.rootElement.style[prop] = style[prop];
      });
    }
    if (columns !== prevProps.columns) {
      if (columns > 1) {
        if (!prevProps.columns || prevProps.columns <= 1) {
          this.rootElement.setAttribute('class',classnames(
            classes.root,
            classes.columns
          ));
        }
        this.rootElement.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
      } else if (prevProps.columns > 1) {
        this.rootElement.style.gridTemplateColumns = '';
      }
    }
  }

  showSelf() {
    const {anchorEl} = this.props;
    if (anchorEl) {
      this.documentBody.appendChild(this.rootElement);

      let t = anchorEl;
      let left = 0;
      let top = 0;
      while (t) {
        left += t.offsetLeft;
        top += t.offsetTop;
        t = t.offsetParent;
      }

      this.rootElement.style.left = `${left}px`;
      this.rootElement.style.top = `${top}px`;
    }
  }
  hideSelf() {
    this.documentBody.removeChild(this.rootElement);
  }

  render() {
    const {anchorEl} = this.props;
    if (!anchorEl) return null;

    return ReactDOM.createPortal(
      this.props.children,
      this.rootElement
    )
  }
}

export default withStyles(styles)(MenuPopout);

