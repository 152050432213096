import React from 'react';
import QueueAnim from "rc-queue-anim";
import Paper from "@material-ui/core/Paper";

import {UnicodeCSV} from "components/App/Utilities/UnicodeCSV";

export const UtilitiesView = props => (
  <section className="container-fluid chapter">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <article className="article">
          <div>
            <h2 className="article-title">
              Utilities
            </h2>
            <Paper>
              <UnicodeCSV />
            </Paper>
          </div>
        </article>
      </div>
    </QueueAnim>
  </section>
);