import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Switch, Route, Redirect} from 'react-router-dom';

import {ClassificationStandardsListView} from "views/classification/ClassificationStandardsList";

import {selectClassification} from "reducers/classificationsSelect";

export const ClassificationRoutes = ({match: {url, path, params: {classificationId}}}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectClassification(classificationId))
  }, [classificationId]);

  const selectedClassification = useSelector(state => state.classificationsSelect.selected);
  if (!selectedClassification) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/standards`} />
      <Route path={`${path}/standards`} component={ClassificationStandardsListView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
