import React from 'react';
import {connect} from "react-redux";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import IconCheckCircle from '@material-ui/icons/Restore';

import {formatTime} from 'constants/Date';
import {types as Status} from 'constants/ApprovalStatuses';

import PagedTable from '../Base/index';
import reducer from 'reducers/LegacyTables/elementVersions/index';
import {setElementCurrentAndPublishedVersion} from "reducers/elements";

const columnData = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    content: (r) => {
      let color = 'black';
      switch (r.status) {
        case Status.DRAFT: color = '#aaa'; break;
        case Status.AWAITING_REVIEW: color = '#aa0'; break;
        case Status.DISAPPROVED: color = '#800'; break;
        case Status.APPROVED: color = '#0b0'; break;
      }
      return <span style={{color}}>{r.status}</span>;
    }
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Author',
    content: r => r.author,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    content: r => formatTime(r.createdAt),
  }
];

const Actions = props => {
  const onClick = () => {
    const setVersion = props.setElementCurrentVersion(props.elementId, props.selectedContentVersionID);
    if (props.onSetVersion) {
      setVersion.then(props.onSetVersion);
    }
  };

  return (
    <Tooltip key="load" title="Load this version">
      <IconButton onClick={onClick} aria-label="Load">
        <IconCheckCircle/>
      </IconButton>
    </Tooltip>
  );
};
const ConnectedActions = connect(
  state => ({
    elementId: state.elements.selected,
    selectedContentVersionID: reducer.Selectors.getState(state).selected
  }),
  dispatch => ({
    setElementCurrentVersion: (elementId, versionId) => dispatch(setElementCurrentAndPublishedVersion(elementId, versionId))
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    elementId: ownProps.elementId || stateProps.elementId,
  }))(Actions);

export default PagedTable({
  columnData,
  reducer,
  select: 'single',
  actions: props => <ConnectedActions {...props} />
});