import icons from "../icons";
import schema from '../schema';
import {wrapInList, liftListItem} from 'prosemirror-schema-list';
import {selectionInside} from './util';
import {matchTypes, findAncestor} from "../util";
import {asArray} from "constants/util/map";

const replaceList = (nodeType, attrs) => (state, dispatch) => {
  const $list = getSelectedList(state);
  console.log($list);
  if (!$list) return false;
  if (dispatch) dispatch(state.tr.setNodeMarkup($list.pos, nodeType, attrs));
  return true;
};

export const ListTypes = {
  NUMERIC: {
    value: 'decimal',
    title: 'Numeric List',
    icon: icons.list.ordered_numeric,
    replace: replaceList(schema.nodes.ordered_list, {listStyle: 'decimal'}),
    wrap: wrapInList(schema.nodes.ordered_list, {listStyle: 'decimal'}),
  },
  LOWER_ALPHA: {
    value: 'lower-alpha',
    title: 'Alphabetic List (lowercase)',
    icon: icons.list.ordered_lower_alpha,
    replace: replaceList(schema.nodes.ordered_list, {listStyle: 'lower-alpha'}),
    wrap: wrapInList(schema.nodes.ordered_list, {listStyle: 'lower-alpha'}),
  },
  UPPER_ALPHA: {
    value: 'upper-alpha',
    title: 'Alphabetic List (uppercase)',
    icon: icons.list.ordered_upper_alpha,
    replace: replaceList(schema.nodes.ordered_list, {listStyle: 'upper-alpha'}),
    wrap: wrapInList(schema.nodes.ordered_list, {listStyle: 'upper-alpha'}),
  },
  LOWER_ROMAN: {
    value: 'lower-roman',
    title: 'Roman Numerals (lowercase)',
    icon: icons.list.ordered_lower_roman,
    replace: replaceList(schema.nodes.ordered_list, {listStyle: 'lower-roman'}),
    wrap: wrapInList(schema.nodes.ordered_list, {listStyle: 'lower-roman'}),
  },
  UPPER_ROMAN: {
    value: 'upper-roman',
    title: 'Roman Numerals (uppercase)',
    icon: icons.list.ordered_upper_roman,
    replace: replaceList(schema.nodes.ordered_list, {listStyle: 'upper-roman'}),
    wrap: wrapInList(schema.nodes.ordered_list, {listStyle: 'upper-roman'}),
  },
  BULLET: {
    value: 'bullet',
    title: 'Bullet List',
    icon: icons.list.bullet,
    replace: replaceList(schema.nodes.bullet_list, {listStyle: 'bullet'}),
    wrap: wrapInList(schema.nodes.bullet_list, {listStyle: 'bullet'}),
  },
  PLAIN: {
    value: 'plain',
    title: 'Plain List',
    icon: icons.bars,
    replace: replaceList(schema.nodes.bullet_list, {listStyle: 'plain'}),
    wrap: wrapInList(schema.nodes.bullet_list, {listStyle: 'plain'}),
  },
  NONE: {
    value: 'none',
    title: 'Remove List',
    icon: icons.ban
  }
};
const listTypesArray = asArray(ListTypes);

const insideOl = selectionInside(schema.nodes.ordered_list);
const insideUl = selectionInside(schema.nodes.bullet_list);
const lift = liftListItem(schema.nodes.list_item);

export const listOperationEnabled = state => {
  return insideOl(state) || insideUl(state) || ListTypes.NUMERIC.wrap(state);
};
export const listActive = state => {
  return insideOl(state) || insideUl(state);
};

const isOl = matchTypes(schema.nodes.ordered_list);
const isUl = matchTypes(schema.nodes.bullet_list);
const getSelectedList = state => {
  const { $from } = state.selection;
  const $ol = findAncestor($from, isOl);
  const $ul = findAncestor($from, isUl);
  if ($ol && (!$ul || $ol.depth > $ul.depth)) return $ol;
  if ($ul && (!$ol || $ul.depth > $ol.depth)) return $ul;
  return null;
};

export const getListType = state => {
  const $node = getSelectedList(state);
  if ($node && $node.nodeAfter.type === schema.nodes.ordered_list) {
    if ($node.nodeAfter.attrs.listStyle) return $node.nodeAfter.attrs.listStyle;
    return ListTypes.NUMERIC.value;
  }
  if ($node && $node.nodeAfter.type === schema.nodes.bullet_list) {
    if ($node.nodeAfter.attrs.listStyle) return $node.nodeAfter.attrs.listStyle;
    return ListTypes.BULLET.value;
  }
  return null;
};
export const setListType = listTypeName => (state, dispatch) => {
  if (listTypeName === ListTypes.NONE.value) {
    return lift(state, dispatch);
  }
  const listType = listTypesArray.find(t => t.value === listTypeName);
  const list = getSelectedList(state);
  if (list) {
    return listType.replace(state,dispatch);
  } else {
    return listType.wrap(state,dispatch);
  }
};